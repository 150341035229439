<template>
  <IconBase
    icon-name="icPlayL"
    :icon-label="iconLabel"
    :width="60"
    :height="60"
    viewBox="0 0 60 60"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path d="M18.461 9.528v40.495l30.37-20.247Z" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcPlayL',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Play',
    },
  },
};
</script>

<style scoped></style>
