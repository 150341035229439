<template>
  <div class="modal_wrap" :class="{ is_show: showUsdc }">
    <div
      id="InsufficientBalance"
      ref="InsufficientBalance"
      class="modal"
      role="dialog"
      aria-labelledby="InsufficientBalance_title"
      aria-describedby="InsufficientBalance_desc"
    >
      <div class="modal_head">
        <h3 id="InsufficientBalance_title" class="modal_ttl">
          {{ modalData.title }}
        </h3>
      </div>
      <div class="modal_body">
        <div class="img_area">
          <picture>
            <img
              :srcset="`${require('@/assets/img/illust_Insufficient_balance.png')} 4x, ${require('@/assets/img/illust_Insufficient_balance.png')} 3x, ${require('@/assets/img/illust_Insufficient_balance.png')}`"
              :src="require('@/assets/img/illust_Insufficient_balance.png')"
              width="164"
              alt="illust_Insufficient_balance"
            />
          </picture>
        </div>
        <p id="InsufficientBalance_desc" class="desc">
          {{ modalData.desc }}
        </p>
      </div>
      <div class="modal_foot">
        <div class="btn_area">
          <button class="btn w_s h_m strong" @click="openApp">
            {{ modalData.button }}
          </button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';
export default {
  name: 'InsufficientBalance',
  components: { IcCloseM },
  props: {
    modalId: {
      type: String,
      default: 'modal',
    },
    modalSize: {
      type: String,
      default: '',
    },
    modalData: {
      type: Object,
      default: () => ({
        title: 'Insufficient Balance',
        desc: 'Your balance is insufficient. Buy USDC Hedera from exchanges on Wallypto.',
        button: 'Purchase USDC Hedera',
      }),
    },
    showUsdc: {
      type: Boolean,
      default: false,
    },
    associatedSign: {
      typle: String,
      default: '',
    },
  },
  watch: {
    showUsdc(v) {
      if (v === true) {
        this.$root.$emit('setScrollFixed', true);
      } else {
        this.$root.$emit('setScrollFixed', false);
      }
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    close() {
      this.$root.$emit('setScrollFixed', false);
      this.$emit('close', 'showUsdc');
    },
    openApp() {
      if (this.associatedSign) {
        this.onCallApp(this.associatedSign);
      } else {
        this.onCallApp('finance');
      }
      this.close();
    },
  },
};
</script>

<style scoped>
.img_area {
  max-width: 16.4rem;
  margin: 1.5rem auto;
}
.modal .modal_body + .modal_foot {
  margin-top: 2.5rem;
}
</style>
