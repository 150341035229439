<template>
  <main id="contents" class="purchase_page">
    <h1 class="page_ttl is_blind">My List</h1>
    <article class="purchase_sec">
      <div class="set_inner">
        <div class="guide_ui">
          <div class="img_area">
            <picture>
              <source
                media="(max-width: 600px)"
                type="image/png"
                :srcset="`${require('@/assets/img/img_mob_illust_changeaccount_l.png')} 4x, ${require('@/assets/img/img_mob_illust_changeaccount_m.png')} 3x, ${require('@/assets/img/img_mob_illust_changeaccount.png')}`"
              />
              <img
                :src="require('@/assets/img/img_illust_changeaccount.png')"
                alt="img_illust_changeaccount"
              />
            </picture>
          </div>
          <h2 class="ttl">You’ve Changed <br class="dv_mob" />An Account.</h2>
          <p class="desc">You have just changed an account successfully.</p>
          <div class="btn_area">
            <a href="/" class="btn basic w_l h_l">Go Home</a>
            <router-link to="/collections" class="btn strong w_l h_l"
              >Shop Marketplace</router-link
            >
          </div>
        </div>
      </div>
    </article>
  </main>
</template>

<script>
export default {
  name: 'WalletChanged',
  data() {
    return {
      type: 'change',
      isMob: false,
    };
  },
  methods: {
    // 모바일 디바이스 체크 임시
    checkDev() {
      const winW = window.innerWidth;
      this.isMob = winW <= 600 ? true : false;
    },
  },
  mounted() {
    this.checkDev();
    window.addEventListener('resize', this.checkDev);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDev);
  },
};
</script>

<style scoped>
.purchase_page {
}
.purchase_sec {
  display: flex;
  min-height: calc(var(--min) * 1px);
  align-items: center;
}
.purchase_sec .btn_area {
  margin-top: 70px;
}

@media screen and (max-width: 600px) {
  .purchase_sec .btn_area {
    display: inline-flex;
    margin-top: 5rem;
  }
  .purchase_sec .btn_area .btn {
    flex: 1;
    min-width: 0;
    max-width: 18rem;
  }
}
@media screen and (max-width: 450px) {
  .purchase_sec .btn_area {
    display: flex;
    justify-content: center;
  }
  .purchase_sec .btn_area .btn {
    min-width: 0;
  }
}
</style>
