<template>
  <IconBase
    icon-name="icMember"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path data-name="Path 61" d="M25.972 25.279a15.778 15.778 0 0 0-22.28 0" />
    <circle
      data-name="Ellipse 22"
      cx="5.863"
      cy="5.863"
      transform="translate(8.822 4.721)"
      r="5.863"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcMember',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Member',
    },
  },
};
</script>

<style scoped></style>
