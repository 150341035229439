<template>
  <div class="modal_wrap" :class="{ is_show: show }" @click="dimEvt">
    <div class="modal s type_dialog" v-if="alertData">
      <div v-if="alertData.title" class="modal_head">
        <h3 class="ttl">{{ alertData.title }}</h3>
      </div>
      <div class="modal_body">
        <IcWarn
          v-if="
            alertData.type === 'warn' ||
            alertData.type === 'warn_2' ||
            alertData.type === 'warn_3'
          "
        />
        <IcCheckMark v-else-if="alertData.type === 'success'" />
        <IcQuestionMark
          v-else-if="
            alertData.type === 'question' || alertData.type === 'question2'
          "
        />

        <div class="img_area" v-else-if="alertData.type === 'networkError'">
          <picture>
            <source
              media="(max-width: 600px)"
              type="image/png"
              :srcset="`${require('@/assets/img/img_mob_illust_network_l.png')} 4x, ${require('@/assets/img/img_mob_illust_network_m.png')} 3x, ${require('@/assets/img/img_mob_illust_network.png')}`"
            />
            <img
              :src="require('@/assets/img/img_illust_network.png')"
              alt="img_illust_network"
            />
          </picture>
        </div>

        <h3 class="ttl-bigger">{{ alertData.titleDescription }}</h3>

        <p class="txt">{{ alertData.desc }}</p>

        <p class="txt-left" v-if="alertData.type === 'networkError'">
          Sorry, this service is currently unavailable in your country.
        </p>

        <p class="txt-left" v-if="alertData.type === 'networkError'">
          We deeply appreciate your interest. We are working our best to expand
          our service to your country.
        </p>

        <p class="txt-left" v-if="alertData.type === 'networkError'">
          Please stay tuned!
        </p>

        <div class="wrap-addition-alert" v-if="alertData.additional">
          <div
            class="addition-itm"
            v-for="(itm, key) in alertData.additional"
            :key="key"
          >
            <div class="additional-lb">{{ itm.label }}</div>
            <div class="additional-vl">{{ itm.value }}</div>
          </div>
        </div>
      </div>
      <div class="modal_foot">
        <div
          class="btn_area flex"
          v-if="
            alertData.type === 'question' ||
            alertData.type === 'question2' ||
            alertData.type === 'warn_2' ||
            alertData.type === 'warn_3'
          "
        >
          <button type="button" class="btn h_m basic" @click="close">
            Cancel
          </button>
          <button
            v-if="alertData.type === 'question'"
            type="button"
            class="btn h_m strong"
            @click="submit"
          >
            OK
          </button>
          <button
            v-if="alertData.type === 'question2'"
            type="button"
            class="btn h_m strong"
            @click="close2"
          >
            OK
          </button>
          <button
            v-if="alertData.type === 'warn_2'"
            type="button"
            class="btn h_m strong"
            @click="submit"
            :title="popupTitle"
          >
            {{ alertData.success ? alertData.success : 'OK' }}
          </button>
          <button
            v-if="alertData.type === 'warn_3'"
            type="button"
            class="btn h_m strong"
            @click="goMySettings"
          >
            {{ alertData.success ? alertData.success : 'OK' }}
          </button>
        </div>
        <div class="btn_area" v-else>
          <button type="button" class="btn w_m h_m strong" @click="success">
            {{ alertData.success ? alertData.success : 'OK' }}
          </button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcWarn from '@/components/ic/IcWarn';
import IcCloseM from '@/components/ic/IcCloseM';
import IcCheckMark from '@/components/ic/IcCheckMark';
import IcQuestionMark from '@/components/ic/IcQuestionMark';
export default {
  name: 'AlertModal',
  components: { IcQuestionMark, IcCheckMark, IcWarn, IcCloseM },
  props: ['alertData'],
  data() {
    return {
      show: false,
      routeName: '',
    };
  },
  computed: {
    popupTitle() {
      return this.alertData.success == 'Sign in'
        ? 'Open in new Sign in popup'
        : '';
    },
  },
  methods: {
    open() {
      this.$root.$emit('setScrollFixed', true);
      this.show = true;
      this.setFocusTrap(true, '.modal.s.type_dialog');
    },
    close() {
      this.setFocusTrap(false, '.modal.s.type_dialog');
      this.$root.$emit('setScrollFixed', false);
      this.modalProc();
      this.show = false;
    },
    close2() {
      this.setFocusTrap(false, '.modal.s.type_dialog');
      this.$root.$emit('setScrollFixed', false);
      this.modalProc();
      this.show = false;
      this.$root.$emit('QrModalOn', false);
    },
    success() {
      this.$root.$emit('setScrollFixed', false);
      if (this.alertData.id) {
        this.$root.$emit(this.alertData.id, true);
      }
      this.modalProc();
      this.show = false;
    },
    modalProc() {
      if (!this.alertData) return false;
      if (this.alertData.modalClass) {
        this.setFocusTrap(
          true,
          this.alertData.modalClass,
          this.alertData.modalIndex,
        );
      }
      if (this.alertData.focus) {
        setTimeout(() => {
          this.alertData.focus.focus();
        }, 100);
      }
    },
    submit() {
      this.$root.$emit('setScrollFixed', false);
      if (this.alertData.id) {
        this.$root.$emit(this.alertData.id, true);
      } else {
        this.$root.$emit('alertSubmit', true);
      }
    },
    goMySettings() {
      this.$root.$emit('setScrollFixed', false);
      this.show = false;
      this.$router.push({
        name: 'settings',
      });
    },
    dimEvt(e) {
      const t = e.target;
      if (t.closest('.modal')) return;
      // this.close();
    },
  },
};
</script>

<style scoped>
.ttl-bigger {
  margin-top: 10px;
  font-size: 1.6rem;
}

.wrap-addition-alert {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 7px;
}
.addition-itm {
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}
.additional-vl {
  font-weight: bold;
}

.txt-left {
  margin-top: 1.2rem;
  font-size: 1.3rem !important;
  font-weight: 300;
  line-height: 1.375;
  color: #fff;
  white-space: pre-line;
  text-align: left;
}
</style>
