<template>
  <IconBase
    icon-name="icSearch"
    :icon-label="iconLabel"
    :width="width"
    :height="height"
    viewBox="0 0 30 30"
    :icon-color="iconColor"
    :stroke-width="0"
  >
    <path
      d="M20.635 22.483A9.962 9.962 0 0 1 14 25 10 10 0 0 1 4 15 10 10 0 0 1 14 5a10 10 0 0 1 10 10 9.961 9.961 0 0 1-2.517 6.634l4.305 4.305-.848.849ZM5.2 15a8.81 8.81 0 0 0 8.8 8.8 8.81 8.81 0 0 0 8.8-8.8A8.81 8.81 0 0 0 14 6.2 8.81 8.81 0 0 0 5.2 15Z"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcSearch',
  components: { IconBase },
  props: {
    width: {
      type: [Number, String],
      default: 30,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
    iconColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Search',
    },
  },
};
</script>

<style scoped></style>
