<template>
  <main id="contents_marketplace" class="market_page list_page">
    <div class="set_inner">
      <article class="list_sec market_list_sec">
        <header class="sec_head">
          <div class="ttl_area">
            <h1 class="ttl"><b>Marketplace</b></h1>
          </div>
        </header>
        <div class="sec_body">
          <div class="list_wrap">
            <header class="list_head has_elem">
              <p class="count">
                <span class="c_point">{{ !totalCount ? 0 : totalCount }}</span>
                results
              </p>
              <div class="sort_group">
                <div class="input_area select type_hoz drop_ui">
                  <span id="dropLabel" class="label">Sort by</span>
                  <div class="input_box drop_box">
                    <button
                      class="drop_btn has_arw"
                      :class="{ is_active: showSelect }"
                      role="combobox"
                      ref="listbox"
                      aria-haspopup="listbox"
                      aria-controls="dropOpts"
                      aria-labelledby="dropLabel"
                      :aria-activedescendant="'sort_op_' + focusOpt"
                      :aria-expanded="showSelect ? 'true' : 'false'"
                      @click="openDrop('dropOpts', $event)"
                    >
                      {{ selectedOpt.label }}
                      <ArwSelect aria-hidden="true" />
                    </button>
                    <transition name="fade">
                      <ul
                        v-if="showSelect"
                        ref="dropOpts"
                        id="dropOpts"
                        class="drop_op_list"
                        role="listbox"
                      >
                        <li
                          v-for="(opt, i) in sortOptData"
                          :key="'sort_op_' + i"
                          :id="'sort_op_' + i"
                          role="option"
                          :aria-selected="focusOpt === i ? 'true' : 'false'"
                          :class="[
                            { is_focus: focusOpt === i },
                            { is_active: selectedOpt.value === opt.value },
                          ]"
                          @click="selOtp(opt, i)"
                        >
                          <button>{{ opt.label }}</button>
                        </li>
                      </ul>
                    </transition>
                  </div>
                </div>
                <div class="filter_ui filter_frame">
                  <button
                    class="btn type_ic btn_filter"
                    ref="btnFilter"
                    aria-haspopup="true"
                    :aria-expanded="showFilter ? true : false"
                    aria-controls="filterOpts"
                    :aria-label="!showFilter ? 'Open Filter' : 'Close Filter'"
                    @click="openFilter('filterOpts')"
                  >
                    <IcFilter
                      :icon-color="showFilter ? 'var(--main-color)' : '#fff'"
                    />
                  </button>

                  <transition name="fade">
                    <div
                      v-if="showFilter"
                      ref="filterOpts"
                      id="filterOpts"
                      class="filter_opt_group"
                      role="dialog"
                      aria-labelledby="filterTitle"
                      aria-describedby="filterDesc"
                    >
                      <header class="filter_opt_head">
                        <h3 id="filterTitle" class="ttl">Filters</h3>
                      </header>
                      <div id="filterDesc" class="filter_opt_body">
                        <!-- PUB : 필터 블록체인 옵션 추가 S -->
                        <div class="input_area check">
                          <h4 class="label">Blockchains</h4>
                          <div class="input_box">
                            <label>
                              <input
                                v-model="filterBlockchainsType"
                                type="checkbox"
                                name="f_blockchains"
                                value="20"
                                label="ethereum"
                              />
                              <span
                                ><IcEthS
                                  style="width: 2.2rem; height: 2.4rem"
                                />
                                Ethereum</span
                              >
                            </label>
                          </div>
                          <div class="input_box">
                            <label>
                              <input
                                v-model="filterBlockchainsType"
                                type="checkbox"
                                name="f_blockchains"
                                value="10"
                                label="hedera"
                              />
                              <span
                                ><IcHederaS
                                  style="width: 2.2rem; height: 2.4rem"
                                />
                                Hedera</span
                              >
                            </label>
                          </div>
                        </div>
                        <!-- PUB : 필터 블록체인 옵션 추가 E -->

                        <div class="input_area check">
                          <h4 class="label">Status</h4>
                          <div class="input_box">
                            <label>
                              <input
                                v-model="filterStatusType"
                                type="checkbox"
                                name="f_status"
                                value="01"
                                label="status01"
                                ref="onSale"
                              />
                              <span>On Sale</span>
                            </label>
                          </div>
                          <div class="input_box">
                            <label>
                              <input
                                v-model="filterStatusType"
                                type="checkbox"
                                name="f_status"
                                value="02"
                                label="status02"
                              />
                              <span>Not for Sale</span>
                            </label>
                          </div>
                        </div>

                        <div class="input_area txt range_area">
                          <h4 class="label">Price Range</h4>
                          <div class="hoz_group">
                            <div class="input_box">
                              <span>$</span>
                              <input
                                v-model="filterPriceRangeMin"
                                @keyup="checkKey"
                                @input="
                                  checkInput($event, 'filterPriceRangeMin')
                                "
                                @blur="checkBlur('filterPriceRangeMin')"
                                @paste.prevent
                                type="text"
                                step="0.01"
                                title="Min Price"
                                placeholder="Min"
                                label="Min"
                              />
                            </div>
                            <span>~</span>
                            <div class="input_box">
                              <span>$</span>
                              <input
                                v-model="filterPriceRangeMax"
                                @keyup="checkKey"
                                @input="
                                  checkInput($event, 'filterPriceRangeMax')
                                "
                                @blur="checkBlur('filterPriceRangeMax')"
                                @paste.prevent
                                type="text"
                                step="0.01"
                                title="Max Price"
                                placeholder="Max"
                                label="Max"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="input_area check">
                          <h4 class="label">Edition Type</h4>
                          <div class="input_box">
                            <label>
                              <input
                                v-model="filterEditionType"
                                type="checkbox"
                                name="f_edit"
                                value="01"
                                label="edit01"
                              />
                              <span>1 Edition</span>
                            </label>
                          </div>
                          <div class="input_box">
                            <label>
                              <input
                                v-model="filterEditionType"
                                type="checkbox"
                                name="f_edit"
                                value="02"
                                label="edit02"
                              />
                              <span>Multi Edition</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <footer class="filter_opt_foot">
                        <div class="btn_area">
                          <button class="btn basic w_s h_m" @click="reset()">
                            Reset
                          </button>
                          <button
                            class="btn strong w_s h_m"
                            @click="applyFilterOption()"
                          >
                            Apply
                          </button>
                        </div>
                      </footer>
                      <button
                        class="btn type_ic close_filter"
                        aria-label="Close Filter"
                        @click="openFilter()"
                      >
                        <IcCloseM />
                      </button>
                    </div>
                  </transition>
                </div>
              </div>
            </header>
            <div class="list_body">
              <ul
                v-if="itemInfos && itemInfos.length"
                class="grid grid_15 ib edit_list"
                ref="ulRef"
              >
                <li
                  v-for="(item, i) in itemInfos"
                  :key="i"
                  class="col col_3 edit_node"
                >
                  <MarketItem :item-data="item" :item-index="i" />
                </li>
              </ul>
              <div
                v-if="!isLoading && !itemInfos.length"
                class="guide_ui no_data"
              >
                <div class="img_area">
                  <picture>
                    <source
                      media="(max-width: 600px)"
                      type="image/png"
                      :srcset="`${require('@/assets/img/img_mob_illust_nodata_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nodata_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nodata.png')}`"
                    />
                    <img
                      :src="require('@/assets/img/img_illust_nodata.png')"
                      alt="nodata"
                    />
                  </picture>
                </div>
                <p class="desc">
                  No artworks for sale on the Marketplace yet.<br />
                  Great artists from all over the world have created gorgeous
                  artworks for you.<br />
                  Take a look around the Drops menu to buy artworks you like.
                </p>
                <div class="btn_area">
                  <button class="btn strong w_l h_l" @click="goDropsList()">
                    Go to Drops
                  </button>
                </div>
              </div>
              <InfinityScroll @scroll="loadMore" />
            </div>
          </div>
        </div>
      </article>
    </div>
  </main>
</template>

<script>
import IcFilter from '@/components/ic/IcFilter';
import IcCloseM from '@/components/ic/IcCloseM';
import IcEthS from '@/components/ic/IcEthS';
import IcHederaS from '@/components/ic/IcHederaS';
import MarketItem from '@/components/marketplace/MarketItem';
import ArwSelect from '@/components/ic/arwSelect';
import InfinityScroll from '@/components/common/InfinityScroll';
// PUB : 이더리움 및 헤데라 아이콘 컴포넌트 추가
export default {
  name: 'MarketplaceView',
  components: {
    ArwSelect,
    MarketItem,
    IcCloseM,
    IcFilter,
    IcEthS,
    IcHederaS,
    InfinityScroll,
    // PUB : 이더리움 및 헤데라 아이콘 추가
  },
  data() {
    return {
      itemInfos: [],
      filterBlockchainsType: ['10', '20'],
      filterStatusType: ['01', '02'],
      filterEditionType: ['01', '02'],
      filterPriceRangeMin: '',
      filterPriceRangeMax: '',
      sortOptData: [
        {
          label: 'Recently Listed',
          value: '01',
        },
        {
          label: 'Popular',
          value: '02',
        },
        {
          label: 'Highest Price',
          value: '03',
        },
        {
          label: 'Lowest Price',
          value: '04',
        },
      ],
      showSelect: false,
      showFilter: false,
      selectedOpt: {
        label: 'Recently Listed',
        value: '01',
      },
      focusOpt: 0,
      page: 1,
      sort: '01',
      viewCount: 10,
      totalCount: 0,
      isLoading: false,
      rangeOverAlert: {
        type: 'warn',
        desc: 'Please check the min or max price again.',
        id: 'rangeOver',
      },
    };
  },
  mounted() {
    if (this.$route.params.sortType === 'popular') {
      let popular = { label: 'Popular', value: '02' };
      this.selOtp(popular, 1, 'home');
    }
    this.getMarketPlaceItemList();
    this.$EventBus.$on('showFilter', boolean => {
      if (!boolean) this.showFilter = boolean;
    });

    this.filterPriceRangeMax = '';
    this.filterPriceRangeMin = '';
    this.filterBlockchainsType = ['10', '20'];
    this.filterStatusType = ['01', '02'];
    this.filterEditionType = ['01', '02'];
  },
  methods: {
    getMarketPlaceItemList() {
      let query = {
        bcNwTp:
          !this.filterBlockchainsType.length ||
          this.filterBlockchainsType.length === 2
            ? ''
            : this.filterBlockchainsType[0],
        filterEditionType:
          !this.filterEditionType.length || this.filterEditionType.length === 2
            ? ''
            : this.filterEditionType[0],
        filterStatusType:
          !this.filterStatusType.length || this.filterStatusType.length === 2
            ? ''
            : this.filterStatusType[0],
        filterPriceRangeMax: this.filterPriceRangeMax,
        filterPriceRangeMin: this.filterPriceRangeMin,
        page: this.page,
        sortType: this.sort,
      };
      this.isLoading = true;
      this.api.getMarketPlaceItemList(query).then(res => {
        this.isLoading = false;
        if (res.code === 0) {
          this.totalCount = res.totalCount;
          this.itemInfos.push(...res.itemInfos);
          setTimeout(() => {
            this.$refs.listbox.focus();
          }, 500);
        }
      });
    },
    goDropsList() {
      this.$router.push('/drops');
    },
    reset() {
      this.filterPriceRangeMax = '';
      this.filterPriceRangeMin = '';
      this.filterStatusType = ['01', '02'];
      this.filterEditionType = ['01', '02'];
      this.filterBlockchainsType = ['10', '20'];
    },
    openFilter() {
      this.showFilter = !this.showFilter;
      this.showSelect = false;
      if (this.showFilter) {
        this.$EventBus.$emit('showFilter', this.showFilter);
        setTimeout(() => {
          this.$refs.onSale.focus();
        }, 500);
      } else {
        setTimeout(() => {
          this.$refs.btnFilter.focus();
        }, 500);
      }
    },
    openDrop() {
      this.showSelect = !this.showSelect;
      this.showFilter = false;
    },
    selOtp(opt, idx, type) {
      this.showSelect = false;
      this.selectedOpt = opt;
      this.focusOpt = idx;
      if (type === 'home') return false;

      this.page = 1;
      this.viewCount = 10;

      // 정렬기준 1: Offer, 2: PriceHigh, 3: PriceLow
      if (opt.value !== this.sort) {
        this.itemInfos = [];
        this.sort = String(opt.value);
        this.getMarketPlaceItemList();
      }
    },
    loadMore() {
      if (this.viewCount < this.totalCount) {
        this.page++;
        this.viewCount += 10;
        this.getMarketPlaceItemList();
      }
    },
    applyFilterOption() {
      // filterEditionType 01: 1 Edition, 02: Multi Edition
      // filterStatusType 01: On Sale, 02 Not for sale
      if (!this.checkPrice()) return false;

      //min max 범위값 1개만 있다면 검색이 됨
      if (this.filterPriceRangeMin == 0) {
        this.filterPriceRangeMin = '';
      }
      if (this.filterPriceRangeMax == 0) {
        this.filterPriceRangeMax = '';
      }

      this.page = 1;
      this.viewCount = 10;
      this.itemInfos = [];
      this.getMarketPlaceItemList();

      this.openFilter();
    },
    checkBlur(rangePrice) {
      if (!this[rangePrice]) return false;
      var regExp = /^\.|\.$/;
      let value = this[rangePrice];
      if (regExp.test(value)) {
        this[rangePrice] = value.replace('.', '');
      }

      this.checkPrice(rangePrice);
    },
    checkInput(e, v) {
      var value = e.target.value;
      var regExp = /^(1|[1-9]\d*)(?:\.\d{0,2})?$/;
      if (!regExp.test(value)) {
        let result = value.substring(0, value.length - 1);
        this[v] = result;
      }
    },
    checkKey(e) {
      let charCode = e.which || e.keyCode;
      if (!((charCode >= 48 && charCode <= 57) || charCode === 46)) {
        e.preventDefault();
        return false;
      }
    },
    checkPrice(rangePrice) {
      if (rangePrice) {
        let pattern = /(^\d+$)|(^\d{1,}.\d{0,2}$)/;
        let res = pattern.test(this[rangePrice]);

        if (!res && this[rangePrice].includes('.')) {
          let price = this[rangePrice].split('.');
          let priceDecimal = price[1].substr(0, 2);
          let resultPrice = price[0] + '.' + priceDecimal;
          this[rangePrice] = resultPrice;
        }
      }

      let min =
        this.filterPriceRangeMin === ''
          ? 0
          : parseFloat(this.filterPriceRangeMin);
      let max =
        this.filterPriceRangeMax === ''
          ? 0
          : parseFloat(this.filterPriceRangeMax);

      if (!min && !max) {
        return true;
      }
      if (min && max && min >= max) {
        this.showFilter = false;
        this.$root.$emit('openAlert', this.rangeOverAlert);
        this.setEvent();

        return false;
      }
      return true;
    },
    setEvent() {
      this.$root.$off('rangeOver');
      this.$root.$on('rangeOver', Boolean => {
        if (Boolean) {
          setTimeout(() => {
            this.openFilter();
          }, 100);
        }
      });
    },
  },
};
</script>

<style scoped>
@import url(../../assets/css/market.css);
.list_body {
  min-height: 100vh;
}
</style>
