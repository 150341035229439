<template>
  <main id="contents" class="my_collection_page" v-cloak>
    <h1 class="page_ttl is_blind">MY Collections</h1>
    <section class="my_collection_visual_sec" v-if="currentCollection">
      <div
        v-if="
          currentCollection.mimeType === 'image/jpeg' ||
          currentCollection.otherMaplYn == 'Y'
        "
        class="bg_el"
        :style="`background-image: url(${currentCollection.videoThumbInfos[0].resUrl})`"
      ></div>
      <video
        v-if="currentCollection.mimeType === 'video/mp4'"
        :key="currentCollection.priority"
        class="bg_el"
        ref="videoRef"
        id="videoId"
        autoplay
        playsinline
        muted
        @pause="onVideoPaused"
      >
        <source
          v-if="currentCollection.videoInfos"
          :src="currentCollection.videoInfos[0].resUrl + '#t=,15'"
          :type="currentCollection.mimeType"
        />
      </video>
      <div class="set_inner">
        <div class="show_item_info_wrap">
          <div class="show_item">
            <div class="txt_area">
              <h2 class="ttl">
                {{
                  currentCollection.artistName
                    ? currentCollection.artistName
                    : currentCollection.otherPlatform
                }}
              </h2>
              <p class="desc">{{ currentCollection.title }}</p>
              <p class="editions" role="status">
                <span v-if="currentCollection.listType !== 'D'"
                  >{{ currentCollection.editionId }} of</span
                >
                {{ currentCollection.totalCount }}
                {{
                  currentCollection.totalEdition > 1 ? 'Editions' : 'Edition'
                }}
              </p>
            </div>
            <div class="btn_area">
              <p
                v-if="currentCollection.otherMaplYn !== 'Y'"
                class="btn strong h_l w_l view"
                style="cursor: pointer"
                @click="goMarketPlace(currentCollection)"
              >
                View Details
              </p>
              <router-link
                v-if="winW <= 1024"
                to="/collections/mode"
                class="btn type_line_w h_l w_l mode"
              >
                Collection Mode<ArwNextS />
              </router-link>
            </div>
          </div>
        </div>
        <BgSlide
          setTitle="Collections"
          :itemData="ownedCollections"
          :num="ownedCollections.length"
          :currentIndex="currentIndex"
          :dimmable="false"
          @setCurrent="setCurrent"
          @swiperClick="swiperClick"
        />
      </div>
    </section>
    <DetailViewer
      v-if="isShowDetailView"
      :collections.sync="ownedCollections"
      :fixedIndex="fixedIndex"
      :isFullScreen="true"
      :currentIndex="currentCollection.priority"
      :singleVideoLoop="ownedCollections.length == 1"
      @hide="hide"
    />
  </main>
</template>

<script>
import BgSlide from '@/components/mycollection/BgSlide';
import ArwNextS from '@/components/ic/arwNextS';
import DetailViewer from '@/views/DetailViewer.vue';

export default {
  name: 'MyCollectionView',
  components: {
    ArwNextS,
    BgSlide,
    DetailViewer,
  },
  data() {
    return {
      item: [],
      ownedCollections: [],
      currentIndex: 0,
      // currentItem: null,
      winW: 0,
      // isLoad: false,
      index: 0,
      isShowDetailView: false,
      isForcePause: false,
      backgroundPlay: true,
      // imageTime: 5,
      // NOTE: image play time changed 5->15 requested by hyLee(pm)
      imageTime: 15,
      videoTime: 15,
      slidesPerView: 4,
      showImageTimerId: null,
      fixedIndex: 0,
    };
  },
  computed: {
    currentCollection() {
      if (this.showImageTimerId) {
        clearTimeout(this.showImageTimerId);
      }

      let currentCollection = this.ownedCollections[this.currentIndex];
      if (
        currentCollection?.mimeType === 'image/jpeg' ||
        currentCollection?.otherMaplYn == 'Y'
      ) {
        this.showImage();
      }
      return currentCollection;
    },
  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  async mounted() {
    this.ownedCollections = await this.getOwnedCollections();
  },
  methods: {
    async getOwnedCollections() {
      let getResult = await this.api.getMyCollectionList({});
      if (getResult.code !== 0) {
        this.$router.push('/network');
        return [];
      }

      this.item = getResult;

      let ownedCollections = getResult?.myCollectionInfos[0]?.collectionInfos;

      if (!ownedCollections) {
        this.$router.push('/collections/mode');
        return [];
      }

      // NOTE: 23-02-14  expose FHD video(1920 * 1080) at background video - requested by hyLee (PM)
      // TODO : resource List sort method commonization ( like mixins )
      let standardOfPriority = 51;
      ownedCollections = ownedCollections.map((ownedCollection, index) => {
        ownedCollection.priority = index;
        if (
          ownedCollection.videoInfos &&
          ownedCollection.videoInfos.length > 1
        ) {
          ownedCollection.videoInfos.sort((a, b) => {
            if (
              Math.abs(a.rsrcTp - standardOfPriority) >
              Math.abs(b.rsrcTp - standardOfPriority)
            ) {
              return 1;
            } else if (
              Math.abs(a.rsrcTp - standardOfPriority) <
              Math.abs(b.rsrcTp - standardOfPriority)
            ) {
              return -1;
            } else {
              return a.rsrcTp < b.rsrcTp ? 1 : -1;
            }
          });
        }

        return ownedCollection;
      });

      // this.currentItem =
      //   getResult.myCollectionInfos[0].collectionInfos[this.index];
      // if (this.currentItem.mimeType === 'image/jpeg') {
      //   this.isPlayedImage();
      // }
      return ownedCollections;
    },
    goMarketPlace(item) {
      if (!item || item.listType == 'D') {
        return false;
      }

      this.$router.push(
        `/marketplace/detail?artworkId=${item.artworkId}&editionId=${item.editionId}`,
      );
    },
    swiperClick() {
      this.showFullScreen();
    },
    setCurrent(data) {
      this.currentIndex = data.index;

      // this.currentItem = data.item;

      // this.index = data.index;
      // this.fixedIndex = this.index;

      // if (this.currentItem.mimeType === 'image/jpeg') {
      //   this.isPlayedImage();
      // }
      // if (this.currentItem.mimeType === 'video/mp4') {
      //   this.isPlayedVideo();
      // }
    },
    handleResize() {
      this.winW = window.innerWidth;
    },
    async showFullScreen() {
      if (
        this.currentCollection.otherMaplYn == 'Y' &&
        !this.currentCollection.videoInfos &&
        !this.currentCollection.videoFail
      ) {
        this.currentCollection.videoInfos = await this.setOtherMarket(
          this.currentCollection.contractAddr,
          this.currentCollection.tokenId,
        );
      }

      this.isShowDetailView = true;
      this.fixedIndex = this.currentIndex;

      if (
        this.currentCollection?.mimeType === 'image/jpeg' ||
        this.currentCollection.otherMaplYn == 'Y'
      ) {
        clearTimeout(this.showImageTimerId);
      } else if (this.currentCollection?.mimeType === 'video/mp4') {
        this.isForcePause = true;
      }
    },
    hide(data) {
      data;
      if (this.backgroundPlay) {
        if (
          this.currentCollection?.mimeType === 'image/jpeg' ||
          this.currentCollection.otherMaplYn == 'Y'
        ) {
          this.showImage();
        } else if (this.currentCollection?.mimeType === 'video/mp4') {
          this.isForcePause = false;
          this.$refs.videoRef.play();
        }

        // NOTE: Unknown Intent Code
        // this.currentItem =
        //   this.item.myCollectionInfos[0].collectionInfos[data.index];
        // if (this.currentItem.mimeType === 'video/mp4') {
        //   if (this.$refs.videoRef) {
        //     this.$refs.videoRef.play();
        //   } else {
        //     console.log("refs dosen't have videRef");
        //     //NOTE: i can't find your
        //     // const video = document.createElement('video');
        //     // video.src = this.currentItem.videoInfos[0].resUrl;
        //     // video.currentTime = data.currentTime;
        //     // video.play();
        //   }
        // }
      }
      this.isShowDetailView = false;
    },

    showImage() {
      this.showImageTimerId = setTimeout(() => {
        this._showNextCollectionInBackground();
      }, this.imageTime * 1000);
      // if (
      //   this.item.myCollectionInfos[0].collectionInfos.length >
      //   this.index + 1
      // ) {
      //   this.$nextTick(() => {
      //     this.timeOutId = setTimeout(() => {
      //       this.index++;
      //       this.currentItem =
      //         this.item.myCollectionInfos[0].collectionInfos[this.index];
      //       if (this.currentItem.mimeType === 'image/jpeg') {
      //         this.isPlayedImage();
      //       }
      //       if (this.currentItem.mimeType === 'video/mp4') {
      //         this.isPlayedVideo();
      //       }
      //     }, this.imageTime * 1000);
      //   });
      // }
    },

    // NOTE: ORIGIN IMAGE PlAY CODE
    // isPlayedImage() {
    //   if (
    //     this.item.myCollectionInfos[0].collectionInfos.length >
    //     this.index + 1
    //   ) {
    //     this.$nextTick(() => {
    //       this.timeOutId = setTimeout(() => {
    //         this.index++;
    //         this.currentItem =
    //           this.item.myCollectionInfos[0].collectionInfos[this.index];
    //         if (this.currentItem.mimeType === 'image/jpeg') {
    //           this.isPlayedImage();
    //         }
    //         if (this.currentItem.mimeType === 'video/mp4') {
    //           this.isPlayedVideo();
    //         }
    //       }, this.imageTime * 1000);
    //     });
    //   }
    // },

    // isPlayedVideo() {
    //   if (
    //     this.item.myCollectionInfos[0].collectionInfos.length >
    //     this.index + 1
    //   ) {
    //     this.$nextTick(() => {
    //       const vue = this;
    //       if (this.$refs.videoRef) {
    //         this.$refs.videoRef.onloadedmetadata = function () {
    //           if (this.duration > vue.videoTime) {
    //             // vue.timeOutId = setTimeout(() => {
    //             //   if (
    //             //     vue.item.myCollectionInfos[0].collectionInfos.length >
    //             //     vue.index + 1
    //             //   ) {
    //             //     vue.index++;
    //             //     vue.currentItem =
    //             //       vue.item.myCollectionInfos[0].collectionInfos[vue.index];
    //             //     if (vue.currentItem.mimeType === 'image/jpeg') {
    //             //       vue.isPlayedImage();
    //             //     }
    //             //     if (vue.currentItem.mimeType === 'video/mp4') {
    //             //       vue.isPlayedVideo();
    //             //     }
    //             //   }
    //             // }, vue.videoTime * 1000);
    //           }
    //         };
    //       }
    //     });
    //   }
    // },
    onVideoPaused() {
      //02-16 video Ended vue ended로 변경
      //마지막 시점 기획 정의가 되지 않아 loop 처리는 하지 않음
      if (this.$refs?.videoRef?.currentTime && !this.isForcePause) {
        this._showNextCollectionInBackground();
      }
    },

    _showNextCollectionInBackground() {
      if (this.ownedCollections.length == 1) {
        this.$refs.videoRef.currentTime = 0;
        this.$refs.videoRef.play();
      } else {
        this.ownedCollections.length - 1 > this.currentIndex
          ? this.currentIndex++
          : (this.currentIndex = 0);
      }
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/mycollection.css';
</style>
