<template>
  <IconBase
    icon-name="icLike"
    :icon-label="status ? 'Not Selected, Like' : 'Selected, Like'"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    :icon-color="iconColor"
    :stroke-width="0"
  >
    <path
      d="M24.8 6.602c-2.5-2.5-6.5-2.5-8.9 0l-.9.9-.8-.8c-2.5-2.5-6.5-2.5-8.9 0s-2.5 6.5 0 8.9l9.8 9.7 9.8-9.7c2.4-2.5 2.4-6.5-.1-9z"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';
import { mapGetters } from 'vuex';

export default {
  name: 'IcLike',
  data() {
    return {
      loginAlert: {
        type: 'warn_2',
        desc: 'Please sign in.',
        success: 'Sign in',
      },
    };
  },
  components: { IconBase },
  computed: {
    ...mapGetters({
      accessToken: 'appStore/accessToken',
    }),
  },
  props: {
    iconColor: {
      type: String,
      default: '#999999',
    },
    iconLabel: {
      type: String,
      default: 'Like',
    },
    artworkId: {
      type: String,
      default: '',
    },
    favoritesYn: {
      type: String,
      default: 'N',
    },
    status: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    loginCheck(divLike) {
      if (!this.accessToken) {
        this.loginAlert.focus = divLike;
        this.$root.$emit('openAlert', this.loginAlert);
      }
      return this.accessToken ? true : false;
    },
    likeCheck(id) {
      const checkIdx = this.likedList.indexOf(id);
      if (checkIdx !== -1) {
        this.likedList.splice(checkIdx, 1);
        return 'N';
      } else {
        this.likedList.push(id);
        return 'Y';
      }
    },

    // ---------------------------------------------//
    // Artwork 좋아요 처리//
    // ---------------------------------------------//
    async likeArtwork(params) {
      if (!this.loginCheck()) return;

      await this.api.setArtworkFavorites(params).then(res => {
        if (res.code === 0) {
          console.log(
            `ArtworkLike ${params.artworkId} to ${params.favoritesYn}`,
          );

          console.log(params);
          console.log(res);
        }
      });
    },

    // ---------------------------------------------//
    // Edition 좋아요 처리//
    // ---------------------------------------------//

    async likeEdition(params) {
      await this.api.setEditionFavorites(params).then(res => {
        if (res.code === 0) {
          console.log(
            `EditionLike ${params.editionId}to ${params.favoritesYn}`,
          );

          console.log(params);
          console.log(res);
        }
      });
    },

    // TODO:0725 : setDropsFavorites 안쓰니까 쓰는데 있는지 파악해볼것

    // ---------------------------------------------//
    // Drops Detail 화면에서      Artwork 좋아요 처리//
    // ---------------------------------------------//

    // LiveDrops는 특정 edition에 도달하여 좋아요처리하지 않고
    // ArtworkID를 넘겨 좋아요처리함.
    async setLiveDropsFavorites(id, likeStatus) {
      if (!this.loginCheck()) return;

      let favoritesYn = likeStatus === true ? 'N' : 'Y';

      let params = {
        artworkId: id,
        favoritesYn,
      };
      this.likeArtwork(params);
    },

    // ---------------------------------------------//
    // Ended Drops 리스트에서 Ended Drops 좋아요 처리//
    // ---------------------------------------------//

    async setEndedDropsFavorites(id, likeStatus) {
      if (!this.loginCheck()) return;

      let favoritesYn = likeStatus === true ? 'N' : 'Y';

      let params = {
        dropId: id,
        favoritesYn,
      };

      await this.api.getLiveDropDetail(params).then(res => {
        // 이 Drop에 속한 모든 Artwork들에 대해서도 좋아요 처리를 해줘야 하는데
        // 이 Drop 에 어떤 Artwork들이 있는지 정보를 모르기 때문에
        // DropDetail을 호출해서 각각의 artwork 정보를 가져오는 처리가 필요함.

        if (res.code === 0) {
          res.dropDetailInfos.forEach(x => {
            if (x.editionId === 0) {
              console.log(
                `${x.artworkId}는 멀티에디션 : editionId : ${x.editionId}`,
              );
              let newParams = {
                artworkId: x.artworkId,
                favoritesYn: params.favoritesYn,
              };
              // Artwork가 멀티에디션일 경우 :
              // likeArtwork() 함수를 통해
              // editionId = 0 인 멀티에디션만 좋아요 처리 (api : setArtworkFavorites)
              // 멀티에디션 하위에 있는 에디션들은 좋아요 안함.
              this.likeArtwork(newParams);
            } else {
              console.log(
                `${x.artworkId}는 싱글에디션 : editionId : ${x.editionId}`,
              );
              // Artwork가 싱글에디션일 경우 :
              // likeEdition() 함수를 통해
              // 해당 Artwork에 물려있는 하나의 에디션까지 도달해서 좋아요 처리 (api : setEditionFavorites)
              let newParams = {
                artworkId: x.artworkId,
                editionId: x.editionId,
                favoritesYn: params.favoritesYn,
              };
              this.likeEdition(newParams);
              // 그리고 Ended drops 화면에서도 좋아요 표시가 연동되어야 하기떄문에 likeArtwork()도 호출
              this.likeArtwork(newParams);
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped></style>
