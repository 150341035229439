<template>
  <main id="contents" class="viewer_page">
    <h1 class="page_ttl is_blind">Player</h1>
    <article
      class="viewer_sec"
      :class="{ is_control: controlMode }"
      @click="changeMode"
      ref="viewer"
    >
      <div class="viewer_wrap">
        <transition name="fade">
          <div v-if="controlMode" class="ttl_area">
            <!-- PUB : 로고 영역 추가 -->
            <div
              class="logo"
              v-if="otherMarket"
              :style="`background-image: url(${collections[index].otherMaplLogoUrl})`"
            >
              <h2 class="ttl">
                {{ collections[index].otherPlatform }}
              </h2>
            </div>
            <div v-if="this.dropInfo">
              <h2 class="ttl">{{ dropInfo.artistName }}</h2>
              <p class="desc">{{ dropInfo.title }}</p>
            </div>
            <div v-if="!this.dropInfo">
              <h2 class="ttl">{{ collections[index].title }}</h2>
              <p class="desc">{{ collections[index].artistName }}</p>
              <p class="desc">{{ collections[index].description }}</p>
            </div>
            <div v-if="collections[index].listType !== 'D' && !this.dropInfo">
              <button
                class="btn type_line_w w_l h_l"
                @click="handleClick"
                v-if="collections[index].otherMaplYn !== 'Y'"
              >
                <span>View Details</span>
              </button>
            </div>
          </div>
        </transition>
        <div class="view_area">
          <video
            :key="index"
            :loop="singleVideoLoop"
            ref="videoRef"
            id="videoId"
            autoplay
            playsinline
            v-if="
              collections[index].mimeType === 'video/mp4' &&
              collections[index].videoInfos
            "
          >
            <source
              :src="collections[index].videoInfos[0].resUrl"
              :type="collections[index].videoInfos[0].mimeType"
              @error="videoError"
            />
          </video>
          <img
            :key="collections[index].editionId"
            v-if="
              collections[index].mimeType === 'image/jpeg' ||
              (collections[index].otherMaplYn == 'Y' &&
                !collections[index].videoInfos)
            "
            :srcset="`${collections[index].imageInfos[2].resUrl} 598w, 
                      ${collections[index].imageInfos[1].resUrl} 1920w,
                      ${collections[index].imageInfos[0].resUrl} 1920w`"
            size="(max-width: 598px) 592px, 1920px"
            :alt="collections[index].title ? collections[index].title : ''"
          />
        </div>
      </div>
      <transition name="fade">
        <div v-if="controlMode" class="controller_wrap">
          <div class="progress_area">
            <progress
              :max="playTime"
              :value="currentTime"
              @click="moveProgress"
            ></progress>
            <!-- <div class="progress">
              <div class="progress-fill" />
            </div> -->
          </div>
          <div class="btn_group">
            <div class="btn_area play_area">
              <button
                ref="prev"
                class="btn type_ic control_player btn_prev"
                aria-label="Play previous file"
                @click="prev"
              >
                <IcPrevFile />
              </button>
              <button
                class="btn type_ic btn_play"
                aria-label="Play"
                @click="play"
                v-if="!isPlay"
              >
                <IcPlayM class="ic_play" />
              </button>
              <button
                class="btn type_ic btn_play"
                aria-label="Pause"
                @click="pause"
                v-else
              >
                <IcPauseM />
              </button>
              <button
                class="btn type_ic control_player btn_next"
                aria-label="Play next file"
                @click="next"
              >
                <IcNextFile />
              </button>
            </div>
            <div class="btn_area state_area">
              <!-- Dev: 220503 추가 - 다운로드 버튼 추가 -->
              <!-- 
              <a
                :href="
                  collections[index].mimeType === 'image/jpeg'
                    ? collections[index].imageInfos[0].resUrl
                    : collections[index].videoInfos[0].resUrl
                "
                target="_blank"
                download
              > -->
              <button
                v-if="false"
                class="btn type_ic btn_download"
                aria-label="download"
                @click="download"
              >
                <IcDownloadL />
              </button>
              <!-- 
              </a> -->
              <button
                class="btn type_ic open_playlist"
                aria-label="Open Playlist"
                @click="onPlayList(true)"
              >
                <IcItems />
                <span style="display: inline-block; width: 61px"
                  >{{ index + 1 }} / {{ collections.length }}</span
                >
              </button>
              <button
                class="btn type_ic btn_zoom"
                aria-label="Zoom in"
                @click="zoomIn"
                v-if="!fullscreen"
              >
                <IcZoomIn />
              </button>
              <button
                class="btn type_ic btn_zoom"
                aria-label="Zoom out"
                @click="zoomOut"
                v-else
              >
                <IcZoomOut />
              </button>
            </div>
          </div>
        </div>
      </transition>
      <button v-if="controlMode" class="btn type_ic close_btn" @click="exit">
        <IcCloseM stroke-color="#fff" />
      </button>
    </article>
    <aside v-if="showPlaylist" class="playlist_sec">
      <p class="count">
        <span class="c_point">{{ collections.length }}</span> play list
      </p>
      <PlaylistSlide
        :current="index"
        :item-data="collections"
        @changeCurrent="changeCurrent"
      />
      <button class="btn type_ic close_btn" @click="onPlayList(false)">
        <IcCloseM stroke-color="#fff" />
      </button>
    </aside>
    <!-- <div class="loader" v-if="isLoading">Loading...</div> -->
  </main>
</template>

<script>
import IcPrevFile from '@/components/ic/IcPrevFile';
import IcPlayM from '@/components/ic/IcPlayM';
import IcPauseM from '@/components/ic/IcPauseM';
import IcNextFile from '@/components/ic/IcNextFile';
import IcItems from '@/components/ic/IcItems';
import IcZoomIn from '@/components/ic/IcZoomIn';
import IcZoomOut from '@/components/ic/IcZoomOut';
import PlaylistSlide from '@/components/common/PlaylistSlide';
import IcCloseM from '@/components/ic/IcCloseM';
import IcDownloadL from '@/components/ic/IcDownloadL';
import { mapGetters } from 'vuex';

export default {
  name: 'DetailViewer',
  components: {
    IcDownloadL,
    IcCloseM,
    PlaylistSlide,
    IcZoomIn,
    IcZoomOut,
    IcItems,
    IcNextFile,
    IcPauseM,
    IcPlayM,
    IcPrevFile,
  },
  props: [
    'isFullScreen',
    'collections',
    'currentIndex',
    'fixedIndex',
    'dropInfo',
    'singleVideoLoop',
  ],
  data() {
    return {
      currentFile: 0,
      controlMode: true,
      controlModeId: null,
      showPlaylist: false,
      playTime: 0,
      currentTime: 0,
      prevCurrentTime: 0,
      imageTime: 30,
      index: this.currentIndex,
      fullscreen: this.isFullScreen,
      timeOutId: null,
      intervalId: null,
      isViewerEnd: false,
      isPlay: true,
      isLoading: false,
      keydownCallback: null,

      downloadFailAlert: {
        type: 'warn',
        desc: 'Sorry, Something is wrong.',
      },
    };
  },
  watch: {
    controlMode(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.prev.focus();
        }, 100);
      }
    },
  },
  computed: {
    otherMarket() {
      return this.collections[this.index].otherMaplYn == 'Y' ? true : false;
    },
    ...mapGetters({
      email: 'userStore/email',
    }),
  },

  methods: {
    changeMode(e) {
      const t = e.target;
      if (t.closest('button, a')) return;
      this.controlMode = !this.controlMode;
      if (this.controlModeId) clearTimeout(this.controlModeId);
    },
    async changeCurrent(idx) {
      this.index = idx;
      clearTimeout(this.timeOutId);

      await this.loadViewer();
      this.currentTime = 0;
      if (!this.isPlay) {
        this.isPlay = true;
        await this.handleProgress();
      }
    },
    async setOtherMarketUrl() {
      if (
        this.collections[this.index].otherMaplYn == 'Y' &&
        !this.collections[this.index].videoInfos &&
        !this.collections[this.index].videoFail
      ) {
        let tmpCollections = this.collections;
        tmpCollections[this.index].videoInfos = await this.setOtherMarket(
          this.collections[this.index].contractAddr,
          this.collections[this.index].tokenId,
        );
        this.$emit('update:collections', tmpCollections);
      }
    },
    zoomIn() {
      const contents = document.querySelector('#contents');
      if (contents.webkitEnterFullscreen) {
        contents.webkitEnterFullscreen();
      } else if (contents.webkitRequestFullscreen) {
        contents.webkitRequestFullscreen();
      } else if (contents.requestFullscreen) {
        contents.requestFullscreen();
      }
      this.fullscreen = true;
      document.body.style.touchAction = 'none';
      document.body.style.overflow = 'hidden';
    },
    zoomOut() {
      this.fullscreen = false;
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
        if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    exit() {
      document.removeEventListener('keydown', this.keydownCallback);
      if (document.fullscreenElement) {
        document.exitFullscreen();
        document.webkitExitFullscreen();
      }
      const data = {
        index: this.fixedIndex,
      };
      if (
        this.collections[this.index].mimeType === 'video/mp4' &&
        this.collections[this.index].videoInfos
      ) {
        data.currentTime = this.$refs.videoRef.currentTime;
      }
      this.$emit('hide', data);
    },
    async prev() {
      // TEST: for infinity play test code

      // if (this.index > 0) {
      this.currentTime = 0;
      if (
        this.collections[this.index].mimeType === 'image/jpeg' ||
        !this.collections[this.index].videoInfos
      ) {
        clearTimeout(this.timeOutId);
      }
      if (this.index == 0) {
        this.index = this.collections.length - 1;
      } else {
        this.index--;
      }
      await this.loadViewer();
      if (!this.isPlay) {
        this.isPlay = true;
        await this.handleProgress();
      }
      // }
    },
    async next() {
      // TEST: for infinity play test code

      // if (this.collections.length - 1 > this.index) {
      this.currentTime = 0;
      if (
        this.collections[this.index].mimeType === 'image/jpeg' ||
        !this.collections[this.index].videoInfos
      ) {
        clearTimeout(this.timeOutId);
      }
      if (this.index == this.collections.length - 1) {
        this.index = 0;
      } else {
        this.index++;
      }

      await this.loadViewer();
      if (!this.isPlay) {
        this.isPlay = true;
        await this.handleProgress();
      }
      // }
    },
    play() {
      // TEST: for infinity play test code

      // if (this.isViewerEnd) {
      //   this.index = 0;
      //   this.currentTime = 0;
      //   this.loadViewer();
      //   this.isViewerEnd = false;
      // }
      if (
        this.collections[this.index].mimeType === 'video/mp4' &&
        this.collections[this.index].videoInfos
      ) {
        if (this.$refs.videoRef.paused) this.$refs.videoRef.play();
      }
      this.handleProgress();
      this.isPlay = true;
    },
    pause() {
      if (
        this.collections[this.index].mimeType === 'video/mp4' &&
        this.collections[this.index].videoInfos
      ) {
        this.$refs.videoRef.pause();
      } else if (this.collections[this.index].mimeType === 'image/jpeg') {
        clearTimeout(this.timeOutId);
      }
      this.isPlay = false;
      if (this.intervalId) clearInterval(this.intervalId);
    },
    async loadImage() {
      if (this.collections.length - 1 >= this.index) {
        this.currentTime = 0;
        this.playTime = this.imageTime;
        this.timeOutId = setTimeout(() => {
          this.currentTime = this.imageTime;
          // TEST: for infinity play test code
          if (this.index == this.collections.length - 1) {
            this.index = 0;
          } else {
            this.index++;
          }
          this.loadViewer();

          // if (this.collections.length - 1 > this.index) {
          //   this.index++;
          //   this.loadViewer();
          // } else {
          //   this.isPlay = false;
          //   // this.isViewerEnd = true;
          //   clearInterval(this.intervalId);
          // }
        }, this.imageTime * 1000);
        this.isPlay = true;
      } else {
        this.isPlay = false;
        // this.isViewerEnd = true;
        clearInterval(this.intervalId);
      }
    },
    loadVideo() {
      if (this.collections.length - 1 >= this.index) {
        this.currentTime = 0;
        let vue = this;
        this.$nextTick(() => {
          try {
            this.$refs.videoRef.onloadedmetadata = function () {
              vue.playTime = this.duration;
            };
            this.$refs.videoRef.addEventListener('onprogress', () => {
              vue.prevCurrentTime = vue.currentTime;
              vue.currentTime = this.currentTime;
            });
            this.$refs.videoRef.addEventListener('ended', () => {
              this.currentTime = this.$refs.videoRef.currentTime;
              // TEST: for infinity play test code
              if (this.index == this.collections.length - 1) {
                this.index = 0;
              } else {
                this.index++;
              }
              this.loadViewer();
            });
          } catch (e) {
            console.log(e);
          }
        });
      } else {
        this.isPlay = false;
        // this.isViewerEnd = true;
        clearInterval(this.intervalId);
      }
    },
    videoError() {
      if (this.collections[this.index].otherMaplYn == 'Y') {
        let tmpCollections = this.collections;
        tmpCollections[this.index].videoInfos = null;
        tmpCollections[this.index].videoFail = true;
        this.$emit('update:collections', tmpCollections);
        this.loadViewer();
      }
    },
    async loadViewer() {
      await this.setOtherMarketUrl();
      if (
        this.collections[this.index].mimeType === 'video/mp4' &&
        this.collections[this.index].videoInfos &&
        !this.collections[this.index].videoFail
      ) {
        await this.loadVideo();
      } else {
        await this.loadImage();
      }
    },
    download() {
      const data = {
        artworkId: this.collections[this.index].artworkId,
        editionId: this.collections[this.index].editionId,
      };
      const type = this.collections[this.index].mimeType;
      const title = this.collections[this.index].title;
      this.axios({
        method: 'post',
        url: '/api/v1/download/file',
        responseType: 'blob',
        data: data,
      }).then(res => {
        if (res.status === 200) {
          const blob = new Blob([res.data], { type: type });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${title}.${type === 'image/jpeg' ? 'jpeg' : 'mp4'}`;
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } else {
          this.$root.$emit('openAlert', this.downloadFailAlert);
        }
      });
    },
    moveProgress(event) {
      let posX =
        Math.floor(
          ((event.pageX - event.target.offsetLeft) * 100) /
            event.currentTarget.offsetWidth,
        ) * 0.01;
      // if (this.isViewerEnd) this.isViewerEnd = false;
      if (
        this.collections[this.index].mimeType === 'video/mp4' &&
        this.collections[this.index].videoInfos
      ) {
        this.$refs.videoRef.currentTime = Math.floor(this.playTime * posX);
        this.currentTime = Math.floor(this.playTime * posX);
      } else if (this.collections[this.index].mimeType === 'image/jpeg') {
        clearTimeout(this.timeOutId);
        this.currentTime = Math.floor(this.imageTime * posX);
        this.timeoutId = setTimeout(() => {
          if (this.collections.length - 1 > this.index) {
            this.index++;
            this.loadViewer();
          } else {
            this.isPlay = false;
            // this.isViewerEnd = true;
            clearInterval(this.intervalId);
          }
        }, (this.imageTime - this.currentTime) * 1000);
      }
    },
    handleClick() {
      this.$router.push(
        `/marketplace/detail?artworkId=${
          this.collections[this.index].artworkId
        }&editionId=${this.collections[this.index].editionId}`,
      );
    },
    handleProgress() {
      this.intervalId = setInterval(() => {
        this.prevCurrentTime = this.currentTime;
        if (
          this.collections[this.index].mimeType === 'video/mp4' &&
          this.collections[this.index].videoInfos
        ) {
          if (!this.collections[this.index].videoInfos) return false;
          this.currentTime = Math.floor(this.$refs.videoRef.currentTime);
        } else {
          this.currentTime = this.currentTime + 1;
        }

        if (this.prevCurrentTime === this.currentTime) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
      }, 1000);
    },
    onPlayList(boolean) {
      this.showPlaylist = boolean;
    },
    focusTrap() {
      this.keydownCallback = event => {
        if (event.key === 'Tab') this.changeMode(event);
        let viewerEls = document.querySelector('.viewer_page');
        let focusEls = viewerEls.querySelectorAll(
          'a[href], area[href], input:not([disabled]), select:not([disabled]),ul, li, textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]',
        );
        let firstEl = focusEls[0];
        let lastEl = focusEls[focusEls.length - 1];
        if (event.key === 'Enter') return;
        if (event.key === 'Escape') {
          if (this.controlMode) this.$refs.prev.focus();
          this.controlMode = false;
        } else if (document.activeElement === lastEl && !event.shiftKey) {
          firstEl.focus();
          event.preventDefault();
        } else if (document.activeElement === firstEl && event.shiftKey) {
          lastEl.focus();
          event.preventDefault();
        }
      };
      document.addEventListener('keydown', this.keydownCallback);
    },
  },
  async mounted() {
    if (this.isFullScreen) {
      const contents = document.querySelector('#contents');
      if (contents.webkitEnterFullscreen) {
        await contents.webkitEnterFullscreen();
      } else if (contents.webkitRequestFullscreen) {
        await contents.webkitRequestFullscreen();
      } else if (contents.requestFullscreen) {
        await contents.requestFullscreen();
      }
    }
    this.loadViewer();
    this.handleProgress();

    // Viewer 렌더링시엔 스크롤 숨김 처리 - 2022/07/27 윤정현
    document.body.style.touchAction = 'none';
    document.body.style.overflow = 'hidden';

    this.focusTrap();
    if (this.controlMode) this.$refs.prev.focus();

    this.controlModeId = setTimeout(() => {
      this.controlMode = false;
    }, 3000);
  },
  beforeDestroy() {
    document.body.style.touchAction = 'auto';
    document.body.style.overflow = 'auto';
    clearTimeout(this.timeOutId);
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
#contents {
  z-index: 400;
}
/* .loader {
  margin: auto;
  font-size: 15px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  bottom: 50%;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
} */

.viewer_page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.viewer_sec,
.viewer_sec .viewer_wrap,
.viewer_sec .view_area {
  height: 100%;
  background-color: #000;
}
.viewer_sec .ttl_area,
.viewer_sec .controller_wrap {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 50;
}
.viewer_sec .ttl_area:before,
.viewer_sec .controller_wrap:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  height: 30rem;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  opacity: 0.73;
}
.viewer_sec .ttl_area {
  top: 0;
  padding: 4rem 8rem;
  font-weight: 500;
}
.viewer_sec .ttl_area:before {
  top: 0;
  transform: rotate(180deg);
}
.viewer_sec .ttl_area .ttl {
  font-size: 4rem;
  margin-bottom: 0.8rem;
}
.viewer_sec .ttl_area .desc {
  font-size: 1.8rem;
  color: #fff;
}
.viewer_sec .ttl_area .btn {
  margin-top: 2.7rem;
}
.viewer_sec .ttl_area .logo {
  width: 5rem;
  height: 5rem;
  margin-bottom: 0.8rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  /* background: #ff521d url(); */
  /* background: #ff521d; */
  -webkit-background-size: cover;
  background-size: cover;
}
.logo .ttl {
  margin-left: 6.5rem;
}
.view_area video,
.view_area img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.controller_wrap > div {
  float: none;
}
.viewer_sec .controller_wrap {
  bottom: 0;
  padding: 5rem 8rem;
}
.viewer_sec .controller_wrap:before {
  bottom: 0;
}
.controller_wrap .btn_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.playlist_sec {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 5.8rem 8rem 5rem;
  background: rgba(0, 0, 0, 0.7);
}
.playlist_sec > .btn {
  position: absolute;
  right: 2rem;
  top: 2rem;
}
.playlist_sec .count {
  margin-bottom: 1.7rem;
  font-size: 1.6rem;
  color: var(--txt-gray);
  font-weight: 300;
}
.viewer_sec > .btn {
  /* display: none; */
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 100;
}
@media screen and (max-width: 320px) {
  .viewer_sec > .btn {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .viewer_sec > .btn {
    display: block;
  }
  .viewer_sec .ttl_area {
    padding: 2rem;
  }
  .viewer_sec .ttl_area .ttl {
    font-size: 2rem;
  }
  .viewer_sec .ttl_area .desc {
    margin-top: 0.7rem;
    font-size: 1.4rem;
    font-weight: 300;
  }
  .viewer_sec .controller_wrap {
    padding: 2rem;
  }
  .controller_wrap .progress_group,
  .controller_wrap .progress_area {
    margin-bottom: 2rem;
  }
  .viewer_sec .ttl_area .btn {
    min-width: 12rem;
    margin-top: 2.6rem;
    font-size: 1.4rem;
  }
  .controller_wrap .btn_group .btn_zoom {
    display: none;
  }
  .playlist_sec {
    display: flex;
    width: 100%;
    margin: 10px 0;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    top: 0;
    padding: 3rem 2rem;
    overflow: scroll;
  }
  .viewer_sec .ttl_area:before,
  .viewer_sec .controller_wrap:before {
    height: 120%;
    opacity: 0.55;
  }
  .controller_wrap .btn_group .open_playlist {
    margin-right: 1rem;
    margin-left: 1rem; /* Dev: 220503 추가 */
  }
  .viewer_sec .ttl_area .logo {
    width: 3.8rem;
    height: 3.8rem;
  }
}
@media screen and (max-width: 1000px) and (max-height: 600px) and (orientation: landscape) {
  .viewer_sec > .btn {
    display: block;
  }
  .viewer_sec .ttl_area {
    padding: 2rem;
  }
  .viewer_sec .ttl_area .ttl {
    font-size: 2rem;
  }
  .viewer_sec .ttl_area .desc {
    margin-top: 0.7rem;
    font-size: 1.4rem;
    font-weight: 300;
  }
  .viewer_sec .controller_wrap {
    padding: 2rem;
  }
  .controller_wrap .progress_group,
  .controller_wrap .progress_area {
    margin-bottom: 2rem;
  }
  .viewer_sec .ttl_area .btn {
    min-width: 12rem;
    margin-top: 2.6rem;
    font-size: 1.4rem;
  }
  .controller_wrap .btn_group .btn_zoom {
    display: none;
  }
  .playlist_sec {
    display: flex;
    width: 100%;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    top: 0;
    padding: 3rem 2rem;
  }
  .viewer_sec .ttl_area:before,
  .viewer_sec .controller_wrap:before {
    height: 120%;
    opacity: 0.55;
  }
  .controller_wrap .btn_group .open_playlist {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
</style>
