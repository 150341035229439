<template>
  <main id="contents" class="search_page">
    <div class="set_inner">
      <section class="result_sec">
        <header class="sec_head">
          <div class="ttl_area">
            <h2 class="ttl">
              {{ totalSearchCount }} Results For “<b class="c_point">{{
                resultKeyword
              }}</b
              >”
            </h2>
          </div>
          <div class="tab_wrap">
            <div class="tab_list type_stack" role="tablist">
              <template>
                <div
                  v-for="tab in searchTabData"
                  :key="tab.id"
                  class="tab_node"
                  role="presentation"
                >
                  <button
                    :id="'tab_' + tab.id"
                    class="tab_item"
                    role="tab"
                    :class="{ is_active: currentTab === tab.id }"
                    @click="setTab(tab.id)"
                    :aria-controls="tab.id + '_panel'"
                    :aria-selected="tab.id === currentTab ? true : false"
                  >
                    <span
                      >{{ tab.name }}
                      <em class="count" v-if="tab.id === 'artworks'">{{
                        totalArtworkCount
                      }}</em>
                      <em class="count" v-else-if="tab.id === 'artists'">{{
                        totalArtistCount
                      }}</em>
                      <em class="count" v-else>{{ totalOtherProfileCount }}</em>
                    </span>
                  </button>
                </div>
              </template>
            </div>
          </div>
        </header>

        <div
          :id="currentTab + '_panel'"
          class="sec_body tab_panel"
          role="tabpanel"
          :aria-labelledby="'tab_' + currentTab"
        >
          <template v-if="currentTab === 'artworks'">
            <div class="list_wrap works_list_wrap">
              <ul
                v-if="totalArtworkCount > 0"
                class="grid grid_15 ib edit_list works_list"
              >
                <li
                  v-for="item in artworkInfos"
                  :key="item.id"
                  class="col col_3 edit_node"
                >
                  <!-- <p>{{ item }}</p> -->
                  <WorkItem :item-data="item" />
                </li>
              </ul>
              <div v-else class="guide_ui no_data">
                <div class="img_area">
                  <picture>
                    <source
                      media="(max-width: 600px)"
                      type="image/png"
                      :srcset="`${require('@/assets/img/img_mob_illust_nosearch_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nosearch_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nosearch.png')}`"
                    />
                    <img
                      :src="require('@/assets/img/img_illust_nosearch.png')"
                      alt="No Result"
                    />
                  </picture>
                </div>
                <p class="desc">
                  We couldn’t find any match for “<span class="c_point">{{
                    resultKeyword
                  }}</span
                  >”.<br />
                  Try another keyword.
                </p>
              </div>
            </div>
          </template>
          <template v-if="currentTab === 'artists'">
            <div class="list_wrap user_list_wrap">
              <ul
                v-if="totalArtistCount > 0"
                class="grid grid_15 ib user_list artist_list"
              >
                <li
                  v-for="item in artistInfos"
                  :key="item.id"
                  class="col col_3 user_node"
                >
                  <ArtistItem :item-data="item" />
                </li>
              </ul>
              <div v-else class="guide_ui no_data">
                <div class="img_area">
                  <picture>
                    <source
                      media="(max-width: 600px)"
                      type="image/png"
                      :srcset="`${require('@/assets/img/img_mob_illust_nosearch_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nosearch_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nosearch.png')}`"
                    />
                    <img
                      :src="require('@/assets/img/img_illust_nosearch.png')"
                      alt="No Result"
                    />
                  </picture>
                </div>
                <p class="desc">
                  We couldn’t find any match for “<span class="c_point">{{
                    resultKeyword
                  }}</span
                  >”.<br />
                  Try another keyword.
                </p>
              </div>
            </div>
          </template>
          <template v-if="currentTab === 'other'">
            <div class="list_wrap user_list_wrap">
              <ul
                v-if="totalOtherProfileCount > 0"
                class="grid grid_15 ib user_list"
              >
                <li
                  v-for="item in otherProfileInfos"
                  :key="item.id"
                  class="col col_3 user_node"
                >
                  <UserItem :item-data="item" />
                </li>
              </ul>
              <div v-else class="guide_ui no_data">
                <div class="img_area">
                  <picture>
                    <source
                      media="(max-width: 600px)"
                      type="image/png"
                      :srcset="`${require('@/assets/img/img_mob_illust_nosearch_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nosearch_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nosearch.png')}`"
                    />
                    <img
                      :src="require('@/assets/img/img_illust_nosearch.png')"
                      alt="No Result"
                    />
                  </picture>
                </div>
                <p class="desc">
                  We couldn’t find any match for “<span class="c_point">{{
                    resultKeyword
                  }}</span
                  >”.<br />
                  Try another keyword.
                </p>
              </div>
            </div>
          </template>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import WorkItem from '@/components/search/WorkItem';
import ArtistItem from '@/components/search/ArtistItem';
import UserItem from '@/components/search/UserItem';
export default {
  name: 'SearchResultView',
  components: { UserItem, ArtistItem, WorkItem },
  data() {
    return {
      artistInfos: [],
      artworkInfos: [],
      otherProfileInfos: [],
      profileInfos: null,
      totalArtistCount: 0,
      totalArtworkCount: 0,
      totalOtherProfileCount: 0,
      totalProfileCount: 0,
      totalSearchCount: 0,
      resultKeyword: '',
      searchTabData: [
        {
          id: 'artworks',
          name: 'Artworks',
        },
        {
          id: 'artists',
          name: 'Artists',
          icon: 'items',
          class: 'type_edit',
        },
        {
          id: 'other',
          name: 'Other Profiles',
        },
      ],
      currentTab: 'artworks',
      keyword: '',
    };
  },
  mounted() {
    this.resultKeyword = this.$route.params.keyword;
    if (!this.resultKeyword) {
      this.$router.push('/');
    }
    this.getSearch();
  },
  methods: {
    setTab(id) {
      this.currentTab = id;
    },
    getSearch() {
      let query = {
        keyword: this.resultKeyword,
      };
      this.api.getSearchResults(query).then(res => {
        if (res.code === 0) {
          this.artworkInfos = res.artworkInfos;
          this.artistInfos = res.artistInfos;
          this.artworkInfos = res.artworkInfos;
          this.otherProfileInfos = res.otherProfileInfos;
          this.profileInfos = res.profileInfos;
          this.totalArtistCount = res.totalArtistCount;
          this.totalArtworkCount = res.totalArtworkCount;
          this.totalOtherProfileCount = res.totalOtherProfileCount;
          this.totalProfileCount = res.totalProfileCount;
          this.totalSearchCount = res.totalSearchCount;

          // 검색결과 탭에 결과가 있는 탭 먼저 보여주게 하는 화면처리 분기코드
          // 순서 : Artworks > Artist > Other Profile
          if (this.totalArtworkCount == 0 && this.totalSearchCount) {
            if (this.totalArtistCount) {
              this.setTab('artists');
            } else if (this.totalOtherProfileCount) {
              this.setTab('other');
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.result_sec {
  padding-top: 6rem;
  min-height: calc(var(--min) * 1px);
  box-sizing: border-box;
}
.result_sec .sec_head .ttl_area {
  margin-bottom: 25px;
}
.result_sec .sec_head .ttl {
  font-size: 3.2rem;
  font-weight: 200;
}
.result_sec .sec_body {
  padding: 50px 0 150px;
}
.result_sec .guide_ui {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  min-height: calc(var(--min) * 1px - 39rem);
}
.result_sec .guide_ui .img_area {
  margin-bottom: -1rem;
}
@media screen and (max-width: 600px) {
  .result_sec {
    padding-top: 4rem;
  }
  .result_sec .sec_head .ttl {
    font-size: 2rem;
  }
  .result_sec .sec_body {
    padding-top: 2rem;
    padding-bottom: 8rem;
  }
  .grid.grid_15 > .col {
    width: 100%;
  }
  .result_sec .guide_ui {
    flex-flow: column !important;
  }
  .result_sec .guide_ui .img_area {
    margin-bottom: 0;
  }
}
</style>
