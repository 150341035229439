<template>
  <div class="modal_wrap" :class="{ is_show: showPriceModal }">
    <div
      id="setPrice"
      ref="setPrice"
      class="modal"
      :class="modalSize"
      role="dialog"
      aria-labelledby="setPrice_title"
      aria-describedby="setPrice_desc"
    >
      <div class="modal_head">
        <h3 class="modal_ttl">Set A Price</h3>
        <p>Set a price for the artwork.</p>
        <p>(A 10% royalty payment will be applied)</p>
      </div>
      <div class="modal_body">
        <div v-if="isLoading">
          <LoadingSpinner />
        </div>
        <div class="input_area txt" v-else>
          <div class="input_box">
            <span>$</span>
            <!-- inputmode="numeric" -->
            <input
              v-model="setPrice"
              @keyup="checkKey"
              @input="checkInput"
              @blur="checkBlur"
              @paste.prevent
              type="number"
              step="1"
              title="Price"
              placeholder="1"
              label="set Price"
            />
          </div>
        </div>
      </div>
      <div class="modal_foot">
        <div class="btn_area grid flex">
          <button class="btn col basic w_s h_m" @click="close">Cancel</button>
          <button
            class="btn col strong w_s h_m"
            @click="onAppSetPrice"
            v-if="!isLoading"
          >
            OK
          </button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { mapGetters } from 'vuex';
export default {
  name: 'SetPriceModal',
  components: { IcCloseM, LoadingSpinner },
  props: {
    modalId: {
      type: String,
      default: 'modal',
    },
    modalSize: {
      type: String,
      default: '',
    },
    artworkId: {
      type: String,
      default: '',
    },
    editionId: {
      type: Number,
      default: 0,
    },
    showPriceModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      setPrice: null,
      apiFailAlert: {
        type: 'warn',
        desc: "We're sorry, something went wrong. Transaction failed. Something is wrong with your wallet. Please try again later.",
      },
      polling: null,
      overtimer: 180000,
      signAddr: '',
      priceSettingTp: '',
      isLoading: false,
    };
  },
  watch: {
    showPriceModal(v) {
      if (v === true) {
        this.$root.$emit('setScrollFixed', true);
      } else {
        this.$root.$emit('setScrollFixed', false);
      }
    },
  },
  computed: {
    ...mapGetters({
      wlltType: 'userStore/wlltType',
      email: 'userStore/email',
    }),
  },
  methods: {
    async onAppSetPrice() {
      this.checkPrice();
      let walletParams = {
        artworkId: this.artworkId,
        editionId: this.editionId,
        price: this.setPrice,
        priceSettingTp: '30',
      };

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      // if (process.env.VUE_APP_PROFILE === 'DEV') {
      walletParams.bcNwTp = '10';
      // }

      if (this.isLoading) return false;
      this.isLoading = true;

      await this.api.getQrUrlForSalePriceSetting(walletParams).then(res => {
        let walletInfo = {
          walletType: this.wlltType,
          resultPrice: this.setPrice,
        };
        if (res.code === 0) {
          if (this.wlltType === 'WLT001') {
            let params = {
              hostServer: res.hostServer,
              signAddr: res.signAddr,
              scheme: 'setup',
              price: this.setPrice,
              type: 'forsale',
            };
            this.onBlade(params, this.email);
          } else if (this.wlltType === 'WLT002') {
            this.onCallApp(res.signUrl);
          } else {
            this.$router.push({
              name: 'network',
            });
          }

          this.signAddr = res.signAddr;

          this.polling = setInterval(() => {
            this.getAppCallback();
            if (this.overtimer <= 0) {
              clearInterval(this.polling);
              this.isLoading = false;
            }
            this.overtimer -= 3000;
          }, 3000);
        } else if (res.code === 1033) {
          this.isLoading = false;
          this.$root.$emit('walletTokenExpired', walletInfo);
        } else if (res.code === 1034) {
          this.isLoading = false;
          this.$root.$emit('walletChange', walletInfo);
        } else {
          this.isLoading = false;
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
    async getAppCallback() {
      let params = {
        signAddr: this.signAddr,
      };
      await this.api.getPriceSettingCmpltYn(params).then(res => {
        if (res.code === 0) {
          if (res.cmpltYn === 'Y' || res.cmpltYn === 'N') {
            this.isLoading = false;
            clearInterval(this.polling);
            this.close();
          }
        } else {
          this.$router.push({
            name: 'network',
          });
        }
      });
    },
    checkBlur() {
      if (!this.setPrice) return false;
      var regExp = /^\.|\.$/;
      let value = this.setPrice;
      if (regExp.test(value)) {
        this.setPrice = value.replace('.', '');
      }
      this.checkPrice();
    },
    checkInput(e) {
      var value = e.target.value;
      var regExp = /^(1|[1-9]\d*)(?:\.\d{0,2})?$/;

      if (!regExp.test(value)) {
        let result = value.substring(0, value.length - 1);
        this.setPrice = result;
      }
    },
    checkKey(e) {
      e = e || window.event;
      let charCode = e.which || e.keyCode;
      if (!((charCode >= 48 && charCode <= 57) || charCode === 46)) {
        e.preventDefault();
        return false;
      }
    },
    checkPrice() {
      let pattern = /(^\d+$)|(^\d{1,}.\d{0,2}$)/;
      let res = pattern.test(this.setPrice);

      if (!res && this.setPrice.includes('.')) {
        let price = this.setPrice.split('.');
        let priceDecimal = price[1].substr(0, 2);
        let resultPrice = price[0] + '.' + priceDecimal;
        this.setPrice = resultPrice;
      }
    },
    close() {
      this.setPrice = null;
      this.isLoading = false;
      clearInterval(this.polling);
      this.$root.$emit('setScrollFixed', false);
      this.$emit('close', 'showPriceModal');
    },
  },
  beforeDestroy() {
    this.isLoading = false;
    clearInterval(this.polling);
  },
};
</script>

<style scoped>
.modal .modal_body + .modal_foot {
  margin-top: 34px;
}
.input_box {
  width: 100%;
}
</style>
