<template>
  <div id="app">
    <LayoutLoader>
      <router-view :key="$route.fullPath" />
    </LayoutLoader>
  </div>
</template>
<script>
import '@/assets/css/base.css';
import '@/assets/css/ui.css';
import '@/assets/css/animate.css';
import '@/assets/css/layout.css';
import LayoutLoader from '@/layouts/LayoutLoader';

export default {
  components: {
    LayoutLoader,
  },
};
</script>
