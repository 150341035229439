<template>
  <IconBase
    icon-name="icZoomOut"
    :icon-label="iconLabel"
    :width="width"
    :height="height"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="strokeWidth"
  >
    <path data-name="Path 2729" d="M4.011 11.004h6.993V4.011" />
    <path data-name="Path 2730" d="M18.998 4.01v6.993h6.993" />
    <path data-name="Path 2731" d="M11.002 25.99v-6.993H4.009" />
    <path data-name="Path 2734" d="M18.997 25.99v-6.993h6.993" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcZoomOut',
  components: { IconBase },
  props: {
    width: {
      type: [Number, String],
      default: 30,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
    strokeWidth: {
      type: [Number, String],
      default: 1.2,
    },
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Cancel fullscreen',
    },
  },
};
</script>

<style scoped></style>
