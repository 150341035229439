<template>
  <div
    class="modal_wrap"
    :class="{ is_show: showNotice /*is_home: showHome */ }"
  >
    <div class="modal" role="dialog">
      <div class="modal_head">
        <h3 class="modal_ttl">{{ subject }}</h3>
      </div>
      <div class="modal_body">
        <p class="desc">
          {{ content }}
        </p>
      </div>
      <!-- <div class="modal_foot">
        <div class="btn_area">
          <button class="btn w_s h_m strong" @click="close">
            {{ btnTxt }}
          </button>
        </div>
      </div> -->
      <!-- <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button> -->
    </div>
  </div>
</template>

<script>
//import IcCloseM from '@/components/ic/IcCloseM';

export default {
  name: 'noticeModal',
  //components: { IcCloseM },
  props: {
    modalId: {
      type: String,
      default: 'modal',
    },
    modalSize: {
      type: String,
      default: '',
    },
    modalData: {
      type: Object,
      default: null,
    },
    showNotice: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showNotice: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.$root.$emit('setScrollFixed', true);
        } else {
          this.$root.$emit('setScrollFixed', false);
        }
      },
    },
  },
  computed: {
    subject() {
      return 'LG Art Lab Update';
    },
    content() {
      // return `Dear LG Art Lab Customers,

      // We are deeply grateful for your interest in our service.
      //  After launching LG Art Lab, we have received some good feedbacks that we have decided to upgrade our service.
      //  We will come back with better user experience and high-quality service.
      //  Thank you for all the support.\n
      //  We look forward to meeting you soon.
      //  Thank you.`;
      return `We are currently working on a new service.\n
      This will only take less than 12 hours.\n
      Stay tuned.`;
    },
    btnTxt() {
      return 'Ok';
    },
    /*
    showHome() {
      return this.$route.path === '/' ? true : false;
    },
    */
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('closeNotice');
    },
  },
};
</script>

<style scoped>
/*
.modal_wrap.is_home {
  background: none !important;
}
*/
.img_area {
  max-width: 16.4rem;
  margin: 1.5rem auto;
}
.modal .modal_body + .modal_foot {
  margin-top: 2.5rem;
}
.is_loading {
  background: #e9ecef !important;
  color: black !important;
}
.desc {
  white-space: pre-line;
  text-align: left;
}
</style>
