<template>
  <IconBase
    icon-name="icRefreshS"
    :icon-label="iconLabel"
    :width="24"
    :height="24"
    viewBox="0 0 24 24"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <g stroke-miterlimit="10">
      <path d="m11.5 2.5 4 4-4 4" />
      <path d="M15.158 6.501H12a7.5 7.5 0 1 0 7.5 7.515" />
    </g>
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcRefreshS',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Refresh',
    },
  },
};
</script>

<style scoped></style>
