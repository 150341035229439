import { render, staticRenderFns } from "./IcTwitter.vue?vue&type=template&id=6cb44a45&scoped=true"
import script from "./IcTwitter.vue?vue&type=script&lang=js"
export * from "./IcTwitter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb44a45",
  null
  
)

export default component.exports