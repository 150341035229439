<template>
  <IconBase
    icon-name="icCopy"
    :icon-label="iconLabel"
    :width="24"
    :height="24"
    viewBox="0 0 24 24"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path d="M4.6 4.6h11.8v11.8H4.6z" />
    <path :fill="fillColor" stroke-width="0" d="M19 6v13H6v1h14V6z" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcCopy',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    fillColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Copy',
    },
  },
};
</script>

<style scoped></style>
