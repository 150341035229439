<template>
  <div class="modal_wrap" :class="{ is_show: showChangeWallet }">
    <div class="modal" role="dialog">
      <div class="modal_head">
        <h3 class="modal_ttl">
          {{ modalData.title }}
        </h3>
        <p>{{ modalData.desc }}</p>
      </div>
      <div class="modal_body">
        <ul class="wallet_list">
          <li class="wallet_item" v-if="openBlade">
            <button class="btn type_line" @click="close('WLT001')">
              <picture class="thumb">
                <img
                  width="30"
                  height="30"
                  :src="require('@/assets/img/img_mob_wallet_blade.png')"
                  :srcset="`${require('@/assets/img/img_mob_wallet_blade_4x.png')} 4x, ${require('@/assets/img/img_mob_wallet_blade_3x.png')} 3x, ${require('@/assets/img/img_mob_wallet_blade_2x.png')}`"
                  alt="img_mob_wallet_blade"
                />
              </picture>
              <strong>Blade Wallet</strong>
              <b class="badge">Popular</b>
            </button>
          </li>
          <li class="wallet_item" v-if="openWallypto">
            <button class="btn type_line" @click="close('WLT002')">
              <picture class="thumb">
                <img
                  width="30"
                  height="30"
                  :src="require('@/assets/img/img_mob_wallet_wallypto.png')"
                  :srcset="`${require('@/assets/img/img_mob_wallet_wallypto_4x.png')} 4x, ${require('@/assets/img/img_mob_wallet_wallypto_3x.png')} 3x, ${require('@/assets/img/img_mob_wallet_wallypto_2x.png')}`"
                  alt="img_mob_wallet_wallypto"
                />
              </picture>
              <strong>Wallypto</strong>
            </button>
          </li>
        </ul>
      </div>
      <button class="btn type_ic close_modal" @click="close('')">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';
export default {
  name: 'ChangeWalletModal',
  components: { IcCloseM },
  props: {
    showChangeWallet: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: () => ({
        title: 'Connect Your Wallet',
        desc: 'There is no wallet linked to your account. \n(Please wait while we check your wallet connection.)',
      }),
    },
  },
  watch: {
    showChangeWallet: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.$root.$emit('setScrollFixed', true);
        } else {
          this.$root.$emit('setScrollFixed', false);
        }
      },
    },
  },
  computed: {
    openBlade() {
      return JSON.parse(process.env.VUE_APP_OPEN_BLADE) ? true : false;
    },
    openWallypto() {
      return JSON.parse(process.env.VUE_APP_OPEN_WALLYPTO) ? true : false;
    },
  },
  data() {
    return {};
  },
  methods: {
    close(type) {
      this.$root.$emit('setScrollFixed', false);
      this.$emit('close', type);
    },
  },
};
</script>

<style scoped>
.modal .modal_head p {
  font-size: 1.4rem;
}

@media screen and (max-width: 320px) {
  .modal .modal_head p {
    white-space: normal;
  }
}
</style>
