import { render, staticRenderFns } from "./BgSlide.vue?vue&type=template&id=311fae98&scoped=true"
import script from "./BgSlide.vue?vue&type=script&lang=js"
export * from "./BgSlide.vue?vue&type=script&lang=js"
import style0 from "./BgSlide.vue?vue&type=style&index=0&id=311fae98&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "311fae98",
  null
  
)

export default component.exports