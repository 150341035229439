import dayJs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');
var advanced = require('dayjs/plugin/advancedFormat');

dayJs.extend(utc);
dayJs.extend(timezone);
dayJs.extend(advanced);

dayJs.tz.setDefault('America/New_York');

export default {
  install(Vue) {
    Vue.prototype.$dayJs = dayJs;
    Vue.mixin({
      methods: {
        GmtToTz(dateTime, format) {
          let result = null;
          if (!dateTime) {
            return result;
          }

          if (typeof dateTime == 'string') {
            dateTime = dayJs.utc(dateTime);
          }

          if (!dateTime.isValid()) {
            return result;
          }

          result = dateTime.tz();
          return format ? result.format(format) : result;
        },

        TzToGmt(dateTime, format) {
          let result = null;

          if (!dateTime) {
            return result;
          }

          if (typeof dateTime == 'string') {
            dateTime = dayJs.tz(dateTime);
          }

          if (!dateTime.isValid()) {
            return result;
          }

          result = dateTime.utc();

          return format ? result.format(format) : result;
        },

        GmtToLocal(dateTime, format) {
          let result = null;
          if (!dateTime) {
            return result;
          }

          if (typeof dateTime == 'string') {
            dateTime = dayJs.utc(dateTime);
          }

          if (!dateTime.isValid()) {
            return result;
          }

          result = dateTime.local();

          return format ? result.format(format) : result;
        },

        LocalToGmt(dateTime, format) {
          let result = null;

          if (!dateTime) {
            return result;
          }

          if (typeof dateTime == 'string') {
            dateTime = dayJs.local(dateTime);
          }

          if (!dateTime.isValid()) {
            return result;
          }

          result = dateTime.utc();

          return format ? result.format(format) : result;
        },
      },
    });

    Vue.filter('GmtToTz', (value, format) => {
      format = format ?? 'YYYY-MM-DD HH:mm:ss';

      if (!value || typeof value != 'string') {
        return null;
      }
      value = dayJs(value).format(format);

      return dayJs.utc(value).tz().format(format);
    });

    Vue.filter('TzToGmt', (value, format) => {
      format = format ?? 'YYYY-MM-DD HH:mm:ss';

      if (!value || typeof value != 'string') {
        return null;
      }

      return dayJs.tz(value).utc().format(format);
    });

    Vue.filter('GmtToLocal', (value, format) => {
      format = format ?? 'YYYY-MM-DD HH:mm:ss';

      if (!value || typeof value != 'string') {
        return null;
      }

      return dayJs.utc(value).local().format(format);
    });

    Vue.filter('LocalToGmt', (value, format) => {
      format = format ?? 'YYYY-MM-DD HH:mm:ss';

      if (!value || typeof value != 'string') {
        return null;
      }

      return dayJs.local(value).utc().format(format);
    });

    Vue.filter('KFormat', (value, timezone) => {
      if (!value || typeof value != 'string') {
        return '-';
      }

      timezone = timezone ?? 'tz';
      value = dayJs[timezone](value);

      if (!value.isValid()) {
        return '-';
      }

      return value.format('YYYY-MM-DD HH:mm:ss (z)');
    });

    Vue.filter('UsaFormat', (value, option, timezone) => {
      if (!value || typeof value != 'string') {
        return '-';
      }

      timezone = timezone ?? 'tz';
      value = dayJs[timezone](value);

      if (!value.isValid()) {
        return '-';
      }

      if (option == 'newLine') {
        // newLine 옵션의 경우 받는 쪽에서 아래 inline style을 지정해야 함.
        //   <p v-else style="white-space: pre-line">
        //   {{ flow.dropsStartDate | GmtToTz | UsaFormat('newLine') }}
        // </p>
        return (
          value.format('MMM D, YYYY') + ' \n ' + value.format('hh:mm A (z)')
        );
      } else if (option == 'onlyDate') {
        // 시분초 제외한 날짜+타임존 값만 넘김
        return value.format('MMM D, YYYY (z)');
      } else {
        return (
          value.format('MMM D, YYYY') + ' | ' + value.format('hh:mm A (z)')
        );
      }
    });
  },
};
