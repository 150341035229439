<template>
  <IconBase
    icon-name="icCalendar"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path
      class="st1"
      d="M5.6 7.6h18.8v16.8H5.6V7.6zM10.6 9.5v-4M19.4 9.5v-4M5 12.4h20"
    />
    <path
      class="st2"
      d="M10 14.8h2V16h-2v-1.2zM10 17.8h2V19h-2v-1.2zM10 20.8h2V22h-2v-1.2zM14 14.8h2V16h-2v-1.2zM14 17.8h2V19h-2v-1.2zM14 20.8h2V22h-2v-1.2zM18 14.8h2V16h-2v-1.2zM18 17.8h2V19h-2v-1.2zM18 20.8h2V22h-2v-1.2z"
      :fill="strokeColor"
      stroke-width="0"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcCalendar',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#939393',
    },
    iconLabel: {
      type: String,
      default: 'Calendar',
    },
  },
};
</script>

<style scoped></style>
