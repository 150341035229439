<template>
  <div class="modal_wrap" :class="{ is_show: show }" @click="dimEvt">
    <div class="modal follow">
      <div class="modal_head">
        <h3 class="modal_ttl">Follower / Following</h3>
        <div class="tab_list type_stack">
          <div class="tab_node" role="tablist">
            <button
              class="tab_item"
              role="tab"
              v-for="(item, index) in tabMenu"
              :key="index"
              :class="{ is_active: index === activeIndex }"
              @click="handleTabClicked(index)"
            >
              <span class="is_blind" v-if="index === activeIndex"
                >Selected,
              </span>
              {{ item }}
            </button>
          </div>
        </div>
      </div>
      <div class="modal_body">
        <div class="has_scroll_y">
          <ul class="follow_list" v-if="tabMenu[activeIndex] === 'Followers'">
            <p v-if="!followers.length">No Follower Data</p>
            <li v-for="item in followers" :key="item.mbrNo" class="follow_node">
              <div class="follow_item">
                <div class="profile_info profile_info_area">
                  <a
                    v-if="item.avatarRsrcUrl"
                    class="profile"
                    :style="`background-image: url('${item.avatarRsrcUrl}')`"
                    @click="handleRoute(item)"
                  ></a>
                  <span v-else @click="handleRoute(item)">
                    <IcProfile class="profile" />
                  </span>
                  <h2 class="ttl name">{{ item.name }}</h2>
                </div>
                <div class="is_row_focus" v-if="item.mbrNo !== myMbrNo">
                  <button
                    @click="follow(item)"
                    ref="follow"
                    class="btn type_line_w w_xs h_m"
                    :class="{ is_active: item.followYn === 'Y' }"
                  >
                    {{ item.followYn === 'N' ? 'Follow' : 'Following' }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="follow_list" v-else>
            <p v-if="!following.length">No Following Data</p>
            <li
              v-for="item in following"
              :key="item.userId"
              class="follow_node"
            >
              <div class="follow_item">
                <div class="profile_info profile_info_area">
                  <a
                    v-if="item.avatarRsrcUrl"
                    class="profile"
                    :style="`background-image: url('${item.avatarRsrcUrl}')`"
                    @click="handleRoute(item)"
                  ></a>
                  <span v-else @click="handleRoute(item)">
                    <IcProfile class="profile" />
                  </span>
                  <h2 class="ttl name">{{ item.name }}</h2>
                </div>
                <div class="is_row_focus" v-if="item.mbrNo !== myMbrNo">
                  <button
                    @click="follow(item)"
                    ref="follow"
                    class="btn type_line_w w_xs h_m"
                    :class="{ is_active: item.followYn === 'Y' }"
                  >
                    {{ item.followYn === 'N' ? 'Follow' : 'Following' }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
import IcCloseM from '@/components/ic/IcCloseM';
import { mapGetters } from 'vuex';
export default {
  name: 'FollowList',
  components: {
    IcCloseM,
    IcProfile,
  },
  props: {
    mbrNo: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      myMbrNo: 'userStore/mbrNo',
    }),
  },
  data() {
    return {
      tabMenu: ['Followers', 'Following'],
      show: false,
      activeIndex: 0,
      followers: [],
      following: [],
      param: {
        mbrNo: this.mbrNo,
      },
    };
  },
  methods: {
    getFollowData() {
      this.param.page = 1;
      this.api.getUserFollowers(this.param).then(res => {
        this.followers = res.userInfos;
      });
      this.api.getUserFollowings(this.param).then(res => {
        this.following = res.userInfos;
      });
    },
    follow(item) {
      const data = {
        followingMbrNo: item.mbrNo,
        followingYn: item.followYn === 'N' ? 'Y' : 'N',
      };
      this.api.setUserFollowing(data).then(res => {
        if (res.code === 0) {
          this.getFollowData();
          this.$emit('follow');
        }
      });
    },
    open(boolean, type) {
      this.activeIndex = boolean ? 0 : 1;
      this.show = true;
      this.getFollowData();
      this.$root.$emit('setScrollFixed', true);

      if (type === 'artist') {
        this.setFocusTrap(true, '.modal.follow', '', '1');
      } else {
        this.setFocusTrap(true, '.modal.follow');
      }
    },
    close() {
      this.setFocusTrap(false, '.modal.follow');
      this.show = false;
      this.$root.$emit('setScrollFixed', false);
      this.$emit('onSetCloseFocus', this.activeIndex);
    },
    dimEvt(e) {
      const t = e.target;
      if (t.closest('.modal')) return;
      // this.close();
    },
    handleRoute(item) {
      if (item.userType === 1) {
        this.$emit('follow', item);
        this.close();
        if (this.$route.name === 'otherProfile') {
          this.$router.replace({
            name: 'otherProfile',
            params: {
              mbrNo: item.mbrNo,
            },
          });
        }
      }
      if (item.userType === 2) {
        this.$emit('follow', item);
        this.close();
        if (this.$route.name === 'ProfileArtist') {
          this.$router.replace({
            name: 'ProfileArtist',
            params: {
              mbrNo: item.mbrNo,
            },
          });
        }
      }
    },
    handleTabClicked(index) {
      this.activeIndex = index;
    },
  },
  // created() {
  //   this.getFollowData();
  // },
};
</script>

<style scoped>
.modal_head .tab_list {
  margin-top: 2rem;
  margin-bottom: 0;
}
.modal_head .tab_list:before {
  background: #707070;
}
.modal_head .tab_list .tab_node {
  width: 100%;
}

.modal_head .tab_list .tab_node .tab_item {
  display: inline-block;
  width: 50%;
  margin-left: 0;
  padding: 1.4rem 0;
  font-size: 1.8rem;
  font-weight: 300;
}
.modal .modal_head + .modal_body {
  margin-top: 0;
  margin-bottom: -5rem;
}
.modal_body .has_scroll_y {
  max-height: 65.4rem;
}
.modal_body .has_scroll_y::-webkit-scrollbar-thumb {
  border-top: 4rem solid #393939;
  border-bottom: 5rem solid #393939;
}
.modal_body .follow_list {
  padding: 4rem 0 5rem;
}
.modal_body .follow_node {
  padding: 1.5rem 2.4rem 1.5rem 2rem;
}
.modal_body .follow_node:first-child {
  padding-top: 0;
}
.modal_body .follow_node:last-child {
  padding-bottom: 0;
}
.follow_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile_info_area .profile {
  display: inline-block;
  width: 5rem !important;
  height: 5rem !important;
  margin-right: 1.6rem;
  background-size: cover;
  vertical-align: middle;
}

.profile_info_area .ttl {
  font-size: 1.8rem;
  font-weight: 200;
  color: #fff;
  vertical-align: middle;
}

@media screen and (max-width: 600px) {
  .modal_head .tab_list {
    padding: 0;
  }
  .modal_body .follow_node {
    padding-left: 0;
    padding-right: 0;
  }
  .profile_info_area {
  }
  .profile_info_area .profile {
    width: 3.6rem !important;
    height: 3.6rem !important;
  }
  .modal_body .follow_item .btn {
    min-width: 0;
    width: 9rem;
  }
  .modal .modal_head + .modal_body {
    margin-bottom: -3rem;
  }
  .modal_body .follow_list {
    padding: 3rem 0;
  }
  .modal_body .has_scroll_y {
    max-height: 50rem;
  }
  .modal_body .has_scroll_y::-webkit-scrollbar-thumb {
    border-top: 3rem solid #393939;
    border-bottom: 3rem solid #393939;
  }
  .profile_info_area .ttl {
    font-size: 1.5rem;
    padding-right: 1rem;
  }
}
</style>
