<template>
  <div class="modal_wrap" :class="{ is_show: showEthLoading }">
    <div
      id="setPrice"
      ref="setPrice"
      class="modal"
      :class="modalSize"
      role="dialog"
      aria-labelledby="setPrice_title"
      aria-describedby="setPrice_desc"
    >
      <div class="modal_head">
        <h3 class="modal_ttl">Not for sale</h3>
        <p>Cancellation signature is in progress.</p>
        <p>When completed, the screen will be updated</p>
      </div>
      <div class="modal_body">
        <div>
          <LoadingSpinner />
        </div>
      </div>
      <!-- <div class="modal_foot">
        <div class="btn_area grid flex">
          <button class="btn col strong w_s h_m" @click="close">OK</button>
        </div>
      </div> -->
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { mapGetters } from 'vuex';
export default {
  name: 'SetPriceModal',
  components: { IcCloseM, LoadingSpinner },
  props: {
    modalId: {
      type: String,
      default: 'modal',
    },
    modalSize: {
      type: String,
      default: '',
    },
    showEthLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiFailAlert: {
        type: 'warn',
        desc: "We're sorry, something went wrong. Transaction failed. Something is wrong with your wallet. Please try again later.",
      },
    };
  },
  watch: {
    showEthModal(v) {
      if (v === true) {
        this.$root.$emit('setScrollFixed', true);
      } else {
        this.$root.$emit('setScrollFixed', false);
      }
    },
  },
  computed: {
    ...mapGetters({
      getWeb3ModalStatus: 'web3Store/getWeb3ModalStatus',
    }),
  },
  methods: {
    close() {
      this.$root.$emit('setScrollFixed', false);
      this.$emit('close', 'showEthLoading');
    },
  },
  beforeDestroy() {
    this.$root.$emit('setScrollFixed', false);
  },
};
</script>

<style scoped>
.modal_wrap {
  overflow: hidden;
}
.modal .modal_body + .modal_foot {
  margin-top: 34px;
}
.input_box {
  width: 100%;
}
</style>
