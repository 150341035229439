<template>
  <IconBase
    icon-name="icEth"
    :icon-label="iconLabel"
    :width="34"
    :height="37"
    viewBox="0 0 34 37"
  >
    <g id="그룹_3900" data-name="그룹 3900" transform="translate(-0.001 0)">
      <path
        id="패스_3803"
        data-name="패스 3803"
        d="M33,9.249a7.458,7.458,0,0,0-2.734-2.741l-7.783-4.5L20.74,1a7.478,7.478,0,0,0-7.48,0L3.735,6.509c-.173.1-.34.206-.5.321l-.044.032A7.485,7.485,0,0,0,0,12.991V24.007a7.515,7.515,0,0,0,.771,3.313c.068.144.148.288.227.428a7.448,7.448,0,0,0,.844,1.183q.159.185.331.357a7.357,7.357,0,0,0,1.136.937c.136.092.279.18.423.263L13.26,36a7.48,7.48,0,0,0,7.48,0L25.5,33.243l4.764-2.754c.019-.01.035-.023.054-.034q.088-.053.169-.106a7.43,7.43,0,0,0,2.334-2.308c.084-.132.165-.265.239-.4A7.49,7.49,0,0,0,34,24.009V12.993A7.5,7.5,0,0,0,33,9.249Z"
        transform="translate(0.001 0.001)"
        fill="#191919"
        opacity="0.4"
      />
      <g id="그룹_3833" data-name="그룹 3833" transform="translate(11 8)">
        <path
          id="패스_3804"
          data-name="패스 3804"
          d="M20.671,31.908a.79.79,0,0,1-.26-.244l-5.079-7.373a.449.449,0,0,1-.079-.258.454.454,0,0,1,.08-.258.366.366,0,0,1,.2-.146.331.331,0,0,1,.24.029L21,26.407l5.219-2.747a.33.33,0,0,1,.24-.029.363.363,0,0,1,.2.146.449.449,0,0,1,.08.258.443.443,0,0,1-.079.258l-5.079,7.373a.79.79,0,0,1-.26.244.625.625,0,0,1-.65,0ZM21,24.531V11a.651.651,0,0,1,.35.1.761.761,0,0,1,.261.284l5.308,9.268a.59.59,0,0,1,.074.215.61.61,0,0,1-.011.23.576.576,0,0,1-.09.212.5.5,0,0,1-.161.149L21.2,24.48a.372.372,0,0,1-.187.051Zm-.2-.051-5.531-3.019a.5.5,0,0,1-.161-.149.576.576,0,0,1-.091-.212.624.624,0,0,1-.011-.23.59.59,0,0,1,.074-.215l5.308-9.268a.757.757,0,0,1,.261-.284A.652.652,0,0,1,21,11V24.531h-.013A.372.372,0,0,1,20.8,24.48Z"
          transform="translate(-14.998 -10.999)"
          fill="#fff"
          opacity="0.9"
        />
        <path
          id="패스_3807"
          data-name="패스 3807"
          d="M21.012,22.564v5.612a.373.373,0,0,1-.2-.051L15.28,25.106a.5.5,0,0,1-.161-.149.577.577,0,0,1-.091-.212Z"
          transform="translate(-15.011 -14.643)"
          fill="#d1d1d1"
          opacity="0.9"
        />
        <path
          id="패스_3808"
          data-name="패스 3808"
          d="M21,11v7.921L15.016,21.1a.616.616,0,0,1-.012-.23.591.591,0,0,1,.072-.215l5.309-9.269a.764.764,0,0,1,.261-.284A.651.651,0,0,1,21,11Z"
          transform="translate(-14.998 -10.999)"
          fill="#fff"
          opacity="0.9"
        />
        <path
          id="패스_3809"
          data-name="패스 3809"
          d="M30.983,21.1,25,18.921V11a.652.652,0,0,1,.35.1.764.764,0,0,1,.261.284l5.309,9.271a.591.591,0,0,1,.072.215.617.617,0,0,1-.012.23Z"
          transform="translate(-18.998 -10.999)"
          fill="#e9e9e9"
          opacity="0.9"
        />
        <path
          id="패스_3810"
          data-name="패스 3810"
          d="M30.984,24.744a.578.578,0,0,1-.091.212.5.5,0,0,1-.161.149L25.2,28.124a.373.373,0,0,1-.2.051V22.564Z"
          transform="translate(-18.998 -14.643)"
          fill="#9e9e9e"
          opacity="0.9"
        />
      </g>
    </g>
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcEth',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Ethereum',
    },
  },
};
</script>

<style scoped></style>
