<template>
  <main id="contents" class="my_list_page my_artist_page">
    <div class="set_inner clearFix">
      <h1 class="page_ttl">My Account</h1>
      <AccountMenu />
      <div class="min_inner">
        <section class="artist_sec">
          <div class="artist_sec_head">
            <div
              class="visual_wrap"
              v-if="profileCoverUrls"
              :style="`background-image: url('${profileCoverUrls}')`"
            />
            <div style="display: flex; justify-content: center" v-else>
              <img
                style="height: 200px; justify-content: center"
                :src="require('@/assets/img/img_illust_nodata.png')"
                alt="nodata"
              />
            </div>
            <div class="profile_group">
              <div class="profile_info is_art">
                <span
                  v-if="profileInfos && profileInfos.length"
                  class="profile"
                  :style="`background-image: url('${profileInfos[0].resUrl}')`"
                ></span>
                <IcProfile v-else class="profile" :width="110" :height="110" />
                <!-- Dev: 220503 수정 - 링굵기 수정 -->
                <IcRing
                  class="ic_ring"
                  :width="110"
                  :height="110"
                  :small-ring="14.8"
                />
                <h2 class="ttl">{{ artistInfo.name }}</h2>
                <button
                  class="edit_btn"
                  ref="edit_btn"
                  @click="showProfile = true"
                >
                  <IcWrite />
                </button>
              </div>
              <div v-if="zipCodeFullStr" class="ttl_area zpcd">
                <span class="zpcd_info">{{ zipCodeFullStr }}</span>
              </div>
            </div>
            <div class="follow_group">
              <ul class="follow_area">
                <li class="following">
                  <button
                    @click="openModal('FollowModal', false)"
                    ref="following"
                  >
                    {{ followingCount | numberFormatter(2) }}
                    <span>Following</span>
                  </button>
                </li>
                <li class="followers">
                  <button
                    @click="openModal('FollowModal', true)"
                    ref="followers"
                  >
                    {{ followerCount | numberFormatter(2) }}
                    <span>Followers</span>
                  </button>
                </li>
              </ul>
            </div>

            <!--NOTE: reduce family functions requested by yr.choi -->
            <div
              v-if="familyInfos && familyInfos.length > 0"
              class="family_account_group"
            >
              <div class="account_length_area">
                <p>
                  Family Account <em>{{ familyInfos.length }}</em>
                </p>
                <p></p>
              </div>

              <ul class="profile_info">
                <li v-for="item in familyInfos" :key="item.id">
                  <span
                    v-if="item.avatarRsrcUrl"
                    class="profile"
                    :style="`background-image: url('${item.avatarRsrcUrl}')`"
                    @click="goToProfile(item)"
                  ></span>
                  <div v-else @click="goToProfile(item)">
                    <IcProfile class="profile" width="40" height="40" />
                  </div>
                  <div class="profile_layer">
                    <div class="profile_info_group">
                      <div class="profile_info">
                        <span
                          v-if="item.avatarRsrcUrl"
                          class="profile"
                          :style="`background-image: url('${item.avatarRsrcUrl}')`"
                        ></span>
                        <IcProfile v-else class="profile" />
                      </div>
                      <div class="ttl_area">
                        <h2 class="ttl">{{ item.userName }}</h2>
                      </div>
                    </div>
                    <div class="follow_group">
                      <div class="follow_area">
                        <div class="followers">
                          <dl>
                            <dt role="status">
                              {{ item.followers | numberFormatter(2) }}
                            </dt>
                            <dd>Followers</dd>
                          </dl>
                        </div>
                        <div class="following">
                          <dl>
                            <dt role="status">
                              {{ item.following | numberFormatter(2) }}
                            </dt>
                            <dd>Following</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div class="btn_area">
                      <button
                        @click="follow(item)"
                        ref="follow"
                        class="btn basic w_f h_l"
                        :class="{ is_active: item.followYn === 'Y' }"
                      >
                        <IcMember
                          v-if="item.followYn === 'N'"
                          stroke-color="#000000"
                        />
                        <IcCheck v-if="item.followYn === 'Y'" />
                        {{ item.followYn === 'Y' ? 'Following' : 'Follow' }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="desc_area" :class="{ is_active: expand }">
              <pre class="desc" v-if="profileDetails">
                {{ profileDetails.artistDescription || null }}
              </pre>
            </div>
            <button v-if="!expand" class="expand_btn dv_mob" @click="expandBtn">
              Expand
              <IcExpand />
            </button>
            <div class="sns_area" v-if="profileDetails">
              <a
                :href="profileDetails.twitterUrl"
                target="noopener noreferrer"
                class="sns"
                v-if="profileDetails.twitterUrl"
                ><IcTwitter
              /></a>
              <a
                :href="profileDetails.instagramUrl"
                target="noopener noreferrer"
                class="sns"
                v-if="profileDetails.instagramUrl"
                ><IcInstagram
              /></a>
              <a
                :href="profileDetails.webSiteUrl"
                target="noopener noreferrer"
                class="sns"
                v-if="profileDetails.webSiteUrl"
                ><IcWeb
              /></a>
              <a
                :href="profileDetails.facebookUrl"
                target="noopener noreferrer"
                class="sns"
                v-if="profileDetails.facebookUrl"
                ><IcFaceBook
              /></a>
            </div>
          </div>
          <div class="artist_sec_body">
            <div class="tab_wrap">
              <div class="tab_list type_stack">
                <div class="tab_node">
                  <button
                    @click="tabSelected = 'CREATED'"
                    class="tab_item"
                    :class="{ is_active: tabSelected === 'CREATED' }"
                  >
                    <span
                      >Created
                      <em class="count">{{ artistInfo.crtrCount }}</em></span
                    >
                  </button>
                </div>
                <div class="tab_node">
                  <button
                    @click="tabSelected = 'OWNED'"
                    class="tab_item"
                    :class="{ is_active: tabSelected === 'OWNED' }"
                  >
                    <span
                      >Owned
                      <em class="count">{{ artistInfo.ownrCount }}</em></span
                    >
                  </button>
                </div>
              </div>
            </div>
            <ul class="grid grid_15 ib edit_list">
              <li
                v-for="(item, i) in filterTab"
                :key="item + i"
                class="col col_4 edit_node"
              >
                <ItemCardEdit
                  :mbrNo="mbrNo"
                  :userType="userType"
                  :itemData="item"
                  :key="componentListKey + i"
                  :viewType="'profile'"
                  :hideOwner="true"
                  @open="open"
                  @onAppChangeSelling="onAppChangeSelling"
                />
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
    <EditProfile
      v-if="showProfile"
      :showProfile="showProfile"
      ref="EditModal"
      @close="close"
    />
    <FollowList
      v-if="mbrNo"
      ref="FollowModal"
      :mbrNo="mbrNo"
      @follow="handleFollow"
      @onSetCloseFocus="onSetCloseFocus"
      :key="componentKey"
    />
    <QrModal
      v-if="showQrModal"
      :showQrModal="showQrModal"
      :modal-data="changeSelling"
      @close="close"
    />
    <SetPriceModal
      v-if="showPriceModal"
      :showPriceModal="showPriceModal"
      :editionId="editionId"
      :artworkId="artworkId"
      modal-size="m"
      @close="close"
    />
  </main>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
import IcWrite from '@/components/ic/IcWrite';
import IcTwitter from '@/components/ic/IcTwitter';
import IcFaceBook from '@/components/ic/IcFaceBook';
import IcInstagram from '@/components/ic/IcInstagram';
import IcWeb from '@/components/ic/IcWeb';
import ItemCardEdit from '@/components/mylist/ItemCardEdit';
import EditProfile from '@/components/mylist/EditProfile';
import FollowList from '@/components/mylist/FollowList';
import AccountMenu from '@/components/common/AccountMenu';
import IcRing from '@/components/ic/IcRing';
import IcExpand from '@/components/ic/IcExpand';
import IcMember from '@/components/ic/IcMember';
import IcCheck from '@/components/ic/IcCheck';
import QrModal from '@/components/common/QrModal';
import SetPriceModal from '@/components/common/SetPriceModal';

import { mapGetters } from 'vuex';
import store from '@/store/index';

export default {
  name: 'MyProfileArtistView',
  components: {
    IcRing,
    AccountMenu,
    FollowList,
    EditProfile,
    ItemCardEdit,
    IcWeb,
    IcInstagram,
    IcFaceBook,
    IcTwitter,
    IcWrite,
    IcProfile,
    IcExpand,
    IcMember,
    IcCheck,
    QrModal,
    SetPriceModal,
  },
  data() {
    return {
      artistInfo: {},
      window: {
        width: 0,
        height: 0,
      },
      changeSelling: {
        title: 'Change Selling Type',
        desc: 'Turn on the camera on your mobile phone and scan the QR to change the selling type.',
        qr: {
          path: 'img_qr.png',
        },
      },
      apiFailAlert: {
        type: 'warn',
        desc: "We're sorry, something went wrong. Transaction failed. Something is wrong with your wallet. Please try again later.",
      },
      showQrModal: false,
      showPriceModal: false,
      showProfile: false,
      createdInfos: [],
      liveDropInfos: [],
      ownedEditions: [],
      tabSelected: 'CREATED',
      createdData: [],
      ownedData: [],
      expand: false,
      followingCount: 0,
      followerCount: 0,
      polling: null,
      overtimer: 180000,
      signAddr: '',
      componentKey: 0,
      componentListKey: 0,
      isLoading: false,
    };
  },
  computed: {
    profileCoverUrls() {
      return this.profileCoverInfos && this.profileCoverInfos.length
        ? this.profileCoverInfos[0].resUrl
        : '';
    },
    filterTab() {
      return this.tabSelected === 'CREATED'
        ? this.createdInfos
        : this.ownedEditions;
    },
    ...mapGetters({
      userName: 'userStore/userName',
      userType: 'userStore/userType',
      profileInfos: 'userStore/profileInfos',
      profileCoverInfos: 'userStore/profileCoverInfos',
      profileDetails: 'userStore/profileDetails',
      mbrNo: 'userStore/mbrNo',
      familyInfos: 'userStore/familyInfos',
      zpcd: 'userStore/zpcd',
      city: 'userStore/city',
      stat: 'userStore/stat',
      wlltType: 'userStore/wlltType',
      email: 'userStore/email',
    }),
    zipCodeFullStr() {
      let zipArr = [];
      ['zpcd', 'city', 'stat'].filter(v => {
        if (this[v]) {
          if (this[v].length > 28) {
            zipArr.push(this[v].substring(0, 25) + '...');
          } else {
            zipArr.push(this[v]);
          }
        }
      });
      return this.zpcd ? zipArr.join(', ') : '';
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    if (this.userType === '1') this.$router.go(-1);

    this.getProfile();
    let param = {
      mbrNo: this.mbrNo,
    };
    this.api.getArtistProfile(param).then(res => {
      if (res.code === 0) {
        this.artistInfo = res.artistInfo;
        this.createdInfos = res.createdInfos;
        this.ownedEditions = res.ownedEditions;
        this.componentListKey += res.ownedEditions.length;

        if (this.ownedEditions) {
          this.ownedEditions.forEach(x => {
            if (x.keywords) {
              x.hashtags = '#' + x.keywords.replace(',', ' #') + '#LGArtLab';
            } else {
              x.hashtags = '#LGArtLab';
            }
          });
        }
      }
    });
    // NOTE: for temporary disapear family tab logic ( when zero family )
    // this.api.getUserFamilyList({}).then(res => {
    //   store.commit('userStore/familyInfos', res.familyInfos);
    // });
  },
  methods: {
    handleFollow(mbrNo) {
      this.getProfile(mbrNo);
    },
    getProfile(mbrNo) {
      const data = {
        mbrNo: mbrNo ? mbrNo : this.mbrNo,
      };

      this.api.getProfile(data).then(res => {
        if (res.code === 0) {
          this.followingCount = res.followingCount;
          this.followerCount = res.followerCount;
        }
      });
    },
    open(cardInfo) {
      if (cardInfo) {
        this.artworkId = cardInfo.artworkId;
        this.editionId = cardInfo.editionId;
        this.showPriceModal = true;
      } else {
        this.showQrModal = true;
      }
    },
    openModal(ref, boolean) {
      this.$refs[ref].open(boolean, 'artist');
    },
    expandBtn() {
      this.expand = !this.expand;
    },
    follow(item) {
      const data = {
        followingMbrNo: item.mbrNo,
        followingYn: item.followYn === 'N' ? 'Y' : 'N',
      };
      this.api.setUserFollowing(data).then(res => {
        if (res.code === 0) {
          this.api.getUserFamilyList({}).then(res => {
            store.commit('userStore/familyInfos', res.familyInfos);
            data.followingYn === 'Y'
              ? this.followingCount++
              : this.followingCount--;
            this.componentKey++;
          });
        }
      });
    },
    close(type) {
      this[type] = false;
      this.getProfile();
      clearInterval(this.polling);
      this.isLoading = false;
      setTimeout(() => {
        this.$refs.edit_btn.focus();
      }, 500);
    },
    //Change Selling 비활성화 미 사용 23-04-18
    async onAppChangeSelling(cardInfo) {
      this.artworkId = cardInfo.artworkId;
      this.editionId = cardInfo.editionId;

      let walletParams = {
        artworkId: this.artworkId,
        editionId: this.editionId,
        price: 0,
        priceSettingTp: '10',
      };

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      //if (process.env.VUE_APP_PROFILE === 'DEV') {
      walletParams.bcNwTp = '10';
      //}

      if (this.isLoading) return false;
      this.isLoading = true;

      await this.api.getQrUrlForSalePriceSetting(walletParams).then(res => {
        let walletInfo = {
          walletType: this.wlltType,
          resultPrice: -1,
        };
        if (res.code === 0) {
          if (this.wlltType === 'WLT001') {
            let params = {
              hostServer: res.hostServer,
              signAddr: res.signAddr,
              scheme: 'setup',
              price: -1,
              type: 'notforsale',
              serial: cardInfo.serial,
            };
            this.onBlade(params, this.email);
          } else if (this.wlltType === 'WLT002') {
            this.onCallApp(res.signUrl);
          } else {
            this.$router.push({
              name: 'network',
            });
          }
          this.signAddr = res.signAddr;
          this.polling = setInterval(() => {
            this.getAppCallback();
            if (this.overtimer <= 0) {
              this.isLoading = false;
              clearInterval(this.polling);
            }
            this.overtimer -= 3000;
          }, 3000);
        } else if (res.code === 1033) {
          this.isLoading = false;
          this.$root.$emit('walletTokenExpired', walletInfo);
        } else if (res.code === 1034) {
          this.isLoading = false;
          this.$root.$emit('walletChange', walletInfo);
        } else {
          this.isLoading = false;
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
    async getAppCallback() {
      let params = {
        signAddr: this.signAddr,
      };
      await this.api.getPriceSettingCmpltYn(params).then(res => {
        if (res.code === 0) {
          if (res.cmpltYn === 'Y' || res.cmpltYn === 'N') {
            this.isLoading = false;
            this.$EventBus.$emit('isType', false);
            clearInterval(this.polling);
            this.getUserProfile();
          }
        } else {
          this.$router.push({
            name: 'network',
          });
        }
      });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    goToProfile(item) {
      if (item.userType === '1') {
        this.$router.push(`/mylist/other/${item.mbrNo}`);
      }
      if (item.userType === '2') {
        this.$router.push(`/mylist/artist/${item.mbrNo}`);
      }
    },
    onSetCloseFocus(idx) {
      let foucsEl;
      if (idx === 1) {
        foucsEl = this.$refs.following;
      } else {
        foucsEl = this.$refs.followers;
      }
      setTimeout(() => {
        foucsEl.focus();
      }, 500);
    },
  },
};
</script>

<style scoped>
@import url(../../assets/css/customGlobal.css);

.family_account_group {
  padding-left: 14.5rem;
}
.profile_info {
  display: inline-flex;
  z-index: 11;
  cursor: pointer;
  align-items: center;
  vertical-align: top;
}
.profile_layer .follow_group .followers dl dt,
.profile_layer .follow_group .following dl dt {
  font-size: 1.6rem;
  font-weight: 300;
  padding-left: 0;
}
.profile_info_group {
  display: flex;
  align-items: center;
  margin-bottom: 1.7rem;
}
.profile_layer .followers dl,
.profile_layer .following dl {
  text-align: left;
}
.profile_layer .followers {
  min-width: 12rem;
  display: inline-block;
  position: relative;
  font-size: 0;
  text-align: left;
}
.profile_layer .ttl_area {
  display: flex;
  justify-content: space-between;
  max-width: 28rem;
}
.profile_layer .ttl {
  font-size: 1.8rem !important;
  font-weight: 300 !important;
}
.profile_layer .following {
  min-width: 12rem;
  display: inline-block;
  position: relative;
  font-size: 0;
  text-align: left;
}
.profile_layer .followers:before {
  content: none;
}
.profile_layer .following:before {
  display: block;
  content: '';
  position: absolute;
  left: -2.8rem;
  top: 3px;
  bottom: 3px;
  z-index: 1;
  width: 1px;
  background: var(--line-color);
}
.my_artist_page .profile_layer .btn_area i {
  margin-right: 1rem;
  vertical-align: -0.8rem;
}
.my_artist_page .artist_sec_head .profile_group {
  flex-flow: column;
  align-items: baseline !important;
}
.profile_layer {
  display: none;
  position: absolute;
  top: 5rem;
  width: 32rem;
  padding: 2rem;
  border: 0.1rem solid var(--disabled-colr);
  background: #000;
  box-sizing: border-box;
}
.follow_group {
  padding-left: 14.5rem;
  margin-top: 2.3rem;
  font-size: 0;
}
.profile_layer .follow_group {
  padding-left: 0;
  margin-top: 2.3rem;
  font-size: 0;
}
.btn_area {
  margin-top: 1.8rem;
}
.artist_sec_head .family_account_group {
  margin-top: 2.5rem;
}

.artist_sec_head .family_account_group .account_length_area p {
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--txt-gray);
}

.artist_sec_head .family_account_group .account_length_area p em {
  color: var(--main-color);
}

.artist_sec_head .family_account_group .profile_info {
  display: flex;
}

.artist_sec_head .family_account_group .profile_info .profile {
  width: 4rem;
  height: 4rem;
  box-sizing: content-box;
}

.artist_sec_head .family_account_group .profile_info li + li {
  margin-left: -0.7rem;
}

.artist_sec_head .family_account_group .profile_info li + li .profile {
  /* border: 0.2rem solid #000; */
}
.artist_sec_head .family_account_group .profile_info li {
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.artist_sec_head .family_account_group .profile_info li:hover .profile_layer {
  display: block;
}
@media screen and (max-width: 1024px) {
  .artist_sec_head .family_account_group .profile_info li:hover .profile_layer {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .follow_group {
    padding-left: 0;
  }
  .artist_sec_head .family_account_group .account_length_area p {
    font-weight: 200;
  }
  .artist_sec_head .family_account_group .profile_info li {
    margin-top: 1.4rem;
  }
  .artist_sec_head .family_account_group .profile_info .profile {
    width: 4rem;
    height: 4rem;
  }
  .family_account_group {
    margin-top: 3.5rem;
    padding-left: 0;
  }
}
.desc {
  white-space: pre-line !important;
}
.ttl_area.zpcd {
  text-align: center;
  word-break: break-all;
  margin: 30px 0px 10px 0px;
}
.zpcd_title {
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.zpcd_info {
  font-weight: 600;
  font-size: 2.2rem;
}
</style>
