import { render, staticRenderFns } from "./arwPageFirst.vue?vue&type=template&id=60eb8236&scoped=true"
import script from "./arwPageFirst.vue?vue&type=script&lang=js"
export * from "./arwPageFirst.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60eb8236",
  null
  
)

export default component.exports