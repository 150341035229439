<template>
  <main id="contents" class="footer_page help_page">
    <div class="set_inner">
      <div class="page_ttl_area">
        <h1 class="page_ttl">Help Center</h1>
      </div>
      <section class="board_sec">
        <div class="search_wrap">
          <div class="input_area search">
            <div class="input_box">
              <input
                v-model="search"
                type="search"
                placeholder="Search help articles…"
                @keyup.enter="handleSearch"
                ref="searchRef"
                label="Search"
                title="Search"
              />
              <button class="btn_search" @click="handleSearch">
                <IcSearch class="ic_search" />
              </button>
              <button
                v-if="search !== ''"
                @click="clear"
                type="button"
                class="btn type_ic remove_word"
              >
                <IcCloseS
                  :iconLabel="'Clear search field'"
                  stroke-color="#fff"
                  :width="20"
                  :height="20"
                  :stroke-width="1"
                />
              </button>
            </div>
          </div>
        </div>
        <!-- Dev : 검색 후 레이아웃 -->
        <template v-if="faqInfos && resultIndex === 1">
          <p class="result_num">
            {{ faqInfos.length }} results for “<em>{{ keyword }}</em
            >” in all categories
          </p>
          <ul>
            <li v-for="(item, index) in faqInfos" :key="index">
              <div class="board_item result">
                <router-link
                  :to="{
                    name: `helpCenterDetail`,
                    query: { num: item.num, cateNo: item.cateNo },
                  }"
                >
                  <div class="item_head">
                    <span
                      >Help Center <ArwNextS stroke-color="var(--txt-gray)" />{{
                        item.cateName
                      }}</span
                    >
                    <span>{{ item.date }}</span>
                  </div>
                  <div class="item_body">
                    <div
                      :id="'help_' + item.id"
                      role="text"
                      class="item_info_area"
                    >
                      <p class="ttl">{{ item.title }}</p>
                      <p class="desc">
                        {{ item.content }}
                      </p>
                    </div>
                  </div>
                  <div class="btn_area">
                    <button>
                      <ArwNext />
                    </button>
                  </div>
                </router-link>
              </div>
            </li>
          </ul>
        </template>
        <!-- Dev : 검색결과 없음 -->
        <template v-if="resultIndex === 2">
          <p class="result_num">
            0 results for “<em>{{ keyword }}</em
            >” in all categories
          </p>
          <div class="guide_ui no_data">
            <div class="img_area">
              <picture>
                <source
                  media="(max-width: 600px)"
                  type="image/png"
                  :srcset="`${require('@/assets/img/img_mob_illust_nosearch_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nosearch_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nosearch.png')}`"
                />
                <img
                  :src="require('@/assets/img/img_illust_nosearch.png')"
                  alt="No Result"
                />
              </picture>
            </div>
            <p>
              We couldn’t find any match for “Search word.”<br />
              Try another keyword.
            </p>
            <a @click="$router.push('/help')" class="btn w_l h_l strong">
              Go to Help Center
            </a>
          </div>
        </template>
      </section>
    </div>
  </main>
</template>

<script>
import ArwNext from '@/components/ic/arwNext';
import ArwNextS from '@/components/ic/arwNextS';
import IcSearch from '@/components/ic/IcSearch';
import IcCloseS from '@/components/ic/IcCloseS';

export default {
  name: 'HelpCenterBoardResult',
  components: {
    IcSearch,
    ArwNext,
    IcCloseS,
    ArwNextS,
  },
  data() {
    return {
      tabMenu: [
        'All',
        'Getting Started',
        'Collecting',
        'Connect Wallet',
        'Selling Artworks',
        'Other FAQs',
      ],
      activeIndex: 0,
      resultIndex: 1,
      search: '',
      faqInfos: null,
      keyword: '',
    };
  },
  mounted() {
    this.getFaqSearchList();
  },
  methods: {
    handleSearch(event) {
      this.keyword = event.target.value || this.search;
      if (!this.keyword) return false;
      this.$router.push({
        name: 'helpCenterResult',
        params: { keyword: this.keyword },
      });
    },
    getFaqSearchList(keyword) {
      this.keyword = keyword || this.$route.params.keyword;
      let data = {
        keyword: this.keyword,
      };
      this.api.getFaqSearchList(data).then(res => {
        if (res.code === 0) {
          this.faqInfos = res.faqInfos;
          this.resultIndex = this.faqInfos.length === 0 ? 2 : 1;
        } else {
          this.$router.push('/network');
        }
      });
    },
    clear() {
      this.search = '';
      this.keyword = '';
      this.$refs.searchRef.focus();
    },
  },
};
</script>

<style scoped>
.guide_ui .img_area {
  margin-bottom: -1rem;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
}
@media screen and (max-width: 600px) {
  .guide_ui .img_area {
    margin-bottom: 0;
  }
}
textarea:focus,
input:focus {
  outline: none;
}
</style>
