<template>
  <nav class="thumb_nav_wrap">
    <div ref="wrap" class="thumb_slide_ui">
      <div
        ref="moveElWrap"
        class="has_scroll"
        @mousedown="satStartPoint"
        @mousemove="swipeMove"
        @touchstart="satStartPoint"
        @touchmove="swipeMove"
      >
        <ul ref="moveEl" class="thumb_nav_list move_el">
          <li
            v-for="(item, i) in itemData"
            :key="'docent_' + i"
            class="nav_node"
            @click="setCurrent(item, i)"
          >
            <a
              href="javascript:;"
              ref="item"
              class="nav_item"
              :class="{ is_active: currentDocent === i }"
            >
              <div class="thumb_area">
                <img
                  :src="item.thumbInfos[0].resUrl"
                  :width="180"
                  :height="101"
                  :alt="
                    item.thumbInfos[0].tpDesc ? item.thumbInfos[0].tpDesc : ''
                  "
                />
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div
        v-if="this.itemData.length - this.showItemCount > 0"
        class="control_group"
      >
        <div class="control_area pagination_area type_num">
          <span class="current">{{ currentDocent + 1 }}</span> /
          <span>{{ itemData.length }}</span>
        </div>
        <div class="control_area slide_nav_area">
          <button
            class="btn type_ic prev"
            :disabled="currentDocent < 1"
            @click="setNav('prev')"
          >
            <ArwPrevS />
          </button>
          <button
            class="btn type_ic next"
            :disabled="currentDocent >= itemData.length - 1"
            @click="setNav('next')"
          >
            <ArwNextS />
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import ArwPrevS from '@/components/ic/arwPrevS';
import ArwNextS from '@/components/ic/arwNextS';
export default {
  name: 'DocentThumbSlide',
  components: {
    ArwPrevS,
    ArwNextS,
  },
  data() {
    return {
      currentDocent: 0,
      slideDir: 'ver',
      itemSize: 0,
      slideSize: 0,
      moveWrapSize: 0,
      showItemCount: 5,
      isPress: false,
      before: 0,
      startPoint: 0,
      endPoint: 0,
      isMob: false,
      itemGap: 20,
      mobGap: 0,
    };
  },
  props: {
    itemData: {
      type: Array,
      default: null,
    },
    addClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    setCurrent(item, idx) {
      this.currentDocent = idx;
      this.clickMove();
      this.$emit('setCurrent', item, idx);
    },
    setNav(dir) {
      if (dir === 'prev') {
        this.currentDocent =
          this.currentDocent > 0 ? this.currentDocent - 1 : 0;
      } else {
        this.currentDocent =
          this.currentDocent < this.itemData.length - 1
            ? this.currentDocent + 1
            : this.itemData.length - 1;
      }
      this.clickMove();
      this.$emit('setCurrent', {
        idx: this.currentDocent,
        item: this.itemData[this.currentDocent],
      });
    },
    setMoveSize() {
      const winW = window.innerWidth;
      this.isMob = winW <= 1204 ? true : false;
      this.itemGap = winW > 600 ? 20 : 10;
      this.mobGap = winW > 1204 ? 0 : winW > 600 ? 40 : 10;
      if (!this.isMob) {
        this.itemSize = this.$refs.item[0].scrollHeight;
        this.moveWrapSize = this.$refs.moveElWrap.clientHeight;
        this.slideSize = this.$refs.moveElWrap.scrollHeight;
      } else {
        this.itemSize = this.$refs.item[0].scrollWidth;
        this.moveWrapSize = window.innerWidth;
        this.slideSize = this.$refs.moveElWrap.scrollWidth;
      }
      this.showItemCount =
        parseInt(this.moveWrapSize / (this.itemSize + this.itemGap)) + 1;
    },
    clickMove() {
      if (!this.itemSize) this.setMoveSize();
      const moveSize =
        this.itemData.length - this.showItemCount < 0
          ? 0
          : this.currentDocent > this.itemData.length - this.showItemCount
          ? this.slideSize - this.moveWrapSize - this.itemGap + this.mobGap
          : (this.itemSize + this.itemGap) * this.currentDocent;
      this.moveEvt(moveSize * -1, true);
    },
    swipeMove(e) {
      e.preventDefault();
      const elWrap = this.$refs.moveElWrap;
      if (this.isPress) {
        const move = this.isMob
          ? e.changedTouches[0].clientX - elWrap.offsetLeft
          : e.clientY - elWrap.offsetTop;
        const moveSize = move - this.startPoint;
        this.$refs.moveEl.classList.add('is_moving');
        const distance = moveSize * 0.6 + this.endPoint;
        this.moveEvt(distance);
      }
    },
    moveEvt(distance, set = false) {
      this.$refs.moveEl.style.transform = this.isMob
        ? 'translate3d(' + distance + 'px, 0, 0)'
        : 'translate3d(0, ' + distance + 'px, 0)';
      if (set) this.before = this.endPoint = distance;
    },
    satStartPoint(e) {
      if (!this.isPress) {
        this.startPoint = this.isMob
          ? e.changedTouches
            ? e.changedTouches[0].clientX
            : 0
          : e.clientY;
        this.isPress = true;
      }
    },
    setEndPoint(e) {
      this.isPress = false;
      this.$refs.moveEl.classList.remove('is_moving');
      let calc = 0;
      if (this.$refs.moveEl.style.transform)
        calc = this.isMob
          ? this.$refs.moveEl.style.transform
              .split(',')[0]
              .split('(')[1]
              .trim()
              .replace('px', '')
          : this.$refs.moveEl.style.transform
              .split(',')[1]
              .trim()
              .replace('px', '');
      this.endPoint = Number(calc);
      const slideEnd = this.slideSize - this.moveWrapSize - this.itemGap;
      const itemHalf = this.itemSize / 2;
      if (this.itemData.length - this.showItemCount < 0) {
        this.moveEvt(0, true);
        return;
      }
      if (
        Math.abs(this.endPoint - this.before) < 5 &&
        (!e.target.closest('.thumb_slide_ui') ||
          e.target.closest('.thumb_slide_ui .slide_nav_area .btn') ||
          e.target.closest('.thumb_nav_list'))
      )
        return;
      // 슬라이드 이전 영역
      if (this.endPoint > 0) this.currentDocent = 0;
      // 슬라이드 이후 영역
      else if (slideEnd + this.endPoint <= 0)
        this.currentDocent =
          Math.floor(slideEnd / (this.itemSize + this.itemGap)) + 1;
      // 움직인 영역이 이미지크기의 반보다 작을때 (Flicking으로 구현하진 않았습니다. Flicking으로 구현되면 더 좋을것 같긴합니다.)
      else if (Math.abs(this.endPoint - this.before) < itemHalf) {
        if (this.endPoint - this.before > 0) {
          this.currentDocent--;
        } else if (this.endPoint - this.before < 0) {
          this.currentDocent++;
        }
      } else {
        const count =
          Math.floor(
            Math.abs(this.endPoint - this.before) /
              (this.itemSize + this.itemGap),
          ) + 1;
        const distance = (this.itemSize + this.itemGap) * count;
        if (
          this.before - this.endPoint > 0 &&
          itemHalf + (distance + this.endPoint) > -itemHalf
        ) {
          this.currentDocent = this.currentDocent + count;
        }
        if (
          this.before - this.endPoint < 0 &&
          itemHalf + (distance + this.endPoint) > 0
        ) {
          this.currentDocent = this.currentDocent - count;
        }
      }
      this.clickMove();
      this.before = this.endPoint;
    },
  },
  watch: {
    isMob() {
      if (this.isMob) {
        document.addEventListener('touchend', this.setEndPoint);
        document.removeEventListener('mouseup', this.setEndPoint);
      } else {
        document.addEventListener('mouseup', this.setEndPoint);
        document.removeEventListener('touchend', this.setEndPoint);
      }
      this.$refs.moveEl.classList.add('is_moving');
      this.clickMove();
      this.isPress = false;
      setTimeout(() => {
        this.$refs.moveEl.classList.remove('is_moving');
      }, 50);
    },
  },
  mounted() {
    this.setMoveSize();
    window.addEventListener('resize', this.setMoveSize);
    this.isMob
      ? document.addEventListener('touchend', this.setEndPoint)
      : document.addEventListener('mouseup', this.setEndPoint);
  },
  beforeDestroy() {
    this.isMob
      ? document.removeEventListener('touchend', this.setEndPoint)
      : document.removeEventListener('mouseup', this.setEndPoint);
    window.removeEventListener('resize', this.setMoveSize);
  },
};
</script>

<style scoped>
.move_el {
  transition: ease-out 0.3s;
}
.move_el.is_moving {
  pointer-events: none;
  transition: 0s;
  user-select: none;
}
</style>
