<template>
  <header id="subHeader" class="sub_header">
    <article class="page_sec">
      <div class="page_head">
        <h1 class="page_ttl">{{ setTitle }}</h1>
        <button class="btn type_ic close_page" @click="close">
          <IcCloseS />
        </button>
      </div>
    </article>
  </header>
</template>

<script>
import IcCloseS from '@/components/ic/IcCloseS';
export default {
  name: 'SubHeaderBase',
  components: { IcCloseS },
  props: {
    setTitle: {
      type: String,
      default: 'Search',
    },
  },
  methods: {
    close() {
      this.$router.back();
    },
  },
};
</script>

<style scoped></style>
