<template>
  <IconBase
    icon-name="IcDownload"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    :stroke-color="strokeColor"
    :stroke-width="strokeWidth"
  >
    <path d="m7 13.3 8 8 8-8M15 21.3v-17M26 25.7H4" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcDownloadL',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    strokeWidth: {
      type: [Number, String],
      default: 1.2,
    },
    iconLabel: {
      type: String,
      default: 'Download',
    },
  },
};
</script>

<style scoped></style>
