<template>
  <IconBase
    icon-name="icWrite"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path data-name="Path 3650" d="M22.5 17.474V23.5h-16v-16H15" />
    <path
      data-name="Path 3649"
      d="m23.243 5.151-8.486 8.486-1.414 4.242 4.243-1.414L26.07 7.98Z"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'Write',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Edit My Profile',
    },
  },
};
</script>

<style scoped></style>
