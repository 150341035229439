<template>
  <div class="collection_wrap">
    <div class="edit_item type_card">
      <div
        class="input_area check"
        role="checkbox"
        v-if="showButtons"
        :aria-describedby="'ttl_' + itemData.artworkId"
        :aria-checked="isChecked"
        @click="setChk(itemData)"
      >
        <div class="input_box">
          <label>
            <input
              type="checkbox"
              :value="itemData.artworkId"
              aria-hidden="true"
              :label="'check_' + itemData.artworkId"
              v-model="isChecked"
            />
            <span></span>
          </label>
        </div>
      </div>
      <div class="item_head">
        <img
          class="img_card"
          :src="
            itemData.mimeType === 'image/jpeg'
              ? itemData.imageInfos[0].resUrl
              : itemData.videoThumbInfos[0].resUrl
          "
          :width="337"
          :alt="itemData.artistName ? itemData.artistName : ''"
        />
        <!-- PUB : Origin code-->
        <!-- <span
          class="ic_area type_video"
          v-if="itemData.mimeType === 'video/mp4'"
        >
          icon-color="#fff"  FF521D
          <IcVideo />
        </span> -->
        <!-- PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가 -->
        <div class="ic_area type_blockchains">
          <IcHedera v-if="itemData.bcNwTp == '10'" />
          <IcEth v-else />
        </div>
      </div>
      <div class="item_body">
        <div class="basic_info_group">
          <!-- TODO: 7/7 현재 아티스트 이름만 불러올 수 있는 상태이므로 추후 수정되어야 함 -->
          <div
            class="profile_info is_art"
            :tabindex="showButtons ? -1 : 0"
            @click="goArtistProfile()"
          >
            <span
              v-if="itemData.avatarRsrcUrl"
              class="profile"
              :style="`background-image: url('${itemData.avatarRsrcUrl}')`"
            ></span>
            <IcProfile v-else class="profile" />
            <IcRing class="ic_ring" />
            <span class="name">{{ itemData.artistName }}</span>
          </div>
          <div :id="'ttl_' + itemData.id" class="ttl_area">
            <h3 class="ttl">{{ itemData.title }}</h3>
            <p class="edit">
              {{ itemData.editionId }} of {{ itemData.totalCount }} Editions
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
// import IcVideo from '@/components/ic/IcVideo';
import IcRing from '@/components/ic/IcRing';
import IcEth from '@/components/ic/IcEth';
import IcHedera from '@/components/ic/IcHedera';
// PUB : 비디오 아이콘 컴포넌트 삭제, 이더리움 및 헤데라 아이콘 컴포넌트 추가
export default {
  name: 'CollectionsCard',
  components: {
    IcRing,
    // IcVideo,
    IcProfile,
    IcEth,
    IcHedera,
    // PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가
  },
  props: {
    itemData: {
      type: Object,
      default: null,
    },
    showBadge: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
    isAllChecked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    goArtistProfile() {
      // artist mbrno API 추가 필요
      // this.$router.push({
      //   name: 'ProfileArtist',
      //   params: {
      //     mbrNo: this.itemData.mbrNo,
      //   },
      // });
    },
    setChk(item) {
      this.isChecked = !this.isChecked;
      const data = {
        item: item,
        isChecked: this.isChecked,
      };
      this.$emit('setChecked', data);
    },
  },
  watch: {
    isAllChecked(value) {
      this.isChecked = value;
    },
  },
};
</script>

<style scoped>
.img_card {
  aspect-ratio: 16 / 9;
  object-fit: contain;
}
</style>
