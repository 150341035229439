<template>
  <main id="contents" class="my_list_page activities_page" v-if="activityInfos">
    <div class="set_inner clearFix">
      <h1 class="page_ttl only_pc">My Account</h1>
      <h1 class="page_ttl dv_tab">Activities</h1>
      <AccountMenu />
      <div class="min_inner">
        <section class="activities_sec">
          <div class="activities_sec_head">
            <div class="ttl_area">
              <h2 class="ttl">Select Term</h2>
            </div>
            <div class="date_group">
              <ul class="date_btn_area">
                <li>
                  <button
                    class="btn basic w_xs h_l"
                    type="button"
                    :class="{ is_active: termMonth == 1 }"
                    @click="selectMonthTerm(1)"
                    :aris-pressed="termMonth == 1 ? 'true' : 'false'"
                  >
                    <span class="is_blind" v-if="termMonth == 1">Selected</span>
                    1 month
                  </button>
                </li>
                <li>
                  <button
                    class="btn basic w_xs h_l"
                    type="button"
                    :class="{ is_active: termMonth == 3 }"
                    @click="selectMonthTerm(3)"
                    :aris-pressed="termMonth == 3 ? 'true' : 'false'"
                  >
                    <span class="is_blind" v-if="termMonth == 3">Selected</span>
                    3 month
                  </button>
                </li>
                <li>
                  <button
                    class="btn basic w_xs h_l"
                    type="button"
                    :class="{ is_active: termMonth == 6 }"
                    @click="selectMonthTerm(6)"
                    :aris-pressed="termMonth == 6 ? 'true' : 'false'"
                  >
                    <span class="is_blind" v-if="termMonth == 6">Selected</span>
                    6 month
                  </button>
                </li>
                <li>
                  <button class="dv_tab" @click="openDate">
                    <IcMore v-if="!showDate" :iconLabel="'UnFold'" />
                    <IcMobCloseDrop v-else :iconLabel="'Fold'" />
                  </button>
                </li>
              </ul>
              <p class="or only_pc">or</p>
              <div class="input_area date" :class="{ is_active: showDate }">
                <div class="input_box">
                  <Datepicker
                    v-model="srchFromLabel"
                    :disabled-dates="{ from: new Date(srchToLabel) }"
                    :aria-label="'Calendar-startdate'"
                    calendarClass="custom-calendar"
                  ></Datepicker>
                  <IcCalendar />
                </div>
                <p class="wiggle only_pc">~</p>
                <div class="input_box">
                  <Datepicker
                    v-model="srchToLabel"
                    :disabled-dates="{ to: new Date(srchFromLabel) }"
                    :aria-label="'Calendar-enddate'"
                    calendarClass="custom-calendar"
                  ></Datepicker>
                  <IcCalendar class="datepickers" />
                </div>
              </div>
              <button
                class="search_btn"
                :class="{ is_active: showDate }"
                @click="dateSearch"
              >
                <IcSearch />
              </button>
            </div>
          </div>
          <div class="activities_sec_body">
            <div class="list_wrap">
              <header class="list_head has_elem">
                <p class="count">
                  <span class="c_point">{{ totalSearchCount }}</span>
                  Activities
                </p>
                <div class="sort_group">
                  <div class="input_area select type_hoz drop_ui">
                    <span id="dropLabel" class="label">Filter by</span>
                    <div class="input_box drop_box">
                      <!-- Dev: 220503 추가 - drop menu 열려을때 클래스 바인드 -->
                      <button
                        class="drop_btn has_arw"
                        :class="{ is_active: showSelect }"
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-controls="dropOpts"
                        aria-labelledby="dropLabel"
                        :aria-expanded="showSelect ? 'true' : 'false'"
                        :aria-activedescendant="'sort_op_' + focusOpt"
                        @click="openDrop('dropOpts', $event)"
                      >
                        {{ filteredText }}
                        <ArwSelect aria-hidden="true" />
                      </button>
                      <!-- PUB : 필터 블록체인 옵션 추가 S -->
                      <transition name="fade">
                        <ul
                          v-if="showSelect"
                          ref="dropOpts"
                          id="dropOpts"
                          class="drop_op_list"
                          role="listbox"
                        >
                          <li class="sort_tit">
                            <span class="noTopM">Blockchains</span>
                          </li>
                          <li role="option" :aria-selected="false">
                            <div class="input_area check">
                              <div class="input_box">
                                <label>
                                  <input
                                    v-model="filterBlockchainsType"
                                    type="checkbox"
                                    name="f_blockchains"
                                    value="20"
                                    label="ethereum"
                                  />
                                  <span
                                    ><IcEthS
                                      style="width: 2.4rem; height: 2.6rem"
                                    />
                                    Ethereum</span
                                  >
                                </label>
                              </div>
                            </div>
                          </li>
                          <li role="option" :aria-selected="false">
                            <div class="input_area check">
                              <div class="input_box">
                                <label>
                                  <input
                                    v-model="filterBlockchainsType"
                                    type="checkbox"
                                    name="f_blockchains"
                                    value="10"
                                    label="hedera"
                                  />
                                  <span
                                    ><IcHederaS
                                      style="width: 2.4rem; height: 2.6rem"
                                    />
                                    Hedera</span
                                  >
                                </label>
                              </div>
                            </div>
                          </li>
                          <li class="sort_tit">
                            <span class="bdTop">Purchase Status</span>
                          </li>
                          <!-- PUB : 필터 블록체인 옵션 추가 E -->
                          <li
                            :key="'sort_op_0'"
                            :id="'sort_op_0'"
                            role="option"
                            aria-selected="false"
                          >
                            <div class="input_area check" @click="selectAll()">
                              <div class="input_box">
                                <label>
                                  <input
                                    type="checkbox"
                                    v-model="allSelected"
                                    label="isAllChecked"
                                  />
                                  <span>All</span>
                                </label>
                              </div>
                            </div>
                          </li>
                          <li
                            v-for="(opt, i) in sortOptData"
                            :key="'sort_op_' + i + 1"
                            :id="'sort_op_' + i + 1"
                            role="option"
                            aria-selected="false"
                            @click="selOtp(opt, i)"
                          >
                            <div class="input_area check">
                              <div class="input_box">
                                <label>
                                  <input
                                    type="checkbox"
                                    v-model="selected"
                                    :value="opt.label"
                                    :label="opt.label"
                                  />
                                  <span>{{ opt.label }}</span>
                                </label>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </transition>
                    </div>
                  </div>
                </div>
              </header>
              <div v-if="totalSearchCount > 0">
                <ActivitiesItem :itemData="activityInfos" />
              </div>
              <div v-else>
                <div class="guide_ui no_data">
                  <div class="img_area">
                    <picture>
                      <source
                        media="(max-width: 600px)"
                        type="image/png"
                        :srcset="`${require('@/assets/img/img_mob_illust_nodata_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nodata_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nodata.png')}`"
                      />
                      <img
                        :src="require('@/assets/img/img_illust_nodata.png')"
                        alt="nodata"
                      />
                    </picture>
                  </div>
                  <p>You don’t have any activities yet!</p>
                  <button
                    class="btn w_xl h_l strong no_data"
                    @click="goMarketPlaceItemList()"
                  >
                    Go to Marketplace
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import IcSearch from '@/components/ic/IcSearch';
import ArwSelect from '@/components/ic/arwSelect';
import ActivitiesItem from '@/components/mylist/ActivitiesItem';
import AccountMenu from '@/components/common/AccountMenu';
import IcMore from '@/components/ic/IcMore';
import Datepicker from 'vuejs-datepicker';
import IcCalendar from '@/components/ic/IcCalendar';
import IcMobCloseDrop from '@/components/ic/IcMobCloseDrop';
import IcEthS from '@/components/ic/IcEthS';
import IcHederaS from '@/components/ic/IcHederaS';
// PUB : 이더리움 및 헤데라 아이콘 컴포넌트 추가
export default {
  name: 'ActivitiesView',
  components: {
    IcMobCloseDrop,
    IcCalendar,
    IcMore,
    AccountMenu,
    ActivitiesItem,
    IcSearch,
    IcEthS,
    IcHederaS,
    ArwSelect,
    Datepicker,
    // PUB : 리프레시 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가
  },
  data() {
    return {
      isFirstLanding: false,
      testt: '',
      nowTime: '',
      showDate: false,
      ellipsis: '',
      activityType: '',
      srchFrom: '',
      srchTo: '',
      srchFromLabel: '',
      srchToLabel: '',
      termMonth: 1,
      activityInfos: null,
      totalSearchCount: 0,
      sortOptData: [
        {
          label: 'Pending',
          value: 80,
        },
        {
          label: 'Purchased',
          value: 20,
        },
        {
          label: 'Sold',
          value: 30,
        },
        {
          label: 'Transferred',
          value: 60,
        },
        {
          label: 'Failed',
          value: 90,
        },
      ],
      // PUB : 필터 블록체인 옵션 추가
      filterBlockchainsType: ['10', '20'],
      showSelect: false,
      selectedOpt: {
        label: 'All',
        value: '',
      },
      focusOpt: 0,
      allSelected: false,
      selected: [],
      selectedValue: [],
      isAll: false,
      allReseted: false,
      state: {
        disableDates: {
          // to: ,
          from: this.srchFrom,
        },
      },
    };
  },
  watch: {
    filterBlockchainsType() {
      this.getActivities();
    },
  },
  computed: {
    filteredText() {
      let text = '';
      if (this.selected.length == 4 || this.selected.length == 0) {
        text = 'All';
      } else {
        text = this.selected.join(', ');
      }
      // 15자 제한
      return text.length > 15 ? text.substring(0, 13) + '...' : text;
    },
  },
  methods: {
    selectMonthTerm(num) {
      this.termMonth = num;
      this.srchFrom = '';
      this.srchTo = '';

      this.srchFromLabel = this.$dayJs()
        .subtract(this.termMonth, 'month')
        .format('DD MMM YYYY');
      this.srchToLabel = this.$dayJs().format('DD MMM YYYY');
      this.getActivities();
    },
    dateSearch() {
      this.srchFrom = this.$dayJs(this.srchFromLabel).format('YYYYMMDD');
      this.srchTo = this.$dayJs(this.srchToLabel).format('YYYYMMDD');
      this.termMonth = 0;

      this.getActivities();
    },
    openDrop() {
      this.showSelect = !this.showSelect;
      this.showFilter = false;
    },
    goMarketPlaceItemList() {
      this.$router.push('/marketplace');
    },
    selectAll() {
      this.isAll = true; // All 버튼을 눌렀는지 여부 /  this.allSelected는 현재 전체선택중인지 여부
      if (this.allSelected) {
        this.disableAllSelected();
      } else {
        this.allSelected = true;
        this.selected = ['Purchased', 'Sold', 'Transferred', 'Restored'];
        this.selectedValue = ['20', '30', '60', '70'];
      }

      if (this.isFirstLanding) {
        this.termMonth = 1;
        this.isFirstLanding = false;
      } else {
        this.termMonth = 0;
      }

      this.activityType = '';
      this.selectedOpt = {
        label: 'All',
        value: '',
      };
      this.getActivities();
    },

    disableAllSelected() {
      this.allReseted = true;
      this.allSelected = false;
      this.selected = [];
      this.selectedVale = [];
    },

    selOtp(opt) {
      this.termMonth = 0;
      let x = this.selected.indexOf(opt.label);

      if (x != -1) {
        this.selected.splice(x, 1);
        if (this.isAll) {
          this.selectedValue = ['20', '30', '60', '70'];
          this.isAll = false;
          this.allSelected = false;
        }
        let filtered = this.selectedValue.filter(
          element => element != opt.value,
        );
        this.selectedValue = filtered;
      } else {
        if (this.allReseted) {
          this.selectedValue = [];
          this.allReseted = false;
        }
        this.selected.push(opt.label);
        this.selectedValue.push(opt.value);

        if (this.selected.length == 4) {
          this.selectAll();
        }
      }

      this.selectedOpt = opt;

      this.srchFrom = this.$dayJs(this.srchFromLabel).format('YYYYMMDD');
      this.srchTo = this.$dayJs(this.srchToLabel).format('YYYYMMDD');
      this.activityType = this.selectedValue.join();
      this.getActivities();
      // if (this.selectedOpt.value == opt.value) {
      //   this.activityType = '';

      //   this.getActivities();
      // } else {
      // this.focusOpt = idx;
    },
    openDate() {
      this.showDate = !this.showDate;
    },
    async getActivities() {
      let params = {
        activityType: this.activityType,
        srchFrom: this.srchFrom,
        srchTo: this.srchTo,
        termMonth: this.termMonth,
      };

      params.bcNwTp =
        !this.filterBlockchainsType.length ||
        this.filterBlockchainsType.length === 2
          ? ''
          : this.filterBlockchainsType[0];

      let activitiesResult = await this.api.getUserActivityHis(params);
      if (activitiesResult.code === 0) {
        this.activityInfos = [...activitiesResult.activityInfos];
        this.totalSearchCount = activitiesResult.totalSearchCount;
      }
    },
  },
  mounted() {
    this.isFirstLanding = true;
    this.srchFromLabel = this.$dayJs()
      .subtract(this.termMonth, 'month')
      .format('DD MMM YYYY');
    this.srchToLabel = this.$dayJs().format('DD MMM YYYY');
    this.selectAll();
  },
};
</script>

<style scoped>
@import url(../../assets/css/customGlobal.css);
.btn.no_data {
  margin-top: 3rem;
  min-width: 30rem;
}
::v-deep .thumb_item.type_list .owner_info_group dt {
  margin-right: 0;
}
::v-deep .thumb_item.type_list .owner_info_group dd.profile_info {
  cursor: default;
}
</style>
