<template>
  <IconBase
    icon-name="icCheckMark"
    :icon-label="iconLabel"
    :width="60"
    :height="60"
    viewBox="0 0 60 60"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path d="m19.4 30.3 7.6 6.8 12.7-13.5" />
    <circle cx="30" cy="30" r="24.5" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcCheckMark',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Check Mark',
    },
  },
};
</script>

<style scoped></style>
