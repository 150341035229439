<template>
  <IconBase
    icon-name="icVideo"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    :strokeColor="strokeColor"
    stroke-width="1.2"
  >
    <path d="m20.4 18.4 6 2.6V9.6l-6 2.8V8.6H3.6v13.8h16.8v-4z" />
    <circle cx="7.5" cy="12.5" r="1.5" :fill="strokeColor" stroke-width="0" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcVideo',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Video',
    },
  },
};
</script>

<style scoped></style>
