<template>
  <div>
    <div id="go_main">
      <a href="#container">Skip to content</a>
    </div>
    <div
      id="wrapper"
      :style="`--min: ${minH}; --nf: ${noneFoot};`"
      :class="{ nothead: $route.meta.isHeaderHide }"
      @click="handleClick"
      @keyup.tab="handleClick"
    >
      <HeaderBase
        ref="header"
        :class="pageClass"
        @openSignin="openSignin"
        v-if="!$route.meta.isHeaderHide"
      />
      <SubHeaderBase
        v-if="subHead"
        ref="subHeader"
        :class="pageClass"
        :setTitle="title"
        @openSignin="openSignin"
      />
      <div id="container">
        <slot />
      </div>
      <FooterBase
        v-if="!$route.meta.isFooterHide"
        ref="footer"
        :class="pageClass"
      />
      <AlertModal ref="alert" :alertData="alertData" />
      <TutorialModal ref="tutorial" />
      <!-- <SigninModal ref="signin" @openWallet="openWalletConnect()" /> -->
      <!-- <WalletQrModal
      v-if="showWallet && !$isMobile()"
      :showWallet="showWallet"
      @close="closeWalletConnect"
    />
    -->
      <ConnectWalletModal
        v-if="showWallet && $isMobile()"
        :showWallet="showWallet"
        :walletTokenExpired="walletTokenExpired"
        :walletChange="walletChange"
        :walletInfo="walletInfo"
        @close="closeWalletConnect"
      />
      <noticeModal
        v-if="showNotice"
        :showNotice.sync="showNotice"
        @closeNotice="closeNotice"
      />
      <commonModal
        v-if="commonModal"
        :commonModal="commonModal"
        @closeCommon="closeCommon"
      />
    </div>
  </div>
</template>

<script>
import HeaderBase from '@/components/layout/HeaderBase';
import FooterBase from '@/components/layout/FooterBase';
import AlertModal from '@/components/common/AlertModal';
import SubHeaderBase from '@/components/layout/SubHeaderBase';
import TutorialModal from '@/components/common/TutorialModal';
/*
import SigninModal from '@/components/common/SigninModal';
import WalletQrModal from '@/components/mylist/WalletQrModal';
*/
import ConnectWalletModal from '@/components/marketplace/ConnectWalletModal';
import noticeModal from '@/components/common/noticeModal';
import commonModal from '@/components/common/commonModal';

export default {
  name: 'DefaultLayout',
  components: {
    TutorialModal,
    SubHeaderBase,
    AlertModal,
    HeaderBase,
    FooterBase,
    /*
    SigninModal,
    WalletQrModal,
    */
    ConnectWalletModal,
    noticeModal,
    commonModal,
  },
  data() {
    return {
      minH: 0,
      noneFoot: 0,
      alertData: null,
      isMob: false,
      pageClass: '',
      modalCount: 0,
      subHead: false,
      title: '',
      showAlarm: false,
      showMyListMenu: false,
      isShare: false,
      isType: false,
      showFilter: false,
      showWallet: false,
      walletTokenExpired: false,
      walletChange: false,
      showNotice: false,
      commonModal: false,
      walletInfo: '',
    };
  },
  created() {
    // Alert 모달 활성화
    this.$root.$on('openAlert', alertData => {
      this.alertData = alertData;
      this.$refs.alert.open();
    });

    this.$root.$on('closeAlert', () => {
      this.$refs.alert.close();
    });

    // 페이지 클래스 부여
    this.$root.$on(
      'setPage',
      ({ page: page, subHead: subHead, title: title }) => {
        this.pageClass = page;
        this.subHead = subHead ?? false;
        this.title = title;
      },
    );
    // 스크롤 차단
    this.$root.$on('setScrollFixed', Boolean => {
      const devIOS = this.$isMobile(); // iOS 일 경우 : html에 'dv_ios' 클래스 부여 필요
      if (devIOS) {
        this.iosScrollFixed(Boolean);
        return;
      }
      this.scrollFixed(Boolean);
    });

    this.$root.$on('openTutorial', () => {
      this.$refs.tutorial.open();
    });
    this.$root.$on('walletTokenExpired', walletInfo => {
      this.walletInfo = walletInfo;
      this.showWallet = true;
      this.walletChange = false;
      this.walletTokenExpired = true;
    });
    this.$root.$on('walletChange', walletInfo => {
      this.walletInfo = walletInfo;
      this.showWallet = true;
      this.walletChange = true;
      this.walletTokenExpired = false;
    });
    this.$root.$on('noticeModal', () => {
      this.showNotice = true;
    });
    this.$root.$on('commonModal', () => {
      this.commonModal = true;
    });
  },
  watch: {
    $route() {
      this.pageInit(); // 페이지 초기화
    },
  },
  computed: {
    openService() {
      return JSON.parse(process.env.VUE_APP_OPEN_SERVICE) ? true : false;
    },
  },
  mounted() {
    if (!this.openService) {
      this.showNotice = true;
    }
    this.minHeight(); // 화면 최소 높이 세팅
    this.checkDev(); // 모바일 체크
    window.addEventListener('resize', this.minHeight);
    window.addEventListener('resize', this.checkDev);

    this.$EventBus.$on('showAlarm', boolean => {
      this.showAlarm = boolean;
    });
    this.$EventBus.$on('showMyListMenu', boolean => {
      this.showMyListMenu = boolean;
    });
    this.$EventBus.$on('showMobMenu', boolean => {
      this.showMobMenu = boolean;
    });
    this.$EventBus.$on('isShare', boolean => {
      this.isShare = boolean;
    });
    this.$EventBus.$on('isType', boolean => {
      this.isType = boolean;
    });
    this.$EventBus.$on('showFilter', boolean => {
      this.showFilter = boolean;
    });
  },
  methods: {
    minHeight() {
      const winW = window.innerWidth;
      const winH = window.innerHeight;
      const head = winW < 1025 ? 60 : 70;
      const foot = this.$refs.footer?.$el;
      this.noneFoot = winH - head;
      this.minH = foot ? winH - head - foot.clientHeight - 1 : winH - head;
    },
    openSignin() {
      //user 닉네임 비활성화 : nickname 등록 화면을 제거하고 자동생성 제공으로 변경
      //미사용
      this.$refs.signin.open();
    },
    //userName 입력완료 -> 지갑연결 로직 필요가 없어짐 비활성화
    // openWalletConnect() {
    //   this.showWallet = true;
    // },
    closeWalletConnect() {
      this.showWallet = false;
      this.walletTokenExpired = false;
      this.walletChange = false;
    },
    checkDev() {
      const winW = window.innerWidth;
      this.isMob = winW <= 1024 ? true : false;
    },
    scrollFixed(Boolean) {
      const doc = document.documentElement;
      if (Boolean) {
        doc.classList.add('overHidden');
        this.modalCount++;
        return;
      }
      if (this.modalCount > 1) {
        this.modalCount--;
      } else {
        doc.classList.remove('overHidden');
        this.modalCount = 0;
      }
    },
    iosScrollFixed(Boolean) {
      const doc = document.documentElement;
      if (Boolean) {
        if (!doc.getAttribute('data-st')) {
          const st = window.pageYOffset;
          doc.setAttribute('data-st', st);
          doc.style.top = '-' + st + 'px';
        }
        doc.classList.add('overHidden');
        this.modalCount++;
        return;
      }
      if (this.modalCount > 1) {
        this.modalCount--;
      } else {
        const st = doc.getAttribute('data-st');
        doc.style.removeProperty('top');
        doc.removeAttribute('data-st');
        doc.classList.remove('overHidden');
        this.modalCount = 0;
        window.scrollTo(0, st);
      }
    },
    pageInit() {
      this.pageClass = '';
      const doc = document.documentElement;
      const st = doc.getAttribute('data-st');
      doc.classList.remove('overHidden');
      if (st) {
        doc.style.removeProperty('top');
        doc.removeAttribute('data-st');
      }
      this.modalCount = 0;
      this.minHeight();
    },
    closeNotice() {
      this.showNotice = false;
      this.$router.replace('/notice');
    },
    closeCommon() {
      this.commonModal = false;
    },
    handleClick(event) {
      if (this.showAlarm) {
        if (
          !event.target.closest('.notification-wrapper') &&
          !event.target.classList.contains('btn_alarm')
        ) {
          this.showAlarm = false;
          this.$EventBus.$emit('showAlarm', this.showAlarm);
        }
      }
      if (this.showMyListMenu) {
        if (
          !event.target.closest('.my_account_menu_wrap') &&
          !event.target.classList.contains('member_btn')
        ) {
          if (!event.target.closest('.pc-profile')) {
            this.showMyListMenu = false;
            this.$EventBus.$emit('showMyListMenu', this.showMyListMenu);
          }
        }
      }
      if (this.showMobMenu) {
        if (
          !event.target.closest('.anb') &&
          !event.target.closest('.toggle_menu')
        ) {
          this.showMobMenu = false;
          this.$EventBus.$emit('showMobMenu', this.showMobMenu);
        }
      }
      if (this.isShare) {
        if (
          !event.target.closest('.isShare') &&
          !event.target.classList.contains('share')
        ) {
          this.isShare = false;
          this.$EventBus.$emit('isShare', this.isShare);
        }
      }
      if (this.isType) {
        if (
          !event.target.closest('.isType') &&
          !event.target.classList.contains('type-button')
        ) {
          this.isType = false;
          this.$EventBus.$emit('isType', this.isType);
        }
      }
      if (this.showFilter) {
        if (
          !event.target.closest('.filter_opt_group') &&
          !event.target.classList.contains('btn_filter')
        ) {
          this.showFilter = false;
          this.$EventBus.$emit('showFilter', this.showFilter);
        }
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.minHeight);
    window.removeEventListener('resize', this.checkDev);
  },
};
</script>
