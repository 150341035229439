<template>
  <div class="list_wrap" :class="{ is_hover: isHover }" style="cursor: pointer">
    <div class="swiper_container list">
      <div class="list_head">
        <div class="ttl_area">
          <h2 class="ttl" role="status">
            <em>{{ num }}</em> {{ setTitle }}
          </h2>
        </div>
        <router-link
          v-if="window.width > 1024"
          to="/collections/mode"
          class="btn type_line_w h_l w_l mode"
        >
          Collection Mode<ArwNextS />
        </router-link>
      </div>
      <swiper
        :options="subVisualOpt"
        ref="subSlide"
        class="list_body"
        @click.native="$emit('swiperClick')"
      >
        <swiper-slide
          class="edit_node"
          v-for="(item, index) in itemData"
          :key="index"
        >
          <div class="collection_wrap">
            <div
              class="edit_item type_card"
              :class="{ is_active: currentIndex === index }"
              @mouseenter="setCurrent(item, index)"
              @mouseleave="removeHover"
            >
              <!-- Dev: 220503 삭제 - 모바일 링크 삭제 -->
              <div class="item_head">
                <img
                  v-if="item.videoThumbInfos || item.imageInfos"
                  :src="
                    item.mimeType === 'image/jpeg' && item.imageInfos
                      ? item.imageInfos[0].resUrl
                      : item.videoThumbInfos[0].resUrl
                  "
                  :width="498"
                  :alt="
                    item.title ? 'Capture of Artwork for the ' + item.title : ''
                  "
                />
                <!-- PUB : Origin code-->
                <!-- <span
                  class="ic_area type_video"
                  v-if="item.mimeType === 'video/mp4'"
                >
                  icon-color="#fff"  FF521D
                  <IcVideo />
                </span> -->
                <!-- PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가 -->
                <!-- WIP : 아이콘 분기-->
                <div class="ic_area type_blockchains">
                  <IcHedera v-if="item.bcNwTp == '10'" />
                  <IcEth v-else />
                </div>
              </div>
              <div class="item_body">
                <div class="basic_info_group">
                  <button
                    class="profile_info"
                    tabindex="0"
                    v-if="item.otherMaplYn == 'Y'"
                  >
                    <span
                      v-if="item.otherMaplLogoUrl"
                      class="profile"
                      :style="`background-image: url(${item.otherMaplLogoUrl})`"
                    ></span>
                    <IcProfile v-else class="profile" />
                    <span class="name">{{ item.otherPlatform }}</span>
                  </button>
                  <button class="profile_info" tabindex="0" v-else>
                    <span
                      v-if="item.avatarRsrcUrl"
                      class="profile"
                      :style="`background-image: url(${item.avatarRsrcUrl})`"
                    ></span>
                    <IcProfile v-else class="profile" />
                    <span class="name">{{
                      item.name ? item.name : item.artistName
                    }}</span>
                  </button>
                  <!-- Dev: 220503 삭제 - 아이디 삭제 -->
                  <div class="ttl_area" role="text">
                    <h3 class="ttl">{{ item.title }}</h3>
                    <p class="edit" v-if="item.listType === 'M'">
                      {{ item.editionId }} of
                      {{ item.totalCount }}
                      {{ item.totalEdition > 1 ? 'Editions' : 'Edition' }}
                    </p>
                    <p v-else>
                      {{ item.totalCount }}
                      {{ item.totalEdition > 1 ? 'Editions' : 'Edition' }}
                    </p>
                  </div>
                </div>
                <!--
                <router-link
                  :to="`/marketplace/detail?artworkId=${item.artworkId}&editionId=${item.editionId}`"
                  class="btn type_ic ic-info"
                >
                  <IcInfo />
                </router-link>
                -->
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <!-- <div v-if="isHover" class="dim_el"></div> -->
      <div v-if="dimmable" :class="{ dim_el: isHover }"></div>
      <div class="control_area slide_nav_area" v-if="window.width > 1024">
        <button class="btn type_ic prev">
          <ArwPrev />
        </button>
        <button class="btn type_ic next">
          <ArwNext />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import IcVideo from '@/components/ic/IcVideo';
import IcProfile from '@/components/ic/IcProfile';
import ArwPrev from '@/components/ic/arwPrev';
import ArwNext from '@/components/ic/arwNext';
import ArwNextS from '@/components/ic/arwNextS';
// import IcInfo from '@/components/ic/IcInfo';
import IcEth from '@/components/ic/IcEth';
import IcHedera from '@/components/ic/IcHedera';
// PUB : 비디오 아이콘 컴포넌트 삭제, 이더리움 및 헤데라 아이콘 컴포넌트 추가

export default {
  name: 'BgSlide',
  components: {
    // IcInfo,
    ArwNextS,
    // IcVideo,
    IcProfile,
    IcEth,
    IcHedera,
    // PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가
    ArwPrev,
    ArwNext,
  },
  props: {
    itemData: {
      type: Array,
      default: null,
    },
    setTitle: {
      type: String,
      default: null,
    },
    num: {
      type: Number,
      default: null,
    },
    dimmable: {
      type: Boolean,
      default: true,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      subVisualOpt: {
        loopedSlides: this.itemData.length,
        // autoplay: { delay: 5000 },
        slidesPerView: 4,
        spaceBetween: 20,
        navigation: {
          prevEl: '.slide_nav_area .prev',
          nextEl: '.slide_nav_area .next',
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          840: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          200: {
            slidesPerView: 1.4,
            spaceBetween: 20,
          },
        },
      },
      currentSlide: 0,
      window: {
        width: 0,
        height: 0,
      },
      isHover: false,
    };
  },
  computed: {
    swiper() {
      return this.$refs.subSlide.$swiper;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    setCurrent(item, index) {
      const data = { item: item, index: index };
      if (this.window.width > 1024) this.isHover = true;
      this.$emit('setCurrent', data);
    },
    removeHover() {
      if (this.window.width > 1024) this.isHover = false;
    },
  },
  watch: {
    currentIndex(index) {
      // NOTE: something odd code
      // let slidePerView = 4;
      // if (this.swiper.currentBreakpoint === '600') {
      //   slidePerView = 1.4;
      // }
      // if (index + 1 > slidePerView) {
      //   this.swiper.slideTo(index - 1 + slidePerView, 1000, false);
      // }

      // FIX:a temporary measure
      let currentBreakpoint = this.swiper.currentBreakpoint;
      let slidePerView =
        this.subVisualOpt?.breakpoints[currentBreakpoint]?.slidesPerView ?? 4;

      if (index % Math.floor(slidePerView) == 0) {
        this.swiper.slideTo(index, 1000, false);
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1200px) {
  .ic-info {
    margin-top: 1px;
    margin-left: 35px;
  }
}
.is_active:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid var(--main-color);
  pointer-events: none;
}
/* .swiper .swiper-slide {
  height: auto;
} */
.swiper-slide img {
  width: auto;
  /* height: 200px; */
  aspect-ratio: 16/9;
  object-fit: contain;
}
</style>
