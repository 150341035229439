<template>
  <IconBase
    icon-name="icProfile"
    :icon-label="iconLabel"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="strokeWidth"
  >
    <circle class="st0" cx="10" cy="10" r="9.4" />
    <path class="st0" d="M17.2 16.2c-4-4-10.4-4-14.4 0" />
    <circle class="st0" cx="10.1" cy="7.6" r="2.9" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcProfile',
  components: { IconBase },
  props: {
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    strokeWidth: {
      type: [Number, String],
      default: 1.2,
    },
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'no profile picture',
    },
  },
};
</script>

<style scoped></style>
