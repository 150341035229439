<template>
  <IconBase
    icon-name="IcEmail"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <g transform="translate(-1054 -670)">
      <path
        d="m1065.241 688.888-8.012-4.659 19.807-7.136-7.265 19.678Z"
        data-name="패스 3715"
      />
      <path d="m1065.364 688.778 10.77-10.77" data-name="패스 3716" />
    </g>
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcEmail',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Email',
    },
  },
};
</script>

<style scoped></style>
