import { tvappStoreActions } from './tvappStoreActions';
export const tvappStore = {
  namespaced: true,
  state: {
    cursorVisibility: false, // cursor visibility
    dialog: false, // dialog
  },
  getters: {
    cursorVisibility: state => {
      return state.cursorVisibility;
    },
    dialog: state => {
      return state.dialog;
    },
  },
  mutations: {
    cursorVisibility: (state, cursorVisibility) => {
      state.cursorVisibility = cursorVisibility;
    },
    dialog: (state, dialog) => {
      state.dialog = dialog;
    },
  },
  actions: tvappStoreActions,
};
