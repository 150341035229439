<template>
  <div class="modal_wrap" :class="{ is_show: show }">
    <div class="modal userProfile">
      <div class="modal_head">
        <h3 class="modal_ttl">Edit Profile</h3>
      </div>
      <div class="modal_body">
        <div class="profile_wrap">
          <div class="profile_area">
            <span
              v-if="profileUrl"
              :style="`background-image: url('${profileUrl}')`"
              class="profile"
            >
              <button class="ic_area type_camera">
                <input
                  type="file"
                  ref="uploadImage"
                  label="uploadImage"
                  title="profile photo"
                  @change="onFileUpload($event)"
                />
                <IcCamera />
              </button>
            </span>
            <span v-else class="profile none">
              <IcProfile class="ic_profile" width="132" height="132" />
              <button class="ic_area type_camera">
                <input
                  type="file"
                  ref="uploadImage"
                  label="uploadImage"
                  title="profile photo"
                  @change="onFileUpload($event)"
                />
                <IcCamera />
              </button>
            </span>
          </div>
          <div class="name_group">
            <div class="input_area txt">
              <div class="hoz_group">
                <div class="input_box">
                  <label class="is_blind" for="Username">ID</label>
                  <input
                    v-model="userNameData"
                    id="Username"
                    type="text"
                    title="Username"
                    @input="userNameInput($event)"
                  />
                </div>
                <div class="btn_area_no_margin_top">
                  <button
                    class="btn type_line_w w_s h_m"
                    :class="{
                      is_active: userNameData && userNameData.length > 0,
                    }"
                    @click="checkId"
                  >
                    Check <br class="dv_mob" />Availability
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- PUB : ZIP CODE 디자인 수정 S -->
          <div class="zipcode_area">
            <h4>Zip Code</h4>
            <div class="zipcode_group">
              <UpdateInfoModal
                ref="UpdateInfoModal"
                @setZipcode="setZipcode"
                :inner="this.zipInner"
                :profileType="'edit'"
              />
            </div>
          </div>
          <!-- PUB : ZIP CODE 디자인 수정 E -->

          <FamilyList v-if="show && !temporaryDisappearConditions" />
        </div>
      </div>
      <div class="modal_foot">
        <div class="btn_area">
          <button class="btn basic w_xs h_m" @click="close">Cancel</button>
          <button
            class="btn strong w_xs h_m"
            @click="onEditUser"
            :disabled="userValid == 'N'"
          >
            Save
          </button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
    <LoadingModal :isLoading="isLoading" message="Wait for Image Upload..." />
  </div>
</template>

<script>
import FamilyList from '@/components/mylist/FamilyList';
import IcCamera from '@/components/ic/IcCamera';
import IcCloseM from '@/components/ic/IcCloseM';
import IcProfile from '@/components/ic/IcProfile';
import UpdateInfoModal from '@/components/marketplace/UpdateInfoModal';
import { mapGetters, mapMutations } from 'vuex';
import store from '@/store/index';

export default {
  name: 'UserEditProfile',
  components: { FamilyList, IcCamera, IcCloseM, IcProfile, UpdateInfoModal },
  data() {
    return {
      show: false,
      successAlert: {
        type: 'success',
        desc: 'Username you created is available',
        modalClass: '.modal.userProfile',
        modalIndex: '2',
      },
      userValid: 'Y',
      overlapAlert: {
        type: 'warn',
        desc: 'Username you created has \nalready been taken.',
        modalClass: '.modal.userProfile',
        modalIndex: '2',
      },
      noInputAlert: {
        type: 'warn',
        title: '',
        desc: 'Username is mendatory for using LG Art Lab',
        modalClass: '.modal.userProfile',
        modalIndex: '2',
      },
      uploadFail: {
        type: 'warn',
        desc: '',
        id: 'uploadFail',
        modalClass: '.modal.userProfile',
      },
      profileUrl: null,
      isLoading: false,
      userNameData: '',
      profileRsrcId: '',

      zipInner: false,
      modalZipCodeInfo: {},

      // NOTE: for temporary disapear family tab logic ( when zero family )
      temporaryDisappearConditions: false,
    };
  },
  computed: {
    ...mapGetters({
      userType: 'userStore/userType',
      mbrNo: 'userStore/mbrNo',
      userName: 'userStore/userName',
      email: 'userStore/email',
      profileInfos: 'userStore/profileInfos',
      zpcd: 'userStore/zpcd',
      city: 'userStore/city',
      stat: 'userStore/stat',
    }),
    showSelectBox() {
      return this.zipCode.length === 5 && this.cityData.length > 0
        ? true
        : false;
    },
    showSelectBoxScroll() {
      return this.zipCode.length === 5 && this.cityData.length > 1
        ? true
        : false;
    },
    showPriceBox() {
      return this.zipCode.length === 5 &&
        this.cityOpt.city &&
        this.cityData.length > 0
        ? true
        : false;
    },
    viewCity() {
      return this.cityOpt.city
        ? this.cityOpt.city + ' ' + this.cityOpt.stat
        : 'Please Select City';
    },
  },
  async mounted() {
    // NOTE: for temporary disapear family tab logic ( when zero family )
    //let familyAccounts = await this.getFamilyAccounts();
    //this.temporaryDisappearConditions = familyAccounts.length == 0;
  },
  methods: {
    setZipcode(modalZipCodeInfo) {
      if (modalZipCodeInfo) {
        this.modalZipCodeInfo = modalZipCodeInfo;
      } else {
        this.modalZipCodeInfo = undefined;
      }
    },
    async zipCodeSave() {
      if (!this.modalZipCodeInfo.cityOpt.city) {
        return false;
      }
      let zipParam = {
        zpcd: this.modalZipCodeInfo.zipCode,
        city: this.modalZipCodeInfo.cityOpt.city,
        stat: this.modalZipCodeInfo.cityOpt.stat,
      };

      await this.api.setZipCodeSave(zipParam).then(res => {
        if (res.code === 0) {
          this.setZpcd(zipParam.zpcd);
          this.setCity(zipParam.city);
          this.setStat(zipParam.stat);
        }
      });
    },
    setData() {
      this.userNameData = this.userName;
      if (this.profileInfos && this.profileInfos.length > 0) {
        this.profileUrl = this.profileInfos[0].resUrl;
      } else {
        this.profileUrl = '';
      }
      this.userValid = 'Y';
      this.zipInner = true;
    },
    open() {
      this.show = true;
      this.setData();
      this.$root.$emit('setScrollFixed', true);
      this.setFocusTrap(true, '.modal.userProfile');
    },
    close() {
      this.setFocusTrap(false, '.modal.userProfile');
      this.$refs.uploadImage.value = '';
      this.show = false;
      this.zipInner = false;
      this.$root.$emit('setScrollFixed', false);
      setTimeout(() => {
        document.querySelector('.edit_btn').focus();
      }, 100);
    },
    userNameInput(event) {
      let userName = event.target.value;
      if (!userName) {
        return;
      }
      this.userNameData = userName;
      this.userValid = 'N';
    },
    async checkId() {
      if (!this.userNameData || this.userNameData.length < 1) {
        this.$root.$emit('openAlert', this.noInputAlert);
        return false;
      }

      let param = { userName: this.userNameData };
      await this.api.checkUserName(param).then(res => {
        if (res.code === 0) {
          this.userValid = res.enableYn;
          if (this.userValid === 'Y') {
            this.$root.$emit('openAlert', this.successAlert, 'signin');
          } else {
            this.$root.$emit('openAlert', this.overlapAlert);
          }
        } else {
          console.log('checkUserName fail');
        }
      });
    },
    async onEditUser() {
      let param = {
        userType: this.userType,
        mbrNo: this.mbrNo,
        email: this.email,
        userName: this.userNameData,
      };
      if (this.profileRsrcId) param.profileRsrcId = this.profileRsrcId;

      if (this.modalZipCodeInfo) {
        if (
          this.zpcd !== this.modalZipCodeInfo.zipCode ||
          this.city !== this.modalZipCodeInfo.cityOpt.city
        )
          this.zipCodeSave();
      }

      await this.api.editProfile(param).then(res => {
        if (res.code === 0) {
          this.setUserName(res.userName);
          store.commit('userStore/profileInfos', res.profileInfos);
          this.close();
        } else {
          console.log('createUserName fail');
        }
      });
    },
    onFileUpload(event) {
      this.isLoading = true;

      let formData = new FormData();
      let file = event.target.files[0];
      formData.append('file', file);
      formData.append('resourceType', 30);

      this.axios.post('/api/v1/file/upload', formData).then(res => {
        this.isLoading = false;
        this.$refs.uploadImage.value = '';
        if (res.data.code === 0) {
          this.profileRsrcId = res.data.resourceId;
          this.profileUrl = res.data.filePath;
        }
        if (res.data.code === -1) {
          this.uploadFail.desc = 'Upload fail';
          this.$root.$emit('openAlert', this.uploadFail);
        }
        if (res.data.code === 1020) {
          this.uploadFail.desc =
            'Cannot upload with this extension.\nOnly image(.JPG) files can be registered.';
          this.$root.$emit('openAlert', this.uploadFail);
        }
      });
    },

    // NOTE: for temporary disapear family tab logic ( when zero family )
    getFamilyAccounts() {
      return this.api.getUserFamilyList({}).then(res => {
        if (res.code == 0) {
          // NOTE: save only confirmed family members at store
          let family = res.familyInfos ?? [];
          let confirmedFamilyMembers = family.filter(
            member => member.approveYn == 'Y',
          );
          store.commit('userStore/familyInfos', confirmedFamilyMembers);
          return family;
        } else {
          this.$router.push('/network');
        }
      });
    },
    ...mapMutations({
      setUserName: 'userStore/userName',
      setZpcd: 'userStore/zpcd',
      setCity: 'userStore/city',
      setStat: 'userStore/stat',
    }),
  },
};
</script>

<!-- PUB : CSS수정 S -->
<style scoped>
.modal .modal_body + .modal_foot {
  margin-top: 4.5rem;
}
.modal_body .bg_edit_wrap .ic_area.type_camera {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.modal_body .profile_area {
  position: relative;
}

.modal_body .profile_area .profile {
  position: relative;
  display: inline-block;
  width: 13.2rem;
  height: 13.2rem;
  border-radius: 50%;
  background-size: cover;
}

.modal_body .profile_area .profile .ic_area.type_camera {
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;
}

.modal_body .name_group {
  max-width: 100%;
  margin: 2rem auto 1rem;
}

.modal_body .name_group .input_box {
  width: calc(100% - 17rem);
}

.modal_body .name_group .btn {
  padding: 0;
}

.modal_body .zipcode_area h4 {
  margin: 2.5rem 0 1.5rem 0;
  text-align: left;
  font-size: 1.8rem;
  font-weight: 500;
}

.modal_body .zipcode_area .zipcode_group {
  padding: 0 2rem 1.8rem 2rem;
  border: 1px solid #4a4a4a;
}

.modal_body + .modal_foot {
  margin-top: 30px;
}

.account_group .profile_info {
  margin-right: 2rem;
  vertical-align: middle;
}

.email_input_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email_input_group label {
  display: block;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.4;
  color: #fff;
}
.drop_ui {
  text-align: left;
}
.is_scroll {
  height: 80px !important;
  overflow-y: auto;
}
@media screen and (max-width: 767px) {
  /* .modal_body .profile_area .profile {
    width: 10rem !important;
    height: 10rem !important;
  } */
  .modal_body .profile_area .profile .ic_area.type_camera {
    right: -1rem;
    bottom: 0;
    width: 3.6rem;
    height: 3.6rem;
    padding: 0.6rem;
  }

  .ic_area.type_camera .ic {
    width: 2.4rem !important;
    height: 2.4rem !important;
  }
  .modal_body .name_group {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .modal_body .name_group .input_box {
    flex: 1;
    margin-right: 1rem;
  }
  .modal_body .name_group .btn {
    display: flex;
    min-width: 10rem;
    height: 4rem;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
  }
  .account_group .profile_info .profile {
    width: 4rem !important;
    height: 4rem !important;
  }
  .btn_area_no_margin_top {
    margin-top: 0px;
  }
  .modal_body .zipcode_area h4 {
    margin: 2.5rem 0 1.5rem 0;
    text-align: left;
    font-size: 1.6rem;
    font-weight: 500;
  }

  .modal_body .zipcode_area .zipcode_group {
    padding: 0;
    border: none;
  }
}
</style>
<!-- PUB : CSS수정 E -->
