<template>
  <IconBase
    icon-name="icCloseDrop"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="2"
  >
    <path d="m5.92 19.54 8.648-9.08 9.512 9.08" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcMobCloseDrop',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#6c6c6c',
    },
    iconLabel: {
      type: String,
      default: 'Close',
    },
  },
};
</script>

<style scoped></style>
