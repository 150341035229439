<template>
  <div class="user_item type_card">
    <div class="item_body">
      <div @click="goOtherProfile(itemData)" class="basic_info_group">
        <div class="profile_info" tabindex="0">
          {{ itemData.avatartInfo }}
          <div
            v-if="itemData.avatarInfo && itemData.avatarInfo.length"
            class="profile l"
            :style="`background-image: url(${itemData.avatarInfo[0].resUrl})`"
          >
            <span class="is_blind">Artist profile picture</span>
          </div>
          <div v-else>
            <IcProfile
              :width="50"
              :height="50"
              :stroke-width="1"
              class="profile l"
            />
          </div>
        </div>
        <div class="ttl_area">
          <h3 class="ttl name">
            <b>{{ itemData.userName }}</b>
          </h3>
          <p class="own">
            Owned <b>{{ itemData.ownedCount }}</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
export default {
  name: 'UserItem',
  components: { IcProfile },
  props: {
    itemData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    goOtherProfile(itemData) {
      if (itemData.artistYn === 'Y') {
        this.$router.push({
          name: 'ProfileArtist',
          params: {
            mbrNo: itemData.mbrNo,
          },
        });
      } else {
        this.$router.push({
          name: 'otherProfile',
          params: {
            mbrNo: itemData.mbrNo,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.user_item.type_card .item_body {
  padding: 2rem 2rem;
  cursor: pointer;
}
/*Dev: 220503 수정 */
.user_item.type_card .profile_info .profile {
  width: 5rem;
  height: 5rem;
  margin: 0 2rem 0 0;
}
@media screen and (max-width: 600px) {
  .user_item.type_card .profile_info .profile {
    width: 3.6rem !important;
    height: 3.6rem !important;
    margin: 0 2rem 0 0;
  }
}
</style>
