<template>
  <div class="edit_item type_card">
    <div class="item_head">
      <button
        class="thumb_area"
        aria-hidden="true"
        @click="goCuration(itemData.dropsId)"
      >
        <img
          style="object-fit: cover"
          v-if="source.small"
          :src="source.small"
          :width="480"
          :alt="itemData.title ? itemData.title : ''"
        />
        <img
          v-else
          :src="require('@/assets/img/img_illust_nodata.png')"
          alt="nodata"
        />
      </button>
      <!-- <div v-if="itemData.mimeType === 'video/mp4'" class="ic_area type_video">
        <IcVideo />
      </div> -->
      <!-- WIP : 아이콘 분기-->
      <div class="ic_area type_blockchains">
        <IcHedera v-if="itemData.bcNwTp == '10'" />
        <IcEth v-else />
      </div>
      <button
        class="btn type_ic btn_like"
        type="button"
        :class="{
          is_active: likeStatus,
        }"
        @click="likeThis(itemData.dropsId)"
      >
        <IcLike
          v-if="!likeStatus"
          :status="!likeStatus"
          icon-color="#fff"
          ref="icLike"
        />
        <IcLike v-else icon-color="#ff521d" ref="icLike" />
      </button>
    </div>
    <div class="item_body">
      <button
        class="link"
        @click="goCuration(itemData.dropsId)"
        :aria-describedby="itemData.dropsId"
        :title="itemData.dropsId"
      ></button>
      <div class="basic_info_group">
        <button class="profile_info is_art" @click="goArtistProfile()">
          <span
            v-if="
              itemData.artistAvatarInfos && itemData.artistAvatarInfos.length
            "
            class="profile"
            :style="`background-image: url(${itemData.artistAvatarInfos[0].resUrl})`"
          ></span>
          <IcProfile v-else class="profile" />
          <IcRing class="ic_ring" />
          <span class="name">{{ itemData.artistName }}</span>
        </button>
        <div class="ttl_area">
          <h3 :id="itemData.artworkId" class="ttl">{{ itemData.title }}</h3>
          <p class="edit"></p>
        </div>
      </div>

      <div class="state_info clearFix">
        <div class="date_area endedDate">
          {{ itemData.endedEndDate | GmtToTz | UsaFormat('onlyDate') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcLike from '@/components/ic/IcLike';
// import IcVideo from '@/components/ic/IcVideo';
import IcProfile from '@/components/ic/IcProfile';
import IcRing from '@/components/ic/IcRing';
import IcEth from '@/components/ic/IcEth'; //이더리움 추가
import IcHedera from '@/components/ic/IcHedera'; // 헤데라 추가
export default {
  name: 'DropsItem',
  components: {
    IcRing,
    IcProfile,
    IcLike,
    IcEth,
    IcHedera,
  },
  data() {
    return {
      source: {},
      likeStatus: false,
    };
  },
  props: {
    itemData: {
      type: Object,
      default: null,
    },
    showBadge: {
      type: Boolean,
      default: false,
    },
    showChip: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.source = this.setThumbnailImg(this.itemData);
    this.likeStatus = this.itemData.likeYn === 'Y' ? true : false;
  },
  methods: {
    goArtistProfile() {
      this.$router.push({
        name: 'ProfileArtist',
        params: {
          mbrNo: this.itemData.artistMbrNo,
        },
      });
    },

    goCuration(dropsId) {
      this.$router.push({
        name: 'drops-curation',
        query: { dropsId: dropsId },
      });
    },
    likeThis(id) {
      if (!this.$refs.icLike.loginCheck()) {
        return;
      } else {
        this.$refs.icLike.setEndedDropsFavorites(id, this.likeStatus);
        this.likeStatus = !this.likeStatus;
      }
    },
  },
};
</script>

<style scoped>
.type_card {
  cursor: pointer;
}
.endedDate {
  padding-top: 10px;
}
.item_body {
  height: 162px;
}
.edit {
  padding-top: 24px;
}
</style>
