<template>
  <div id="Pagination" class="pagination_wrap">
    <div class="pagination">
      <div class="btn_num_group">
        <button
          class="btn_num first"
          :class="{ disabled: isLoading }"
          :disabled="currentPage == 1 || isLoading"
          @click="changePage(1)"
        >
          <ArwPageFirst />
        </button>
        <button
          class="btn_num prev"
          :class="{ disabled: isLoading }"
          :disabled="currentPage == 1 || isLoading"
          @click="changePage(currentPage - 1)"
        >
          <ArwPagePrev />
        </button>
        <button v-for="page in pages" :key="page" @click="changePage(page)">
          <div
            class="btn_num list"
            :class="{
              is_active: currentPage == page,
              disabled: isLoading,
            }"
            :disabled="isLoading"
            @click="changePage(page)"
          >
            {{ page }}
          </div>
        </button>
        <button
          class="btn_num next"
          :class="{ disabled: isLoading }"
          :disabled="currentPage >= totalPage || isLoading"
          @click="changePage(currentPage + 1)"
        >
          <ArwPageNext />
        </button>
        <button
          class="btn_num last"
          :class="{ disabled: isLoading }"
          :disabled="currentPage == totalPage || isLoading"
          @click="changePage(totalPage)"
        >
          <ArwPageLast />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ArwPageLast from '@/components/ic/arwPageLast';
import ArwPageNext from '@/components/ic/arwPageNext';
import ArwPageFirst from '@/components/ic/arwPageFirst';
import ArwPagePrev from '@/components/ic/arwPagePrev';
export default {
  name: 'Pagination',
  props: {
    totalCount: {
      type: [Number, String],
    },
    pageSize: {
      type: [Number, String],
    },
    value: {
      type: [String, Number],
      default: () => {
        return 1;
      },
    },
    isLoading: {
      default: () => {
        return false;
      },
    },
    paginationRange: {
      default: () => {
        return 5;
      },
    },
  },
  components: {
    ArwPageLast,
    ArwPageNext,
    ArwPageFirst,
    ArwPagePrev,
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.value;
      },
      set(page) {
        this.$emit('input', page);
      },
    },
    totalPage() {
      return Math.ceil(this.totalCount / this.pageSize);
    },
    minPage() {
      let hasSubtraction = this.currentPage % this.paginationRange == 0;
      let minPage =
        (Math.floor(this.currentPage / this.paginationRange) - hasSubtraction) *
          this.paginationRange +
        1;

      return minPage;
    },
    maxPage() {
      let maxPage =
        Math.ceil(this.currentPage / this.paginationRange) *
        this.paginationRange;
      maxPage = this.totalPage > maxPage ? maxPage : this.totalPage;
      if (!maxPage) {
        maxPage = 1;
      }
      return maxPage;
    },
    pages() {
      return [...Array(this.maxPage - this.minPage + 1)].map((page, index) => {
        return index + this.minPage;
      });
    },
  },
};
</script>
