<template>
  <IconBase
    icon-name="arwNextS"
    :icon-label="iconLabel"
    :width="24"
    :height="24"
    viewBox="0 0 24 24"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="strokeWidth"
  >
    <path data-name="Path 2581" d="m8.5 5.651 6.675 6.357L8.5 18.997" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'ArwNextS',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Next',
    },
    strokeWidth: {
      type: [Number, String],
      default: 2,
    },
  },
};
</script>

<style scoped></style>
