<!-- 
개발일 : 2022/04/27
dayjs를 이용한 Drops 시/분/초 Countdown 

<CountDown :date-time="live.dropsEndDate" />
dayjs('2019-01-25').format('MMM DD,YYYY')

import CountDown from '@/components/drops/CountDown';

components: { CountDown },

//샘플용 시간 데이터
data() {
    return {
      live: {
        dropsEndDate: '2022-05-01 00:00:00',
      },
    }
}
-->
<template>
  <div
    v-if="openService"
    class="time_group clearFix"
    :class="{ is_Not_Solded: soldedCount == 0 && dropsDivision !== 'live' }"
  >
    <span v-if="dropsDivision == 'ended'" style="font-size: 2rem">
      {{ endedDateTime | GmtToTz | UsaFormat('onlyDate') }}</span
    >
    <template v-else>
      <ul>
        <li class="time_area" :aria-live="'days ' + days">
          <span class="num">{{ days }}</span
          ><span class="txt">days</span>
        </li>
        <li class="time_area" :aria-live="'hours ' + hours">
          <span class="num">{{ hours }}</span
          ><span class="txt">hours</span>
        </li>
        <li class="time_area" :aria-live="'minutes ' + minutes">
          <span class="num">{{ minutes }}</span
          ><span class="txt">minutes</span>
        </li>
        <li class="time_area" :aria-live="'seconds ' + seconds">
          <span class="num">{{ seconds }}</span
          ><span class="txt">seconds</span>
        </li>
      </ul>
    </template>
  </div>
</template>
<script>
export default {
  name: 'CountDown',
  data() {
    return {
      countdown: '-',
      days: '-',
      hours: '-',
      minutes: '-',
      seconds: '-',
      localTime: null,
      endedDateTime: '',
    };
  },
  props: {
    dateTime: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
    dropsDivision: {
      type: String,
      default: '',
    },
    soldedCount: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.dateTime) {
      this.localTime = this.GmtToLocal(this.dateTime);
      this.setupCountdownTimer();
      this.endedDateTime = this.$dayJs(this.dateTime).format('MMM DD, YYYY');
    }
  },
  computed: {
    openService() {
      return JSON.parse(process.env.VUE_APP_OPEN_SERVICE) ? true : false;
    },
  },
  methods: {
    setupCountdownTimer() {
      let timer = setInterval(() => {
        this.countdown =
          this.$dayJs(this.localTime).valueOf() - this.$dayJs().valueOf();
        if (this.countdown <= 0) {
          this.countdown = 0;
          clearInterval(timer);
        }

        this.millisToMinutesAndSeconds(this.countdown);
      }, 1000);
    },

    millisToMinutesAndSeconds(millis) {
      const days = Math.floor(millis / (1000 * 60 * 60 * 24)); // 일
      const hours = String(
        Math.floor((millis / (1000 * 60 * 60)) % 24),
      ).padStart(2, '0'); // 시

      const minutes = String(Math.floor((millis / (1000 * 60)) % 60)).padStart(
        2,
        '0',
      ); // 분

      const seconds = String(Math.floor((millis / 1000) % 60)).padStart(2, '0'); // 초

      this.days = days;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
    },
  },
};
</script>
<style>
.is_Not_Solded {
  left: 30px !important;
}
</style>
