import { appStoreActions } from './appStoreActions';
export const appStore = {
  namespaced: true,
  state: {
    accessToken: '', // access token
    refreshToken: '', // refresh token
    expiredFlag: '', // expired flag
    loginFlag: '', // login flag
  },
  getters: {
    accessToken: state => {
      if (state.accessToken === undefined || state.accessToken === null || state.accessToken === '') {
        const jsonStr = localStorage.getItem('accessToken');
        if (jsonStr === undefined || jsonStr === null) {
          state.accessToken = null;
          return state.accessToken;
        } else {
          state.accessToken = JSON.parse(jsonStr);
        }
      }
      return state.accessToken;
    },
    refreshToken: state => {
      if (state.refreshToken === undefined || state.refreshToken === null || state.refreshToken === '') {
        const jsonStr = localStorage.getItem('refreshToken');
        if (jsonStr === undefined || jsonStr === null) {
          state.refreshToken = null;
          return state.refreshToken;
        } else {
          state.refreshToken = JSON.parse(jsonStr);
        }
      }
      return state.refreshToken;
    },
    expiredFlag: state => {
      return state.expiredFlag;
    },
    loginFlag: state => {
      return state.loginFlag;
    },
  },
  mutations: {
    accessToken: (state, accessToken) => {
      if (accessToken === undefined || accessToken === null) {
        localStorage.removeItem('accessToken');
      } else {
        localStorage.setItem('accessToken', JSON.stringify(accessToken));
      }
      state.accessToken = accessToken;
    },
    refreshToken: (state, refreshToken) => {
      if (refreshToken === undefined || refreshToken === null) {
        localStorage.removeItem('refreshToken');
      } else {
        localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
      }
      state.refreshToken = refreshToken;
    },
    expiredFlag: (state, expiredFlag) => {
      state.expiredFlag = expiredFlag;
    },
    loginFlag: (state, loginFlag) => {
      state.loginFlag = loginFlag;
    },
  },
  actions: appStoreActions,
};
