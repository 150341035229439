<template>
  <div
    v-if="show"
    class="modal_wrap type_full"
    :class="{ is_show: show }"
    @click="dimEvt"
  >
    <div
      id="Tutorial"
      ref="Tutorial"
      class="modal"
      :class="modalSize"
      role="dialog"
      aria-labelledby="tutorial_title"
    >
      <div class="modal_head">
        <h3 id="tutorial_title" class="modal_ttl">
          Getting started on <Logo class="ic_logo" width="352" height="56" />
        </h3>
      </div>
      <div class="modal_body">
        <div class="set_inner">
          <section class="info_sec sign_info">
            <h4 class="sec_ttl">
              How To Set Up Your <b>LG Art Lab Collector Account</b>
            </h4>
            <p>
              First, create an LG Account if you do not have one. Select the
              Sign In button on the upper right corner.
              <br />
              You can also create your LG Account through LG Art Lab website
              www.lgartlab.com <br />
              using both PC and mobile (for mobile, select the three line button
              and select the Sign In menu)
            </p>
            <div class="img_group">
              <div class="img_area">
                <figure>
                  <figcaption>PC</figcaption>
                  <picture>
                    <source
                      media="(max-width: 600px)"
                      type="image/png"
                      :srcset="`${require('@/assets/img/img_tutorial_pc_sign_m_3x.png')} 3x, ${require('@/assets/img/img_tutorial_pc_sign_m_2x.png')} 2x, ${require('@/assets/img/img_tutorial_pc_sign_m.png')}`"
                    />
                    <source
                      type="image/png"
                      :srcset="`${require('@/assets/img/img_tutorial_pc_sign_2x.png')} 2x, ${require('@/assets/img/img_tutorial_pc_sign.png')}`"
                    />
                    <img
                      :src="require('@/assets/img/img_tutorial_pc_sign.png')"
                      width="646"
                      height="360"
                      alt="PC sign in Guide"
                    />
                  </picture>
                </figure>
              </div>
              <div class="img_area">
                <figure>
                  <figcaption>Mobile</figcaption>
                  <picture>
                    <source
                      media="(max-width: 600px)"
                      type="image/png"
                      :srcset="`${require('@/assets/img/img_tutorial_mob_sign_m_3x.png')} 3x, ${require('@/assets/img/img_tutorial_mob_sign_m_2x.png')} 2x, ${require('@/assets/img/img_tutorial_mob_sign_m.png')}`"
                    />
                    <source
                      type="image/png"
                      :srcset="`${require('@/assets/img/img_tutorial_mob_sign_2x.png')} 2x, ${require('@/assets/img/img_tutorial_mob_sign.png')}`"
                    />
                    <img
                      :src="require('@/assets/img/img_tutorial_mob_sign.png')"
                      width="640"
                      height="360"
                      alt="Mobile sign in Guide"
                    />
                  </picture>
                </figure>
              </div>
            </div>
            <hr />
            <p>
              LG Account page will show up after selecting Sign In button. If
              you already have an LG Account, sign in to your LG Account.
            </p>
            <p class="c_point">
              Tip: If you add your Zip code, State, and City by entering <br />
              Account Management > Edit Account Information <br />
              before collecting art, the process can go quick.
            </p>
            <div class="img_area">
              <figure>
                <picture>
                  <source
                    media="(max-width: 600px)"
                    type="image/png"
                    :srcset="`${require('@/assets/img/img_tutorial_account_m_3x.png')} 3x, ${require('@/assets/img/img_tutorial_account_m_2x.png')} 2x, ${require('@/assets/img/img_tutorial_account_m.png')}`"
                  />
                  <source
                    type="image/png"
                    :srcset="`${require('@/assets/img/img_tutorial_account_2x.png')} 2x, ${require('@/assets/img/img_tutorial_account.png')}`"
                  />
                  <img
                    :src="require('@/assets/img/img_tutorial_account.png')"
                    width="1320"
                    height="495"
                    alt="USD Coin Guide image"
                  />
                </picture>
              </figure>
            </div>
          </section>
          <section class="info_sec wallet_info">
            <h4 class="sec_ttl">
              How To Set Up Your <b>Account With Wallet Connect</b>
            </h4>
            <div class="info_group">
              <p>
                You’ll need to download a mobile wallet app that supports
                collecting NFT Art on Hedera Blockchain platform. We recommend
                downloading Wallypto App. Once you’ve downloaded Wallypto,
                follow the in-app instructions to set up your wallet. Make sure
                you buy with USDC-Coin in Wallypto.
              </p>
              <div class="img_area">
                <figure>
                  <picture>
                    <source
                      media="(max-width: 600px)"
                      type="image/png"
                      :srcset="`${require('@/assets/img/img_tutorial_coin_m_3x.png')} 3x, ${require('@/assets/img/img_tutorial_coin_m_2x.png')} 2x, ${require('@/assets/img/img_tutorial_coin_m.png')}`"
                    />
                    <source
                      type="image/png"
                      :srcset="`${require('@/assets/img/img_tutorial_coin_2x.png')} 2x, ${require('@/assets/img/img_tutorial_coin.png')}`"
                    />
                    <img
                      :src="require('@/assets/img/img_tutorial_coin.png')"
                      width="660"
                      height="400"
                      alt="My wallet connection guide image"
                    />
                  </picture>
                </figure>
              </div>
            </div>
            <hr />
            <p>
              Once you have set your Wallypto wallet account, select Profile
              image icon which is located on the upper right corner. (same
              location as Sign In button)
              <br />
              In there you can check your LG Art Lab account information
              including your wallet. Go to My Wallet sub-menu and select Connect
              Wallet button.
              <br />
              Scan the QR Code on mobile wallet. A notification from your wallet
              will pop up and ask you to sign. Once you select sign, your
              account will be fully set up.
              <br />
              Your wallet will popup and ask you to sign anytime you log into
              your account, or initiate any transactions, such as buying or
              selling an NFT.
            </p>
            <div class="img_area">
              <figure>
                <picture>
                  <source
                    media="(max-width: 600px)"
                    type="image/png"
                    :srcset="`${require('@/assets/img/img_tutorial_wallet_m_3x.png')} 3x, ${require('@/assets/img/img_tutorial_wallet_m_2x.png')} 2x, ${require('@/assets/img/img_tutorial_wallet_m.png')}`"
                  />
                  <source
                    type="image/png"
                    :srcset="`${require('@/assets/img/img_tutorial_wallet_2x.png')} 3x, ${require('@/assets/img/img_tutorial_wallet.png')}`"
                  />
                  <img
                    :src="require('@/assets/img/img_tutorial_wallet.png')"
                    width="1057"
                    height="1345"
                    alt="LG account registration guide image"
                  />
                </picture>
              </figure>
            </div>
          </section>
        </div>
      </div>
      <div class="modal_foot">
        <div class="btn_area taC">
          <button class="btn strong w_xl h_l" @click="close">
            Go to LG Art Lab
          </button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM class="ic_close" />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';
import Logo from '@/components/ic/Logo';
export default {
  name: 'TutorialModal',
  components: { Logo, IcCloseM },
  props: {
    modalSize: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
      this.$root.$emit('setScrollFixed', true);
    },
    close() {
      this.show = false;
      this.$root.$emit('setScrollFixed', false);
    },
    dimEvt(e) {
      const t = e.target;
      if (t.closest('.modal')) return;
      this.close();
    },
  },
};
</script>

<style>
#Tutorial.modal {
  min-width: 150rem;
  padding-top: 27rem;
}
#Tutorial.modal .modal_head {
  right: 1.7rem;
  width: auto;
  padding: 12rem 1rem 7rem;
}
#Tutorial.modal .modal_head:before {
  display: none;
}
#Tutorial.modal .modal_ttl {
  font-size: 7rem;
  font-weight: 200;
}
#Tutorial.modal .modal_ttl .ic_logo {
  vertical-align: -0.2rem;
}
#Tutorial.modal .modal_body {
  text-align: left;
}
#Tutorial.modal .info_sec {
  padding: 8rem 6rem;
  background: var(--box-bg);
}
#Tutorial.modal .info_sec + .info_sec {
  margin-top: 4rem;
}
#Tutorial.modal .info_sec .sec_ttl {
  margin-bottom: 2rem;
  font-size: 3.6rem;
  font-weight: 200;
}
#Tutorial.modal .info_sec .sec_ttl b {
  font-weight: 500;
}
#Tutorial.modal .info_sec .img_group {
  display: flex;
  justify-content: space-between;
  margin-top: -2rem;
}
#Tutorial.modal .info_sec .img_area {
  margin-top: 5rem;
}
#Tutorial.modal .info_sec p {
  margin-bottom: 2rem;
  color: var(--txt-gray);
  line-height: 1.5;
}
#Tutorial.modal .info_sec .img_group figcaption {
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--txt-gray);
  text-transform: uppercase;
}
#Tutorial.modal .info_sec hr {
  display: block;
  height: 0.1rem;
  margin: 7rem 0 6rem;
  border: 0 none;
  background: var(--line-color);
}
#Tutorial.modal .info_sec .info_group {
  display: flex;
}
#Tutorial.modal .info_sec .info_group .img_area {
  flex-shrink: 0;
  margin-top: 0;
  margin-left: 6rem;
}
#Tutorial.modal .info_sec.sign_info .img_group .img_area {
  flex: 1;
  width: calc(50% - 2rem);
  padding: 0 2rem;
}
#Tutorial.modal .info_sec.sign_info .img_group {
  margin-left: -2rem;
  margin-right: -2rem;
}
#Tutorial.modal .info_sec.sign_info .img_area:first-of-type figure {
  margin-right: -6px;
}
#Tutorial.modal .info_sec.wallet_info .sec_ttl {
  margin-bottom: 6rem;
}
#Tutorial.modal .info_sec.wallet_info .img_area {
  text-align: center;
}
#Tutorial .modal_foot {
  padding: 6rem 0 15rem;
}
#Tutorial .modal_foot .btn {
  min-width: 30.3rem;
}
#Tutorial .close_modal,
#Tutorial .close_modal .ic_close {
  width: 5.6rem !important;
  height: 5.6rem !important;
}
#Tutorial .close_modal {
  right: 2.7rem;
}
#Tutorial .close_modal .ic_close g {
  stroke-width: 1;
}

@media screen and (max-width: 1024px) {
  #Tutorial.modal {
    min-width: auto;
    padding-top: 25rem;
  }
  #Tutorial.modal .modal_head {
    right: 0;
  }
  #Tutorial.modal .modal_ttl {
    font-size: 4.5rem;
  }
  #Tutorial.modal .modal_ttl .ic_logo {
    width: 24rem !important;
    height: 4rem !important;
  }
  #Tutorial.modal .info_sec .sec_ttl {
    font-size: 2.6rem;
  }
  #Tutorial.modal .info_sec p:not(.c_point) br {
    display: none;
  }
  #Tutorial.modal .info_sec img {
    height: auto;
  }
  #Tutorial.modal .info_sec .info_group p {
    flex: 1.5;
  }
  #Tutorial.modal .info_sec .info_group .img_area {
    flex: 1;
  }
  #Tutorial.modal .info_sec .info_group .img_area figure {
    margin: 0 -4rem;
  }
  #Tutorial .close_modal {
    right: 1rem;
  }
}
@media screen and (max-width: 600px) {
  #Tutorial.modal {
    padding-top: 2rem;
  }
  #Tutorial.modal .modal_head {
    padding: 6rem 1rem 5rem;
  }
  #Tutorial.modal .modal_ttl {
    font-size: 3.2rem;
  }
  #Tutorial.modal .modal_ttl .ic_logo {
    display: block;
    width: 18rem !important;
    margin: 1rem auto 0;
  }
  #Tutorial.modal .info_sec {
    margin-top: 15rem !important;
    padding: 4rem 2rem;
  }
  #Tutorial.modal .info_sec .img_area {
    margin-top: 4rem;
  }
  #Tutorial.modal .info_sec .img_group figcaption,
  #Tutorial.modal .info_sec p {
    font-size: 1.4rem;
  }
  #Tutorial.modal .info_sec.sign_info .img_group,
  #Tutorial.modal .info_sec .info_group {
    flex-flow: column;
  }
  #Tutorial.modal .info_sec.sign_info .img_group .img_area {
    width: 100%;
  }
  #Tutorial.modal .info_sec .sec_ttl {
    margin-bottom: 5rem;
    font-size: 2rem;
    text-align: center;
    line-height: 1.5;
  }
  #Tutorial.modal .info_sec .sec_ttl b {
    display: block;
  }
  #Tutorial .close_modal,
  #Tutorial .close_modal .ic_close {
    width: 3rem !important;
    height: 3rem !important;
  }
  #Tutorial .close_modal .ic_close g {
    stroke-width: 1.2;
  }
  #Tutorial.modal .info_sec .info_group .img_area,
  #Tutorial.modal .info_sec .info_group .img_area figure {
    margin: 0;
  }
  #Tutorial.modal .info_sec.wallet_info .info_group .img_area {
    margin-top: 3rem;
  }
  #Tutorial.modal .info_sec hr {
    margin: 4.3rem 0 4rem;
  }
  #Tutorial .modal_foot {
    padding: 4rem 0 7rem;
  }
  #Tutorial .modal_foot .btn {
    min-width: 28rem;
  }
}
</style>
