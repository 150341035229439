<template>
  <IconBase
    icon-name="icEye"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path
      data-name="Path 2594"
      d="M3 15s3.83 9 12 9 12-9 12-9-3.83-9-12-9-12 9-12 9Z"
    />
    <circle
      data-name="Ellipse 361"
      cx="4.5"
      cy="4.5"
      r="4.5"
      transform="translate(10.5 10.5)"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcEye',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Show',
    },
  },
};
</script>

<style scoped></style>
