<template>
  <main id="contents" class="error_sec">
    <div class="modal">
      <div class="modal_head">
        <h3 id="signin_title" class="modal_ttl">Enter Password</h3>
      </div>
      <div class="modal_body">
        <div class="input_area txt has_btn">
          <div class="input_box">
            <input
              v-model="password"
              type="text"
              placeholder="Please Input Password...."
              label="setPassword"
              @keyup.enter="setPassword"
            />
          </div>
        </div>
      </div>
      <div class="modal_foot">
        <div class="btn_area taC">
          <button class="btn strong w_s h_m" @click="setPassword">Enter</button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ErrorLogin',
  data() {
    return {
      password: '',
      overlapAlert: {
        type: 'warn',
        desc: 'Passwords do not match.',
      },
      noInputAlert: {
        type: 'warn',
        title: '',
        desc: 'Please Input Password',
      },
    };
  },
  computed: {
    ...mapGetters({
      accessToken: 'appStore/accessToken',
    }),
  },
  methods: {
    async setPassword() {
      if (!this.password) {
        this.$root.$emit('openAlert', this.noInputAlert);
        return;
      }

      await this.api.loginTmp({ password: this.password }).then(res => {
        if (res.code === 0) {
          sessionStorage.setItem('nftKey', 'Y');

          let cesLink = sessionStorage.getItem('cesLink');
          let redirect = sessionStorage.getItem('redirect');

          if (cesLink) {
            location.href = cesLink;
            sessionStorage.removeItem('cesLink');
          }

          if (redirect && this.accessToken) {
            sessionStorage.removeItem('redirect');
            location.href = redirect;
          } else {
            this.$router.push('/');
          }
        } else {
          sessionStorage.setItem('nftKey', 'N');
          this.$root.$emit('openAlert', this.overlapAlert);
        }
      });
    },
  },
};
</script>

<style scoped>
.error_sec {
  display: flex;
  min-height: calc(var(--nf) * 1px);
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
