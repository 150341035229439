<template>
  <IconBase
    icon-name="icFilter"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    :icon-color="iconColor"
    :stroke-width="0"
  >
    <path
      d="m13.2 24.1 3.6-1.8v-7.4l7.7-9.6h-19l7.7 9.6v9.2M12 26V15.2L3 4h24l-9 11.2V23l-6 3z"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcFilter',
  components: { IconBase },
  props: {
    iconColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Filter',
    },
  },
};
</script>

<style scoped></style>
