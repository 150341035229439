<template>
  <IconBase
    icon-name="icEthS"
    :icon-label="iconLabel"
    :width="32"
    :height="34"
    viewBox="0 0 32 34"
  >
    <g id="그룹_3854" data-name="그룹 3854" transform="translate(0.125)">
      <path
        id="패스_3803"
        data-name="패스 3803"
        d="M31.059,8.5a6.938,6.938,0,0,0-2.573-2.518L21.16,1.846,19.52.92a7.186,7.186,0,0,0-7.04,0L3.515,5.981c-.163.092-.32.189-.475.3L3,6.305A6.9,6.9,0,0,0,.8,8.759,6.773,6.773,0,0,0,0,11.938V22.061a6.772,6.772,0,0,0,.726,3.045c.064.132.14.264.214.393a6.849,6.849,0,0,0,.794,1.087q.15.17.312.328a6.891,6.891,0,0,0,1.069.861c.128.084.263.165.4.242l8.968,5.062a7.188,7.188,0,0,0,7.04,0L24,30.547l4.484-2.531c.018-.009.033-.021.051-.031q.083-.049.159-.1a6.916,6.916,0,0,0,2.2-2.121c.079-.121.155-.244.225-.37A6.755,6.755,0,0,0,32,22.063V11.94A6.766,6.766,0,0,0,31.059,8.5Z"
        transform="translate(-0.125 0.001)"
        fill="#333"
      />
      <g id="그룹_3833" data-name="그룹 3833" transform="translate(10.276 7.1)">
        <path
          id="패스_3804"
          data-name="패스 3804"
          d="M20.315,30.7a.742.742,0,0,1-.244-.23L15.31,23.523a.425.425,0,0,1-.074-.243.429.429,0,0,1,.075-.243A.343.343,0,0,1,15.5,22.9a.309.309,0,0,1,.225.028l4.892,2.588,4.892-2.588a.308.308,0,0,1,.225-.028.34.34,0,0,1,.192.137A.424.424,0,0,1,26,23.28a.419.419,0,0,1-.074.243l-4.76,6.946a.742.742,0,0,1-.244.23.583.583,0,0,1-.609,0Zm.308-6.95V11a.608.608,0,0,1,.328.1.716.716,0,0,1,.245.267L26.17,20.1a.558.558,0,0,1,.069.2.578.578,0,0,1-.011.217.544.544,0,0,1-.084.2.468.468,0,0,1-.151.141L20.81,23.7a.347.347,0,0,1-.175.048Zm-.187-.048-5.184-2.844a.468.468,0,0,1-.151-.141.544.544,0,0,1-.085-.2.591.591,0,0,1-.01-.217.558.558,0,0,1,.069-.2l4.975-8.733a.712.712,0,0,1,.245-.267.608.608,0,0,1,.328-.1v12.75h-.012A.347.347,0,0,1,20.435,23.7Z"
          transform="translate(-14.998 -10.999)"
          fill="#fff"
          opacity="0.9"
        />
        <path
          id="패스_3807"
          data-name="패스 3807"
          d="M20.636,22.564v5.287a.348.348,0,0,1-.187-.048l-5.184-2.844a.469.469,0,0,1-.151-.141.545.545,0,0,1-.085-.2Z"
          transform="translate(-15.012 -15.101)"
          fill="#d1d1d1"
          opacity="0.9"
        />
        <path
          id="패스_3808"
          data-name="패스 3808"
          d="M20.623,11v7.463l-5.608,2.054A.583.583,0,0,1,15,20.3a.559.559,0,0,1,.068-.2l4.976-8.733a.719.719,0,0,1,.245-.267A.608.608,0,0,1,20.623,11Z"
          transform="translate(-14.998 -10.999)"
          fill="#fff"
          opacity="0.9"
        />
        <path
          id="패스_3809"
          data-name="패스 3809"
          d="M30.608,20.517,25,18.463V11a.609.609,0,0,1,.328.1.718.718,0,0,1,.245.267L30.549,20.1a.559.559,0,0,1,.068.2.584.584,0,0,1-.012.217Z"
          transform="translate(-19.375 -10.999)"
          fill="#e9e9e9"
          opacity="0.9"
        />
        <path
          id="패스_3810"
          data-name="패스 3810"
          d="M30.608,24.618a.546.546,0,0,1-.085.2.469.469,0,0,1-.151.141L25.188,27.8a.348.348,0,0,1-.187.048V22.564Z"
          transform="translate(-19.375 -15.101)"
          fill="#9e9e9e"
          opacity="0.9"
        />
      </g>
    </g>
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcEthS',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'ethereum',
    },
  },
};
</script>

<style scoped></style>
