<template>
  <main id="contents" class="my_list_page artist_page">
    <h1 class="page_ttl is_blind">artist_page</h1>
    <section
      class="main_visual_sec"
      :style="`background-image: url('${profileCoverUrl}')`"
    ></section>
    <div class="set_inner">
      <section class="artist_sec">
        <div class="artist_sec_head">
          <div class="profile_group">
            <div class="profile_info is_art">
              <span
                v-if="profileUrl"
                class="profile"
                :style="`background-image: url('${profileUrl}')`"
              ></span>
              <IcProfile
                v-else
                :width="110"
                :height="110"
                :stroke-width="0.8"
                class="profile"
              />
              <!-- Dev: 220503 수정 - 링굵기 수정 -->
              <IcRing
                :width="110"
                :height="110"
                :small-ring="14.8"
                class="ic_ring"
              />
              <h2 class="ttl">{{ userName }}</h2>
            </div>
          </div>
          <div class="follow_group">
            <ul class="follow_area">
              <li class="following">
                <button
                  @click="openModal('FollowModal', false)"
                  ref="following"
                  role="status"
                >
                  {{ following | numberFormatter(2) }}
                  <span>Following</span>
                </button>
              </li>
              <li class="followers">
                <button
                  @click="openModal('FollowModal', true)"
                  ref="followers"
                  role="status"
                >
                  {{ followers | numberFormatter(2) }}
                  <span>Followers</span>
                </button>
              </li>
            </ul>
            <div class="btn_area" v-if="mbrNo !== $route.params.mbrNo">
              <button
                @click="follow()"
                ref="follow"
                class="btn basic w_m h_l"
                :class="{ is_active: followYn === 'Y' }"
              >
                <IcMember v-if="followYn === 'N'" stroke-color="#000000" />
                <IcCheck v-if="followYn === 'Y'" />
                {{ followYn === 'Y' ? 'Following' : 'Follow' }}
              </button>
            </div>
          </div>
          <div class="desc_area" :class="{ is_active: expand }">
            <p class="desc">
              {{ artistDesc }}
            </p>
          </div>
          <button v-if="!expand" class="expand_btn dv_mob" @click="expandBtn">
            Expand
            <IcExpand />
          </button>
          <div class="sns_area">
            <a
              v-if="facebookUrl"
              :href="facebookUrl"
              target="noopener noreferrer"
              class="sns"
              ><IcFaceBook
            /></a>
            <a
              :href="instagramUrl"
              v-if="instagramUrl"
              target="noopener noreferrer"
              class="sns"
              ><IcInstagram
            /></a>
            <a
              :href="twitterUrl"
              v-if="twitterUrl"
              target="noopener noreferrer"
              class="sns"
              ><IcTwitter
            /></a>
            <a
              :href="webSiteUrl"
              v-if="webSiteUrl"
              target="noopener noreferrer"
              class="sns"
              ><IcWeb
            /></a>
          </div>
        </div>
        <div class="artist_sec_body">
          <div class="tab_wrap">
            <div class="tab_list type_stack" role="tablist">
              <div class="tab_node">
                <button
                  @click="tabSelected = 'CREATED'"
                  class="tab_item"
                  :class="{ is_active: tabSelected === 'CREATED' }"
                  role="tab"
                >
                  <span v-if="tabSelected === 'CREATED'" class="is_blind"
                    >Selected,
                  </span>
                  <span
                    >Created
                    <em class="count">{{ createdInfos.length }}</em></span
                  >
                </button>
              </div>
              <div class="tab_node">
                <button
                  @click="tabSelected = 'OWNED'"
                  class="tab_item"
                  :class="{ is_active: tabSelected === 'OWNED' }"
                  role="tab"
                >
                  <span v-if="tabSelected === 'OWNED'" class="is_blind"
                    >Selected,
                  </span>
                  <span
                    >Owned
                    <em class="count">{{ ownedEditions.length }}</em></span
                  >
                </button>
              </div>
            </div>
          </div>
          <ul class="grid grid_15 ib edit_list">
            <li
              v-for="item in filterTab"
              :key="item.rsrcId"
              class="col col_3 edit_node"
            >
              <ItemCardEdit
                :itemData="item"
                :userType="userType"
                :viewType="'other'"
                :mbrNo="mbrNo"
              />
            </li>
          </ul>
        </div>
      </section>
    </div>
    <FollowList
      ref="FollowModal"
      :mbrNo="$route.params.mbrNo"
      @follow="handleFollow"
      @onSetCloseFocus="onSetCloseFocus"
      :key="componentKey"
    />
  </main>
</template>

<script>
import FollowList from '@/components/mylist/FollowList';
import ItemCardEdit from '@/components/mylist/ItemCardEdit';
import IcWeb from '@/components/ic/IcWeb';
import IcInstagram from '@/components/ic/IcInstagram';
import IcFaceBook from '@/components/ic/IcFaceBook';
import IcTwitter from '@/components/ic/IcTwitter';
import IcProfile from '@/components/ic/IcProfile';
import IcMember from '@/components/ic/IcMember';
import IcCheck from '@/components/ic/IcCheck';
import IcRing from '@/components/ic/IcRing';
import IcExpand from '@/components/ic/IcExpand';
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileArtistView',
  components: {
    IcExpand,
    IcRing,
    IcCheck,
    IcMember,
    FollowList,
    ItemCardEdit,
    IcWeb,
    IcInstagram,
    IcFaceBook,
    IcTwitter,
    IcProfile,
  },
  data() {
    return {
      tabSelected: 'CREATED',
      createdInfos: [],
      ownedEditions: [],
      followBtn: true,
      followText: 'Following',
      expand: false,
      followers: 0,
      following: 0,
      followYn: 'N',
      profileUrl: '',
      profileCoverUrl: '',
      userName: '',
      artistDesc: '',
      facebookUrl: '',
      twitterUrl: '',
      webSiteUrl: '',
      instagramUrl: '',
      loginAlert: {
        type: 'warn_2',
        desc: 'Please sign in.',
        success: 'Sign in',
      },
      userType: '2',
      componentKey: 0,
    };
  },
  computed: {
    filterTab() {
      return this.tabSelected === 'CREATED'
        ? this.createdInfos
        : this.ownedEditions;
    },
    ...mapGetters({
      accessToken: 'appStore/accessToken',
      mbrNo: 'userStore/mbrNo',
    }),
  },
  mounted() {
    if (!this.$route.params.mbrNo) this.$router.push('/unknown');
    this.getUserProfile();
  },
  methods: {
    getUserProfile(item) {
      const data = {
        mbrNo: item?.mbrNo || this.$route.params.mbrNo,
      };
      if (item?.userType === 2 || !item) {
        this.api.getArtistProfile(data).then(res => {
          if (res.code === 0) {
            this.followers = res.artistInfo.followers;
            this.following = res.artistInfo.following;
            this.profileUrl =
              res.artistInfo.avatarInfo && res.artistInfo.avatarInfo.length > 0
                ? res.artistInfo.avatarInfo[0].resUrl
                : '';
            this.profileCoverUrl = res.artistInfo.repInfo
              ? res.artistInfo.repInfo[0]?.resUrl
              : '';
            this.followYn = res.artistInfo.followYn;
            this.userName = res.artistInfo.name;
            this.artistDesc = res.artistInfo.about;
            this.facebookUrl = res.artistInfo.facebookUrl;
            this.instagramUrl = res.artistInfo.instagramUrl;
            this.twitterUrl = res.artistInfo.twitterUrl;
            this.webSiteUrl = res.artistInfo.webSiteUrl;

            this.createdInfos = res.createdInfos;
            if (this.createdInfos === null) {
              this.createdInfos = [];
            }
            this.ownedEditions = res.ownedEditions;
          } else {
            this.$router.push('/network');
          }
        });
      }
      if (item?.userType === 1) {
        this.$router.push(`/mylist/other/${item.mbrNo}`);
      }
    },
    follow() {
      if (this.accessToken) {
        const data = {
          followingMbrNo: this.$route.params.mbrNo,
          followingYn: this.followYn === 'N' ? 'Y' : 'N',
        };
        this.api.setUserFollowing(data).then(res => {
          if (res.code === 0) {
            this.getUserProfile();
          }
        });
      } else {
        this.$root.$emit('openAlert', this.loginAlert);
      }
    },
    openModal(ref, boolean) {
      this.$refs[ref].open(boolean);
    },
    expandBtn() {
      this.expand = !this.expand;
    },
    handleFollow(item) {
      this.componentKey++;
      this.getUserProfile(item);
    },
    onSetCloseFocus(idx) {
      let foucsEl;
      if (idx === 1) {
        foucsEl = this.$refs.following;
      } else {
        foucsEl = this.$refs.followers;
      }
      setTimeout(() => {
        foucsEl.focus();
      }, 500);
    },
  },
};
</script>

<style scoped></style>
