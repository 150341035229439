<template>
  <main id="contents" class="footer_page help_detail_page" v-if="faqDetails">
    <div class="set_inner">
      <div class="page_ttl_area">
        <h1 class="page_ttl">Help Center</h1>
      </div>
      <section class="board_sec">
        <div class="board_sec_head">
          <span>{{ faqDetails.cateName }}</span>
          <p class="ttl">{{ faqDetails.title }}</p>
        </div>
        <div class="board_sec_body">
          <pre
            ref="contentFaqRef"
            class="detail"
            v-html="faqDetails.content"
          ></pre>
        </div>
        <div class="board_sec_foot" @click="$router.back()">
          <button class="btn strong w_s h_l">Back to List</button>
        </div>
      </section>
    </div>
    <MailModal ref="MailModal" @closeModal="closeModal" />
  </main>
</template>

<script>
import MailModal from '@/components/common/MailModal';
import { mapGetters } from 'vuex';

export default {
  name: 'HelpCenterDetailView',
  components: {
    MailModal,
  },
  computed: {
    ...mapGetters({
      accessToken: 'appStore/accessToken',
    }),
  },
  data() {
    return {
      faqDetails: null,
      loginAlert: {
        type: 'warn_2',
        desc: 'Please sign in.',
        success: 'Sign in',
      },
    };
  },
  created() {
    this.$root.$emit('setPage', {
      page: 'help_detail_page',
      subHead: true,
      title: 'Help Center',
    });
    if (!this.$route.query.num) this.$router.push('/help');
    const data = {
      cateNo: this.$route.query.cateNo,
      num: this.$route.query.num,
    };
    this.api.getFaqDetail(data).then(res => {
      if (res.code === 0) {
        this.faqDetails = res.faqInfo;
        this.$nextTick(() => {
          const funcElms = this.$refs.contentFaqRef.querySelectorAll('*');
          funcElms.forEach(el => {
            if (el.attributes['@click']) {
              el.addEventListener(
                'click',
                this[
                  this.$refs.contentFaqRef.querySelectorAll('*')[0].attributes[
                    '@click'
                  ].value
                ],
              );
            }
          });
        });
      } else {
        this.$router.push('/network');
      }
    });
  },
  mounted() {},
  methods: {
    closeModal() {
      setTimeout(() => {
        this.$refs.modalBtn.focus();
      }, 100);
    },
    openMailForm() {
      if (this.accessToken) {
        this.$refs['MailModal'].open();
      } else {
        this.$root.$emit('openAlert', this.loginAlert);
      }
    },
  },
};
</script>
Ï

<style scoped>
@import url(../../assets/css/customGlobal.css);
.detail {
  white-space: pre-wrap;
}
.detail > ::v-deep a {
  color: #9090ff;
}
</style>
