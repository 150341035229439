<template>
  <nav class="my_account_menu">
    <div class="my_account_head" v-if="userType === '1'">
      <div class="profile_group">
        <div class="profile_info">
          <span
            class="profile"
            v-if="profileUrl"
            :style="`background-image: url('${profileUrl}')`"
          >
            <span class="is_blind">My profile picture</span>
          </span>
          <span v-else class="profile none">
            <IcProfile class="ic_profile" width="113" height="113" />
          </span>
        </div>
        <div class="ttl_area">
          <h2 class="ttl">{{ userName }}</h2>
          <button class="edit_btn" @click="openModal('UserEditModal')">
            <IcWrite />
          </button>
        </div>
        <div v-if="zipCodeFullStr" class="ttl_area zpcd">
          <span class="zpcd_info">
            {{ zipCodeFullStr }}
          </span>
        </div>
      </div>
      <div class="follow_group">
        <ul class="follow_area">
          <li class="followers">
            <button
              @click="openModal('FollowModal', true)"
              ref="followers"
              role="status"
            >
              {{ followers | numberFormatter(2) }}
              <span>Followers</span>
            </button>
          </li>
          <li class="following">
            <button
              @click="openModal('FollowModal', false)"
              ref="following"
              role="status"
            >
              {{ following | numberFormatter(2) }}
              <span>Following</span>
            </button>
          </li>
        </ul>
      </div>
      <!--NOTE: reduce family functions requested by yr.choi -->
      <div
        v-if="familyInfos && familyInfos.length > 0"
        class="family_account_group"
      >
        <div class="account_length_area">
          <p>
            Family Account <em>{{ familyInfos.length }}</em>
          </p>
          <p></p>
        </div>

        <ul class="profile_info">
          <li
            v-for="item in familyInfos"
            :key="item.id"
            style="cursor: pointer"
          >
            <span
              v-if="item.avatarRsrcUrl"
              class="profile"
              :style="`background-image: url('${item.avatarRsrcUrl}')`"
              @click="goToProfile(item)"
            ></span>
            <IcProfile
              v-else
              class="profile"
              width="32"
              height="32"
              @click="goToProfile(item)"
            />
            <div class="profile_layer">
              <div class="profile_info_group">
                <div class="profile_info">
                  <span
                    v-if="item.avatarRsrcUrl"
                    class="profile"
                    :style="`background-image: url('${item.avatarRsrcUrl}')`"
                  >
                    <span class="is_blind">My profile picture</span>
                  </span>
                  <IcProfile v-else class="profile" />
                </div>
                <div class="ttl_area">
                  <h2 class="ttl">{{ item.userName }}</h2>
                </div>
              </div>
              <div class="follow_group">
                <div class="follow_area">
                  <div class="followers">
                    <dl>
                      <dt>
                        {{ item.followers | numberFormatter(2) }}
                      </dt>
                      <dd>Followers</dd>
                    </dl>
                  </div>
                  <div class="following">
                    <dl>
                      <dt>
                        {{ item.following | numberFormatter(2) }}
                      </dt>
                      <dd>Following</dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div class="btn_area">
                <button
                  @click="follow(item)"
                  ref="follow"
                  class="btn basic w_f h_l"
                  :class="{ is_active: item.followYn === 'Y' }"
                >
                  <IcMember
                    v-if="item.followYn === 'N'"
                    stroke-color="#000000"
                  />
                  <IcCheck v-if="item.followYn === 'Y'" />
                  {{ item.followYn === 'Y' ? 'Following' : 'Follow' }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="my_account_body" :class="{ is_blind: window <= 1024 }">
      <ul>
        <li>
          <router-link :to="`/mylist/${userType === '2' ? 'myartist' : 'user'}`"
            >Profile</router-link
          >
        </li>
        <li><router-link to="/mylist/favorites">Favorites</router-link></li>
        <li><router-link to="/mylist/wallet">My Wallet</router-link></li>
        <li><router-link to="/mylist/activities">Activities</router-link></li>
        <li><router-link to="/mylist/settings">Settings</router-link></li>
      </ul>
    </div>
    <UserEditProfile ref="UserEditModal" />
    <FollowList
      ref="FollowModal"
      v-if="mbrNo"
      :mbrNo="mbrNo"
      @follow="handleFollow"
      @onSetCloseFocus="onSetCloseFocus"
      :key="componentKey"
    />
  </nav>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
import IcWrite from '@/components/ic/IcWrite';
import UserEditProfile from '@/components/mylist/UserEditProfile';
import IcMember from '@/components/ic/IcMember';
import IcCheck from '@/components/ic/IcCheck';
import FollowList from '@/components/mylist/FollowList';
import { mapGetters } from 'vuex';
import store from '@/store/index';

export default {
  name: 'AccountMenu',
  components: {
    UserEditProfile,
    IcProfile,
    IcWrite,
    IcMember,
    IcCheck,
    FollowList,
  },
  props: {
    window: {
      type: Number,
      require: true,
    },
  },
  computed: {
    ...mapGetters({
      userName: 'userStore/userName',
      mbrNo: 'userStore/mbrNo',
      profileInfos: 'userStore/profileInfos',
      userType: 'userStore/userType',
      familyInfos: 'userStore/familyInfos',
      followers: 'userStore/followers',
      following: 'userStore/following',
      zpcd: 'userStore/zpcd',
      city: 'userStore/city',
      stat: 'userStore/stat',
    }),
    profileUrl() {
      let profile = this.profileInfos;
      return profile && profile.length ? profile[0].resUrl : null;
    },
    zipCodeFullStr() {
      let zipArr = [];
      ['zpcd', 'city', 'stat'].filter(v => {
        if (this[v]) {
          if (this[v].length > 28) {
            zipArr.push(this[v].substring(0, 25) + '...');
          } else {
            zipArr.push(this[v]);
          }
        }
      });
      return this.zpcd ? zipArr.join(', ') : '';
    },
  },
  data() {
    return {
      followText: 'Following',
      followBtn: true,
      componentKey: 0,
    };
  },
  methods: {
    openModal(ref, boolean) {
      this.$refs[ref].open(boolean);
    },
    handleFollow() {
      this.getProfile();
    },
    getProfile() {
      this.api.getProfile({}).then(res => {
        if (res.code === 0) {
          store.commit('userStore/followers', res.followerCount);
          store.commit('userStore/following', res.followingCount);
        }
      });
    },
    follow(item) {
      const data = {
        followingMbrNo: item.mbrNo,
        followingYn: item.followYn === 'N' ? 'Y' : 'N',
      };
      this.api.setUserFollowing(data).then(res => {
        if (res.code === 0) {
          this.api.getUserFamilyList({}).then(res => {
            store.commit('userStore/familyInfos', res.familyInfos);

            let tmpFollowing = this.following;
            tmpFollowing =
              data.followingYn === 'Y' ? tmpFollowing + 1 : tmpFollowing - 1;
            store.commit('userStore/following', tmpFollowing);
            this.componentKey++;
          });
        }
      });
    },
    goToProfile(item) {
      if (item.userType === '1') {
        this.$router.push(`/mylist/other/${item.mbrNo}`);
      }
      if (item.userType === '2') {
        this.$router.push(`/mylist/artist/${item.mbrNo}`);
      }
    },
    onSetCloseFocus(idx) {
      let foucsEl;
      if (idx === 1) {
        foucsEl = this.$refs.following;
      } else {
        foucsEl = this.$refs.followers;
      }
      setTimeout(() => {
        foucsEl.focus();
      }, 500);
    },
  },
};
</script>

<style scoped>
.ttl_area.zpcd {
  word-break: break-all;
}
.zpcd_title {
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}
.zpcd_info {
  font-weight: 600;
  font-size: 1.4rem;
  /* font-size: 15px;
  letter-spacing: -2px; */
}
</style>
