<template>
  <IconBase
    icon-name="IcLink"
    :icon-label="iconLabel"
    :width="24"
    :height="24"
    viewBox="0 0 24 24"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path
      stroke-linejoin="round"
      d="m12.858 15.954-3.633 3.632a3.4 3.4 0 0 1-4.81 0 3.4 3.4 0 0 1 0-4.81l3.848-3.848a3.4 3.4 0 0 1 4.81 0l.42.419"
      data-name="패스 607"
    />
    <path
      stroke-linejoin="round"
      d="m11.144 8.047 3.632-3.632a3.4 3.4 0 0 1 4.811 0 3.4 3.4 0 0 1 0 4.81l-3.848 3.849a3.4 3.4 0 0 1-4.811 0l-.415-.416"
      data-name="패스 608"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcLink',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Link',
    },
  },
};
</script>

<style scoped></style>
