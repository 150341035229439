<template>
  <div class="family_account_wrap">
    <div class="family_account_head">
      <h3 class="ttl">{{ title }}</h3>
      <template v-if="!temporaryDisappearConditions">
        <button class="add_btn" @click="toggleFamilyAddForm">
          Add Account
        </button>
      </template>
    </div>
    <div class="family_account_body">
      <ul>
        <li
          class="account_item"
          v-for="account in familyAccounts"
          :key="account.mbrNo"
        >
          <div class="account_group">
            <div class="profile_info">
              <span
                v-if="account.avatarRsrcUrl"
                class="profile"
                :style="`background-image: url('${account.avatarRsrcUrl}')`"
              ></span>
              <span v-else class="profile none">
                <IcProfile class="ic_profile" width="50" height="50" />
              </span>
            </div>
            <div class="profile_info_area">
              <p class="name">{{ account.userName }}</p>
              <p v-if="account.approveDate" class="date">
                {{ account.approveDate }}
              </p>
              <p
                v-if="account.approveYn !== null"
                class="date"
                :class="{
                  is_success: account.approveYn == '',
                  is_error: account.approveYn == 'N',
                }"
              >
                {{ approveStatusList[account.approveYn] }}
              </p>
            </div>
          </div>
          <button class="ic_area" @click="confirmDeleteFamily(account.mbrNo)">
            <IcRemove />
          </button>
        </li>
      </ul>
      <div v-if="isAddFormExposed" class="email_wrap">
        <div class="email_input_group">
          <div class="input_area email">
            <label
              >Type in the email address you want to add and click the send
              button.</label
            >
            <div
              class="input_box"
              :class="{ is_error: emailValidateErrorMessage }"
            >
              <input
                v-model="email"
                type="email"
                label="Family Account Email"
                placeholder="Family Account Email"
              />

              <button class="ic_area" @click="addFamily">
                <IcEmail />
              </button>
            </div>
            <p v-if="emailValidateErrorMessage" class="validation">
              <!-- Dev : email 입력형식 오류 -->
              <span>{{ emailValidateErrorMessage }}</span>
              <!-- Dev : 등록된 계정이 아닐 경우 -->
              <!-- <span>This Account is not registered for LG Art Lab.</span> -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
import IcRemove from '@/components/ic/IcRemove';
import IcEmail from '@/components/ic/IcEmail';
import { mapGetters } from 'vuex';
import store from '@/store/index';

export default {
  name: 'FamilyList',
  components: { IcEmail, IcRemove, IcProfile },
  props: ['type'],
  data() {
    return {
      familyAccounts: [],
      email: '',
      isAddFormExposed: false,
      approveStatusList: {
        '': 'Waiting for the Approval',
        N: 'Request’s denied',
      },
      errors: {
        1000: {
          type: 'warn',
          desc: 'This Account is not registered on LG Art Lab',
        },
        1022: {
          type: 'warn',
          desc: 'This is a family user that has already been added',
        },
        1029: {
          type: 'warn',
          desc: 'Request should not be sent to yourself.',
        },
        max: {
          type: 'warn',
          desc: 'You cannot add more than\n 4 members to the family account.',
        },
      },

      deleteConfirmAlert: {
        type: 'question',
        desc: 'Are you sure you want to\n delete this account?',
        id: 'deleteConfirmAlert',
      },
      deleteSuccessAlert: {
        type: 'success',
        desc: 'The account has been deleted.',
        id: 'withdrawSuccess',
      },
      reg: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
    };
  },
  computed: {
    ...mapGetters({
      mbrNo: 'userStore/mbrNo',
    }),
    emailValidateErrorMessage() {
      if (!this.email) {
        return false;
      }

      return !this.reg.test(this.email) && 'This email is not valid.';
    },

    isFamilyAddable() {
      return (this.familyAccounts.length >= 4 && 'max') || true;
    },
    title() {
      return this.type === 'myProfileArtist' ? '' : 'Family Account';
    },

    temporaryDisappearConditions() {
      // return process.env.VUE_APP_PROFILE === 'PRD';
      return true;
    },
  },

  async mounted() {
    this.familyAccounts = await this.getFamilyAccounts();
  },
  methods: {
    getFamilyAccounts() {
      return this.api.getUserFamilyList({}).then(res => {
        if (res.code == 0) {
          // NOTE: save only confirmed family members at store
          let family = res.familyInfos ?? [];
          let confirmedFamilyMembers = family.filter(
            member => member.approveYn == 'Y',
          );
          store.commit('userStore/familyInfos', confirmedFamilyMembers);
          return family;
        } else {
          this.$router.push('/network');
        }
      });
    },

    async addFamily() {
      if (this.isFamilyAddable !== true) {
        let errorCode = this.isFamilyAddable;
        this.$root.$emit('openAlert', this.errors[errorCode]);
        return false;
      }
      let email = this.email;

      let addResult = await this.api.addUserFamily({ email });
      if (addResult.code == 0) {
        this.familyAccounts = await this.getFamilyAccounts();
      } else if (addResult.code > 0) {
        this.$root.$emit('openAlert', this.errors[addResult.code]);
      } else {
        this.$router.push('/network');
      }

      this.email = '';

      return true;
    },

    confirmDeleteFamily(mbrNo) {
      this.selectedMbrNo = mbrNo;
      this.$root.$emit('openAlert', this.deleteConfirmAlert);
      this.setEvent();
    },
    setEvent() {
      this.$root.$off('deleteConfirmAlert');
      this.$root.$on('deleteConfirmAlert', async isConfirmed => {
        if (isConfirmed) {
          await this.deleteFamily();
        } else {
          this.selectedMbrNo = '';
        }
      });
    },
    async deleteFamily() {
      let mbrNo = this.selectedMbrNo;
      let deleteResult = await this.api.removeUserFamily({ mbrNo });
      if (deleteResult.code == 0) {
        this.familyAccounts = await this.getFamilyAccounts();
        this.$root.$emit('openAlert', this.deleteSuccessAlert);
      }

      this.selectedMbrNo = '';
    },
    toggleFamilyAddForm() {
      if (!this.isAddFormExposed) {
        if (this.isFamilyAddable !== true) {
          let errorCode = this.isFamilyAddable;
          this.$root.$emit('openAlert', this.errors[errorCode]);
          return false;
        }
      }

      this.isAddFormExposed = !this.isAddFormExposed;
    },
  },
};
</script>

<style scoped>
.family_account_wrap {
  clear: both;
}
.family_account_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.3rem;
}

.family_account_head .ttl {
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
}

.family_account_head .add_btn {
  width: 10.3rem;
  padding-bottom: 0.6rem;
  border-bottom: 0.1rem solid #fff;
  font-size: 1.6rem;
  font-weight: 300;
  text-align: center;
  color: #fff;
}

.account_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  background: #4a4a4a;
}
.account_item + .account_item {
  margin-top: 1rem;
}

.account_group .profile_info {
  margin-right: 2rem;
  vertical-align: middle;
}

.account_group .profile_info .profile {
  width: 5rem;
  height: 5rem;
  margin-right: 0;
}
.account_group .profile_info_area {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.account_group .profile_info_area .name {
  font-size: 1.8rem;
  font-weight: 200;
  color: #fff;
}

.account_group .profile_info_area .date {
  font-size: 1.4rem;
  font-weight: 200;
  color: var(--txt-gray-2);
}

.account_group .profile_info_area .date.is_error {
  color: #e31f1f;
}

.account_group .profile_info_area .date.is_error:before {
  display: inline-block;
  content: '';
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.6rem;
  background: #e31f1f;
  border-radius: 50%;
  vertical-align: middle;
}

.account_group .profile_info_area .date.is_success {
  color: #00b981;
}

.account_group .profile_info_area .date.is_success:before {
  display: inline-block;
  content: '';
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.6rem;
  background: #00b981;
  border-radius: 50%;
  vertical-align: middle;
}

.account_item .ic_area {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.email_wrap {
  position: relative;
  margin-top: 1rem;
  padding: 2rem 3rem;
  border: 0.1rem solid #4a4a4a;
}

.email_input_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email_input_group label {
  display: block;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.4;
  color: #fff;
}

.email_wrap .input_area.email {
  width: 34rem;
  text-align: left;
}

.email_wrap .input_area.email .input_box {
  width: 100%;
  padding: 1rem 1.4rem;
  background: #2e2e2e;
}

.email_wrap .input_area.email .input_box input {
  width: 100%;
  background: #2e2e2e;
  font-size: 1.6rem;
  color: #fff;
}

.email_wrap .input_area.email .input_box.is_error {
  border: 0.1rem solid #e31f1f;
}

.email_wrap .ic_area {
  position: absolute;
  top: 50%;
  right: 2rem;
  width: 4rem;
  height: 4rem;
  margin-top: -2rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.validation {
  margin-top: 0.5rem;
  font-size: 1.3rem;
  font-weight: 300;
  color: #e31f1f;
}
@media screen and (max-width: 767px) {
  .family_account_head .ttl {
    font-size: 1.6rem;
  }
  .account_group .profile_info_area .name {
    font-size: 1.5rem;
  }
  .family_account_head .add_btn,
  .account_group .profile_info_area .date {
    font-size: 1.3rem;
  }
  .family_account_head {
    margin-bottom: 1.8rem;
  }
  .family_account_head .add_btn {
    width: auto;
  }
  .account_item {
    padding: 2rem 1.6rem;
  }
  .account_item .ic_area {
    background: rgba(0, 0, 0, 0.2);
  }
  .account_item .ic_area .ic {
    width: 3.6rem !important;
    height: 3.6rem !important;
  }
  .account_group .profile_info .profile {
    width: 4rem !important;
    height: 4rem !important;
  }
  .account_group .profile_info_area .date {
    margin-top: 0.7rem;
  }

  .email_wrap {
    position: relative;
    padding: 1.6rem;
  }
  .email_wrap .input_area.email {
    width: 100%;
  }
  .email_wrap .input_area.email .input_box {
    position: relative;
    padding-right: 4rem;
  }
  .email_wrap .ic_area {
    right: 0;
    top: 0;
    margin-top: -0.2rem;
    background: transparent;
  }
  .email_wrap .ic_area .ic {
    width: 2.4rem !important;
    height: 2.4rem !important;
  }
}
</style>
