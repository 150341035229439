<template>
  <div id="mail_modal" class="modal_wrap" :class="{ is_show: show }">
    <div class="modal helpCenter">
      <div class="modal_head">
        <h3 class="modal_ttl">Contact Artist</h3>
        <span> Send email to offer your purchase price to the artist </span>
      </div>
      <div class="modal_body">
        <div class="introduce_wrap">
          <div class="input_area type_hoz text">
            <div
              class="input_box"
              :class="reg.test(this.email) ? '' : 'is_error'"
            >
              <label for="txt-email">* Your email address</label>
              <input
                id="txt-email"
                type="text"
                v-model="email"
                @input="isValidText()"
                aria-describedby="text-email-error"
              />
            </div>
            <p v-if="errorMsg" class="validation">
              <!-- Dev : email 입력형식 오류 -->
              <span v-if="isError" id="text-email-error"
                >This email is not valid. ex)abcd@kkk.com</span
              >
              <!-- Dev : 등록된 계정이 아닐 경우 -->
              <!-- <span>This Account is not registered for LG Art Lab.</span> -->
            </p>
          </div>
          <div class="input_area type_hoz text">
            <label for="subject">* Subject</label>
            <div class="input_box">
              <input
                type="text"
                label="Subject"
                v-model="subject"
                id="subject"
              />
            </div>
          </div>
          <div class="input_area type_hoz textarea">
            <label for="description">* Description</label>
            <div class="input_box">
              <textarea
                id="description"
                aria-labelledby="description"
                title="Description"
                v-model="description"
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="modal_foot">
        <div class="btn_area">
          <button class="btn basic w_xs h_m" @click="close">Cancel</button>
          <button class="btn strong w_xs h_m" @click="sendEmail">Send</button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';
import { mapGetters } from 'vuex';

export default {
  name: 'ContactArtistModal',
  components: { IcCloseM },
  data() {
    return {
      show: false,
      reg: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
      isError: false,
      errorMsg: false,
      email: '',
      subject: '',
      description: '',
      emailAlert: {
        type: 'warn',
        desc: 'Plese enter Email \n* Mark is mendatory.',
        modalClass: '.modal.helpCenter',
        modalIndex: '0',
      },
      subjectAlert: {
        type: 'warn',
        desc: 'Plese enter Subject \n* Mark is mendatory.',
        modalClass: '.modal.helpCenter',
        modalIndex: '9',
      },
      descriptionAlert: {
        type: 'warn',
        desc: 'Plese enter Description \n* Mark is mendatory.',
        modalClass: '.modal.helpCenter',
        modalIndex: '10',
      },
      completeAlert: {
        type: 'success',
        desc: 'Your contact is completed.',
      },
    };
  },
  computed: {
    ...mapGetters({
      accessToken: 'appStore/accessToken',
      userEmail: 'userStore/email',
    }),
  },
  methods: {
    disableParentScroll(event) {
      event.stopPropagation();
    },
    isValidText() {
      if (this.reg.test(this.email)) {
        this.isError = false;
        this.errorMsg = false;
      } else {
        this.errorMsg = true;
        this.isError = true;
      }
    },
    open() {
      this.show = true;
      if (this.accessToken) this.email = this.userEmail;
      this.$root.$emit('setScrollFixed', true);
      this.setFocusTrap(true, '.modal.helpCenter');
    },
    close() {
      this.isError = false;
      this.setFocusTrap(false, '.modal.helpCenter');
      this.show = false;
      this.$root.$emit('setScrollFixed', false);
      this.$emit('closeModal');
    },
    openDrop() {
      this.showSelect = !this.showSelect;
    },
    async sendEmail() {
      if (this.isError) {
        this.$root.$emit('openAlert', this.emailAlert);
        return;
      }
      if (!this.email) {
        this.$root.$emit('openAlert', this.emailAlert);
        return;
      }
      if (!this.subject) {
        this.$root.$emit('openAlert', this.subjectAlert);
        return;
      }
      if (!this.description) {
        this.$root.$emit('openAlert', this.descriptionAlert);
        return;
      }

      let submitResult = await this.submit();
      if (submitResult.code != 0) {
        alert('submit failed');
        return null;
      }
      this.$root.$emit('openAlert', this.completeAlert);

      this.email = '';
      this.subject = '';
      this.description = '';
      this.close();
    },

    async submit() {
      let params = {
        email: this.email,
        typeOfInquiry: 'Contact artist to buy',
        subject: this.subject,
        description: this.description,
      };

      return await this.api.getSubmitRequest(params);
    },
  },
};
</script>

<style scoped>
.drop_ui .drop_btn {
  width: 100%;
  text-align: left;
  background-color: #2e2e2e;
}
.validation {
  color: red;
  padding: 10px;
  font-weight: 500;
}
.choose-type {
  padding: 2px;
}
.modal_head {
  text-align: left;
}
.modal_body .bg_edit_wrap {
  position: relative;
  width: 100%;
  height: 13.6rem;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.modal_body .bg_edit_wrap .ic_area.type_camera {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.modal_body .profile_area {
  position: relative;
  display: block;
  cursor: auto;
}

.modal_body .profile_area .profile {
  position: relative;
  display: inline-block;
  width: 11rem !important;
  height: 11rem !important;
  margin-top: -5.5rem !important;
  margin-right: 0.3rem !important;
  margin-bottom: 1.5rem !important;
  border-radius: 50%;
  background-size: cover;
}
.modal_body .profile_area.is_art .profile {
  width: 9.4rem !important;
  height: 9.4rem !important;
}

.modal_body .profile_area .ic_ring {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: -1;
  margin-top: -6.3rem !important;
  margin-left: -5.4rem !important;
}
.modal_body .profile_area .profile .ic_area.type_camera {
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
}

.modal_body .profile_area .ttl {
  display: block;
  margin-top: 1rem;
  font-size: 2.4rem;
  font-weight: 300;
  color: #fff;
}

.modal_body .introduce_wrap {
  margin-top: 3.3rem;
  margin-bottom: 1.3rem;
  text-align: left;
}
.modal_body .introduce_wrap .input_area.textarea textarea {
  min-height: 10rem;
}

.modal_body .introduce_wrap .input_area.text label {
  width: 9.5rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: #fff;
  vertical-align: middle;
}
.modal_body .introduce_wrap .input_area.text .input_box {
  width: calc(100% - 11rem);
  vertical-align: middle;
}

.modal_body .introduce_wrap .input_area.text .input_box input {
  width: 100%;
  padding: 1.1rem 1.4rem;
  background: #2e2e2e;
  font-size: 1.6rem;
  font-weight: 300;
  color: #fff;
}

.modal_body .introduce_wrap .input_area + .input_area {
  margin-top: 1.4rem;
}
.modal_body + .modal_foot {
  margin-top: 30px;
}

.input_area {
  display: flex;
  flex-flow: column;
}
.input_box {
  width: 100% !important;
}
.modal_body .introduce_wrap .input_area label,
.modal_body .introduce_wrap .input_area.text label {
  width: 100% !important;
  padding-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .modal_wrap {
    overflow: hidden !important;
  }
  .choose-type {
    display: block;
  }
  .modal_body .introduce_wrap .input_area.text label {
    padding-bottom: 5px;
  }

  .modal_body .introduce_wrap .input_area.text .input_box {
    width: 100%;
  }
  .modal_body .introduce_wrap .input_area label,
  .modal_body .introduce_wrap .input_area.text label {
    margin-bottom: 0 !important;
    font-size: 1.4rem;
    width: 100%;
  }
  .modal_body .introduce_wrap .input_area.textarea textarea {
    height: 5rem;
  }
  .modal_body .introduce_wrap .input_area + .input_area {
    margin-top: 0.7rem;
  }
  .modal_body .introduce_wrap {
    max-height: 270px;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 0 2rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.file-box {
  display: flex !important;
}
.file-input {
  width: 80% !important;
}
.file-btn {
  padding: 1.1rem 1.4rem;
}
.input_area > select,
.input_area > select option {
  color: #000;
  height: 42px;
}
</style>
