<template>
  <IconBase
    icon-name="arwPageLast"
    :icon-label="iconLabel"
    :width="11.043"
    :height="9.664"
    viewBox="0 0 11.043 9.664"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="2"
  >
    <g fill="none" data-name="그룹 3748">
      <path d="m6.374.332 4 4.5-4 4.5" data-name="패스 3750" />
      <path d="m.374.332 4 4.5-4 4.5" data-name="패스 3752" />
    </g>
  </IconBase>
</template>

<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'arwPageLast',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#6c6c6c',
    },
    iconLabel: {
      type: String,
      default: 'PageLast',
    },
  },
};
</script>

<style scoped></style>
