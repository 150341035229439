<template>
  <div class="modal_wrap" :class="{ is_show: showWallet }">
    <div class="modal" role="dialog">
      <div class="modal_head">
        <h3 class="modal_ttl">{{ subject }}</h3>
      </div>
      <div class="modal_body">
        <div class="img_area">
          <picture>
            <img
              :srcset="`${require('@/assets/img/img_modal_illust_connectwallet_l.png')} 4x, ${require('@/assets/img/img_modal_illust_connectwallet_m.png')} 3x, ${require('@/assets/img/img_modal_illust_connectwallet.png')}`"
              :src="require('@/assets/img/img_modal_illust_connectwallet.png')"
              width="164"
              alt="illust_connectwallet"
            />
          </picture>
        </div>
        <p class="desc">
          {{ content }}
        </p>
      </div>
      <div class="modal_foot">
        <div class="btn_area">
          <button
            class="btn w_s h_m strong"
            @click="connectWallet"
            :class="{
              is_loading: isLoading,
            }"
          >
            {{ btnTxt }}
          </button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ConnectWalletModal',
  components: { IcCloseM },
  props: {
    modalId: {
      type: String,
      default: 'modal',
    },
    modalSize: {
      type: String,
      default: '',
    },
    modalData: {
      type: Object,
      default: null,
    },
    showWallet: {
      type: Boolean,
      default: false,
    },
    walletTokenExpired: {
      type: Boolean,
      default: false,
    },
    walletChange: {
      type: Boolean,
      default: false,
    },
    walletInfo: {
      type: Object,
      default: () => ({
        walletType: 'WLT002',
        resultPrice: 0,
      }),
    },
  },
  watch: {
    showWallet: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.$root.$emit('setScrollFixed', true);
        } else {
          this.$root.$emit('setScrollFixed', false);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      walletYn: 'userStore/walletYn',
      email: 'userStore/email',
    }),
    subject() {
      return this.walletChange ? 'Switch Your Wallet' : 'Connect Your Wallet';
    },
    content() {
      let desc = 'There is no wallet linked to your account.';
      let loading =
        'There is no wallet linked to your account. (Please wait while we check your wallet connection.)';
      if (this.walletTokenExpired) {
        desc =
          'Your previously connected wallet is expired. Please re-connect to your wallet.';
      }
      if (this.walletChange) {
        desc =
          'The wallet you purchased for this art work is different from currently connected wallet. Please switch to the purchased wallet.';
      }
      return this.isLoading ? loading : desc;
    },
    btnTxt() {
      let basic = 'Connect Wallet';
      let loading = 'Checking...';
      return this.isLoading ? loading : basic;
    },
  },
  data() {
    return {
      polling: null,
      overtimer: 180000,
      isLoading: false,
    };
  },
  methods: {
    async connectWallet() {
      if (this.isLoading) return false;
      this.isLoading = true;
      clearInterval(this.polling);

      let walletParams = {
        wlltType: this.walletInfo.walletType,
      };

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      // if (process.env.VUE_APP_PROFILE === 'DEV') {
      walletParams.bcNwTp = '10';
      // }

      await this.api.connectWallet(walletParams).then(res => {
        if (res.code === 0) {
          if (this.walletInfo.walletType === 'WLT001') {
            let params = {
              hostServer: res.hostServer,
              signAddr: res.signAddr,
              scheme: 'setup',
              price: this.walletInfo.resultPrice,
            };
            let purchasePage = [
              { marketplaceDetail: 'marketplace' },
              { dropsDetail: 'drop' },
            ];
            for (let v of purchasePage.values()) {
              let purchaseType = v[this.$route.name];
              if (purchaseType) params.purchaseType = purchaseType;
            }

            if (params.purchaseType === 'drop') {
              params.NFTImage = this.walletInfo.NFTImage;
              params.NFTName = this.walletInfo.NFTName;
            }
            this.onBlade(params, this.email);
          } else if (this.walletInfo.walletType === 'WLT002') {
            this.onCallApp(res.signUrl);
          } else {
            this.$router.push({
              name: 'network',
            });
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 10000);

          this.polling = setInterval(() => {
            this.getProfile();
            if (this.overtimer <= 0) {
              clearInterval(this.polling);
              this.isLoading = false;
            }
            this.overtimer -= 3000;
          }, 3000);
        } else {
          this.isLoading = false;
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
    async getProfile() {
      await this.api.getProfile({}).then(res => {
        if (res.code === 0) {
          if (res.walletYn === 'Y') {
            this.isLoading = false;
            clearInterval(this.polling);
            this.setWalletYn(res.walletYn);
            this.close();
          }
        } else {
          this.$router.push({
            name: 'network',
          });
        }
      });
    },
    close() {
      this.isLoading = false;
      this.$root.$emit('setScrollFixed', false);
      clearInterval(this.polling);
      this.$emit('close', 'showWallet');
    },
    ...mapMutations({
      setWalletYn: 'userStore/walletYn',
    }),
  },
};
</script>

<style scoped>
.img_area {
  max-width: 16.4rem;
  margin: 1.5rem auto;
}
.modal .modal_body + .modal_foot {
  margin-top: 2.5rem;
}
.is_loading {
  background: #e9ecef !important;
  color: black !important;
}
</style>
