import { homeStoreActions } from './homeStoreActions';
export const homeStore = {
  namespaced: true,
  state: {
    dropInfos: [], // Drop 작품
    purchasedItems: [], // Edition 목록
  },
  getters: {
    dropInfos: state => {
      return state.dropInfos;
    },
    purchasedItems: state => {
      return state.purchasedItems;
    },
  },
  mutations: {
    dropInfos: (state, dropInfos) => {
      state.dropInfos = dropInfos;
    },
    purchasedItems: (state, purchasedItems) => {
      state.purchasedItems = purchasedItems;
    },
  },
  actions: homeStoreActions,
};
