<template>
  <IconBase
    icon-name="IcInstagram"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    stroke-width="1.2"
    :icon-color="iconColor"
  >
    <path
      d="M10 5h10c2.8 0 5 2.2 5 5v10c0 2.8-2.2 5-5 5H10c-2.8 0-5-2.2-5-5V10c0-2.8 2.2-5 5-5z"
      fill="none"
      style="stroke-miterlimit: 10"
      :stroke="iconColor"
    />
    <path
      d="M14.9 10.5h.2c2.4 0 4.4 2 4.4 4.4v.2c0 2.4-2 4.4-4.4 4.4h-.2c-2.4 0-4.4-2-4.4-4.4v-.2c0-2.4 2-4.4 4.4-4.4z"
      fill="none"
      style="stroke-miterlimit: 10"
      :stroke="iconColor"
    />
    <path
      d="M22.3 8.9c0 .7-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2.5-1.2 1.2-1.2 1.2.6 1.2 1.2"
      stroke-width="0"
    />
  </IconBase>
</template>

<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcInstagram',
  components: { IconBase },
  props: {
    iconLabel: {
      type: String,
      default: 'Instagram',
    },
    iconColor: {
      type: String,
      default: '#fff',
    },
  },
};
</script>

<style scoped></style>
