<template>
  <IconBase
    icon-name="icPrevFile"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path d="M7 14.855 23.5 25.5v-21Z" />
    <path data-name="Path 2727" d="M7.5 4.5v21" transform="translate(-1)" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcPrevFile',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Play previous file',
    },
  },
};
</script>

<style scoped></style>
