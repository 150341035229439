<template>
  <IconBase
    icon-name="icCloseS"
    :icon-label="iconLabel"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="strokeWidth"
  >
    <path data-name="Line 1" d="m5.5 5.5 13 13" />
    <path data-name="Line 2" d="m18.5 5.5-13 13" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcCloseS',
  components: { IconBase },
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    strokeColor: {
      type: String,
      default: '#939393',
    },
    strokeWidth: {
      type: [Number, String],
      default: 1.2,
    },
    iconLabel: {
      type: String,
      default: 'Clear search field',
    },
  },
};
</script>

<style scoped></style>
