<template>
  <IconBase
    icon-name="IcFaceBook"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    :iconColor="iconColor"
  >
    <path
      d="M19.6 11.5h-3.2V9.4c-.1-.5.3-.9.7-1H19.5V5h-3.1c-2.1-.2-4 1.4-4.2 3.6v2.9h-2V15h2v10h4.2V15h2.8l.4-3.5z"
    />
  </IconBase>
</template>

<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcFaceBook',
  components: { IconBase },
  props: {
    iconColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'FaceBook',
    },
  },
};
</script>

<style scoped></style>
