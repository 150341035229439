<template>
  <div class="activities_item_list_wrap">
    <ul class="thumb_list activities_item_list">
      <li v-for="item in itemData" :key="item.id" class="thumb_node list_note">
        <div class="thumb_item type_list">
          <div class="card_frame">
            <div class="card_head">
              <div class="thumb_area">
                <img
                  v-if="item.mimeType === 'image/jpeg'"
                  :srcset="`${item.imageInfos[0].resUrl} 768w, ${item.imageInfos[1].resUrl}`"
                  :src="item.imageInfos[0].resUrl"
                  :width="262"
                  :alt="item.title ? item.title : ''"
                />
                <img
                  v-else
                  :srcset="`${item.videoThumbInfos[0].resUrl} 768w, 
                        ${item.videoThumbInfos[1].resUrl}`"
                  :src="item.videoThumbInfos[0].resUrl"
                  :width="279"
                  :alt="item.title ? item.title : ''"
                />
                <span v-if="item.type === 'video'" class="ic_area type_video">
                  <IcVideo />
                </span>
                <div v-if="item.reject" class="reject_area">
                  <p>Reject</p>
                </div>
              </div>
            </div>
            <div class="card_body">
              <div class="artist_info_group profile_info is_art">
                <span
                  v-if="item.artistImg"
                  class="profile"
                  :style="`background-image: url('${item.artistImg}')`"
                ></span>
                <IcProfile v-else :width="24" :height="24" class="profile" />
                <IcRing class="ic_ring" />
                <router-link :to="`/mylist/artist/${item.artistMbrNo}`">
                  <p class="name">{{ item.artistName }}</p>
                </router-link>
              </div>
              <div class="ttl_area">
                <h2 class="ttl">{{ item.title }}</h2>
              </div>
              <!-- PUB : Contract Address 추가/ 블록체인 아이콘 추가 및 금액표기 수정 /지갑계정변경 배너 추가 / 반응형 대비 기존마크업 수정 START-->
              <div class="contract_info_group">
                <span>Contract Address</span>
                <button
                  type="button"
                  @click="goContract(item.bcNwTp, item.contractAddr)"
                >
                  {{ addrEllipsis(item.contractAddr) }}
                </button>
              </div>
              <div class="info_area">
                <div class="blockchains_info_group">
                  <p class="blockchains">
                    <!-- 윈도우 리사이즈에 따라 아이콘 변경 -->
                    <!-- WIP : 아이콘 분기-->
                    <!-- PUB : 아이콘 사이즈 수정 S -->
                    <template v-if="window.width > 1024">
                      <IcHederaS
                        v-if="item.bcNwTp == '10'"
                        style="width: 2.2rem; height: 2.4rem"
                      />
                      <IcEthS v-else style="width: 2.2rem; height: 2.4rem" />
                      <!-- PUB : 아이콘 사이즈 수정 E -->
                      <b>{{ item.bcNwTp == '10' ? 'Hedera' : 'Ethereum' }}</b>
                    </template>
                    <template v-else>
                      <IcHedera v-if="item.bcNwTp == '10'" />
                      <IcEth v-else />
                    </template>
                  </p>
                </div>
                <div class="owner_info_group">
                  <p class="editions" v-if="item.totalEdition > 1">
                    {{ item.editionId }} of {{ item.totalEdition }} Editions
                  </p>
                  <p class="editions" v-else>1 Edition</p>
                  <dl>
                    <dt>Owned by</dt>
                    <dd class="profile_info">
                      <span class="name">{{ item.ownerName }}</span>
                    </dd>
                  </dl>
                  <p class="time">
                    <span>{{ item.createDate | GmtToTz | UsaFormat }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card_foot">
              <div class="sale_info_group">
                <dl>
                  <!--  -->
                  <dt v-if="item.activityType == 20" :class="'type_1'">
                    {{ 'Purchased' }}
                  </dt>
                  <dt v-else-if="item.activityType == 30" :class="'type_2'">
                    {{ 'Sold' }}
                  </dt>
                  <dt v-else-if="item.activityType == 70" :class="'type_2'">
                    {{ 'Restored' }}
                  </dt>
                  <dt v-else-if="item.activityType == 60" :class="'type_2'">
                    {{ 'Transferred' }}
                  </dt>
                  <dd class="price">
                    <!-- WIP : 금액표기 분기-->
                    <b v-if="item.bcNwTp == '10'">
                      {{ item.price | setNum }} USDC
                    </b>
                    <b v-else
                      >{{ item.price }}<span class="unit">Ξ</span
                      ><span class="dollars"
                        >({{ item.priceToEthUsd | crtpyAbbreviate }})</span
                      ></b
                    >
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- 지갑 계정 변경 배너 -->
      <!-- WIP : aip 변경 후 적용 예정 0527 -->
      <!-- <li class="account_change_banner">
        <div class="ttl_area">
          <div class="ttl_group">
            <p class="time">
              <span>Mar 13, 2024 | 01:48 AM (EDT)</span>
            </p>
            <span class="ttl">Ethereum Wallet Account changed</span>
          </div>
        </div>
        <button type="button" class="check_wallet_btn">Check My Wallet</button>
      </li> -->
      <!-- PUB : END -->
    </ul>
  </div>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
import IcVideo from '@/components/ic/IcVideo';
import IcRing from '@/components/ic/IcRing';
import IcEth from '@/components/ic/IcEth';
import IcEthS from '@/components/ic/IcEthS';
import IcHedera from '@/components/ic/IcHederaS';
import IcHederaS from '@/components/ic/IcHederaS';
// PUB : 이더리움 및 헤데라 아이콘 컴포넌트 추가
export default {
  name: 'ActivitiesItem',
  components: {
    IcProfile,
    IcVideo,
    IcRing,
    IcEth,
    IcEthS,
    IcHedera,
    IcHederaS,
    // PUB : 이더리움 및 헤데라 아이콘 추가
  },
  props: {
    itemData: {
      type: Array,
      default: null,
    },
    setTitle: {
      type: String,
      default: null,
    },
  },
  // PUB : 윈도우 리사이즈 추가 START
  data() {
    return {
      contractAddress: '',
      artworkAlt: '',
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  // PUB : 윈도우 리사이즈 추가 END
  mounted() {},
  computed: {
    activitiesContentsUrl() {
      let artworkTypes = ['image/jpeg', 'video/mp4'];
      let artworkType = this.itemData.mimeType;
      if (artworkTypes.includes(artworkType)) {
        return artworkType == 'image/jpeg'
          ? this.itemData.imageInfos[0].resUrl
          : this.itemData.videoThumbInfos[0]?.resUrl;
      } else {
        return null;
      }
    },
    activityStatus() {
      let activityType = this.itemData.activityType;
      if (activityType == 20) {
        // this.btnType = 'type_1';
        return 'Purchase';
      } else {
        // this.btnType = 'type_2';
        return 'Sell';
      }
    },
    btnType() {
      let activityType = this.itemData.activityType;
      if (activityType == 20) {
        return 'type_1';
      } else {
        return 'type_2';
      }
    },
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    goContract(bcNwTp, addr) {
      this.contractAddress = addr;
      if (bcNwTp == '20') {
        this.goEth();
      } else {
        this.goHdr();
      }
    },
    goHdr() {
      let dragonLessUrl =
        process.env.VUE_APP_PROFILE === 'DEV'
          ? 'testnet.dragonglass.me'
          : 'app.dragonglass.me';
      window.open(
        'about:blank',
      ).location.href = `https://${dragonLessUrl}/hedera/search?q=${this.contractAddress}`;
    },
    goEth() {
      let etherscan =
        process.env.VUE_APP_PROFILE === 'DEV'
          ? 'sepolia.etherscan.io'
          : 'etherscan.io';
      window.open(
        'about:blank',
      ).location.href = `https://${etherscan}/address/${this.contractAddress}`;
    },
    addrEllipsis(addr) {
      if (this.$isMobile()) {
        return addr.length > 15
          ? addr.substr(0, 8) +
              '...' +
              addr.substr(addr.length - 3, addr.length)
          : addr;
      }
      return addr;
    },
  },
};
</script>

<style scoped>
@import url(../../assets/css/customGlobal.css);
</style>
