<template>
  <IconBase
    icon-name="icPlayS"
    :icon-label="iconLabel"
    :width="24"
    :height="24"
    viewBox="0 0 24 24"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="2"
  >
    <path d="M7.695 5.07V19l10.447-6.965Z" data-name="패스 195" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'icPlayS',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Play',
    },
  },
};
</script>

<style scoped></style>
