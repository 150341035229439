<template>
  <IconBase
    icon-name="arwSelect"
    :icon-label="iconLabel"
    :width="20"
    :height="20"
    viewBox="0 0 20 20"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path d="m4.5 7.499 5.714 6 6.285-6" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'ArwSelect',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Select arrow',
    },
  },
};
</script>

<style scoped></style>
