<template>
  <div v-if="isLoading" class="modal_wrap show">
    <div class="modal s type_dialog">
      <div class="modal_head">
        <h3 class="ttl">{{ message }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingModal',
  props: ['isLoading', 'message'],
};
</script>

<style scoped>
.modal_wrap {
  display: block;
}
</style>
