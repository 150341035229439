<template>
  <main id="contents" class="my_list_page delete_page">
    <div class="set_inner clearFix">
      <h1 class="page_ttl only_pc">My Account</h1>
      <h1 class="page_ttl dv_tab">Settings</h1>
      <AccountMenu />
      <div class="min_inner">
        <section class="delete_sec">
          <div class="delete_sec_head">
            <div class="ttl_area">
              <h2 class="ttl">Delete Account</h2>
              <p>
                If you want to permanently delete your LG Art Lab account, press
                the button below.
              </p>
            </div>
          </div>
          <div class="delete_sec_body">
            <div class="permanent_box">
              <h3>
                When you delete your account, the account will be permanently
                removed.
              </h3>
              <p>When you delete your LG Art Lab account,</p>
              <ul>
                <li>
                  You won’t be able to retrieve the information of your
                  activities within LG Art Lab.
                </li>
                <li>
                  You and other members won’t be able to reuse and restore the
                  username again.
                </li>
                <li>
                  You can find the NFT Artworks you’ve purchased from LG Art Lab
                  in the Wallypto wallet, but won’t be able to resell them on LG
                  Art Lab even after rejoining it.
                </li>
              </ul>
            </div>
            <div class="btn_area">
              <router-link
                to="/mylist/notification"
                class="btn basic w_xl h_l dv_mob"
                >Cancel</router-link
              >
              <button class="btn strong w_xl h_l" @click="checkAccount()">
                Delete Account
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import AccountMenu from '@/components/common/AccountMenu';
export default {
  name: 'DeleteAccountView',
  components: { AccountMenu },
  data() {
    return {
      windowRef: '',
      overlapAlert: {
        type: 'warn_2',
        desc: 'Are you sure you want to delete your account permanently?',
        id: 'withdrawPopup',
      },
      deleteSuccessAlert: {
        type: 'success',
        desc: 'Account’s deleted.',
        id: 'withdrawSuccess',
      },
      apiFailAlert: {
        type: 'warn',
        desc: 'The Internet connection is bad. Try it later or reload the page.',
      },
    };
  },
  mounted() {
    window.withdrawProc = () => {
      this.withdraw();
    };
  },
  methods: {
    openModal(ref) {
      this.$refs[ref].show = true;
    },
    checkAccount() {
      this.$root.$emit('openAlert', this.overlapAlert);
      this.setEvent();
    },
    setEvent() {
      this.$root.$off('withdrawSuccess');
      this.$root.$off('withdrawPopup');

      this.$root.$on('withdrawPopup', Boolean => {
        if (Boolean) {
          const base_url =
            process.env.VUE_APP_EMP + '/member/delete_account_introduce';
          //const callbackUrl = process.env.VUE_APP_SERVICE_HOST + '/withdrawProcess';
          const callbackUrl = window.location.origin + '/withdrawProcess';
          const authSvr = 'emp';
          const country = 'US';
          const language = 'en-US';
          const empUrl = `${base_url}?authSvr=${authSvr}&callbackUrl=${callbackUrl}&redirect_uri=${callbackUrl}&country=${country}&language=${language}`;
          const setOption =
            'top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no';
          this.windowRef = window.open(empUrl, 'EMP LOGIN', setOption);
        }
      });

      this.$root.$on('withdrawSuccess', Boolean => {
        if (Boolean) {
          this.$EventBus.$emit('logout');
        }
      });
    },
    async withdraw() {
      await this.api.withdrawalProfile({}).then(res => {
        if (res.code === 0) {
          this.$root.$emit('openAlert', this.deleteSuccessAlert);
        } else {
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
  },
};
</script>

<style scoped></style>
