<template>
  <div class="set_inner">
    <div class="search_wrap search_form">
      <div class="input_area search">
        <div class="input_box">
          <IcMobSearch class="ic_search" />
          <input
            v-model="keyword"
            type="search"
            placeholder="Search for your favorite art or artist…"
            @keydown.enter="onSearch"
            @input="search($event)"
            ref="keywordRef"
            label="Search"
            title="Search"
          />
          <button
            v-if="keyword.length > 0"
            type="button"
            class="btn type_ic remove_word"
            @click="reset"
          >
            <IcCloseS
              :iconLabel="'Clear search field'"
              stroke-color="#fff"
              :width="20"
              :height="20"
              :stroke-width="1"
            />
          </button>
        </div>
      </div>
      <transition name="fade">
        <div
          v-if="keyword.length > 0"
          class="auto_word_ui auto_word_frame has_scroll_y"
        >
          <ul class="word_list">
            <li
              class="word_node"
              v-for="(item, index) in keywords"
              :key="index"
              @click="onSearch(item)"
            >
              <button type="button" class="word_item">
                <span class="c_point">{{ keyword }}</span
                >{{ item.substr(keyword.length) }}
              </button>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <button v-if="type === 'web'" class="btn type_ic close_ui" @click="close">
      <IcCloseM :iconLabel="'Close search'" />
    </button>
  </div>
</template>

<script>
import IcCloseS from '@/components/ic/IcCloseS';
import IcCloseM from '@/components/ic/IcCloseM';
import IcMobSearch from '@/components/ic/IcMobSearch';
export default {
  name: 'SearchModal',
  components: { IcMobSearch, IcCloseM, IcCloseS },
  props: ['type'],
  data() {
    return {
      show: false,
      keyword: '',
      keywords: [],
    };
  },
  mounted() {
    this.$refs.keywordRef.focus();
  },
  methods: {
    reset() {
      this.keyword = '';
      this.keywords = [];
    },
    open() {
      this.show = true;
      this.$root.$emit('setScrollFixed', true);
    },
    close() {
      this.reset();
      this.$emit('searchClose');
    },
    search(event) {
      let keywrod = event.target.value;
      this.keyword = keywrod;
      if (!keywrod) {
        return;
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchCompleteKeywords();
      }, 200);
    },
    async searchCompleteKeywords() {
      if (this.keyword) {
        let params = { keyword: this.keyword };
        await this.api.getCompleteKeywords(params).then(res => {
          if (res.code === 0) {
            this.keywords = res.keywords;
          }
        });
      }
    },
    onSearch(key) {
      let keyword =
        key.code === 'Enter' || key.code === 'NumpadEnter' ? this.keyword : key;

      if (key.keyCode === 13) {
        keyword = this.keyword;
      }

      this.close();
      this.$router.push({
        name: 'search',
        params: { keyword },
      });
    },
  },
};
</script>

<style scoped>
.modal {
  text-align: left;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
}
textarea:focus,
input:focus {
  outline: none;
}
</style>
