<template>
  <div class="playlist_wrap" :class="{ mobile_wrap: $isMobile() }">
    <div v-if="$isMobile() && checkWidth">
      <div v-for="(item, i) in itemData" :key="item.id" ref="txtSlide">
        <button
          href="javascript:;"
          class="edit_item mobile-item"
          @click="setCurrentIndex(i)"
        >
          <div class="item_body item_txt">
            <div class="ttl_area">
              <h3 class="ttl mobile-ttl" :class="{ is_active: current == i }">
                {{ item.title }}
              </h3>
              <p class="desc">{{ item.description }}</p>
            </div>
          </div>
        </button>
      </div>
    </div>
    <div v-else>
      <swiper
        :options="playlistSlidOpt"
        ref="itemSwiper"
        class="edit_list"
        :class="addClass"
      >
        <swiper-slide
          class="edit_node"
          v-for="(item, i) in itemData"
          :key="item.id"
        >
          <a
            href="javascript:;"
            class="edit_item slide_item"
            :class="{ is_active: current == i }"
            @click="setCurrentIndex(i)"
          >
            <div class="item_head">
              <div class="thumb_area">
                <img
                  :src="
                    item.mimeType === 'image/jpeg'
                      ? item.imageInfos[0].resUrl
                      : item.videoThumbInfos[0].resUrl
                  "
                  :alt="item.title ? item.title : ''"
                />
              </div>
            </div>
            <div class="item_body">
              <div class="ttl_area">
                <h3 class="ttl">{{ item.title }}</h3>
                <p class="desc">{{ item.description }}</p>
              </div>
            </div>
          </a>
        </swiper-slide>
      </swiper>
    </div>
    <div class="set_inner">
      <div class="controller_group">
        <div class="control_area slide_nav_area">
          <button class="btn type_ic prev">
            <ArwPrev />
          </button>
          <button class="btn type_ic next">
            <ArwNext />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArwNext from '@/components/ic/arwNext';
import ArwPrev from '@/components/ic/arwPrev';
export default {
  name: 'Play',
  components: {
    ArwPrev,
    ArwNext,
  },
  props: {
    slideOpt: {
      type: Object,
      default: null,
    },
    itemData: {
      type: Array,
      default: null,
    },
    addClass: {
      type: String,
      default: '',
    },
    current: {
      type: Number,
      default: null,
    },
  },
  computed: {
    checkWidth() {
      let width = document.getElementsByClassName('viewer_sec')[0].clientWidth;
      return width < 440 ? true : false;
    },
  },
  data() {
    return {
      slidesPerGroup: 5,
      playlistSlidOpt: {
        loop: false,
        slidesPerView: 5,
        // slidesPerGroup: 1,
        slidesPerGroup: 5,
        spaceBetween: 19,
        navigation: {
          prevEl: '.playlist_wrap .slide_nav_area .prev',
          nextEl: '.playlist_wrap .slide_nav_area .next',
        },
        breakpoints: {
          2560: {
            slidesPerView: 8,
            slidesPerGroup: 8,
          },
          1024: {
            slidesPerView: 5,
            slidesPerGroup: 5,
          },
          500: {
            slidesPerView: 1.5,
            slidesPerGroup: 1,
          },
        },
      },
    };
  },
  mounted() {
    if (!this.checkWidth) {
      this.slideGroupCount =
        (this.$refs.itemSwiper.$swiper.slides.length + 1) / this.slidesPerGroup;
      this.maximumOfGroup = Math.ceil(this.slideGroupCount);
      let currentGroup = Math.ceil((this.current + 1) / this.slidesPerGroup);
      this.goSlideGroup(currentGroup);
    } else {
      this.$nextTick(this.scrollIntoViewIfActive());
    }
    let focusEl = '';
    if (this.$isMobile()) {
      focusEl = document.querySelectorAll('.mobile-item')[0];
    } else {
      focusEl = document.querySelectorAll('.slide_item')[0];
    }
    setTimeout(() => {
      focusEl.focus();
    }, 500);
  },
  watch: {
    current(newVal, oldVal) {
      let oldGroup = Math.ceil((oldVal + 1) / this.slidesPerGroup);
      let currentGroup = Math.ceil((newVal + 1) / this.slidesPerGroup);

      if (oldGroup != currentGroup) {
        if (!this.checkWidth) {
          this.goSlideGroup(currentGroup);
        } else {
          this.scrollIntoViewIfActive(newVal);
        }
      }
    },
  },
  methods: {
    scrollIntoViewIfActive(val) {
      const txtSlideElements = [...this.$refs.txtSlide];
      for (const div of txtSlideElements) {
        const activeElement = div.querySelector('.is_active');
        if (activeElement) {
          if (val == 0) {
            txtSlideElements[0].scrollIntoView({
              behavior: 'smooth',
            });
          } else {
            activeElement.scrollIntoView({
              behavior: 'smooth',
            });
          }
          break;
        }
      }
    },
    setCurrentIndex(idx) {
      this.$emit('changeCurrent', idx);
    },
    goSlideGroup(slideGroupNumber) {
      this.$refs.itemSwiper.$swiper.slideTo(
        slideGroupNumber * this.slidesPerGroup - 4,
        500,
        false,
      );
    },
  },
};
</script>

<style scoped>
.mobile-wrap {
  height: 250px;
  overflow: auto;
}
.mobile-wrap {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.mobile-wrap::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.mobile-item {
  width: 320px;
  overflow: none;
  text-align: left;
}
.mobile-ttl {
  color: var(--txt-gray);
}
.mobile-ttl.is_active {
  color: #fff;
}

.edit_item {
  display: block;
}
.edit_item .item_body {
  padding-top: 2rem;
}
.edit_item .ttl_area .ttl + p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.edit_item.is_active .item_head:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid var(--main-color);
  pointer-events: none;
}
/* .edit_item.is_active .item_txt:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-bottom: 1px solid var(--main-color);
  pointer-events: none;
} */
.controller_group {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}
.controller_group .slide_nav_area .btn {
  position: absolute;
  margin-top: -2.5rem;
}
.controller_group .slide_nav_area .btn.prev {
  left: 2rem;
}
.controller_group .slide_nav_area .btn.next {
  right: 2rem;
}
@media screen and (max-width: 600px) {
  .playlist_sec .slide_nav_area .btn {
    display: none !important;
  }
  .edit_list {
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .edit_list .edit_node {
    width: 28rem;
  }

  .swiper-container {
    width: 100% !important;
  }
  .swiper-wrapper {
    width: 50% !important;
  }
  .swiper-slide {
    text-align: center !important;
    width: auto !important;
  }
  .thumb_area img {
    height: 170px;
    width: 250px;
  }
  .my-gallery figure {
    margin: 0px;
  }
}
@media screen and (max-width: 1000px) and (orientation: landscape) {
  .playlist_wrap {
    width: 100%;
  }
  .edit_list {
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .playlist_sec .slide_nav_area .btn {
    display: none !important;
  }
  .edit_item .ttl_area .ttl {
    font-size: 1.4rem;
  }
  .edit_item .ttl_area p {
    font-size: 1.2rem;
  }
}
</style>
