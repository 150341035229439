<template>
  <div class="edition_slide_wrap type_item">
    <swiper
      ref="itemSwiper"
      class="edit_list"
      :class="addClass"
      :options="slideOpt"
    >
      <swiper-slide
        class="edit_node"
        v-for="item in itemData"
        :key="item.dropsId"
      >
        <DropsItem :item-data="item" :show-chip="true" />
      </swiper-slide>
    </swiper>
    <div class="control_area pagination_area type_dot"></div>
    <div class="control_area slide_nav_area">
      <button class="btn type_ic prev">
        <ArwPrev />
      </button>
      <button class="btn type_ic next">
        <ArwNext />
      </button>
    </div>
  </div>
</template>

<script>
import ArwPrev from '@/components/ic/arwPrev';
import ArwNext from '@/components/ic/arwNext';
import DropsItem from '@/components/drops/DropsItem';
export default {
  name: 'EndedSlide',
  components: {
    DropsItem,
    ArwNext,
    ArwPrev,
  },
  props: {
    slideOpt: {
      type: Object,
      default: null,
    },
    itemData: {
      type: Array,
      default: null,
    },
    addClass: {
      type: String,
      default: '',
    },
  },
  methods: {},
};
</script>

<style scoped></style>
