<template>
  <IconBase
    icon-name="logo"
    :icon-label="iconLabel"
    :width="150"
    :height="24"
    viewBox="0 0 150 24"
    :icon-color="iconColor"
    stroke-width="0"
  >
    <path
      d="M23.4 15.9H27v3.4c-1 .3-2.1.5-3.2.5-4 0-5.3-2-5.3-6.1 0-3.9 1.3-6.2 5.3-6.2 1.8-.1 3.5.6 4.5 2L31 6.9c-1.7-2.4-4.6-3.1-7.4-3.1-6.2 0-9.5 3.4-9.5 9.8s2.9 9.9 9.4 9.9c2.6.1 5.2-.6 7.5-1.9v-9.3h-7.7l.1 3.6zM5.5 23.2h8.1v-3.7H4.4V4H0v19.2h5.5z"
    />
    <path
      d="M23.4 15.9H27v3.4c-1 .3-2.1.5-3.2.5-4 0-5.3-2-5.3-6.1 0-3.9 1.3-6.2 5.3-6.2 1.8-.1 3.5.6 4.5 2L31 6.9c-1.7-2.4-4.6-3.1-7.4-3.1-6.2 0-9.5 3.4-9.5 9.8s2.9 9.9 9.4 9.9c2.6.1 5.2-.6 7.5-1.9v-9.3h-7.7l.1 3.6zM102 .3h2.2v22.2H102V.3zM126.6 13.6v-9h-2.2v2.8c-3.4-4.4-9.7-5.2-14.1-1.7-2.4 1.9-3.9 4.8-3.9 7.9 0 5.6 4.5 10.1 10.1 10 3.1 0 6-1.4 7.9-3.9v2.8h2.2v-8.9zm-10.1 7.9c-4.4 0-7.9-3.6-7.9-7.9s3.6-7.9 7.9-7.9c4.3 0 7.9 3.5 7.9 7.9v.1c0 4.3-3.5 7.8-7.9 7.8M149.1 13.6c0-5.6-4.5-10.1-10.1-10.1-3.1 0-6 1.4-7.9 3.9V.3h-2.2v22.2h2.2v-2.8c3.4 4.4 9.7 5.2 14.1 1.8 2.4-1.9 3.9-4.8 3.9-7.9M139 21.5c-4.4 0-7.9-3.6-7.9-7.9 0-4.4 3.6-7.9 7.9-7.9 4.4 0 7.9 3.5 7.9 7.9s-3.5 7.9-7.9 7.9M61.4 13.6v-9h-2.2v2.8C55.8 3 49.5 2.3 45.1 5.7c-2.4 1.9-3.9 4.8-3.9 7.9 0 5.6 4.5 10.1 10.1 10 3.1 0 6-1.4 7.9-3.9v2.8h2.2v-8.9zm-10.1 7.9c-4.4 0-7.9-3.6-7.9-7.9 0-4.4 3.6-7.9 7.9-7.9 4.3 0 7.9 3.5 7.9 7.9v.1c0 4.3-3.5 7.8-7.9 7.8M84.3 19.8c1.9 1.9 4.5 3 7.1 3v-2.2c-2.1 0-4.1-.8-5.6-2.3-1.5-1.5-2.3-3.5-2.3-5.6v-2.5h7.9V8h-7.9V.3h-2.2v12.3c0 2.7 1.1 5.3 3 7.2M66.7 6.5c-.3.3-.6.6-.8.9V4.6h-2.2v18h2.2v-9c0-4.4 3.6-7.9 7.9-7.9 1.4 0 2.8.4 4 1.1l1.1-1.9c-4-2.3-9-1.6-12.2 1.6"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'Logo',
  components: { IconBase },
  props: {
    iconColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'LG Art Lab',
    },
  },
};
</script>

<style scoped></style>
