<template>
  <div class="modal_wrap" :class="{ is_show: showEthProgress }">
    <div class="modal" role="dialog">
      <div class="modal_head">
        <h3 class="modal_ttl">Purchase In Progress</h3>
      </div>
      <div class="modal_body">
        <div class="img_area">
          <LoadingSpinner />
          <!-- <picture>
            <img
              :srcset="`${require('@/assets/img/img_illust_done.png')} 4x, ${require('@/assets/img/img_illust_done.png')} 3x, ${require('@/assets/img/img_illust_done.png')}`"
              :src="require('@/assets/img/img_illust_done.png')"
              width="164"
              alt="illust_connectwallet"
            />
          </picture> -->
        </div>
        <p class="desc">
          Your Purchase is securely complete!<br />This may take some time due
          to network speed.<br /><br />
          You can check your purchase status in<br />
          <router-link to="/mylist/activities" class="link"
            >My Account : Activities</router-link
          >
        </p>
      </div>
      <div class="modal_foot">
        <div class="btn_area">
          <button class="btn w_s h_m strong" @click="close">Close</button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';
import LoadingSpinner from '@/components/common/LoadingSpinner';

export default {
  name: 'ConnectWalletModal',
  components: { IcCloseM, LoadingSpinner },
  props: {
    modalId: {
      type: String,
      default: 'modal',
    },
    modalSize: {
      type: String,
      default: '',
    },
    modalData: {
      type: Object,
      default: null,
    },
    showEthProgress: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showEthProgress: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.$root.$emit('setScrollFixed', true);
        } else {
          this.$root.$emit('setScrollFixed', false);
        }
      },
    },
  },
  methods: {
    close() {
      this.$root.$emit('setScrollFixed', false);
      this.$emit('close', 'showEthProgress');
    },
  },
};
</script>

<style scoped>
.modal_wrap {
  overflow: hidden;
}
.img_area {
  max-width: 16.4rem;
  margin: 1.5rem auto;
}
.modal .modal_body + .modal_foot {
  margin-top: 2.5rem;
}
.link {
  color: #0000ff;
  text-decoration: underline;
  font-weight: 600;
}
</style>
