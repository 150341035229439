<template>
  <main id="contents" class="error_page">
    <h1 class="page_ttl is_blind">networkerror page</h1>
    <article class="error_sec">
      <div class="guide_ui error">
        <div class="img_area">
          <picture>
            <source
              media="(max-width: 600px)"
              type="image/png"
              :srcset="`${require('@/assets/img/img_mob_illust_network_l.png')} 4x, ${require('@/assets/img/img_mob_illust_network_m.png')} 3x, ${require('@/assets/img/img_mob_illust_network.png')}`"
            />
            <img
              :src="require('@/assets/img/img_illust_network.png')"
              alt="img_illust_network"
            />
          </picture>
        </div>
        <h2>Sorry,</h2>
        <p>
          The Internet connection is unstable. Try agiain later or reload the
          page.
        </p>
        <div class="btn_area">
          <button class="btn w_l h_l basic" @click="$router.go(-1)">
            Try again
          </button>

          <button class="btn w_l h_l strong" @click="$router.push('/')">
            Go to Home
          </button>
        </div>
      </div>
    </article>
  </main>
</template>

<script>
export default {
  name: 'NetworkError',
};
</script>

<style scoped>
.error_sec {
  display: flex;
  min-height: calc(var(--nf) * 1px);
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
