import { marketPlaceStoreActions } from './marketPlaceStoreActions';
export const marketPlaceStore = {
  namespaced: true,
  state: {
    itemInfos: [], // itemInfos
    sortType: 0, // sortType
    totalCount: 0, // totalCount
  },
  getters: {
    itemInfos: state => {
      return state.itemInfos;
    },
    sortType: state => {
      return state.sortType;
    },
    totalCount: state => {
      return state.totalCount;
    },
  },
  mutations: {
    itemInfos: (state, itemInfos) => {
      state.itemInfos = itemInfos;
    },
    sortType: (state, sortType) => {
      state.sortType = sortType;
    },
    totalCount: (state, totalCount) => {
      state.totalCount = totalCount;
    },
  },
  actions: marketPlaceStoreActions,
};
