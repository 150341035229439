<template>
  <div class="edition_slide_wrap type_img">
    <swiper
      ref="itemSwiper"
      class="edit_list"
      :class="addClass"
      :options="slideOpt"
    >
      <swiper-slide
        class="edit_node"
        v-for="item in itemData"
        :key="item.artworkId"
      >
        <!-- {{ item }} -->
        <div class="edit_item">
          <div class="item_head">
            <div class="thumb_area">
              <img
                :src="`${item.rsrcUrl}`"
                :width="598"
                :alt="'Capture of Artwork for the ' + item.title"
              />
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="control_area pagination_area type_dot"></div>
    <div class="control_area slide_nav_area">
      <button class="btn type_ic prev">
        <ArwPrev />
      </button>
      <button class="btn type_ic next">
        <ArwNext />
      </button>
    </div>
  </div>
</template>

<script>
import ArwPrev from '@/components/ic/arwPrev';
import ArwNext from '@/components/ic/arwNext';
export default {
  name: 'EditionImgSlide',
  components: {
    ArwNext,
    ArwPrev,
  },
  data() {
    return {
      likedList: [],
      likeStatus: '',
    };
  },
  props: {
    slideOpt: {
      type: Object,
      default: null,
    },
    itemData: {
      type: Array,
      default: null,
    },
    addClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped></style>
