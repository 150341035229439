<template>
  <main id="contents" class="footer_page notice_detail_page">
    <div class="set_inner">
      <div class="page_ttl_area">
        <h1 class="page_ttl only_pc">Notice Board</h1>
      </div>
      <section class="board_sec" v-if="noticeDetails">
        <div class="board_sec_head">
          <h2 class="ttl">{{ noticeDetails.title }}</h2>
          <span class="date">{{
            noticeDetails.writeDate | GmtToTz | UsaFormat
          }}</span>
        </div>
        <div class="board_sec_body">
          <pre class="detail">{{ noticeDetails.content }}</pre>
        </div>
        <div class="board_sec_foot">
          <button @click="$router.back()">
            <span class="btn strong w_s h_l">Back to List</span>
          </button>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: 'NoticeDetailView',
  data() {
    return {
      noticeDetails: [],
    };
  },
  created() {
    this.$root.$emit('setPage', {
      page: 'notice_detail_page',
      subHead: true,
      title: 'Notice Board',
    });
    if (!this.$route.params.item) this.$router.push('/notice');
    this.noticeDetails = this.$route.params.item;
  },
};
</script>

<style scoped>
.detail {
  white-space: pre-wrap;
}
.board_sec_body .detail {
  font-size: 2rem !important;
}
</style>
