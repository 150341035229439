<template>
  <IconBase
    icon-name="icRing"
    :icon-label="iconLabel"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    icon-color="none"
    stroke-color="none"
    :stroke-width="0"
    aria-hidden="true"
  >
    <linearGradient
      :id="uniqueId"
      gradientUnits="userSpaceOnUse"
      x1="-645.009"
      y1="389.4"
      x2="-644.224"
      y2="388.499"
      gradientTransform="matrix(32 0 0 -32 20646 12465)"
    >
      <stop offset="0" stop-color="#6BE5D1" />
      <stop offset="1" stop-color="#00A7FF" />
    </linearGradient>
    <circle cx="16" cy="16" r="16" :fill="'url(#' + uniqueId + ')'" />
    <circle cx="16" cy="16" :r="smallRing" fill="#1f1f1f" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcRing',
  components: { IconBase },
  props: {
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    smallRing: {
      type: [Number, String],
      default: 14.4,
    },
    iconLabel: {
      type: String,
      default: 'Artist profile Ring',
    },
  },
  data() {
    return {
      uniqueId: Math.random().toString(36).substring(7),
    };
  },
};
</script>

<style scoped></style>
