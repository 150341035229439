import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@mdi/font/css/materialdesignicons.css';

import '@/plugins/axios';
import utils from '@/plugins/utils';
import w3m from '@/plugins/w3m';
import dayJs from '@/plugins/dayJs';

import './service/apiservice';
import './utils/filters';

import VueMobileDetection from '../node_modules/vue-mobile-detection/dist/vue-mobile-detection.umd.min';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import LoadingModal from '@/components/common/LoadingModal';
import VueGtag from 'vue-gtag';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
Vue.prototype.$EventBus = new Vue();

Vue.config.productionTip = false;

Vue.use(VueMobileDetection);
Vue.use(utils);
Vue.use(w3m);
Vue.use(dayJs);
Vue.use(VueToast, {
  position: 'bottom',
});
Vue.component('LoadingModal', LoadingModal);
Vue.use(VueAwesomeSwiper);

// env dev, qa, prd 추적 ID 설정
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(
    VueGtag,
    {
      config: {
        id: `${process.env.VUE_APP_GOOGLE_ANALYTICS}`,
        params: {
          send_page_view: false,
        },
      },
    },
    router,
  );
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
