<template>
  <main id="contents" class="drops_page detail_page">
    <h1 class="page_ttl is_blind">Drops Detail</h1>
    <section class="overview_sec" v-show="!previewType">
      <div
        class="bg_el"
        :style="`background-image: url(${artworkBackground})`"
      ></div>
      <div class="set_inner">
        <header class="sec_head">
          <div class="thumb_area">
            <img
              v-if="artworkInfo.mimeType === 'image/jpeg'"
              :srcset="`${source} 2x, ${source}`"
              :src="source"
              width="890"
              height="500"
              :alt="artworkAlt ? artworkAlt : ''"
            />
            <video
              v-else
              loop
              autoplay
              muted
              playsinline
              style="width: 'auto'"
              :src="source"
            >
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
          <button
            class="btn type_ic btn_like"
            type="button"
            :class="{
              is_active: likedList.indexOf(artworkId) !== -1,
            }"
          >
            <!-- icon-color="#fff"  FF521D #ff521d-->
            <IcLike
              v-if="likedList.indexOf(artworkId)"
              :status="likedList.indexOf(artworkId)"
              icon-color="#fff"
              ref="icLike"
              :likedList.sync="likedList"
            />
            <IcLike
              v-else
              icon-color="#ff521d"
              ref="icLike"
              :likedList.sync="likedList"
            />
          </button>
        </header>
        <div class="sec_body">
          <div class="ttl_wrap">
            <div class="ttl_area">
              <h3 class="ttl">{{ artworkInfo.title }}</h3>
            </div>
            <div class="profile_info is_art">
              <span
                v-if="artistAvatarInfo && artistAvatarInfo.length"
                class="profile"
                :style="`background-image: url(${artistAvatarInfo[0].resUrl})`"
              ></span>
              <IcProfile v-else class="profile" />
              <IcRing class="ic_ring" />
              <span class="name">{{ artworkInfo.artistName }}</span>
            </div>
            <p class="edit">{{ artworkInfo.totalEdition }} Editions</p>
          </div>
          <div class="buy_wrap float_el">
            <CountDown
              v-if="artworkInfo.dropsDivision == 'live'"
              :date-time="artworkInfo.dropsEndDate"
            />
            <div class="buy_group">
              <div class="label_list_group">
                <dl class="label_list">
                  <dt>Price</dt>
                  <dd class="">
                    <p class="price">
                      <b>${{ artworkInfo.price | setNum }}</b>
                    </p>
                  </dd>
                </dl>
                <dl class="label_list">
                  <dt>Estimated <br class="dv_mob" />Tax</dt>
                  <dd class="">
                    <p class="price">
                      <template>
                        <!-- 예시 -->
                        <b>${{ (artworkInfo.price * 0.008) | setNum }}</b>
                      </template>
                    </p>
                  </dd>
                </dl>
              </div>
              <button v-if="!$isMobile()" class="btn w_f strong h_l">
                {{ getDropStatus }}
              </button>
            </div>
          </div>
          <div class="desc_wrap">
            <div class="desc_group more_ui" :class="{ is_more: showMoreDesc }">
              <h4 class="ttl type_underL">Description</h4>
              <p class="desc">{{ artworkInfo.artworkDesc }}</p>
              <button
                class="btn type_txt has_arw dv_mob toggle_desc"
                @click="showMoreDesc = !showMoreDesc"
              >
                {{ showMoreDesc ? 'Close' : 'More' }}
              </button>
            </div>
            <div class="tag_group">
              <h4 class="ttl type_underL">Tag</h4>
              <ul class="tag_list clearFix">
                <li
                  v-for="(tag, i) in artworkInfo.keywords"
                  :key="'tag_' + i"
                  class="tag_node"
                >
                  <button class="tag_item">
                    <span>{{ tag }}</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="set_inner">
      <section class="detail_sec detail_info_sec">
        <header ref="tabs" class="sec_head" v-if="!previewType">
          <div class="tab_wrap">
            <!-- Dev: tab이 하나일 경우 type_stack 클래스 바인드 -->
            <div class="tab_list" role="tablist">
              <div
                v-for="tab in infoTabData"
                :key="tab.id"
                class="tab_node"
                role="presentation"
              >
                <button
                  :id="'tab_' + tab.id"
                  class="tab_item"
                  role="tab"
                  :class="{ is_active: currentTab === tab.id }"
                  @click="setTab(tab.id)"
                  :aria-controls="tab.id + '_panel'"
                  :aria-selected="tab.id === currentTab ? true : false"
                >
                  <IcTime v-if="tab.icon === 'time'" stroke-color="#fff" />
                  <IcItems v-if="tab.icon === 'items'" stroke-color="#fff" />
                  <IcEye v-if="tab.icon === 'eye'" stroke-color="#fff" />
                  <IcVideo v-if="tab.icon === 'video'" stroke-color="#fff" />
                  <span>{{ tab.name }}</span>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div
          :id="currentTab + '_panel'"
          class="sec_body tab_panel"
          role="tabpanel"
          :aria-labelledby="'tab_' + currentTab"
        >
          <template v-if="currentTab === 'status'">
            <div class="detail_info_wrap">
              <div class="detail_info_inner">
                <header class="detail_info_head taR">
                  <p class="info_txt refresh">
                    <IcRefreshS stroke-color="var(--txt-gray-2)" />
                    <span>Refresh in 2 minutes</span>
                  </p>
                </header>
                <div class="detail_info_body">
                  <ul class="status_list">
                    <li
                      v-for="(status, i) in statusInfos"
                      :key="'status_' + i"
                      class="status_node"
                    >
                      <a href="#" class="status_item">
                        <div
                          class="profile_info"
                          :class="{ is_art: status.artistYn === 'artist' }"
                        >
                          <span
                            v-if="status.buyerAvatarInfo.length > 0"
                            class="profile"
                            :style="`background-image: url(${status.buyerAvatarInfo[0].resUrl})`"
                          ></span>
                          <IcProfile
                            v-else
                            :width="50"
                            :height="50"
                            stroke-width=".7"
                            class="profile"
                          />
                          <IcRing
                            v-if="status.artistYn === 'artist'"
                            :width="50"
                            :height="50"
                            :small-ring="14.8"
                            class="ic_ring"
                          />
                        </div>
                        <div class="txt_info">
                          <span class="name">{{ status.buyerName }}</span>
                          <p class="date">{{ status.buyDate }}</p>
                        </div>
                        <div class="price_info">
                          <b class="price">${{ status.buyPrice | setNum }}</b>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="currentTab === 'editions'">
            <div class="list_wrap detail_info_wrap">
              <header class="list_head detail_info_head">
                <p class="count">
                  <span class="c_point">{{ artworkInfo.totalEdition }}</span>
                  Editions
                </p>
              </header>
              <div class="list_body detail_info_body">
                <ul class="grid grid_10 thumb_list ed_list">
                  <li
                    v-for="(item, i) in editionItems"
                    :key="'edit_' + i"
                    class="col col_3 thumb_node"
                  >
                    <div class="thumb_item">
                      <div class="thumb_area">
                        <img
                          v-if="item.mimeType === 'video/mp4'"
                          :srcset="`${item.videoThumbInfos[0].resUrl} 2x, ${item.videoThumbInfos[1].resUrl}`"
                          :src="`${item.videoThumbInfos[1].resUrl}`"
                          alt="thumbnail"
                        />
                        <img
                          v-else
                          :srcset="`${item.imageInfos[0].resUrl} 2x, ${item.imageInfos[1].resUrl}`"
                          :src="`${item.imageInfos[1].resUrl}`"
                          alt="thumbnail"
                        />
                      </div>
                      <!-- PUB : Origin code -->
                      <!-- <div
                        v-if="item.mimeType === 'video/mp4'"
                        class="ic_area type_video"
                      > -->
                      <!-- icon-color="#fff"  strokeColor  FF521D-->
                      <!-- <IcVideo />
                      </div> -->
                      <!-- PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가 -->
                      <div class="ic_area type_blockchains">
                        <!-- <IcHedera /> -->
                        <IcEth />
                      </div>
                      <p class="edit">
                        <span>{{
                          `${i + 1} of ${artworkInfo.totalEdition}`
                        }}</span>
                        Editions
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <template v-else-if="currentTab === 'docent'">
            <div v-if="docentThumbItems.length">
              <DocentThumbSlide
                :item-data="docentThumbItems"
                @setCurrent="setDocentCurrent"
              />
              <div class="viewer_wrap type_img" v-if="currentDocent.item">
                <div class="img_area">
                  <img
                    v-if="currentDocent.item.thumbInfos[0].resUrl !== undefined"
                    :src="currentDocent.item.thumbInfos[0].resUrl"
                    :width="1220"
                    alt="currentDocent"
                  />
                  <img
                    v-else
                    :src="docentInfo.imageInfos[0].resUrl"
                    :width="1220"
                    alt="currentDocent"
                  />
                </div>
                <div class="desc_area">
                  <p>{{ currentDocent.item.content }}</p>
                </div>
              </div>
            </div>

            <div v-else class="guide_ui no_data">
              <div class="img_area">
                <picture>
                  <source
                    media="(max-width: 600px)"
                    type="image/png"
                    :srcset="`${require('@/assets/img/img_mob_illust_nosearch_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nosearch_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nosearch.png')}`"
                    alt="illust_nosearch"
                  />
                  <img
                    :src="require('@/assets/img/img_illust_nosearch.png')"
                    alt="No Result"
                  />
                </picture>
              </div>
              <p class="desc">No Docent</p>
            </div>
          </template>
          <template v-else-if="currentTab === 'film'">
            <div v-if="makingInfos.resUrl">
              <div class="viewer_wrap type_video">
                <div class="video_area" style="text-align: center">
                  <video ref="video" @ended="isPlay = false" id="makingFilm">
                    <source :src="makingInfos.resUrl" type="video/mp4" />
                  </video>
                </div>
                <div class="controls_area type_start">
                  <button
                    class="btn type_ic player"
                    @click="makingfilmPlayPause"
                  >
                    <IcPlayL v-if="!isPlay" class="ic_play" />
                    <IcPauseL v-else class="ic_pause" />
                  </button>
                </div>
              </div>
            </div>
            <div v-else class="guide_ui no_data">
              <div class="img_area">
                <picture>
                  <source
                    media="(max-width: 600px)"
                    type="image/png"
                    :srcset="`${require('@/assets/img/img_mob_illust_nosearch_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nosearch_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nosearch.png')}`"
                  />
                  <img
                    :src="require('@/assets/img/img_illust_nosearch.png')"
                    alt="No Result"
                  />
                </picture>
              </div>
              <p class="desc">No Making Film</p>
            </div>
          </template>
          <template v-else>
            <div class="guide_ui no_data">
              <div class="img_area">
                <picture>
                  <source
                    media="(max-width: 600px)"
                    type="image/png"
                    :srcset="`${require('@/assets/img/img_mob_illust_nosearch_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nosearch_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nosearch.png')}`"
                  />
                  <img
                    :src="require('@/assets/img/img_illust_nosearch.png')"
                    alt="No Result"
                  />
                </picture>
              </div>
              <p class="desc">The data is not available.</p>
            </div>
          </template>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import IcLike from '@/components/ic/IcLike';
import IcTime from '@/components/ic/IcTime';
import IcItems from '@/components/ic/IcItems';
import IcEye from '@/components/ic/IcEyes';
import IcVideo from '@/components/ic/IcVideo';
import IcRefreshS from '@/components/ic/IcRefreshS';
import IcProfile from '@/components/ic/IcProfile';
import IcPlayL from '@/components/ic/IcPlayL';
import IcPauseL from '@/components/ic/IcPauseL';
import IcRing from '@/components/ic/IcRing';
import IcEth from '@/components/ic/IcEth';
// import IcHedera from '@/components/ic/IcHedera';
import DocentThumbSlide from '@/components/drops/DocentThumbSlide';
import CountDown from '@/components/drops/CountDown';
export default {
  name: 'dropsDetailPreview',
  components: {
    CountDown,
    DocentThumbSlide,
    IcRing,
    IcPlayL,
    IcPauseL,
    IcProfile,
    IcRefreshS,
    IcVideo,
    IcEye,
    IcItems,
    IcTime,
    IcLike,
    IcEth,
    // IcHedera,
  },
  data() {
    return {
      source: '',
      dropId: '',
      artworkId: '',
      docentThumbItems: [],
      likedList: [],
      tabQueue: [
        {
          id: 'editions',
          name: 'Editions',
          icon: 'items',
          class: 'type_edit',
        },
        {
          id: 'docent',
          name: 'Docent Art Show',
          icon: 'eye',
          class: 'type_docent',
        },
        {
          id: 'film',
          name: 'Making Film',
          icon: 'video',
          class: 'type_film',
        },
      ],
      statusInfos: [],
      editionItems: [],
      artworkInfo: {},
      makingInfos: {},
      artworkBackground: '',
      artworkAlt: '',
      artworkThumb: '',
      artworkThumb2x: '',
      artistAvatarInfo: [],
      docentInfo: {},
      imageInfos: [],
      infoTabData: [
        {
          id: 'status',
          name: 'Current status',
          icon: 'time',
          class: 'type_status',
        },
      ],
      currentTab: 'status',
      currentDocent: {
        idx: 0,
        item: null,
      },
      isPlay: false,
      tabPos: 0,
      showMoreDesc: false,
      getDropStatus: '',
      previewType: '',
      validAlert: {
        type: 'warn',
        desc: 'The data is not available.',
      },
    };
  },
  created() {
    this.$root.$emit('setPage', {
      page: 'detail_page',
    });
  },
  async mounted() {
    history.pushState({}, null, '/');
    this.setDisplay();
    if (this.$route.query.previewType) {
      this.previewType = this.$route.query.previewType;
      this.infoTabData = [];

      if (this.previewType === 'film' || this.previewType === 'docent') {
        this.currentTab = this.previewType;
      } else {
        this.currentTab = '';
        this.$root.$emit('openAlert', this.validAlert);
      }
    }

    if (this.$route.query.dropId) {
      this.dropId = this.$route.query.dropId;
      this.artworkId = this.$route.query.artworkId;
      this.query = {
        dropId: this.dropId,
        artworkId: this.artworkId,
      };
    } else {
      this.$root.$emit('openAlert', this.validAlert);
      return;
    }

    await this.getDropArtworkPreview();

    await this.getStatusInfos();

    await this.getDropEditionInfos();

    await this.tabManager();

    this.getStatusRefresh();

    window.addEventListener('scroll', this.setFixedTab);
  },
  methods: {
    makingfilmPlayPause() {
      if (this.isPlay === false) {
        this.isPlay = true;
        this.$refs.video.play();
      } else {
        this.isPlay = false;
        this.$refs.video.pause();
      }
    },
    setDisplay() {
      document.getElementById('header').style.display = 'none';
      document.getElementById('footer').style.display = 'none';
    },
    setTab(id) {
      this.currentTab = id;
      const topGap = this.$isMobile() ? 60 : 0;
      window.scrollTo(0, this.tabPos - topGap);
    },
    setFixedTab() {
      const st = window.pageYOffset;
      const tabWrap = this.$refs.tabs;
      const topGap = window.innerWidth <= 1024 ? 60 : 0;
      this.tabPos = tabWrap.getBoundingClientRect().top + st;
      if (this.tabPos - topGap < st) tabWrap.classList.add('is_fix');
      else tabWrap.classList.remove('is_fix');
    },
    setDocentCurrent(item, idx) {
      this.currentDocent = { idx, item };
    },
    tabManager() {
      this.editionItems.length > 0
        ? this.infoTabData.push(this.tabQueue[0])
        : null;

      this.docentInfo.items.length > 0
        ? this.infoTabData.push(this.tabQueue[1])
        : null;

      this.makingInfos.resUrl != undefined
        ? this.infoTabData.push(this.tabQueue[2])
        : null;
    },

    async getDropArtworkPreview() {
      await this.api.getDropArtworkPreview(this.query).then(res => {
        if (res.code === 0) {
          this.artworkInfo = res.artworkInfo;
          this.artworkId = this.artworkInfo.artworkId;
          this.artistAvatarInfo = this.artworkInfo.artistAvatarInfo;
          this.docentInfo = this.artworkInfo.docentInfo;
          this.makingInfos = this.artworkInfo.makingInfos;
          this.imageInfos = this.artworkInfo.imageInfos;

          if (this.artworkInfo.mimeType === 'video/mp4') {
            this.artworkBackground = this.artworkInfo.videoThumbInfos[0].resUrl;
            this.source = this.artworkInfo.videoInfos[0].resUrl;
          } else {
            this.artworkBackground = this.source =
              this.artworkInfo.imageInfos[0].resUrl;
          }
          this.docentThumbItems = this.docentInfo.items;
          this.currentDocent.item = this.docentThumbItems[0];
          this.getDropStatus = 'Purchase';
        } else {
          this.$router.push({
            name: 'network',
          });
        }
      });
    },
    async getDropEditionInfos() {
      //edition info
      await this.api.getDropEditionItems(this.query).then(res => {
        if (res.code === 0) {
          this.editionItems = res.editionItems;
        }
      });
    },
    async getStatusInfos() {
      await this.api.getDropCurrentStatus(this.query).then(res => {
        if (res.code === 0) {
          this.statusInfos = res.statusInfos;
        }
      });
    },
    getStatusRefresh() {
      this.polling = setInterval(() => {
        this.getStatusInfos();
      }, 120000);
    },
  },
};
</script>
<style scoped>
@import '../../assets/css/detail.css';

.thumb_item {
  position: relative;
}
.thumb_item .ic_area {
  position: absolute;
  z-index: 10;
}

.thumb_item .ic_area {
  width: 40px;
  height: 40px;
  padding: 5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.thumb_item .ic_area.type_video {
  right: 7px;
  top: 7px;
}
</style>
