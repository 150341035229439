<template>
  <IconBase
    icon-name="icMore"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    :icon-color="iconColor"
  >
    <g data-name="그룹 3598" transform="translate(-315 -190)">
      <circle
        cx="2"
        cy="2"
        r="2"
        data-name="타원 402"
        transform="translate(320 203)"
      />
      <circle
        cx="2"
        cy="2"
        r="2"
        data-name="타원 403"
        transform="translate(328 203)"
      />
      <circle
        cx="2"
        cy="2"
        r="2"
        data-name="타원 404"
        transform="translate(336 203)"
      />
    </g>
  </IconBase>
</template>

<script>
import IconBase from '@/components/ic/IconBase';
export default {
  name: 'IcMore',
  components: { IconBase },
  props: {
    iconColor: {
      type: String,
      default: '#6c6c6c',
    },
    iconLabel: {
      type: String,
      default: 'Fold',
    },
  },
};
</script>

<style scoped></style>
