<template>
  <IconBase
    icon-name="arwPrev"
    :icon-label="iconLabel"
    :width="40"
    :height="40"
    viewBox="0 0 40 40"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="2"
  >
    <path data-name="Path 2597" d="m25.535 7.999-12 11.429 12 12.572" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'ArwPrev',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Prev',
    },
  },
};
</script>

<style scoped></style>
