<template>
  <div class="modal_wrap" :class="{ is_show: show }" @click="dimEvt">
    <div class="modal">
      <div class="modal_head">
        <h3 class="modal_ttl">Share This Gorgeous Artwork!</h3>
      </div>
      <div class="modal_body">
        <div class="img_wrap">
          <img
            :srcset="`${editionContentsUrl} 2x, ${editionContentsUrl}`"
            :src="editionContentsUrl"
            :width="560"
            alt="editionContentsUrl"
          />
          <!-- <img
            v-if="item"
            :srcset="`${item.imageInfos[2].resUrl} 400w,
            ${item.imageInfos[1].resUrl} 700w,
            ${item.imageInfos[0].resUrl} 1000w,`"
            :alt="item.title"
            :width="560"
          /> -->
          <div class="btn_area">
            <button class="btn strong w_xl h_m" @click="download">
              <IcDownload />
              Download
            </button>
          </div>
        </div>
        <div class="input_area type_hoz textarea">
          <label class="is_blind" for="editionDescription">Description</label>
          <div class="input_box">
            <textarea
              v-model="editionDescription"
              id="editionDescription"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="modal_foot">
        <button class="btn basic w_xl h_m" @click="copy">
          <IcCopy stroke-color="#000000" fill-color="#000000" />
          Copy Description
        </button>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
    <LoadingModal :isLoading="isLoading" message="Wait for Downloads..." />
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';
import IcDownload from '@/components/ic/IcDownload';
import IcCopy from '@/components/ic/IcCopy';
export default {
  name: 'ContentsCopy',
  components: { IcCopy, IcDownload, IcCloseM },
  data() {
    return {
      show: false,
      item: null,
      isLoading: false,
      description: '',
      downloadFailAlert: {
        type: 'warn',
        desc: 'Sorry, Something is wrong.',
      },
    };
  },
  props: {
    itemData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    editionContentsUrl() {
      let artworkTypes = ['image/jpeg', 'video/mp4'];
      let artworkType = this.item?.mimeType;
      if (artworkTypes.includes(artworkType)) {
        return artworkType == 'image/jpeg'
          ? this.item.imageInfos?.[1].resUrl
          : this.item.videoThumbInfos?.[1].resUrl;
      } else {
        return null;
      }
    },
    editionDescription() {
      return this.description;
    },
  },
  methods: {
    open(item) {
      this.item = item;
      this.show = true;
      this.$root.$emit('setScrollFixed', true);
      this.description = `${
        this.itemData.artistName ? this.itemData.artistName : ''
      }

${this.itemData.hashtags ? this.itemData.hashtags : ''}

${this.itemData.title ? this.itemData.title : ''}

${this.itemData.description ? this.itemData.description : ''}`;
    },

    close() {
      this.show = false;
      this.$root.$emit('setScrollFixed', false);
    },
    dimEvt(e) {
      const t = e.target;
      if (t.closest('.modal')) return;
      // this.close();
    },
    copy() {
      let description = this.description;
      const textarea = document.createElement('textarea');

      textarea.textContent = description;
      document.body.append(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();
      this.$toast.open('Saved to clipboard');
    },
    download() {
      const data = {
        artworkId: this.item.artworkId,
        editionId: this.item.editionId,
      };
      const type = this.item.mimeType;
      const title = this.item.title;
      this.isLoading = true;
      this.axios({
        method: 'post',
        url: '/api/v1/download/file',
        responseType: 'blob',
        data: data,
      }).then(res => {
        this.isLoading = false;
        if (res.status === 200) {
          const blob = new Blob([res.data], { type: type });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${title}.${type === 'image/jpeg' ? 'jpeg' : 'mp4'}`;
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } else {
          this.$root.$emit('openAlert', this.downloadFailAlert);
        }
      });
    },
  },
};
</script>

<style scoped>
.img_wrap .btn_area {
  margin-top: 2.4rem;
}

.img_wrap .btn_area .btn i {
  margin-right: 1rem;
  vertical-align: -6px;
}

.input_area.textarea {
  margin-top: 4rem;
}

.input_area.textarea textarea {
  min-height: 14rem;
}

.modal .modal_body + .modal_foot {
  margin-top: 2.4rem;
}

.modal_foot .btn i {
  margin-right: 1rem;
  vertical-align: -6px;
}
@media screen and (max-width: 767px) {
  .input_area.textarea .input_box {
    width: 100%;
  }
  .modal .btn.w_xl {
    min-width: 18rem;
  }

  .img_wrap .btn_area,
  .modal .modal_body + .modal_foot {
    margin-top: 2rem;
  }
}
</style>
