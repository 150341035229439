<template>
  <div class="edit_item type_card">
    <div class="item_head">
      <div
        @click="goArtworkDetail"
        class="thumb_area"
        aria-hidden="true"
        tabindex="-1"
      >
        <img
          v-if="source.small"
          :src="source.small"
          :width="480"
          :alt="itemData.artworkId ? itemData.artworkId : ''"
        />
        <img
          v-else
          :src="require('@/assets/img/img_illust_nodata.png')"
          alt="nodata"
        />
      </div>
      <!-- PUB : 비디오 아이콘 삭제/ 블록체인 아이콘 추가 -->
      <!-- <div v-if="itemData.mimeType === 'video/mp4'" class="ic_area type_video"> -->
      <div class="ic_area type_blockchains">
        <IcHedera v-if="itemData.bcNwTp == '10'" />
        <IcEth v-else />
      </div>
      <button
        class="btn type_ic btn_like"
        type="button"
        :class="{
          is_active: likeStatus,
        }"
        @click="likeThis(itemData)"
      >
        <IcLike
          v-if="!likeStatus"
          :status="!likeStatus"
          icon-color="#fff"
          ref="icLike"
        />
        <IcLike v-else icon-color="#ff521d" ref="icLike" />
      </button>
    </div>
    <div class="item_body">
      <div
        @click="goArtworkDetail"
        class="link"
        :aria-describedby="itemData.artworkId"
      ></div>
      <div class="basic_info_group">
        <button
          class="profile_info is_art"
          @click="goArtistProfile(itemData.mbrNo)"
        >
          <span
            v-if="itemData.artistAvatarInfo && itemData.artistAvatarInfo.length"
            class="profile"
            :style="`background-image: url(${itemData.artistAvatarInfo[0].resUrl})`"
          ></span>
          <IcProfile
            v-else
            :width="32"
            :height="32"
            :stroke-width="1.1"
            class="profile"
          />
          <IcRing class="ic_ring" />
          <span class="name">{{ itemData.artistName }}</span>
        </button>
        <div class="ttl_area">
          <h3 :id="itemData.id" class="ttl">{{ itemData.title }}</h3>
          <p class="edit">
            {{
              itemData.totalEdition > 1
                ? `${itemData.totalEdition} Editions`
                : `${itemData.totalEdition} Edition`
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
import IcLike from '@/components/ic/IcLike';
// import IcVideo from '@/components/ic/IcVideo';
import IcRing from '@/components/ic/IcRing';
import IcEth from '@/components/ic/IcEth';
import IcHedera from '@/components/ic/IcHedera';
// PUB : 비디오 아이콘 컴포넌트 삭제, 이더리움 및 헤데라 아이콘 컴포넌트 추가
export default {
  name: 'WorkItem',
  components: {
    IcRing,
    // IcVideo,
    IcLike,
    IcProfile,
    IcEth,
    IcHedera,
    // PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가
  },
  props: {
    itemData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      source: {},
      likeStatus: false,
    };
  },
  mounted() {
    this.source = this.setThumbnailImg(this.itemData);
    this.likeStatus = this.itemData.favorites === 'Y' ? true : false;
  },
  methods: {
    goArtistProfile(mbrNo) {
      if (!mbrNo) return false;
      this.$router.push({
        name: 'ProfileArtist',
        params: {
          mbrNo,
        },
      });
    },
    goArtworkDetail() {
      let resultEdition =
        this.itemData.editionId > 0 && this.itemData.totalEdition > 1
          ? 0
          : this.itemData.editionId;

      this.$router.push({
        name: 'marketplaceDetail',
        query: {
          artworkId: this.itemData.artworkId,
          editionId: resultEdition,
        },
      });
    },
    likeThis(item) {
      if (!this.$refs.icLike.loginCheck()) {
        return;
      } else {
        let favoritesYn = this.likeStatus === true ? 'N' : 'Y';

        if (item.editionId === 0) {
          let params = {
            artworkId: item.artworkId,
            favoritesYn,
          };

          this.$refs.icLike.likeArtwork(params);
        } else {
          let params = {
            artworkId: item.artworkId,
            editionId: item.editionId,
            favoritesYn,
          };
          this.$refs.icLike.likeEdition(params);
        }

        this.likeStatus = !this.likeStatus;
      }
    },
  },
};
</script>

<style scoped>
.item_head,
.item_body {
  cursor: pointer;
}
</style>
