<template>
  <IconBase
    icon-name="IcDownload"
    :icon-label="iconLabel"
    :width="24"
    :height="24"
    viewBox="0 0 24 24"
    :stroke-color="strokeColor"
    :stroke-width="strokeWidth"
  >
    <path fill="#fff" stroke="none" d="M12 4h1v13h-1z" />
    <path fill="#fff" stroke="none" d="M20.5 20v1h-16v-1z" />
    <path fill="none" d="m7.504 12 5 5 5-5" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcDownload',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    strokeWidth: {
      type: [Number, String],
      default: 1,
    },
    iconLabel: {
      type: String,
      default: 'Download',
    },
  },
};
</script>

<style scoped></style>
