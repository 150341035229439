import { createWeb3Modal, defaultConfig } from '@web3modal/ethers';
import { BrowserProvider } from 'ethers';
import marketOrderType from '@/assets/json/marketOrderType.json';
import EIP712Domain from '@/assets/json/EIP712Domain.json';
import store from '@/store/index';

const projectId = process.env.VUE_APP_WEB3_MODAL_PROJECT_ID;
const defaultEthereumChain = process.env.VUE_APP_DEFAULT_ETHEREUM_CHAIN;

const chains = {
  mainnet: {
    chainId: 0x1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://ethereum-rpc.publicnode.com',
  },
  sepolia: {
    chainId: 0xaa36a7,
    name: 'sepolia',
    currency: 'ETH',
    explorerUrl: 'https://sepolia.etherscan.io',
    rpcUrl:
      'https://rpc.walletconnect.com/v1/?chainId=eip155:11155111&projectId=' +
      projectId,
  },
  // hedera: {
  //   chainId: 296,
  //   name: 'Hedera testnet',
  //   currency: 'HBAR',
  //   explorerUrl: 'https://https://app.dragonglass.me/hedera/home',
  //   rpcUrl:
  //     'https://rpc.walletconnect.com/v1/?chainId=eip155:296&projectId=' +
  //     projectId,
  // },
};

const metadata = {
  name:
    process.env.VUE_APP_PROFILE == 'PRD'
      ? "LG Art Lab | LG's fine art NFT marketplace"
      : "LG Art Lab | LG's fine art NFT marketplace (Testnet)",
  description:
    'Join us on a journey into the vast universe of LG Art Lab, where the boundaries of art and technology are pushed to their limits.',
  url: window.location.origin,
  icons: [
    'https://artnft.lgartlab.com/email/b50ba8b8a30c41f89eeb01dba56b0554.jpg',
  ],
};

const ethersConfig = defaultConfig({
  metadata,
});

const typedDataList = {
  marketOrder: {
    types: marketOrderType,
    primaryType: 'OrderComponents',
  },
};

function setWeb3Modal() {
  try {
    const web3Modal = createWeb3Modal({
      ethersConfig,
      chains: [chains.mainnet, chains.sepolia],
      projectId,
      defaultChain: chains[defaultEthereumChain].chainId,
    });
    web3Modal.subscribeProvider(statusChanged);

    return web3Modal;
  } catch (e) {
    console.log('web3Modal create error ', e);
  }
}

async function statusChanged({
  provider,
  providerType,
  address,
  error,
  chainId,
  isConnected,
}) {
  let _status = {
    provider,
    providerType,
    address,
    error,
    chainId,
    isConnected,
  };
  await store.commit('web3Store/setWeb3ModalStatus', _status);
  await store.dispatch('web3Store/connectETH');
}
export class Web3Functions {
  getProviderId(provider) {
    let candidate = Object.getOwnPropertyNames(provider)
      .filter(_propertyName => _propertyName.startsWith('is'))
      .map(_propertyName => _propertyName.substring(2));

    if (!candidate || candidate.length == 0) return 'Unknown';

    if (candidate.length == 1) return candidate[0];
    else {
      return candidate
        .filter(_propertyName => _propertyName != 'MetaMask')
        .join(', ');
    }
  }

  async _getProvider() {
    this.web3ModalStatus = await store.getters['web3Store/getWeb3ModalStatus'];
    if (!this.web3ModalStatus.isConnected || !this.web3ModalStatus.address)
      throw new Error({
        name: 'Wallet not connected',
        message: 'web3Modal not connected',
      });

    return {
      provider: this.web3ModalStatus.provider,
      providerType: this.web3ModalStatus.providerType,
    };
  }

  async checkChainNetwork(provider) {
    try {
      console.log('currentChainId', provider.chainId);
      if (provider.chainId != chains[defaultEthereumChain].chainId) {
        await this._swtichEthereumChainNetwork(provider);
        console.log('ChangedCahinId', provider.chainId);
      }

      return true;
    } catch (error) {
      console.log('check and switch network error', error);
      throw error;
    }
  }

  async sendTransaction(txObject) {
    try {
      await store.commit('web3Store/setLoading', {
        objectName: 'web3Modal',
        stateName: 'isSendTransactionOnLoading',
        currentState: true,
      });

      let _p = await this._getProvider();
      let provider = _p.provider;
      let providerType = _p.providerType;

      if (providerType == 'walletConnect') {
        return await this._walletConnectSendTransaction(provider, txObject);
      } else if (providerType == 'eip6963') {
        await this.checkChainNetwork(provider);
        let _transaction = await this._eip6963SendTrasactions(
          provider,
          txObject,
        );
        return _transaction.hash;
      } else {
        throw new Error({
          name: 'provider type error',
          message: providerType + ' is not support provider type',
        });
      }
    } finally {
      await store.commit('web3Store/setLoading', {
        objectName: 'web3Modal',
        stateName: 'isSendTransactionOnLoading',
        currentState: false,
      });
    }
  }
  // async sendTransaction(txObject) {
  //   try {
  //     await store.commit('web3Store/setLoading', {
  //       objectName: 'web3Modal',
  //       stateName: 'isSendTransactionOnLoading',
  //       currentState: true,
  //     });
  //     this.web3ModalStatus = store.getters['web3Store/getWeb3ModalStatus'];
  //     if (!this.web3ModalStatus.isConnected)
  //       return Error('web3Modal not connected');

  //     if (this.web3ModalStatus.providerType == 'walletConnect') {
  //       return this._walletConnectSendTransaction(txObject);
  //     } else if (this.web3ModalStatus.providerType == 'eip6963') {
  //       return this._eip6963SendTrasactions(txObject);
  //     } else {
  //       return Error('provider type error');
  //     }
  //   } catch (e) {
  //     console.log('sendTransaction Error', e);
  //     return e;
  //   } finally {
  //     await store.commit('web3Store/setLoading', {
  //       objectName: 'web3Modal',
  //       stateName: 'isSendTransactionOnLoading',
  //       currentState: false,
  //     });
  //   }
  // }

  async _walletConnectSendTransaction(provider, txObject) {
    if (!txObject.from) {
      if (provider.accounts > 0) {
        txObject.from = provider.accounts[0];
      }
    }

    return await provider?.signer?.client?.request({
      topic: provider?.session?.topic,
      chainId: 'eip155:' + chains[defaultEthereumChain].chainId,
      request: {
        method: 'eth_sendTransaction',
        params: [txObject],
      },
    });
  }

  async _eip6963SendTrasactions(provider, txObject) {
    let providerId = this.getProviderId(provider);
    console.log('current wallet', providerId);

    let browserProvider = new BrowserProvider(provider);
    if (!browserProvider)
      throw new Error({
        name: 'provider not exist',
        message: providerId + 'wallet extension - no provider error!!',
      });

    let browserSigner = await browserProvider.getSigner();
    if (!browserSigner)
      throw new Error({
        name: 'signer not exist',
        message: providerId + 'wallet extension - no signer error!!',
      });

    return await browserSigner.sendTransaction(txObject);
  }

  async signTypedData(typedData, methodType = 'marketOrder') {
    let typeSpecification = typedDataList[methodType];
    if (!typeSpecification)
      throw new Error({
        name: 'type specification error',
        message: 'dosen`t exist Specification',
      });

    try {
      await store.commit('web3Store/setLoading', {
        objectName: 'web3Modal',
        stateName: 'isSendTransactionOnLoading',
        currentState: true,
      });

      let _p = await this._getProvider();
      let provider = _p.provider;
      let providerType = _p.providerType;

      if (providerType == 'walletConnect') {
        return await this._walletConnectSignTypedData(
          provider,
          typedData,
          typeSpecification,
        );
      } else if (providerType == 'eip6963') {
        await this.checkChainNetwork(provider);

        return await this._eip6963SignTypedData(
          provider,
          typedData,
          typeSpecification,
        );
      } else {
        throw new Error({
          name: 'provider type error',
          message: providerType + ' is not support provider type',
        });
      }
    } catch (e) {
      console.log('SignTypedData Error', e);
      throw e;
    } finally {
      await store.commit('web3Store/setLoading', {
        objectName: 'web3Modal',
        stateName: 'isSendTransactionOnLoading',
        currentState: false,
      });
    }
  }

  async _eip6963SignTypedData(provider, typedData, typeSpecification) {
    let providerId = this.getProviderId(provider);
    console.log('current wallet: ', providerId);

    let browserProvider = new BrowserProvider(provider);
    if (!browserProvider)
      throw new Error({
        name: 'provider not exist',
        message: providerId + 'wallet extension - no provider error!!',
      });

    let browserSigner = await browserProvider.getSigner();
    if (!browserSigner)
      throw new Error({
        name: 'signer not exist',
        message: providerId + 'wallet extension - no signer error!!',
      });

    return await browserSigner.signTypedData(
      typedData?.domain,
      typeSpecification.types,
      typedData?.message,
    );
  }

  async _walletConnectSignTypedData(provider, typedData, typeSpecification) {
    return await provider?.signer?.client?.request({
      topic: provider?.session?.topic,
      chainId: 'eip155:' + chains[defaultEthereumChain].chainId,
      request: {
        method: 'eth_signTypedData_v4',
        params: [
          this.web3ModalStatus.address,
          JSON.stringify({
            types: {
              ...EIP712Domain,
              ...typeSpecification.types,
            },
            primaryType: typeSpecification.primaryType,
            domain: typedData?.domain,
            message: typedData?.message,
          }),
        ],
      },
    });
  }

  async _swtichEthereumChainNetwork(provider) {
    try {
      let switchResult = await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId:
              '0x' + Number(chains[defaultEthereumChain].chainId).toString(16),
          },
        ],
      });

      return switchResult;
    } catch (error) {
      if (error.code === 4902) {
        let addNetworkResult = await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: chains[defaultEthereumChain].chainId,
              rpcUrls: [chains[defaultEthereumChain].rpcUrl],
              chainName: chains[defaultEthereumChain].name,
              nativeCurrency: {
                name: 'Ether',
                symbol: 'ETH',
                decimals: 18,
              },
              blockExplorerUrls: [chains[defaultEthereumChain].explorerUrl],
            },
          ],
        });

        return addNetworkResult;
      } else {
        console.log('switch ethereum chain network Error', error);
        throw error;
      }
    }
  }
}

export default {
  async install(Vue) {
    Vue.prototype.W3M = await setWeb3Modal();
    store.commit('web3Store/setWeb3State', {
      objectName: 'web3Modal',
      stateName: 'isInitialized',
      currentState: true,
    });
    Vue.prototype.W3F = new Web3Functions();
  },
};
