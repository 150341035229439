<template>
  <div v-if="show" class="modal_wrap type_full" :class="{ is_show: show }">
    <div
      id="searchModal"
      ref="searchModal"
      class="modal"
      role="dialog"
      aria-labelledby="search_title"
      aria-describedby="search_desc"
    >
      <div class="modal_head">
        <h3 id="search_title" class="modal_ttl">Search</h3>
      </div>
      <div id="search_desc" class="modal_body">
        <SearchInner @searchClose="searchClose" :type="'mobile'" />
      </div>

      <button class="btn type_ic close_modal" @click="searchClose">
        <IcCloseS />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseS from '@/components/ic/IcCloseS';
import SearchInner from '@/components/common/SearchInner';
export default {
  name: 'SearchModal',
  components: { IcCloseS, SearchInner },
  props: {
    itemData: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      keyword: '',
    };
  },
  methods: {
    open() {
      this.show = true;
      this.$root.$emit('setScrollFixed', true);
    },
    searchClose() {
      this.show = false;
      this.$root.$emit('setScrollFixed', false);
    },
  },
};
</script>

<style scoped>
.modal {
  text-align: left;
}
</style>
