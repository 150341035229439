export const userStoreActions = {
    settingUser({commit}, userParam) {
        const stateInfo = {
            redDotYn: '',
            userName: '',
            userType: '',
            svcJoinYn: '',
            mbrNo: '',
            email: '',
            svcJoinYn: '',
            walletYn: '',
            wlltType: '',
            ethWallet: {},
            hdrWallet: {},
            stateYn: '',
            followers: '',
            following: '',
            profileDetails: {
            artistDescription: '',
            facebookUrl: '',
            twitterUrl: '', 
            instagramUrl: '',
            webSiteUrl: '', 
            },
            profileInfos: [],
            profileCoverInfos: [],
        };
    
        for (const [key, value] of Object.entries(stateInfo)) {
            if(userParam) {
                commit(key, userParam[key]);
            } else {
                commit(key, value);
            }
        }
    }
};
