<template>
  <IconBase
    icon-name="icAlarm"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path
      data-name="Path 75"
      d="M22.414 19.69v-7.887a7.313 7.313 0 0 0-14.625 0v7.887L5.2 22.5H25Z"
    />
    <path data-name="Path 109" d="M11 26.5h8" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcAlarm',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Alarm',
    },
  },
};
</script>

<style scoped></style>
