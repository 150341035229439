<template>
  <div class="edit_item type_card">
    <div class="item_head">
      <div
        @click="goToDropsOrMarket()"
        class="thumb_area"
        aria-hidden="true"
        tabindex="-1"
      >
        <button>
          <img
            v-if="source.small"
            :src="source.small"
            :width="480"
            :alt="itemData.artworkId ? itemData.artworkId : ''"
          />
          <img
            v-else
            :src="require('@/assets/img/img_illust_nodata.png')"
            alt="nodata"
          />
        </button>
      </div>
      <!-- PUB : Origin code -->
      <!-- <div v-if="itemData.mimeType === 'video/mp4'" class="ic_area type_video">
        <button>
          <IcVideo />
        </button>
      </div> -->
      <!-- PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가 -->
      <!-- WIP : 아이콘 분기-->
      <div class="ic_area type_blockchains">
        <IcHedera v-if="itemData.bcNwTp == '10'" />
        <IcEth v-else />
      </div>
      <button
        class="btn type_ic btn_like"
        type="button"
        :class="{
          is_active: likeStatus,
        }"
        @click="likeThis(itemData)"
      >
        <IcLike
          v-if="!likeStatus"
          :status="!likeStatus"
          icon-color="#fff"
          ref="icLike"
        />
        <IcLike v-else icon-color="#ff521d" ref="icLike" />
      </button>
    </div>
    <div class="item_body">
      <div
        @click="goToDropsOrMarket()"
        class="link"
        :aria-describedby="itemData.artworkId"
      ></div>
      <div class="basic_info_group">
        <div
          class="profile_info is_art"
          tabindex="0"
          @click="goArtistProfile()"
        >
          <span
            v-if="itemData.artistAvatarInfo && itemData.artistAvatarInfo.length"
            class="profile"
            :style="`background-image: url(${itemData.artistAvatarInfo[0].resUrl})`"
          ></span>
          <IcProfile v-else class="profile" />
          <IcRing class="ic_ring" :width="32" :height="32" />
          <span class="name">{{ itemData.artistName }}</span>
        </div>
        <div class="ttl_area">
          <h3 :id="itemData.artworkId" class="ttl">{{ itemData.title }}</h3>
          <p v-if="itemData.favoritesPathType == '03'" class="edit">
            {{ itemData.editionId }} of {{ itemData.totalCount }} Editions
          </p>
          <p v-else-if="itemData.favoritesPathType == '02'" class="edit">
            {{ itemData.totalEdition }} Editions
          </p>
        </div>
      </div>
      <div class="state_info clearFix">
        <div class="price_area">
          <template
            v-if="itemData.salesMethod && itemData.salesMethod !== 'none'"
          >
            <p class="type">Price</p>
            <p class="price">
              <!-- PUB : 블록체인 금액표기 수정 -->
              <!-- WIP : 금액표기 분기-->
              <b v-if="itemData.bcNwTp == '10'"
                >{{ itemData.price | setNum }} USDC</b
              >
              <b v-else
                >{{ itemData.price }}<span class="unit">Ξ</span
                ><span class="dollars"
                  >({{ itemData.priceToEthUsd | crtpyAbbreviate }})</span
                ></b
              >
            </p>
          </template>
          <p v-else class="price chip">Not for Sale</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
import IcLike from '@/components/ic/IcLike';
// import IcVideo from '@/components/ic/IcVideo';
import IcRing from '@/components/ic/IcRing';
import IcEth from '@/components/ic/IcEth';
import IcHedera from '@/components/ic/IcHedera';
// PUB : 비디오 아이콘 컴포넌트 삭제, 이더리움 및 헤데라 아이콘 컴포넌트 추가
export default {
  name: 'FavoritesItem',
  components: {
    IcRing,
    //IcVideo,
    IcLike,
    IcProfile,
    IcEth,
    IcHedera,
    // PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가
  },
  props: {
    itemData: {
      type: Object,
      default: null,
    },
    showBadge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      source: {},
      likeStatus: false,
    };
  },
  mounted() {
    if (this.itemData.favorites === 'Y') {
      this.likeStatus = true;
    }
    this.source = this.setThumbnailImg(this.itemData);
  },
  methods: {
    // this.favoritesPathType = '';
    // 관심 설정 경로 타입
    // 01: drop artwork 상세
    // 02: market Place multi edition 상세
    // 03: market place one edition 상세

    goArtistProfile() {
      this.$router.push({
        name: 'ProfileArtist',
        params: {
          mbrNo: this.itemData.artistId,
        },
      });
    },
    goToDropsOrMarket() {
      if (this.itemData.favoritesPathType === '01') {
        this.goDropDetail(this.itemData.dropsId, this.itemData.artworkId);
      } else {
        this.goArtworkDetail(this.itemData.artworkId, this.itemData.editionId);
      }
    },
    goArtworkDetail(artworkId, editionId) {
      this.$router.push({
        name: 'marketplaceDetail',
        query: {
          artworkId: artworkId,
          editionId: editionId,
        },
      });
    },
    goDropDetail(dropsId, artworkId) {
      this.$router.push({
        name: 'dropsDetail',
        query: {
          dropId: dropsId,
          artworkId: artworkId,
        },
      });
    },
    likeThis(item) {
      if (!this.$refs.icLike.loginCheck()) {
        return;
      } else {
        let favoritesYn = this.likeStatus === true ? 'N' : 'Y';

        if (item.editionId === 0) {
          let params = {
            artworkId: item.artworkId,
            favoritesYn,
          };

          this.$refs.icLike.likeArtwork(params);
        } else {
          let params = {
            artworkId: item.artworkId,
            editionId: item.editionId,
            favoritesYn,
          };
          this.$refs.icLike.likeEdition(params);
        }

        this.likeStatus = !this.likeStatus;
      }
    },
  },
};
</script>

<style scoped>
.item_head,
.item_body {
  cursor: pointer;
}
</style>
