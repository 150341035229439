import { cryptoSymbol } from 'crypto-symbol';
import { ApiService } from '@/service/apiservice';
const api = new ApiService();

export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          androidWarning: {
            type: 'warn',
            desc: 'android wallet Wallypto App is coming soon !',
          },
          iosWarning: {
            type: 'warn',
            desc: 'iOS wallet Wallypto App is coming soon !',
          },
          bladeWarning: {
            type: 'warn',
            desc: 'The blade app will be temporarily suspended.',
          },
          wallyptoWarning: {
            type: 'warn',
            desc: 'The wallypto app will be temporarily suspended.',
          },
        };
      },
      computed: {
        openAndriod() {
          return JSON.parse(process.env.VUE_APP_OPEN_ANDROID) ? true : false;
        },
        openIphone() {
          return JSON.parse(process.env.VUE_APP_OPEN_IOS) ? true : false;
        },
        openBlade() {
          return JSON.parse(process.env.VUE_APP_OPEN_BLADE) ? true : false;
        },
        openWallypto() {
          return JSON.parse(process.env.VUE_APP_OPEN_WALLYPTO) ? true : false;
        },
        isAndroid() {
          let userAgent = navigator.userAgent;
          return userAgent.match(/Android/i);
        },
        isIOS() {
          let userAgent = navigator.userAgent;
          return userAgent.match(/iPhone/i);
        },
      },
      methods: {
        onBlade(params, email) {
          let schemeType = ['setup', 'purchase'];
          let saleType = ['forsale', 'notforsale'];

          let generateOptions = {
            scheme: schemeType.includes(params.scheme)
              ? params.scheme
              : undefined,
            hostserver: params.hostServer || undefined,
            signkey: params.signAddr || undefined,
            price: params.price || -1,
            type: saleType.includes(params.type) ? params.type : undefined,
          };

          //forsale, notforsale serial key 추가
          if (params.serial) {
            generateOptions.serial = params.serial;
          }

          //blade serial key 노출 여부 drop, marketplace
          if (params.purchaseType) {
            generateOptions.purchasetype = params.purchaseType;

            //drop NFT IMAGE, NT NAME 추가
            if (generateOptions.purchasetype === 'drop') {
              generateOptions.NFTImage = params.NFTImage;

              //NOTE: white space replacing logic reflect all enviroment at 23-01-31 - requested by hyLee
              // ASIS
              // if (process.env.VUE_APP_PROFILE !== 'PRD') {
              //   generateOptions.NFTName = params.NFTName.replace(/\s/gi, '__');
              // } else {
              //   generateOptions.NFTName = params.NFTName.replace(/\s/gi, '');
              // }

              //TOBE 비활성 처리
              // if (process.env.VUE_APP_PROFILE === 'PRD') {
              //   generateOptions.NFTName = params.NFTName.replace(/\s/gi, '');
              // } else {
              // }
              generateOptions.NFTName = params.NFTName;
            }
          }

          if (!this.openBlade) {
            this.$root.$emit('openAlert', this.bladeWarning);
            return false;
          }

          let testUserEmail = [
            'luckyis17@yopmail.com',
            'luckyis18@yopmail.com',
            'cootte@naver.com',
          ];

          if (testUserEmail.includes(email)) {
            // //TEST;
            // window.open(this._bladeAppLink(generateOptions, email));
            // //ORIGINAL;
            // return (location.href = this._bladeAppLink(generateOptions, email));
          }
          return (location.href = this._bladeAppLink(generateOptions, email));
        },
        onCallApp(signUrl) {
          if (!this._openValidation()) return false;

          if (!this.$isMobile()) return false;

          let generateOptions = {
            scheme: signUrl === 'finance' ? 'buyingCoins' : 'dapp',
            'S.signurl': this.isAndroid ? signUrl : undefined,
            signurl: this.isIOS ? signUrl : undefined,
            package: this.isAndroid ? process.env.VUE_APP_AND : undefined,
          };

          return (location.href = this._wallyptoAppLink(generateOptions));
        },
        _openValidation() {
          if (!this.openAndriod && this.isAndroid) {
            this.$root.$emit('openAlert', this.androidWarning);
            return false;
          }
          if (!this.openIphone && this.isIOS) {
            this.$root.$emit('openAlert', this.iosWarning);
            return false;
          }
          if (!this.openWallypto) {
            this.$root.$emit('openAlert', this.wallyptoWarning);
            return false;
          }
          return true;
        },
        _wallyptoAppLink(option) {
          let domain = this.isAndroid
            ? 'intent://wallyptoLink#Intent;'
            : `https://${process.env.VUE_APP_IOS}/ios?`;

          let separaotr = this.isAndroid ? ';' : '&';

          let _queryString = this.setQueryString(option, separaotr);

          let url = domain + _queryString;

          if (
            process.env.VUE_APP_PROFILE === 'DEV' ||
            process.env.VUE_APP_PROFILE === 'QA'
          ) {
            console.log('in DEV, QA test code wallypto');
            alert(this.isAndroid ? url + ';end' : url);
          }
          return this.isAndroid ? url + ';end' : url;
        },
        _bladeAppLink(option, email) {
          let guideUrl = 'https://link.bladewallet.io/?link=';

          let _queryString = encodeURIComponent(this.setQueryString(option));

          // NOTE: ORIGINAL
          let resultUrl = `https://api.bladewallet.io/mobile/lgConnect?${_queryString}`;
          // isi: '1623849951',
          // ibi: 'org.bladelabs.bladewallet',
          // apn: 'org.bladelabs.wallet',
          // amv: '30',
          resultUrl =
            resultUrl +
            `&isi=1623849951&ibi=org.bladelabs.bladewallet&apn=org.bladelabs.wallet&amv=30`;

          let testUserEmail = ['luckyis18@yopmail.com', 'cootte@naver.com'];
          if (testUserEmail.includes(email)) {
            // //TEST;
            // window.open(this._bladeAppLink(generateOptions, email));
            // //ORIGINAL;
            // return (location.href = this._bladeAppLink(generateOptions, email));
          }

          if (
            process.env.VUE_APP_PROFILE === 'DEV' ||
            process.env.VUE_APP_PROFILE === 'QA'
          ) {
            console.log('in DEV, QA test code');
            alert(guideUrl + resultUrl);
          }
          //Rfactoring
          console.log(guideUrl + resultUrl);
          return guideUrl + resultUrl;

          // ORIGINAL
          // return (location.href = guideUrl + resultUrl);
        },
        appMessage(type) {
          return type === 'WLT001' ? this.bladeWarning : this.wallyptoWarning;
        },
        setQueryString(option, separaotr) {
          let _queryString = Object.entries(option).reduce((prev, current) => {
            if (!current[1]) {
              return prev;
            }
            let _result = current.join('=');
            let spr = separaotr || '&';
            return !prev ? _result : [prev, _result].join(spr);
          }, '');

          return _queryString;
        },
        setThumbnailImg(itemData) {
          let artworkBackground;
          let original;
          let medium;
          let small;

          // artworkBackground : 백그라운드 이미지
          // original : 동영상이든 이미지이든 작품 원본 자체가 담기는 변수 (동영상이면 동영상그자체)
          // medium : 동영상의 경우 0번째 배열 썸네일 / 이미지는 1번째 배열 이미지
          // small : 동영상의 경우 1번째 배열 썸네일 / 이미지는 2번째 배열 이미지

          if (itemData.mimeType === 'video/mp4') {
            if (itemData.otherMaplYn == 'Y') {
              artworkBackground = itemData.videoThumbInfos?.[0].resUrl;
              original = itemData.videoInfos?.[0].resUrl;
              medium = itemData.videoThumbInfos?.[0].resUrl;
              small = itemData.videoThumbInfos?.[0].resUrl;
            } else {
              artworkBackground = itemData.videoThumbInfos[0].resUrl;
              original = itemData.videoInfos[0].resUrl;
              medium = itemData.videoThumbInfos?.[0].resUrl;
              small = itemData.videoThumbInfos?.[1].resUrl;
            }
          } else {
            artworkBackground = original = itemData.imageInfos[0].resUrl;
            if (itemData.imageInfos[2]) {
              //  7/26 imageInfos에서 [2]가 없는 경우가 있어 예외처리
              medium = itemData.imageInfos[1].resUrl;
              small = itemData.imageInfos[2].resUrl;
            } else {
              medium = original;
              small = itemData.imageInfos[1].resUrl;
            }
          }
          let source = {
            artworkBackground,
            original,
            medium,
            small,
          };
          return source;
        },
        setUpcomingDateStr(dropsId, dropsStartDate, newLine) {
          let gmToTzDate = this.$options.filters.GmtToTz(dropsStartDate);
          let newLineOption = newLine ? 'newLine' : '';

          return process.env.VUE_APP_DROPS_COMINGSOON.includes(dropsId)
            ? 'Coming Soon'
            : this.$options.filters.UsaFormat(gmToTzDate, newLineOption);
        },
        setFocusTrap(eventBoolean, modalClass, modalIndex, modalListIdx) {
          setTimeout(() => {
            let modal = document.querySelector(modalClass);
            if (!modal) return;
            if (modalListIdx) {
              modal = document.querySelectorAll(modalClass)[modalListIdx];
            }

            let focusEls = modal.querySelectorAll(
              'a[href], area[href], input:not([disabled]), select:not([disabled]),ul, li, textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]',
            );
            let firstEl = focusEls[0];
            let lastEl = focusEls[focusEls.length - 1];

            let modalTrapFocus = event => {
              if (event.key === 'Tab') {
                if (document.activeElement === lastEl && !event.shiftKey) {
                  firstEl.focus();
                  console.log(firstEl);
                  event.preventDefault();
                } else if (
                  document.activeElement === firstEl &&
                  event.shiftKey
                ) {
                  lastEl.focus();
                  event.preventDefault();
                }
              }
            };
            if (!eventBoolean) {
              document.removeEventListener('keydown', modalTrapFocus);
              return;
            }
            if (modalIndex) {
              focusEls[modalIndex].focus();
            } else {
              firstEl.focus();
            }
            document.addEventListener('keydown', modalTrapFocus);
          }, 500);
        },
        async setOtherMarket(contractAddr, tokenId) {
          const params = {
            otherMaplInfo: [
              {
                contractAddr,
                tokenId,
              },
            ],
          };

          let res = await api.getMyOtherMarketUrl(params);
          if (res.code == 0) {
            let animationUrl = res.otherMaplRes[0].animationUrl;

            if (animationUrl.indexOf('ipfs') !== 1) {
              animationUrl = 'https://ipfs.io/' + animationUrl.split('://')[1];
            }
            let ohterVideoInfos = [
              {
                resUrl: animationUrl,
                rsrcId: '',
                rsrcTp: '50',
              },
              {
                resUrl: animationUrl,
                rsrcId: '',
                rsrcTp: '51',
              },
              {
                resUrl: animationUrl,
                rsrcId: '',
                rsrcTp: '52',
              },
            ];
            return ohterVideoInfos;
          } else {
            return null;
          }
        },
        cryptoSymbolLokkup(v) {
          let { symbolLookup } = cryptoSymbol({});
          return symbolLookup(v); // Ethereum -> ETH
        },
        cryptoNameLookup(v) {
          let { nameLookup } = cryptoSymbol({});
          return nameLookup(v); // ETH -> Ethereum
        },
        crtpyAbbreviate(num, digits = 2) {
          let NumAbbr = require('number-abbreviate');
          let numAbbr = new NumAbbr();
          return numAbbr.abbreviate(num, digits);
        },
        //이더리움 value 처리
        toHexEth(decimalValue) {
          return '0x' + Number(decimalValue).toString(16);
        },
      },
    });
  },
};
