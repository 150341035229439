<template>
  <div
    v-if="showAlarm"
    class="modal_wrap type_full"
    :class="{ is_show: showAlarm }"
  >
    <div
      id="notiModal"
      ref="notiModal"
      class="modal"
      role="dialog"
      aria-labelledby="noti_title"
      aria-describedby="noti_desc"
    >
      <div class="modal_head">
        <h3 id="noti_title" class="modal_ttl">Notifications</h3>
      </div>
      <div id="noti_desc" class="modal_body">
        <ul class="alarm_list" v-if="itemData && itemData.length > 0">
          <li
            v-for="(item, i) in itemData"
            :key="'main_menu' + i"
            class="alarm_node"
          >
            <div class="alarm_item">
              <p class="cate">{{ item.title }}</p>
              <h4 class="msg">
                <b>{{ item.content }}.</b>
              </h4>
              <span class="date"
                >{{ item.regDatetime | GmtToTz | UsaFormat }}
              </span>
            </div>
            <ul v-if="itemData.length === 0 && isLoading" class="alarm_list">
              <div class="loader">Loading...</div>
            </ul>
            <ul v-if="itemData.length === 0 && !isLoading" class="alarm_list">
              <div style="padding: 10px 0px">no notifications</div>
            </ul>
          </li>
        </ul>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseS />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseS from '@/components/ic/IcCloseS';
export default {
  name: 'NotificationModal',
  components: { IcCloseS },
  props: {
    itemData: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showAlarm: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    open() {
      this.$root.$emit('setScrollFixed', true);
    },
    close() {
      this.$emit('alarmClose');
      this.$root.$emit('setScrollFixed', false);
    },
  },
};
</script>

<style scoped>
.modal {
  text-align: left;
}
</style>
