<template>
  <main id="contents" class="my_list_page my_user_page">
    <div class="set_inner clearFix">
      <h1 class="page_ttl">My Account</h1>
      <AccountMenu
        :class="{ is_show: window.width <= 1024 }"
        :window="window.width"
      />
      <div class="min_inner" v-if="userInfo">
        <section class="user_sec">
          <div class="user_sec_body">
            <div class="data_length_area" v-if="userInfo.ownrCount > 0">
              <h2 class="ttl">
                <em>{{ userInfo.ownrCount }}</em> Owned
              </h2>
            </div>
            <ul v-if="userInfo.ownrCount > 0" class="grid grid_15 ib edit_list">
              <li
                v-for="(item, i) in ownedEditions"
                :key="item + i"
                class="col col_4 edit_node"
              >
                <ItemCardEdit
                  :mbrNo="mbrNo"
                  :userType="userType"
                  :itemData="item"
                  :itemIndex="i"
                  :key="componentListKey + i"
                  :viewType="'profile'"
                  :hideOwner="true"
                  @open="open"
                  @onAppChangeSelling="onAppChangeSelling"
                  @onSetCloseFocusCard="onSetCloseFocusCard"
                />
              </li>
            </ul>
            <div v-else class="guide_ui no_data">
              <div class="img_area">
                <picture>
                  <source
                    media="(max-width: 600px)"
                    type="image/png"
                    :srcset="`${require('@/assets/img/img_mob_illust_nodata_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nodata_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nodata.png')}`"
                  />
                  <img
                    :src="require('@/assets/img/img_illust_nodata.png')"
                    alt="nodata"
                  />
                </picture>
              </div>
              <p>{{ userName }} doesn’t own any artworks yet!</p>

              <router-link to="/marketplace">
                <button
                  class="btn w_xl h_l strong"
                  @click="goMarketPlaceItemList()"
                >
                  Go to Marketplace
                </button>
              </router-link>
            </div>
          </div>
        </section>
      </div>
    </div>
    <QrModal
      v-if="showQrModal"
      :showQrModal="showQrModal"
      :modal-data="changeSelling"
      @close="close"
    />
    <SetPriceModal
      v-if="showPriceModal"
      :showPriceModal="showPriceModal"
      :editionId="editionId"
      :artworkId="artworkId"
      modal-size="m"
      @close="close"
    />
  </main>
</template>

<script>
import AccountMenu from '@/components/common/AccountMenu';
import ItemCardEdit from '@/components/mylist/ItemCardEdit';
import QrModal from '@/components/common/QrModal';
import { mapGetters } from 'vuex';
import SetPriceModal from '@/components/common/SetPriceModal';
export default {
  name: 'MyProfileUserView',
  components: { ItemCardEdit, AccountMenu, QrModal, SetPriceModal },
  data() {
    return {
      userInfo: null,
      window: {
        width: 0,
        height: 0,
      },
      changeSelling: {
        title: 'Change Selling Type',
        desc: 'Turn on the camera on your mobile phone and scan the QR to change the selling type.',
        qr: {
          path: 'img_qr.png',
        },
      },
      apiFailAlert: {
        type: 'warn',
        desc: "We're sorry, something went wrong. Transaction failed. Something is wrong with your wallet. Please try again later.",
      },
      showQrModal: false,
      showPriceModal: false,
      artworkId: '',
      editionId: 0,
      polling: null,
      overtimer: 180000,
      signAddr: '',
      componentListKey: 0,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      mbrNo: 'userStore/mbrNo',
      userName: 'userStore/userName',
      userType: 'userStore/userType',
      wlltType: 'userStore/wlltType',
      email: 'userStore/email',
    }),
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.getUserProfile();
  },
  methods: {
    async getUserProfile() {
      let param = {
        mbrNo: this.mbrNo,
      };

      await this.api.getUserProfile(param).then(res => {
        if (res.code === 0) {
          this.userInfo = res.userInfo;
          this.ownedEditions = res.ownedEditions;
          if (this.ownedEditions) {
            this.ownedEditions.forEach(x => {
              if (x.keywords) {
                x.hashtags = '#' + x.keywords.replace(',', ' #') + '#LGArtLab';
              } else {
                x.hashtags = '#LGArtLab';
              }
            });
          }

          this.componentListKey += res.ownedEditions.length;
        } else {
          this.$router.push('/network');
        }
      });
    },
    open(cardInfo) {
      if (cardInfo) {
        this.artworkId = cardInfo.artworkId;
        this.editionId = cardInfo.editionId;
        this.showPriceModal = true;
      } else {
        this.showQrModal = true;
      }
    },
    close(type) {
      this[type] = false;
      this.getUserProfile();
    },
    goMarketPlaceItemList() {
      this.$router.push('/marketplace');
    },
    //Change Selling 비활성화 미 사용 23-04-18
    async onAppChangeSelling(cardInfo) {
      this.artworkId = cardInfo.artworkId;
      this.editionId = cardInfo.editionId;

      let walletParams = {
        artworkId: this.artworkId,
        editionId: this.editionId,
        price: 0,
        priceSettingTp: '10',
      };

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      //if (process.env.VUE_APP_PROFILE === 'DEV') {
      walletParams.bcNwTp = '10';
      //}

      if (this.isLoading) return false;
      this.isLoading = true;

      await this.api.getQrUrlForSalePriceSetting(walletParams).then(res => {
        let walletInfo = {
          walletType: this.wlltType,
          resultPrice: -1,
        };
        if (res.code === 0) {
          if (this.wlltType === 'WLT001') {
            let params = {
              hostServer: res.hostServer,
              signAddr: res.signAddr,
              scheme: 'setup',
              price: -1,
              type: 'notforsale',
              serial: cardInfo.serial,
            };
            this.onBlade(params, this.email);
          } else if (this.wlltType === 'WLT002') {
            this.onCallApp(res.signUrl);
          } else {
            this.$router.push({
              name: 'network',
            });
          }
          this.signAddr = res.signAddr;
          this.polling = setInterval(() => {
            this.getAppCallback();
            if (this.overtimer <= 0) {
              this.isLoading = false;
              clearInterval(this.polling);
            }
            this.overtimer -= 3000;
          }, 3000);
        } else if (res.code === 1033) {
          this.isLoading = false;
          this.$root.$emit('walletTokenExpired', walletInfo);
        } else if (res.code === 1034) {
          this.isLoading = false;
          this.$root.$emit('walletChange', walletInfo);
        } else {
          this.isLoading = false;
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
    async getAppCallback() {
      let params = {
        signAddr: this.signAddr,
      };
      await this.api.getPriceSettingCmpltYn(params).then(res => {
        if (res.code === 0) {
          if (res.cmpltYn === 'Y' || res.cmpltYn === 'N') {
            this.isLoading = false;
            this.$EventBus.$emit('isType', false);
            clearInterval(this.polling);
            this.getUserProfile();
          }
        } else {
          this.$router.push({
            name: 'network',
          });
        }
      });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    onSetCloseFocusCard(idx) {
      setTimeout(() => {
        document.querySelectorAll('.share')[idx].focus();
      }, 500);
    },
  },
};
</script>
