<template>
  <IconBase
    icon-name="icZoomIn"
    :icon-label="iconLabel"
    :width="width"
    :height="height"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="strokeWidth"
  >
    <path
      id="패스_2729"
      data-name="패스 2729"
      class="cls-2"
      d="M-816.607,8978H-825v8.394"
      transform="translate(826 -8977.001)"
    />
    <path
      id="패스_3739"
      data-name="패스 3739"
      class="cls-2"
      d="M8.393,7.394H0V-1"
      transform="translate(1 15.6)"
    />
    <path
      id="패스_3738"
      data-name="패스 3738"
      class="cls-2"
      d="M8.393,7.394H0V-1"
      transform="translate(22.993 8.394) rotate(180)"
    />
    <path
      id="패스_3740"
      data-name="패스 3740"
      class="cls-2"
      d="M8.393,0H0V8.394"
      transform="translate(22.992 22.994) rotate(180)"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcZoomIn',
  components: { IconBase },
  props: {
    width: {
      type: [Number, String],
      default: 30,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
    strokeWidth: {
      type: [Number, String],
      default: 1.2,
    },
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'View fullscreen',
    },
  },
};
</script>

<style scoped></style>
