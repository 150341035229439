'use strict';

import Vue from 'vue';
import axios from 'axios';
import store from '@/store/index';
import dayJs from 'dayjs';

//axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

/*
const URL_STRING = window.location.host;
if (URL_STRING.includes('qt2')) {
  axios.defaults.baseURL = 'https://qt2-aic-api.lgartlab.com';
} else if (URL_STRING.includes('qt')) {
  axios.defaults.baseURL = 'https://qt-aic-api.lgartlab.com';
} else {
  axios.defaults.baseURL = 'https://qt2-aic-api.lgartlab.com';
}
*/

let config = {
  // baseURL: process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    if (store.getters['appStore/accessToken']) {
      config.headers.Authorization = store.getters['appStore/accessToken'];
    }
    config.headers.deviceType = Vue.prototype.$isMobile() ? 3 : 2;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

_axios.interceptors.response.use(
  function (response) {
    let nowDate = dayJs().format('YYYY-MM-DD HH:mm:ss');
    let accessTime = sessionStorage.getItem('accessTime');
    let refreshTime = sessionStorage.getItem('refreshTime');
    let diffDate = dayJs(refreshTime).diff(accessTime, 'minute');

    if (response.data.code === 0) {
      sessionStorage.setItem('refreshTime', nowDate);
      // let apiUrl = response.request.responseURL;
      let apiUrl = response.config.url;
      let reddotCheck = apiUrl.includes('common/status');

      if (store.getters['appStore/accessToken'] && !reddotCheck) {
        if (diffDate >= 50 && diffDate <= 59) {
          let param = {
            refreshToken: store.getters['appStore/refreshToken'],
          };
          Vue.api
            .reissueToken(param)
            .then(res => {
              if (res.code === 0) {
                if (process.env.VUE_APP_PROFILE === 'DEV') {
                  console.log(
                    '========== 토큰 재발급 : ',
                    dayJs().format('HH:mm:ss'),
                    ' ==========',
                  );
                }
                store.commit('appStore/accessToken', res.accessToken);
                store.commit('appStore/refreshToken', res.refreshToken);
                sessionStorage.setItem('accessTime', nowDate);
              } else {
                logout();
              }
            })
            .catch(() => {
              logout();
            });
        }
      }
    }

    if (response.data.code === 1015) {
      if (process.env.VUE_APP_PROFILE === 'DEV') {
        console.log(
          '========== 세션 만료 : ',
          dayJs().format('HH:mm:ss'),
          ' ==========',
        );
      }
      store.commit('appStore/expiredFlag', true);
    }

    if (
      response.data.code === 1016 ||
      response.data.code === 1017 ||
      response.data.code === 1018
    ) {
      logout();
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);
function logout() {
  store.commit('appStore/accessToken', '');
  store.commit('appStore/refreshToken', '');
  store.commit('appStore/expiredFlag', '');
  sessionStorage.removeItem('accessTime');
  sessionStorage.removeItem('refreshTime');
}
Plugin.install = function (Vue /*options*/) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
