<template>
  <IconBase
    icon-name="arwPageNext"
    :icon-label="iconLabel"
    :width="5.043"
    :height="9.664"
    viewBox="0 0 5.043 9.664"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="2"
  >
    <path fill="none" d="m.374.332 4 4.5-4 4.5" data-name="패스 3751" />
  </IconBase>
</template>

<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'arwPageNext',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#6c6c6c',
    },
    iconLabel: {
      type: String,
      default: 'PageNext',
    },
  },
};
</script>

<style scoped></style>
