<template>
  <IconBase
    icon-name="icWarn"
    :icon-label="iconLabel"
    :width="60"
    :height="60"
    viewBox="0 0 60 60"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path class="st0" d="m30 9.5 24 41H6l24-41zM29.7 24.5v14" />
    <path
      d="M31.3 43c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5 1.5.7 1.5 1.5"
      :fill="strokeColor"
      stroke-width="0"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcWarn',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Warn',
    },
  },
};
</script>

<style scoped></style>
