import Vue from 'vue';
import Vuex from 'vuex';
import { appStore } from '@/store/appStore';
import { userStore } from '@/store/userStore';
import { cacheStore } from '@/store/cacheStore';
import { homeStore } from '@/store/homeStore';
import { dropsStore } from '@/store/dropsStore';
import { marketPlaceStore } from '@/store/marketPlaceStore';
import { tvappStore } from '@/store/tvappStore';
import { web3Store } from '@/store/web3Store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    appStore,
    userStore,
    cacheStore,
    homeStore,
    dropsStore,
    marketPlaceStore,
    tvappStore,
    web3Store,
  },
});
