<template>
  <div id="wrapper" :style="`--min: ${minH};`">
    <div id="container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyLayout',
  data() {
    return {
      minH: 0,
      isMob: false,
    };
  },
  methods: {
    minHeight() {
      this.minH = window.innerHeight;
    },
  },
  mounted() {
    this.minHeight(); // 화면 최소 높이 세팅
    window.addEventListener('resize', this.minHeight); // 오타수정
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.minHeight);
  },
};
</script>
<style scoped></style>
