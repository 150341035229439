<template>
  <IconBase
    icon-name="icSignOut"
    :icon-label="iconLabel"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :stroke-color="strokeColor"
    :stroke-width="2"
  >
    <path data-name="Path 2369" d="M10.208 4H4v16h6.208" />
    <path
      data-name="Path 2370"
      d="m15.091 8.097 3.848 3.848H8v1.552h10.94l-3.848 3.848 1.1 1.1 5.812-5.736L16.188 7Z"
      :fill="strokeColor"
      stroke-width="0"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcMobSignOut',
  components: { IconBase },
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    strokeColor: {
      type: String,
      default: '#aaa',
    },
    iconLabel: {
      type: String,
      default: 'Sign Out',
    },
  },
};
</script>

<style scoped></style>
