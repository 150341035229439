<template>
  <main id="contents" class="my_list_page notification_page">
    <div class="set_inner clearFix">
      <h1 class="page_ttl only_pc">My Account</h1>
      <h1 class="page_ttl dv_tab">Settings</h1>
      <AccountMenu />
      <div class="min_inner">
        <section class="notification_sec">
          <div class="notification_sec_head">
            <div class="ttl_area">
              <h2 class="ttl">Notification Settings</h2>
              <p>
                LG Art Lab may send you emails regarding important notifications
                about your account and activities.
              </p>
            </div>
          </div>
          <div class="notification_sec_body">
            <ul class="subscribe_list">
              <li>
                <div class="subscribe_group">
                  <div class="info_area">
                    <p class="subscribe">Reminder emails</p>
                    <p>
                      Get email notifications when the upcoming drops you have
                      chosen goes live.
                    </p>
                  </div>
                  <div class="input_area switch">
                    <div class="input_box">
                      <label for="chk_reminderCheck">
                        <input
                          id="chk_reminderCheck"
                          type="checkbox"
                          v-model="reminderYn"
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="subscribe_group">
                  <div class="info_area">
                    <p class="subscribe">Status Change emails</p>
                    <p>
                      Get email notifications for status changes on the artworks
                      you have.
                    </p>
                  </div>
                  <div class="input_area switch">
                    <div class="input_box">
                      <label for="chk_statusChangeCheck">
                        <input
                          id="chk_statusChangeCheck"
                          type="checkbox"
                          v-model="statusChangeYn"
                          label="statusChangeCheck"
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </div>
              </li>
              <!-- <div class="notification_sec_head" style="margin-top: 80px">
                <div class="ttl_area">
                  <h2 class="ttl">Restore my collections</h2>
                  <p class="info">
                    You can update the artwork(purchased with the currently
                    connected wallet) list. If you have re-joined LG Art Lab
                    after having deleted the account, click the Restore button.
                    You can restore the artwork that has been purchased before
                    deletion.
                  </p>
                </div>
              </div>
              <div class="notification_sec_body">
                <li>
                  <div class="subscribe_group">
                    <div class="info_area">
                      <p class="subscribe">Last update</p>
                      <p>
                        <template v-if="!lastRstDate">
                          No restoration history.
                        </template>
                        <template v-else>
                          {{ lastRstDate | GmtToTz | UsaFormat }}
                        </template>
                      </p>
                    </div>
                    <button
                      class="btn w_lg h_l strong restoreBtn"
                      :class="{
                        is_loading: isLoading,
                      }"
                      @click="checkRestore"
                    >
                      Restore
                    </button>
                  </div>
                </li>
              </div> -->
              <!-- 
              <div class="notification_sec_head" style="margin-top: 80px">
                <div class="ttl_area">
                  <h2 class="ttl">NFT Saver Setting</h2>
                  <p class="info">
                    When you view the artworks on your TV for too long, the NFT
                    Saver will be activated to prevent burn-ins. If you don't
                    want the NFT Saver to be activated, turn off the switch.
                  </p>
                </div>
              </div>
              <div class="notification_sec_body">
                <li>
                  <div class="subscribe_group">
                    <div class="info_area">
                      <p class="subscribe">NFT Saver</p>
                      <p>
                        It's better not to turn off the NFT saver if possible to
                        protect the screen.
                      </p>
                    </div>
                    <div class="input_area switch">
                      <div class="input_box">
                        <label>
                          <input type="checkbox" v-model="nftSaverYn" />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </div> -->
            </ul>
            <div class="btn_area">
              <button class="btn w_xl h_l strong" @click="changeSettings">
                Save Changes
              </button>
            </div>
            <div class="delete_account">
              <router-link to="/mylist/delete">
                Delete Account
                <ArwNextS stroke-color="var(--txt-gray)" />
              </router-link>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import ArwNextS from '@/components/ic/arwNextS';
import AccountMenu from '@/components/common/AccountMenu';
import { mapGetters } from 'vuex';

export default {
  name: 'NotificationView',
  components: { AccountMenu, ArwNextS },
  data() {
    return {
      reminderYn: null,
      statusChangeYn: null,
      nftSaverYn: null,
      lastRstDate: null,
      walletInfo: {
        bc_account: '-',
        nftCount: '-',
        usdcBalance: 0,
      },
      successAlert: {
        type: 'success',
        desc: 'Save was successful.',
      },
      failAlert: {
        type: 'warn',
        desc: 'Something wrong when save change settings.',
      },
      nftWarningAlert: {
        type: 'warn',
        desc: 'If you do not run NFT Saver, you may be vulnerable to screen burn-in. Are you sure you want to turn off Saver?',
      },
      apiFailAlert: {
        type: 'warn',
        desc: "We're sorry, something went wrong. Transaction failed. Something is wrong with your wallet. Please try again later.",
      },
      restoreWalletAlert: {
        type: 'warn',
        desc: 'There is no wallet currently connected. First, connect the wallet on My Account > My Wallet menu.',
      },
      restoreSuccess: {
        type: 'success',
        desc: 'Restoration complete.Restored artworks can be checked on My Collections or My Account > Profile.',
      },
      restoreFail: {
        type: 'warn',
        desc: 'There is no wallet currently connected. First, connect the wallet on My Wallet menu.',
      },
      restoreConfirm: {
        type: 'warn_2',
        desc: '',
        id: 'restoreTrue',
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      walletYn: 'userStore/walletYn',
    }),
  },
  //2023-08-01 restore 기능 미사용으로 불필요 호출
  // watch: {
  //   walletYn: {
  //     immediate: true,
  //     handler(v) {
  //       if (v === 'Y') {
  //         this.getNftBalance();
  //       }
  //     },
  //   },
  // },
  mounted() {
    this.getNotificationSetting();
  },
  methods: {
    async getNotificationSetting() {
      await this.api.getNotificationSetting({}).then(res => {
        if (res.code === 0) {
          this.reminderYn = res.reminderYn === 'Y' ? true : false;
          this.statusChangeYn = res.statusChangeYn === 'Y' ? true : false;
          this.lastRstDate = res.lastRstDate == '0' ? false : res.lastRstDate;
        }
      });
    },
    async changeSettings() {
      const data = {
        reminderYn: this.reminderYn ? 'Y' : 'N',
        statusChangeYn: this.statusChangeYn ? 'Y' : 'N',
      };
      await this.api.modifyNotificationSetting(data).then(res => {
        if (res.code === 0) {
          // do something
          // this.$root.$emit('openAlert', this.successAlert);
          this.$toast.open('Saved Change');
        } else {
          this.$root.$emit('openAlert', this.failAlert);
        }
      });
    },
    checkRestore() {
      if (this.walletYn === 'N') {
        this.$root.$emit('openAlert', this.restoreWalletAlert);
        return false;
      }

      if (this.isLoading) return false;
      this.restoreConfirm.desc = `The currently connected wallet is ${this.walletInfo.bc_account}. Do you wish to restore artworks purchased via this wallet?`;
      this.$root.$emit('openAlert', this.restoreConfirm);
      this.setEvent();
    },
    async restore() {
      if (this.isLoading) return false;
      this.isLoading = true;

      await this.api.addUserRestore({}).then(res => {
        this.isLoading = false;
        if (res.code === 0) {
          if (res.cnt > 0) {
            this.$root.$emit('openAlert', this.restoreSuccess);
            this.getNotificationSetting();
          } else {
            this.$root.$emit('openAlert', this.restoreFail);
          }
        } else {
          this.$root.$emit('closeAlert');
          this.$router.push({
            name: 'network',
          });
        }
      });
    },
    setEvent() {
      this.$root.$off('restoreTrue');
      this.$root.$on('restoreTrue', Boolean => {
        if (Boolean) {
          this.restore();
        }
      });
    },
    async getNftBalance() {
      this.isLoading = true;

      let walletParams = {};

      //bcNwTp DEV에서만 적용되도록 변경 0312
      //DEV만 적용하던 것 취소 240802
      //if (process.env.VUE_APP_PROFILE === 'DEV') {
      walletParams.bcNwTp = '10';
      //}
      await this.api.getNftBalance(walletParams).then(res => {
        this.isLoading = false;
        if (res.code === 0) {
          let _walletInfo = {
            bc_account: res.bc_account,
            nftCount: res.nftCount,
            usdcBalance: res.usdcBalance,
          };
          this.walletInfo = {
            ..._walletInfo,
          };
        } else {
          this.$root.$emit('openAlert', this.apiFailAlert);
        }
      });
    },
  },
};
</script>

<style scoped>
.is_loading {
  background: #e9ecef !important;
  color: black !important;
}
.restoreBtn {
  height: 4rem;
  line-height: 3rem;
}
input {
  cursor: pointer;
}
</style>
