<template>
  <IconBase
    icon-name="icTime"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <circle class="st1" cx="15" cy="15" r="10.4" />
    <path class="st1" d="M15.2 8.1v6.7l3.6 3.7" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcTime',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Time',
    },
  },
};
</script>

<style scoped></style>
