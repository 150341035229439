<template>
  <div class="modal_wrap" :class="{ is_show: showProfile }">
    <div class="modal editProfile">
      <div class="modal_head">
        <h3 class="modal_ttl">Edit Profile</h3>
      </div>
      <div class="modal_body">
        <div
          class="bg_edit_wrap"
          :style="`background-image: url('${profileCoverUrl}')`"
        >
          <p class="guide_txt">Recommended image size : 1920 *280</p>
          <label class="ic_area type_camera" for="uploadCover">
            <input
              type="file"
              id="uploadCover"
              ref="uploadCover"
              label="uploadCover"
              title="background profile photo"
              @change="onFileUpload($event, 'cover')"
            />
            <IcCamera />
          </label>
        </div>
        <div class="profile_wrap">
          <div class="profile_area profile_info is_art">
            <div
              class="profile"
              v-if="profileUrl"
              :style="`background-image: url('${profileUrl}')`"
            >
              <label class="ic_area type_camera" for="uploadImage">
                <input
                  type="file"
                  id="uploadImage"
                  ref="uploadImage"
                  label="uploadImage"
                  title="profile photo"
                  @change="onFileUpload($event, 'profile')"
                />
                <IcCamera />
              </label>
            </div>
            <!-- Dev: 220503 수정 - 링굵기 수정 -->
            <IcRing
              class="ic_ring"
              :width="110"
              :height="110"
              :small-ring="14.8"
              v-if="profileUrl"
            />
            <div v-if="!profileUrl" class="profile none">
              <IcProfile class="profile" :width="110" :height="110" />
              <!-- Dev: 220503 수정 - 링굵기 수정 -->
              <IcRing
                class="ic_ring none"
                :width="110"
                :height="110"
                :small-ring="14.8"
              />
              <label class="ic_area type_camera none">
                <input
                  type="file"
                  label="uploadProfile"
                  title="uploadProfile"
                  @change="onFileUpload($event, 'profile')"
                />
                <IcCamera />
              </label>
            </div>
            <h2 class="ttl">{{ userName }}</h2>
          </div>
        </div>

        <UpdateInfoModal
          ref="UpdateInfoModal"
          @setZipcode="setZipcode"
          :inner="this.zipInner"
        />

        <div class="tab_list type_stack">
          <div class="tab_node">
            <button
              class="tab_item"
              v-for="(item, index) in tabMenu"
              :key="index"
              :class="{ is_active: index === activeIndex }"
              @click="() => (activeIndex = index)"
            >
              {{ item }}
            </button>
          </div>
        </div>

        <div class="introduce_wrap" v-if="activeIndex === 0">
          <div class="input_area type_hoz textarea">
            <label>Bio</label>
            <div class="input_box">
              <textarea v-model="profile.artistDescription"> </textarea>
            </div>
          </div>
          <div class="input_area type_hoz text">
            <label>Facebook</label>
            <div class="input_box">
              <input
                v-model="profile.facebookUrl"
                type="text"
                label="Facebook"
              />
            </div>
          </div>
          <div class="input_area type_hoz text">
            <label>Twitter</label>
            <div class="input_box">
              <input v-model="profile.twitterUrl" type="text" label="Twitter" />
            </div>
          </div>
          <div class="input_area type_hoz text">
            <label>Instagram</label>
            <div class="input_box">
              <input
                v-model="profile.instagramUrl"
                type="text"
                label="Instagram"
              />
            </div>
          </div>
          <div class="input_area type_hoz text">
            <label>Website</label>
            <div class="input_box">
              <input v-model="profile.webSiteUrl" type="text" label="Website" />
            </div>
          </div>
        </div>
        <div class="introduce_wrap" v-else>
          <FamilyList :type="$route.name" />
        </div>
      </div>

      <div class="modal_foot" v-if="!activeIndex">
        <div class="btn_area">
          <button class="btn basic w_xs h_m" @click="close">Cancel</button>
          <button class="btn strong w_xs h_m" @click="onEditProfile">
            Save
          </button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
    <LoadingModal :isLoading="isLoading" message="Wait for Image Upload..." />
  </div>
</template>

<script>
import FamilyList from '@/components/mylist/FamilyList';
import IcCloseM from '@/components/ic/IcCloseM';
import IcCamera from '@/components/ic/IcCamera';
import IcProfile from '@/components/ic/IcProfile';
import IcRing from '@/components/ic/IcRing';
import UpdateInfoModal from '@/components/marketplace/UpdateInfoModal';
import { mapGetters, mapMutations } from 'vuex';
import store from '@/store/index';
export default {
  name: 'EditProfile',
  components: {
    FamilyList,
    IcRing,
    IcCamera,
    IcCloseM,
    IcProfile,
    UpdateInfoModal,
  },
  props: {
    showProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      profile: {
        artistDescription: '',
        facebookUrl: '',
        twitterUrl: '',
        instagramUrl: '',
        webSiteUrl: '',
      },
      // tabMenu: ['Profile', 'Family Account'],
      tabMenu: ['Profile'],
      activeIndex: 0,
      editSuccess: {
        type: 'success',
        desc: 'Edit profile success',
        id: 'editSuccess',
      },
      editFail: {
        type: 'warn',
        desc: 'Edit profile fail',
        id: 'editFail',
      },
      uploadFail: {
        type: 'warn',
        desc: '',
        id: 'uploadFail',
      },
      profileUrl: null,
      profileCoverUrl: null,
      isLoading: false,
      profileCoverRsrcId: '',
      profileRsrcId: '',

      zipInner: false,
      modalZipCodeInfo: {},
    };
  },
  computed: {
    ...mapGetters({
      mbrNo: 'userStore/mbrNo',
      userType: 'userStore/userType',
      email: 'userStore/email',
      userName: 'userStore/userName',
      profileInfos: 'userStore/profileInfos',
      profileCoverInfos: 'userStore/profileCoverInfos',
      profileDetails: 'userStore/profileDetails',
      zpcd: 'userStore/zpcd',
      city: 'userStore/city',
      stat: 'userStore/stat',
    }),
  },
  async mounted() {
    this.setData();
    this.$root.$emit('setScrollFixed', true);

    //팝업 메시지 Ok Click close
    this.$root.$on('editSuccess', Boolean => {
      if (Boolean) this.close();
    });

    // NOTE: for temporary disapear family tab logic ( when zero family )
    // let familyAccounts = await this.getFamilyAccounts();
    // if (familyAccounts.length > 0) {
    //   this.tabMenu = ['Profile', 'Family Account'];
    // }
    this.setFocusTrap(true, '.modal.editProfile');
  },
  methods: {
    close() {
      this.setFocusTrap(false, '.modal.editProfile');
      this.$emit('close', 'showProfile');
      Object.assign(this.$data, this.$options.data());
      this.$root.$emit('setScrollFixed', false);
    },
    setData() {
      this.profile = { ...this.profileDetails };
      this.profileUrl = this.profileInfos.length
        ? this.profileInfos[0].resUrl
        : null;
      this.profileCoverUrl = this.profileCoverInfos.length
        ? this.profileCoverInfos[0].resUrl
        : null;
      this.zipInner = true;
    },
    onFileUpload(event, type) {
      this.isLoading = true;
      let imageTypeCode = type === 'cover' ? 40 : 30;
      let formData = new FormData();

      formData.append('file', event.target.files[0]);
      formData.append('resourceType', imageTypeCode);

      this.axios.post('/api/v1/file/upload', formData).then(res => {
        this.isLoading = false;

        if (res.data.code === 0) {
          if (type === 'cover') {
            this.profileCoverRsrcId = res.data.resourceId;
            this.profileCoverUrl = res.data.filePath;
          } else {
            this.profileRsrcId = res.data.resourceId;
            this.profileUrl = res.data.filePath;
          }
        }
        if (res.data.code === -1) {
          this.uploadFail.desc = 'Upload fail';
          this.$root.$emit('openAlert', this.uploadFail);
        }
        if (res.data.code === 1020) {
          this.uploadFail.desc =
            'Cannot upload with this extension.\nOnly image(.JPG) files can be registered.';
          this.$root.$emit('openAlert', this.uploadFail);
        }
      });
    },
    setZipcode(modalZipCodeInfo) {
      if (modalZipCodeInfo) {
        this.modalZipCodeInfo = modalZipCodeInfo;
      } else {
        this.modalZipCodeInfo = undefined;
      }
    },
    async zipCodeSave() {
      console.log(this.modalZipCodeInfo);
      if (!this.modalZipCodeInfo) {
        return false;
      }
      let zipParam = {
        zpcd: this.modalZipCodeInfo.zipCode,
        city: this.modalZipCodeInfo.cityOpt.city,
        stat: this.modalZipCodeInfo.cityOpt.stat,
      };

      await this.api.setZipCodeSave(zipParam).then(res => {
        if (res.code === 0) {
          this.setZpcd(zipParam.zpcd);
          this.setCity(zipParam.city);
          this.setStat(zipParam.stat);
        }
      });
    },
    onEditProfile() {
      let requiredParams = {
        email: this.email,
        mbrNo: this.mbrNo,
        userName: this.userName,
        userType: this.userType,
        twitterUrl: this.profile.twitterUrl,
        facebookUrl: this.profile.facebookUrl,
        instagramUrl: this.profile.instagramUrl,
        webSiteUrl: this.profile.webSiteUrl,
        artistDescription: this.profile.artistDescription,
      };
      if (this.profileCoverRsrcId)
        requiredParams.profileCoverRsrcId = this.profileCoverRsrcId;

      if (this.profileRsrcId) requiredParams.profileRsrcId = this.profileRsrcId;

      if (this.modalZipCodeInfo) {
        if (
          this.zpcd !== this.modalZipCodeInfo.zipCode ||
          this.city !== this.modalZipCodeInfo.cityOpt.city
        )
          this.zipCodeSave();
      }

      this.api.editProfile(requiredParams).then(res => {
        if (res.code === 0) {
          let profile = {
            artistDescription: this.profile.artistDescription,
            facebookUrl: this.profile.facebookUrl,
            twitterUrl: this.profile.twitterUrl,
            instagramUrl: this.profile.instagramUrl,
            webSiteUrl: this.profile.webSiteUrl,
          };

          store.commit('userStore/profileDetails', profile);

          if (this.profileCoverRsrcId) {
            store.commit('userStore/profileCoverInfos', res.profileCoverInfos);
          }
          if (this.profileRsrcId) {
            store.commit('userStore/profileInfos', res.profileInfos);
          }

          this.$root.$emit('openAlert', this.editSuccess);
        } else {
          this.$root.$emit('openAlert', this.editFail);
        }
      });
    },
    // NOTE: for temporary disapear family tab logic ( when zero family )
    getFamilyAccounts() {
      return this.api.getUserFamilyList({}).then(res => {
        if (res.code == 0) {
          // NOTE: save only confirmed family members at store
          let family = res.familyInfos ?? [];
          let confirmedFamilyMembers = family.filter(
            member => member.approveYn == 'Y',
          );
          store.commit('userStore/familyInfos', confirmedFamilyMembers);
          return family;
        } else {
          this.$router.push('/network');
        }
      });
    },
    ...mapMutations({
      setUserName: 'userStore/userName',
      setZpcd: 'userStore/zpcd',
      setCity: 'userStore/city',
      setStat: 'userStore/stat',
    }),
  },
};
</script>

<style scoped>
.profile_wrap {
  margin-bottom: 10px;
}
.modal_body .bg_edit_wrap {
  position: relative;
  width: 100%;
  height: 13.6rem;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.modal_body .bg_edit_wrap .ic_area.type_camera {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.modal_body .profile_area {
  position: relative;
  display: block;
  cursor: auto;
}

.modal_body .profile_area .profile {
  position: relative;
  display: inline-block;
  width: 11rem !important;
  height: 11rem !important;
  margin-top: -5.5rem !important;
  margin-right: 0.3rem !important;
  margin-bottom: 1.5rem !important;
  border-radius: 50%;
  background-size: cover;
}
.modal_body .profile_area.is_art .profile {
  width: 9.4rem !important;
  height: 9.4rem !important;
}

.modal_body .profile_area .ic_ring {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: -1;
  margin-top: -6.3rem !important;
  margin-left: -5.4rem !important;
}
.modal_body .profile_area .profile .ic_area.type_camera {
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
}

.modal_body .profile_area .ttl {
  display: block;
  margin-top: 1rem;
  font-size: 2.4rem;
  font-weight: 300;
  color: #fff;
}

.modal_body .introduce_wrap {
  margin-top: 3.3rem;
  margin-bottom: 1.3rem;
  text-align: left;
}
.modal_body .introduce_wrap .input_area.textarea textarea {
  min-height: 10rem;
}

.modal_body .introduce_wrap .input_area.text label {
  width: 9.5rem;
  font-size: 1.8rem;
  font-weight: 300;
  color: #fff;
  vertical-align: middle;
}
.modal_body .introduce_wrap .input_area.text .input_box {
  width: calc(100% - 11rem);
  vertical-align: middle;
}

.modal_body .introduce_wrap .input_area.text .input_box input {
  width: 100%;
  padding: 1.1rem 1.4rem;
  background: #2e2e2e;
  font-size: 1.6rem;
  font-weight: 300;
  color: #fff;
}

.modal_body .introduce_wrap .input_area + .input_area {
  margin-top: 1.4rem;
}
.modal_body + .modal_foot {
  margin-top: 30px;
}

.tab_list {
  margin-top: 3rem;
  margin-bottom: 0;
}
.tab_list:before {
  background: #707070;
}
.tab_list .tab_node {
  width: 100%;
}

.tab_list .tab_node .tab_item {
  display: inline-block;
  width: 50%;
  margin-left: 0;
  padding: 1.4rem 0;
  font-size: 1.8rem;
  font-weight: 300;
}

.profile.none {
  display: inline !important;
}
.modal_body .profile_area .ic_ring.none {
  top: -17px !important;
}
.type_camera.none {
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .modal_body .introduce_wrap .input_area.text .input_box {
    width: 100%;
  }
  .modal_body .introduce_wrap .input_area label,
  .modal_body .introduce_wrap .input_area.text label {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .modal_body .introduce_wrap .input_area.textarea textarea {
    height: 6.6rem;
  }
  .modal_body .profile_area .ttl {
    font-size: 1.8rem;
  }
  .modal_body .introduce_wrap {
    margin-top: 1.6rem;
  }
  .modal_body .profile_area .profile {
    width: 10rem !important;
    height: 10rem !important;
  }
  .modal_body .profile_area .ic_ring {
    width: 10rem !important;
    height: 10rem !important;
    margin-top: -6rem !important;
    margin-left: -5rem !important;
  }
  .modal_body .profile_area.is_art .profile {
    width: 9.4rem !important;
    height: 9.4rem !important;
    margin-top: -5.7rem !important;
    border: 0.2rem solid var(--box-bg) !important;
  }
  .modal_body .profile_area.is_art .profile.none {
    border: none !important;
  }
  .ic_area.type_camera {
    width: 3.6rem;
    height: 3.6rem;
    padding: 0.6rem;
  }
  .ic_area.type_camera .ic {
    width: 2.4rem !important;
    height: 2.4rem !important;
  }
  .modal_body .profile_area .profile .ic_area.type_camera {
    right: -1rem;
    bottom: 0;
  }
  .modal_body .introduce_wrap {
    max-height: calc(100vh - 60rem);
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 0 2rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .tab_list {
    padding: 0;
  }
}
</style>
