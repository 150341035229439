<template>
  <IconBase
    icon-name="icQuestionMark"
    :icon-label="iconLabel"
    :width="60"
    :height="60"
    viewBox="0 0 60 60"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <circle cx="30" cy="30" r="24.5" />
    <path
      class="st1"
      d="M34.6 21.6c-.5-.7-1.2-1.3-2-1.7-1.6-.8-3.5-.8-5.1 0-.8.4-1.5 1.1-2 1.8-.6.9-.8 2-.8 3.1v.2h1.2v-.2c0-.9.2-1.7.6-2.5.4-.6.9-1.1 1.5-1.4 1.2-.6 2.7-.6 4 0 .6.3 1.1.8 1.5 1.3.4.6.6 1.3.6 2.1 0 .6-.1 1.2-.3 1.7-.2.5-.5 1-.9 1.4-.4.4-.8.9-1.2 1.3-.4.4-.8.9-1.2 1.4-.4.5-.7 1.1-.9 1.7-.3.7-.4 1.5-.4 2.2v.6h1.2V34c0-.6.1-1.3.3-1.9.2-.5.5-1 .9-1.5s.8-.9 1.2-1.3.8-.9 1.2-1.4c.4-.5.7-1 1-1.6.3-.7.4-1.3.4-2 0-.9-.3-1.9-.8-2.7M31.3 39.2c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5 1.5.7 1.5 1.5"
      stroke-width="0"
      :fill="strokeColor"
    />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcQuestionMark',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Question Mark',
    },
  },
};
</script>

<style scoped></style>
