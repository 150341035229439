<template>
  <IconBase
    icon-name="icRemove"
    :icon-label="iconLabel"
    :width="40"
    :height="40"
    x="0"
    y="0"
    viewBox="0 0 40 40"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path
      class="st2"
      stroke-width="0"
      :fill="strokeColor"
      d="M1063.2 677.2v15.6h13.6v-15.6h-13.6m-1.2-1.2h16v18h-16v-18z"
      transform="translate(-1050 -664)"
    />
    <path
      class="st2"
      stroke-width="0"
      :fill="strokeColor"
      d="M1060 676h20v1.2h-20V676z"
      transform="translate(-1050 -664)"
    />
    <path
      class="st3"
      d="M1066.5 680v10M1070 680v10M1073.5 680v10"
      transform="translate(-1050 -664)"
    />
    <path d="M17.6 9.6h4.8" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcRemove',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Remove',
    },
  },
};
</script>

<style scoped></style>
