<template>
  <IconBase
    icon-name="icExpand"
    :icon-label="iconLabel"
    :width="14"
    :height="12.449"
    viewBox="0 0 14 12.449"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <g data-name="그룹 3597">
      <path d="m2 6.6 5 5 5-5" data-name="패스 3702" />
      <path d="M0 .6h14" data-name="패스 3703" />
      <path d="M7 .6v10.383" data-name="패스 3704" />
    </g>
  </IconBase>
</template>

<script>
import IconBase from '@/components/ic/IconBase';
export default {
  name: 'IcExpand',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#707070',
    },
    iconLabel: {
      type: String,
      default: 'Expand',
    },
  },
};
</script>

<style scoped></style>
