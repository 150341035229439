<template>
  <main id="contents" class="family_page">
    <h1 class="page_ttl is_blind">Family Members</h1>
    <div class="set_inner">
      <div class="guide_ui">
        <div class="img_area">
          <picture>
            <source
              media="(max-width: 600px)"
              type="image/png"
              :srcset="`${require('@/assets/img/img_mob_illust_family_l.png')} 4x, ${require('@/assets/img/img_mob_illust_family_m.png')} 3x, ${require('@/assets/img/img_mob_illust_family.png')}`"
            />
            <img
              :src="require('@/assets/img/img_illust_family.png')"
              alt="img_illust_family"
            />
          </picture>
        </div>
        <h2 class="ttl">Family Members!</h2>
        <p v-if="isApproved === true" class="desc">
          <!-- You have just approved <em class="c_point">username</em>’s request.<br />
          <em class="c_point">username</em> can see and enjoy your collections. -->
          You approved Family Member request.<br />
          Requester can now see the your collection.
        </p>
        <p v-if="isApproved === false" class="desc">
          <!-- You have just denied <em class="c_point">username</em>’s request. -->
          You denied Family Member request.
        </p>
        <p v-if="isApproved === null">please wait...</p>
        <div class="btn_area">
          <router-link to="/">
            <span class="btn w_xl h_l strong">Go Home</span>
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'FamilyMembersView',
  data() {
    return {
      isApproved: null,
    };
  },
  created() {
    // key 로 넘어오면 api 콜 07-26
    const request = {
      confirmYn: this.$route.query.confirmYn,
      key: this.$route.query.key,
    };
    if (this.$route.query.key && this.$route.query.confirmYn) {
      this.api.confirmFamilyRequest(request).then(res => {
        if (res.code === 0) {
          this.isApproved = request.confirmYn === 'y' ? true : false;
        } else {
          this.$router.push('/network');
        }
      });
    } else {
      this.$router.push('/network');
    }
  },
};
</script>

<style scoped>
.set_inner {
  display: flex;
  min-height: calc(var(--min) * 1px);
  justify-content: center;
  align-items: center;
}
</style>
