<template>
  <main id="contents" class="footer_page terms_page">
    <div class="set_inner">
      <div class="page_ttl_area">
        <h1 class="page_ttl">{{ termsTitle }}</h1>
      </div>

      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <section class="footer_info_sec">
          <div class="footer_info_sec_head">
            <div v-html="termsContent"></div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import LoadingSpinner from '@/components/common/LoadingSpinner';
export default {
  name: 'TermsView',
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      termsLastUpdate: '',
      termsContent: '',
      termsType: '',
      termsTitles: {
        terms: 'Terms Of Service',
        privacy: 'Privacy Policy',
      },
      termsInfoTypes: {
        terms: 'TOU_PERSPECTIVE',
        privacy: 'PRV_PERSPECTIVE',
      },
      isLoading: false,
    };
  },
  watch: {
    $route(v) {
      this.getTerms(v.name);
    },
  },
  mounted() {
    this.getTerms(this.$route.name);
  },
  computed: {
    termsTitle() {
      return this.termsTitles[this.termsType];
    },
  },
  methods: {
    async getTerms(type) {
      this.termsType = type;
      this.isLoading = true;
      await this.api.getTerms({}).then(res => {
        this.isLoading = false;
        if (res.code === 0) {
          let termsInfos = res.termsInfos.find(
            v => v.termsType === this.termsInfoTypes[this.termsType],
          );
          this.termsContent = termsInfos?.contentHtml.replaceAll(
            '#0000cd',
            '#fff',
          );
        }
      });
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/footer.css';
.footer_page {
  padding-bottom: 6rem;
}
</style>
