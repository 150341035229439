<template>
  <div class="edit_item type_card">
    <div class="item_head">
      <button @click="goRouterMove">
        <div class="thumb_area" aria-hidden="true">
          <img
            v-if="source.small"
            :src="source.small"
            :width="480"
            :alt="itemData.artworkId ? itemData.artworkId : ''"
          />
          <img
            v-else
            :src="require('@/assets/img/img_illust_nodata.png')"
            alt="img_mob_illust_nodata_l"
          />
        </div>
      </button>
      <!-- PUB : Origin code-->
      <!-- <div v-if="itemData.mimeType === 'video/mp4'" class="ic_area type_video">
        <IcVideo />
      </div> -->
      <!-- PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가 -->
      <!-- WIP : 아이콘 분기-->
      <div class="ic_area type_blockchains">
        <IcHedera v-if="itemData.bcNwTp == '10'" />
        <IcEth v-else />
      </div>
      <button
        v-if="!isOtherMakret"
        class="btn type_ic btn_like"
        type="button"
        :class="{
          is_active: likeStatus,
        }"
        @click="likeThis(itemData)"
      >
        <IcLike
          v-if="!likeStatus"
          :status="!likeStatus"
          icon-color="#fff"
          ref="icLike"
        />
        <IcLike v-else icon-color="#ff521d" ref="icLike" />
      </button>
    </div>
    <div class="item_body">
      <div
        @click="goRouterMove"
        class="link"
        :aria-describedby="itemData.artworkId"
      ></div>
      <div class="basic_info_group">
        <div
          v-if="(userType == 1 || userType == 2) && !isOtherMakret"
          class="profile_info is_art"
          tabindex="0"
          @click="goArtistProfile()"
        >
          <span
            v-if="itemData.artistAvatarInfo && itemData.artistAvatarInfo.length"
            class="profile"
            :style="`background-image: url(${
              itemData.artistAvatarInfo.length > 0
                ? itemData.artistAvatarInfo[0].resUrl
                : ''
            })`"
          ></span>
          <IcProfile v-else class="profile" />
          <IcRing class="ic_ring" />
          <span class="name">{{ itemData.artistName }}</span>
        </div>
        <div v-else class="profile_info is_art" tabindex="0">
          <span
            class="profile"
            :style="`background-image: url(${itemData.otherMaplLogoUrl})`"
          ></span>
          <IcRing class="ic_ring" />
          {{ itemData.otherPlatform }}
        </div>
        <div class="ttl_area" v-if="isOtherMakret">
          <h3 :id="itemData.id" class="ttl">{{ itemData.title }}</h3>
          <p class="edit">&nbsp;</p>
        </div>
        <div class="ttl_area" v-else>
          <h3 :id="itemData.id" class="ttl">{{ itemData.title }}</h3>
          <p class="edit" v-if="viewType === 'profile' || viewType === 'other'">
            <span v-if="itemData.listType === 'M'">
              {{ itemData.editionId }} of
              {{ itemData.totalCount }}
              {{ itemData.totalEdition > 1 ? 'Editions' : 'Edition' }}
            </span>
            <span v-else>
              {{ itemData.totalCount }}
              {{ itemData.totalEdition > 1 ? 'Editions' : 'Edition' }}
            </span>
          </p>
          <p class="edit" v-else>
            {{ itemData.totalEdition }} of
            {{ itemData.totalCount }}
            {{ itemData.totalEdition > 1 ? 'Editions' : 'Edition' }}
          </p>
        </div>

        <div
          class="owner_info_area"
          v-if="userType == 2 && itemData.ownerName && !hideOwner"
        >
          <dl>
            <dt>Owned by</dt>
            <dd class="profile_info is_art">
              <span
                v-if="
                  itemData.ownerAvatarInfo &&
                  itemData.ownerAvatarInfo.length > 0
                "
                class="profile"
                :style="`background-image: url(${itemData.ownerAvatarInfo[0].resUrl})`"
              ></span>
              <IcProfile v-else class="profile" />
              <IcRing class="ic_ring" />
              <b class="name">{{ itemData.ownerName }}</b>
            </dd>
          </dl>
        </div>
      </div>
      <div class="state_info_group has_btn clearFix">
        <div class="state_info">
          <div
            class="price_area"
            v-if="showPrice"
            :class="{ priceOwnMob: isMob }"
          >
            <p class="type">Price</p>
            <p class="price">
              <!-- PUB : 블록체인 금액표기 수정 -->
              <!-- WIP : 금액표기 분기-->
              <b v-if="itemData.bcNwTp == '10'"
                >{{ itemData.price | setNum }} USDC</b
              >
              <b v-else
                >{{ itemData.price }}<span class="unit">Ξ</span
                ><span class="dollars"
                  >({{ itemData.priceToEthUsd | crtpyAbbreviate }})</span
                ></b
              >
            </p>
          </div>
          <div class="price_area" v-else>
            <p class="price chip" v-if="itemData.otherMaplYn == 'Y'">
              Other Market
            </p>
            <p class="price chip" v-else>Not for Sale</p>
          </div>

          <div
            :class="{
              status_chip: isMob,
            }"
            v-if="itemData.listType === 'D'"
          >
            <p class="price chip">Live</p>
          </div>
        </div>

        <div
          class="btn_group"
          v-if="
            (!itemData.otherPlatform || itemData.otherPlatform === '') &&
            itemData.listType === 'M'
          "
        >
          <button
            class="share"
            type="button"
            @click="shareBtn"
            :aria-expanded="isShare ? 'true' : 'false'"
          >
            <IcOutLink />
          </button>

          <div
            v-show="isShare"
            class="layer_area layer_area_copy_action isShare"
            role="dialog"
          >
            <div class="copy_group">
              <div class="link_area">
                <button ref="copybtn" @click="copyAction(urlLink)">
                  <IcLink />Copy Link
                </button>
              </div>
              <div class="contents_area">
                <button @click="openModal('CopyModal', itemData)">
                  <IcCopy />Copy Contents
                </button>
              </div>
              <div class="contents_area">
                <button @click="copyAction(itemData.contractAddress)">
                  <IcCopy />Copy Contract Address
                </button>
              </div>
              <div class="contents_area">
                <button @click="copyAction(itemData.collectibleID)">
                  <IcCopy />Copy Collectable ID
                </button>
              </div>
            </div>
            <button
              class="btn type_ic close_menu"
              aria-label="Close my list menu"
              @click="shareBtnClose"
            >
              <IcCloseS />
            </button>
          </div>

          <!-- <div class="selling_area">
            <button class="type-button" @click="TypeBtn">
              <IcSellingBtn />
            </button>
            <div v-if="isType" class="layer_area type isType" role="dialog">
              <h3>Selling Type</h3>
              <div class="input_area check">
                <div class="input_box">
                  <label>
                    <input
                      :checked="tempCheckPrice"
                      class="item-chk"
                      type="checkbox"
                      @change="onChecked($event, 'price')"
                    />
                    <span>Price</span>
                  </label>
                </div>
                <div class="input_box check">
                  <label>
                    <input
                      :checked="tempCheckNotForSale"
                      class="item-chk"
                      type="checkbox"
                      @change="onChecked($event, 'sale')"
                    />
                    <span>Not for Sale</span>
                  </label>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <ContentsCopy :itemData="itemData" ref="CopyModal" />
  </div>
</template>

<script>
// import IcVideo from '@/components/ic/IcVideo';
import IcLike from '@/components/ic/IcLike';
import IcOutLink from '@/components/ic/IcOutLink';
//import IcSellingBtn from '@/components/ic/IcSellingBtn';
import IcLink from '@/components/ic/IcLink';
import IcCopy from '@/components/ic/IcCopy';
import ContentsCopy from '@/components/mylist/ContentsCopy';
import IcRing from '@/components/ic/IcRing';
import IcProfile from '@/components/ic/IcProfile';
import IcCloseS from '@/components/ic/IcCloseS';
import IcEth from '@/components/ic/IcEth';
import IcHedera from '@/components/ic/IcHedera';
// PUB : 비디오 아이콘 컴포넌트 삭제, 이더리움 및 헤데라 아이콘 컴포넌트 추가

export default {
  name: 'ItemCardEdit',
  components: {
    IcProfile,
    IcRing,
    IcCopy,
    IcLink,
    //IcSellingBtn,
    IcOutLink,
    // IcVideo,
    IcLike,
    IcCloseS,
    IcEth,
    IcHedera,
    ContentsCopy,
    // PUB : 비디오 아이콘 삭제, 이더리움 및 헤데라 아이콘 추가
  },
  data() {
    return {
      source: {},

      likeStatus: false,
      isShare: false,
      isType: false,
      urlLink: '',
    };
  },
  props: {
    itemData: {
      type: Object,
      default: null,
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    userType: {
      type: String,
      default: null,
    },
    mbrNo: {
      type: String,
      default: null,
    },
    viewType: {
      type: String,
      default: '',
    },
    hideOwner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tempCheckNotForSale: {
      get() {
        return this.itemData.salesMethod == 1 ? true : false;
      },
      set(value) {
        return value;
      },
    },
    tempCheckPrice: {
      get() {
        return this.itemData.salesMethod == 2 ? true : false;
      },
      set(value) {
        return value;
      },
    },
    isMine() {
      return this.mbrNo && this.mbrNo == this.itemData.ownerId;
    },
    isMineOwner() {
      //Owned는  Owner by내가 산 거라면 소유자가 나니까 안 나오게 맞다.
      return (
        this.mbrNo &&
        this.mbrNo == this.itemData.ownerId &&
        !this.itemData.createdPathType
      );
    },

    showPrice() {
      //otherMarket 예외처리
      if (this.isOtherMakret) {
        return false;
      }

      return (
        this.itemData.salesMethod !== 1 && this.itemData.salesMethod !== 'none'
      );
    },
    isMob() {
      return this.$isMobile();
    },
    isOtherMakret() {
      return this.itemData.bcNwTp === '20' && this.itemData.otherMaplYn == 'Y'
        ? true
        : false;
    },
  },
  mounted() {
    this.urlLink = `${window.location.protocol}//${window.location.host}/marketplace/detail?artworkId=${this.itemData.artworkId}&editionId=${this.itemData.editionId}`;

    if (this.itemData.favorites === 'Y') {
      this.likeStatus = true;
    }
    this.source = this.setThumbnailImg(this.itemData);

    this.$EventBus.$on('isShare', boolean => {
      if (!boolean) this.isShare = boolean;
    });
    this.$EventBus.$on('isType', boolean => {
      if (!boolean) this.isType = boolean;
    });
  },
  methods: {
    goArtistProfile() {
      this.$router.push({
        name: 'ProfileArtist',
        params: {
          mbrNo: this.itemData.artistId,
        },
      });
    },
    goRouterMove() {
      if (this.isOtherMakret) {
        this.$router.push({
          name: 'myCollection',
        });
      }

      if (!this.itemData.listType) return false;

      if (this.itemData.listType === 'D') {
        this.$router.push({
          name: 'dropsDetail',
          query: {
            dropId: this.itemData.dropsId,
            artworkId: this.itemData.artworkId,
          },
        });
      } else {
        this.$router.push({
          name: 'marketplaceDetail',
          query: {
            artworkId: this.itemData.artworkId,
            editionId: this.itemData.editionId,
          },
        });
      }
    },
    likeThis(item) {
      if (!this.$refs.icLike.loginCheck()) {
        return;
      } else {
        let favoritesYn = this.likeStatus === true ? 'N' : 'Y';

        if (item.editionId === 0) {
          let params = {
            artworkId: item.artworkId,
            favoritesYn,
          };

          this.$refs.icLike.likeArtwork(params);
        } else {
          let params = {
            artworkId: item.artworkId,
            editionId: item.editionId,
            favoritesYn,
          };
          this.$refs.icLike.likeEdition(params);
        }

        this.likeStatus = !this.likeStatus;
      }
    },
    shareBtn() {
      this.$EventBus.$emit('isShare', false);
      this.isShare = !this.isShare;
      if (this.isShare) {
        setTimeout(() => {
          this.$refs.copybtn.focus();
        }, 500);
        this.isType = false;
        this.$EventBus.$emit('isShare', this.isShare);
      }
    },
    shareBtnClose() {
      this.isShare = false;
      this.$EventBus.$emit('isShare', this.isShare);
      this.$emit('onSetCloseFocusCard', this.itemIndex);
    },
    TypeBtn() {
      this.$EventBus.$emit('isType', false);
      this.isType = !this.isType;
      if (this.isType) {
        this.isShare = false;
        this.$EventBus.$emit('isType', this.isType);
      }
    },
    openModal(ref, item) {
      this.$refs[ref].open(item);
    },
    //Change Selling 비활성화 미 사용 23-04-18
    onChecked(event, v) {
      let key = v === 'price' ? 'tempCheckPrice' : 'tempCheckNotForSale';
      let checked = this[key] ? true : false;

      this[key] = checked;
      event.target.checked = checked;

      //price -> price는 기술적으로 미제공 불가
      if (this.tempCheckPrice && v === 'price') return false;
      //not for sale 에서 not for sale 불가
      if (this.tempCheckNotForSale && v === 'sale') return false;

      if (this.$isMobile()) {
        let cardInfo = {
          artworkId: this.itemData.artworkId,
          editionId: this.itemData.editionId,
          serial: this.itemData.collectibleID,
        };
        v === 'price'
          ? this.$emit('open', cardInfo)
          : this.$emit('onAppChangeSelling', cardInfo);
      } else {
        this.$emit('open');
      }
    },
    copyAction(stringData) {
      const textarea = document.createElement('textarea');
      textarea.textContent = stringData;
      document.body.append(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();
      this.$toast.open('Saved to clipboard');
    },
  },
};
</script>

<style scoped>
.item_head,
.item_body {
  cursor: pointer;
}
.state_info {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.status_chip {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.state_info_group {
  position: relative;
  padding-top: 11px;
  margin-top: 15px;
  border-top: 1px solid var(--line-color);
}
.state_info_group .state_info {
  float: none;
  margin-top: 0;
  padding-top: 0;
  border: 0 none;
}
.state_info_group .btn_group {
  position: absolute;
  z-index: 11;
  top: 50%;
  right: -0.5rem;
  margin-top: -1.5rem;
  padding-top: 0.7rem;
}

.state_info_group .btn_group .share_area,
.state_info_group .btn_group .selling_area {
  display: inline-block;
}
.state_info_group .btn_group .selling_area {
  margin-left: 0.6rem;
}

.state_info_group .btn_group .share_area > button,
.state_info_group .btn_group .selling_area > button {
  width: 3rem;
  height: 3rem;
}

.state_info_group .btn_group button i {
  vertical-align: middle;
}
.layer_area {
  right: 0px !important;
}
.layer_area_copy_action {
  width: 26rem;
}
.artist_page .basic_info_group {
  min-height: 9rem;
}
.priceOwnMob {
  display: flex;
  align-items: flex-start !important;
  flex-direction: column;
  justify-content: space-between;
}
.isShare > .btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 100;
}
@media screen and (max-width: 600px) {
  .priceOwnMob {
    flex-direction: unset;
    align-items: flex-end !important;
  }
}
</style>
