<template>
  <div class="modal_wrap" :class="{ is_show: showEthModal }">
    <div
      id="setPrice"
      ref="setPrice"
      class="modal ethModal"
      :class="modalSize"
      role="dialog"
      aria-labelledby="setPrice_title"
      aria-describedby="setPrice_desc"
    >
      <div class="modal_head">
        <h3 class="modal_ttl">Set A Price</h3>
        <p>Set a price for the artwork.</p>
        <p>(A 10% royalty payment will be applied)</p>
      </div>
      <div class="modal_body">
        <div v-if="isLoading">
          <LoadingSpinner />
        </div>
        <div class="input_area txt" v-else>
          <div class="input_box">
            <!-- inputmode="numeric" -->
            <input
              v-model="setPrice"
              @input="checkInput"
              @paste.prevent
              type="number"
              title="Price"
              placeholder="0.001"
              label="set Price"
              refs="ethInput"
            />
            <span>ETH</span>
          </div>
        </div>
      </div>
      <div class="modal_foot">
        <div class="btn_area grid flex">
          <button class="btn col basic w_s h_m" @click="close">Cancel</button>
          <button
            class="btn col strong w_s h_m"
            @click="onAppSetPrice"
            v-if="!isLoading"
          >
            OK
          </button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { mapGetters } from 'vuex';
export default {
  name: 'SetPriceModal',
  components: { IcCloseM, LoadingSpinner },
  props: {
    modalId: {
      type: String,
      default: 'modal',
    },
    modalSize: {
      type: String,
      default: '',
    },
    artworkId: {
      type: String,
      default: '',
    },
    editionId: {
      type: Number,
      default: 0,
    },
    showEthModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      setPrice: null,
      apiFailAlert: {
        type: 'warn',
        desc: "We're sorry, something went wrong. Transaction failed. Something is wrong with your wallet. Please try again later.",
      },
      polling: null,
      overtimer: 180000,
      signAddr: '',
      priceSettingTp: '',
      isLoading: false,
    };
  },
  watch: {
    showEthModal(v) {
      if (v === true) {
        this.$root.$emit('setScrollFixed', true);
        this.setFocusTrap(true, '.modal.ethModal');
      } else {
        this.$root.$emit('setScrollFixed', false);
        this.setFocusTrap(false, '.modal.ethModal');
      }
    },
  },
  computed: {
    ...mapGetters({
      wlltType: 'userStore/wlltType',
      getWeb3ModalStatus: 'web3Store/getWeb3ModalStatus',
    }),
  },
  methods: {
    async onAppSetPrice() {
      if (!this.checkPrice()) return false;
      try {
        //주소가 없으면 지갑 연결
        if (!this.getWeb3ModalStatus.address) {
          this.W3M.open();
          return false;
        }

        let signParams = {
          artworkId: this.artworkId,
          bcNwTp: '20',
          editionId: this.editionId,
          price: this.setPrice,
          priceSettingTp: '30',
        };

        this.isLoading = true;

        let requiredTransactions;
        let signResult = await this.api.getMarketEthPriceSetting(signParams);

        if (signResult.code === 0) {
          requiredTransactions =
            signResult.ordersResponse.data.requiredTransactions;
        } else {
          let errorMsg = {
            type: 'warn',
            desc: signResult.message,
          };
          this.$root.$emit('openAlert', errorMsg);

          this.close();

          return null;
        }

        let transactionId = signResult.ordersResponse.transactionId;

        if (requiredTransactions.length !== 0) {
          let requiredTransactionResult = await this.W3F.sendTransaction(
            requiredTransactions[0],
          );

          let transactionHash = requiredTransactionResult;
          // this.getWeb3ModalStatus.providerType == 'walletConnect'
          //   ? requiredTransactionResult
          //   : requiredTransactionResult.hash;

          let params = {
            signAddr: signResult.signAddr,
            transactionHash,
            transactionId,
          };

          let txResult = await this.setCallbackRegister(params);
          console.log('set callback register error', txResult);
          if (txResult.code == 0) {
            console.log(txResult);
            this.polling = setInterval(() => {
              this.ethPermissionCallback(signResult);
              if (this.overtimer <= 0) {
                this.isLoading = false;
                clearInterval(this.polling);
              }
              this.overtimer -= 3000;
            }, 3000);
          } else {
            this.isLoading = false;
            let errorMsg = {
              type: 'warn',
              desc: txResult.message,
            };
            this.$root.$emit('openAlert', errorMsg);
          }
        } else {
          await this.sign(signResult);
        }
      } catch (e) {
        console.log(e);
        let errorMsg = {
          type: 'warn',
          desc: e.info?.error?.message ?? e.message ?? 'MetaMask Error',
        };
        this.$root.$emit('openAlert', errorMsg);
        this.close();
      }
    },
    async setCallbackRegister(params) {
      let res = await this.api.getMarketEthStatusRegister(params);
      console.log('setCallbackRegister proc : @@@@@@@@', res);
      return res;
    },

    async ethPermissionCallback(signResult) {
      await this.api
        .getMarketEthPermissionCallback({ signAddr: signResult.signAddr })
        .then(async res => {
          if (res.code == 0) {
            try {
              if (res.permissionCmpltYn === 'Y') {
                clearInterval(this.polling);
                await this.sign(signResult);
              } else if (res.permissionCmpltYn === 'N') {
                this.isLoading = false;
                clearInterval(this.polling);
              }
            } catch (e) {
              let errorMsg = {
                type: 'warn',
                desc: e.info?.error?.message ?? e.message ?? 'MetaMask Error',
              };
              this.$root.$emit('openAlert', errorMsg);
              this.close();
            }
          } else {
            let errorMsg = {
              type: 'warn',
              desc: res.message,
            };
            this.$root.$emit('openAlert', errorMsg);
            this.close();
          }
        });
    },
    async sign(signResult) {
      let typedDataToSign = signResult.ordersResponse.data.typedDataToSign;

      let typeData = {
        domain: typedDataToSign?.domain,
        message: typedDataToSign?.orderComponent,
      };

      let signTypeDataResult = await this.W3F.signTypedData(
        typeData,
        'marketOrder',
      );

      let orderListingResult = await this.api.getMarketEthListing({
        signAddr: signResult.signAddr,
        signature: signTypeDataResult,
      });

      let listingMsg;
      if (orderListingResult.code == 0) {
        listingMsg = {
          type: 'success',
          desc: 'The change was successful. Updating information,\nplease wait.',
        };
      } else {
        listingMsg = {
          type: 'warn',
          desc: orderListingResult.message,
        };
      }
      this.$root.$emit('openAlert', listingMsg);

      this.isLoading = false;
      clearInterval(this.polling);
      this.close();
      console.log('order listing result', orderListingResult);
    },

    checkInput(e) {
      let value = e.target.value;
      if (!value || typeof value != 'string') {
        value = value.toString();
      }
      value = value.replace(/[^0-9.]/g, '').trim();

      if (value.startsWith('0') && value.length == 2 && value[1] != '.') {
        value = value.slice(1);
      }

      let splited = value.split('.', 2);

      if (splited.length == 2) {
        let beforeDecimalPoint = splited[0];
        let afterDecimalPoint = splited[1];

        if (!beforeDecimalPoint) {
          beforeDecimalPoint = '0';
        }

        afterDecimalPoint = afterDecimalPoint
          .replace(/[^0-9]/g, '')
          .slice(0, 3);

        value = beforeDecimalPoint + '.' + afterDecimalPoint;
      }
      this.setPrice = value;
    },
    checkPrice() {
      let pattern = /(^\d+$)|(^\d{1,}.\d{0,3}$)/;

      if (pattern.test(this.setPrice)) {
        if (this.setPrice > 9999.999) {
          this.setPrice = '';
          let errorMsg = {
            type: 'warn',
            desc: 'The maximum input is 9999.999',
            modalClass: '.modal.ethModal',
            modalIndex: '0',
          };
          this.$root.$emit('openAlert', errorMsg);
          return false;
        }
      }
      if (this.setPrice == 0) {
        let errorMsg = {
          type: 'warn',
          desc: 'The minimum input value must be greater than zero.',
          modalClass: '.modal.ethModal',
          modalIndex: '0',
        };
        this.$root.$emit('openAlert', errorMsg);
        return false;
      }
      return true;
    },
    close() {
      this.clearAllInterval();
      this.setPrice = null;
      this.signAddr = '';
      this.priceSettingTp = '';
      this.isLoading = false;
      this.polling = null;
      this.$root.$emit('setScrollFixed', false);
      this.$emit('close', 'showEthModal');
    },
    clearAllInterval() {
      let id = window.setInterval(function () {}, 0);
      while (id--) {
        window.clearInterval(id);
      }
    },
  },
  beforeDestroy() {
    this.$root.$emit('setScrollFixed', false);
    this.isLoading = false;
    this.clearAllInterval();
  },
};
</script>

<style scoped>
.modal_wrap {
  overflow: hidden;
}
.modal .modal_body + .modal_foot {
  margin-top: 34px;
}
.input_box {
  width: 100%;
}
</style>
