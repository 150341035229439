import { dropsStoreActions } from './dropsStoreActions';
export const dropsStore = {
  namespaced: true,
  state: {
    liveInfos: [], // liveInfos
    upcomingInfos: [], // upcomingInfos
    endedInfos: [], // endedInfos
    dropId: '', // drop id
    artworkId: '', // artwork id
  },
  getters: {
    liveInfos: state => {
      return state.liveInfos;
    },
    upcomingInfos: state => {
      return state.upcomingInfos;
    },
    endedInfos: state => {
      return state.endedInfos;
    },
    dropId: state => {
      return state.dropId;
    },
    artworkId: state => {
      return state.artworkId;
    },
  },
  mutations: {
    liveInfos: (state, liveInfos) => {
      state.liveInfos = liveInfos;
    },
    upcomingInfos: (state, upcomingInfos) => {
      state.upcomingInfos = upcomingInfos;
    },
    endedInfos: (state, endedInfos) => {
      state.endedInfos = endedInfos;
    },
    dropId: (state, dropId) => {
      state.dropId = dropId;
    },
    artworkId: (state, artworkId) => {
      state.artworkId = artworkId;
    },
  },
  actions: dropsStoreActions,
};
