<template>
  <main id="contents" class="footer_page notice_page">
    <div class="set_inner">
      <div class="page_ttl_area">
        <h1 class="page_ttl">Notice Board</h1>
      </div>
      <section class="board_sec">
        <ul>
          <li v-for="item in noticeInfos" :key="item.num">
            <div class="board_item">
              <router-link
                :aria-describedby="'board' + item.num"
                :to="{ name: 'noticeDetail', params: { item: item } }"
              >
                <div class="item_head">
                  <span>{{ item.num }}</span>
                  <span>{{ item.writeDate | GmtToTz | UsaFormat }}</span>
                </div>
                <div class="item_body">
                  <div
                    :id="'board' + item.num"
                    role="text"
                    class="item_info_area"
                  >
                    <p class="ttl">{{ item.title }}</p>
                    <p class="desc">
                      {{ item.contentSummary }}
                    </p>
                  </div>
                </div>
                <div class="btn_area only_pc">
                  <button>
                    <ArwNext />
                  </button>
                </div>
              </router-link>
            </div>
          </li>
        </ul>
        <!-- NOTE:Original Code ( for history ) -->
        <!-- <div class="pagination_wrap">
          <div class="pagination">
            <div class="btn_num_group">
              <button class="btn_num first" @click="first">
                <ArwPageFirst />
              </button>
              <button class="btn_num prev" @click="prev">
                <ArwPagePrev />
              </button>
              <button
                v-for="item in maxViewCount + remainder"
                :key="item"
                @click="changePage(item)"
              >
                <div
                  class="btn_num list"
                  v-if="item <= pageCount && item > remainder"
                  :class="{ is_active: item === currentPage }"
                >
                  {{ item }}
                </div>
              </button>
              <button
                v-if="maxViewCount + remainder < pageCount"
                class="btn_num list"
              >
                ...
              </button>
              <button class="btn_num next" @click="next">
                <ArwPageNext />
              </button>
              <button class="btn_num last" @click="last">
                <ArwPageLast />
              </button>
            </div>
          </div>
        </div> -->
        <Pagination
          v-model="currentPage"
          :totalCount="totalCount"
          :paginationRange="maxViewCount"
          pageSize="10"
        ></Pagination>
      </section>
    </div>
  </main>
</template>

<script>
import ArwNext from '@/components/ic/arwNext';
import Pagination from '@/components/common/Pagination';

export default {
  name: 'NoticeBoardView',
  components: { ArwNext, Pagination },
  data() {
    return {
      noticeInfos: [],
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      rows: 10,
      maxViewCount: 5, // 최대 페이지 표시 개수
      remainder: 0,
    };
  },
  watch: {
    async currentPage() {
      await this.getNoticeList();
    },
  },
  methods: {
    getNoticeList() {
      const data = {
        page: this.currentPage,
      };
      this.api.getNoticeList(data).then(res => {
        if (res.code === 0) {
          this.noticeInfos = res.noticeInfos;
          this.totalCount = res.totalCnt;
          this.pageCount = Math.ceil(this.totalCount / this.rows);
        } else {
          this.$router.push('/network');
        }
      });
    },
  },
  async mounted() {
    await this.getNoticeList();
  },
};
</script>

<style scoped></style>
