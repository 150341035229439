<template>
  <main id="contents" class="main_page">
    <h1 class="page_ttl is_blind">Main</h1>
    <section class="main_visual_sec">
      <!--  bannerData : LiveDrops + Upcoming drops + Banner Infos)-->
      <BannerSlide
        v-if="bannerData.length > 0"
        :slide-opt="mainVisualOpt"
        :itemData="bannerData"
        add-class="mv_banner_list"
        class="main_visual_wrap"
      />
    </section>
    <div class="set_inner">
      <section class="list_sec drops_sec" v-if="liveInfos && liveInfos.length">
        <header class="sec_head" v-if="openService">
          <div class="ttl_area">
            <h2 class="ttl"><b>Live</b> Drops</h2>
          </div>
        </header>
        <div class="sec_body clearFix">
          <div class="info_wrap">
            <div class="profile_info">
              <span class="name">{{ liveInfos[0].artistName }}</span>
            </div>
            <div class="ttl_area">
              <h3 class="ttl">{{ liveInfos[0].title }}</h3>
              <p class="desc">{{ liveInfos[0].artworkDescription }}</p>
            </div>
            <div class="btn_area" v-if="openService">
              <button class="btn w_s h_l strong" @click="goCuration">
                View More
              </button>
            </div>
          </div>

          <EditionImgSlide
            :item-data="liveDropsThumbs"
            :slide-opt="liveDropSlidOpt"
          />
        </div>
      </section>
      <section class="list_sec activity_sec">
        <header class="sec_head">
          <div class="ttl_area">
            <h2 class="ttl"><b>Recent</b> Activities</h2>
          </div>
          <router-link to="marketplace" class="btn h_m type_line float_el"
            ><b>More</b></router-link
          >
        </header>
        <div class="sec_body">
          <div class="market_item_wrap activity_item_wrap">
            <ul class="grid grid_15 edit_list">
              <template v-for="(item, i) in recentActivityItems">
                <li v-if="i < 5" :key="item.id" class="col edit_node">
                  <MarketItem
                    :item-data="item"
                    :item-index="i"
                    :show-badge="false"
                  />
                </li>
              </template>
            </ul>
          </div>
        </div>
      </section>
      <section class="list_sec market_sec">
        <header class="sec_head">
          <div class="ttl_area">
            <h2 class="ttl"><b>Trending</b> in Marketplace</h2>
          </div>
          <button
            @click="goMarketplace"
            to="marketplace"
            class="btn h_m type_line float_el"
          >
            <b>More</b>
          </button>
        </header>
        <div class="sec_body">
          <div class="market_item_wrap">
            <ul class="grid grid_15 ib edit_list">
              <template v-for="(item, i) in hotInMarketplaceItems">
                <li v-if="i < 4" :key="item.id" class="col col_3 edit_node">
                  <MarketItem
                    :item-data="item"
                    :item-index="i"
                    :show-badge="false"
                  />
                </li>
              </template>
            </ul>
          </div>
        </div>
      </section>
    </div>

    <div class="list_sec keyword_sec noBottomM">
      <div class="set_inner">
        <header class="sec_head">
          <div class="ttl_area">
            <h2 class="ttl"><b>Most</b> Searched Keywords</h2>
          </div>
        </header>
        <div class="sec_body">
          <ul class="tag_list type_big clearFix">
            <li
              v-for="(tag, i) in mosKeyword"
              :key="'tag_' + i"
              class="tag_node"
            >
              <button class="tag_item" @click="goTagSearch(tag.keywordName)">
                <span>{{ tag.keywordName }}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BannerSlide from '@/components/common/BannerSlide';
import MarketItem from '@/components/marketplace/MarketItem';
import EditionImgSlide from '@/components/common/EditionImgSlide';
export default {
  name: 'HomeView',
  components: {
    EditionImgSlide,
    MarketItem,
    BannerSlide,
  },
  data() {
    return {
      bannerInfos: [],
      liveInfos: [],
      upcomingInfos: [],
      endedInfos: [],
      bannerData: [],
      homeKeywordInfos: [],
      hotInMarketplaceItems: [],
      recentActivityItems: [],
      liveDropsThumbs: [],
      profileImage: {
        resUrl: '',
      },
      mainVisualOpt: {
        loop: false, // 배너의 수가 2개 이상일때 true
        autoplay: { delay: 5000, disableOnInteraction: false },
        pagination: {
          el: '.banner_wrap .pagination_area',
        },
        navigation: {
          prevEl: '.banner_wrap .slide_nav_area .prev',
          nextEl: '.banner_wrap .slide_nav_area .next',
        },
      },
      liveDropSlidOpt: {
        loop: false,
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 30,
        pagination: {
          el: '.edition_slide_wrap .pagination_area',
        },
        navigation: {
          prevEl: '.edition_slide_wrap .slide_nav_area .prev',
          nextEl: '.edition_slide_wrap .slide_nav_area .next',
        },
        breakpoints: {
          1080: {
            slidesPerView: 2,
            slidesPerGroup: 1,
          },
          280: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
        },
      },
      upcomingForBanner: [],
      mosKeyword: [],
    };
  },
  computed: {
    openService() {
      return JSON.parse(process.env.VUE_APP_OPEN_SERVICE) ? true : false;
    },
  },
  mounted() {
    if (!this.openService) {
      this.$root.$emit('noticeModal');
    }

    this.api.getLiveDrop({}).then(res => {
      if (res.code === 0) {
        this.liveInfos = res.liveInfos;
        if (this.liveInfos && this.liveInfos.length) {
          if (this.liveInfos?.[0].artistAvatarInfos?.length > 0) {
            this.profileImage =
              this.liveInfos?.[0].artistAvatarInfos?.[0].resUrl;
          } else {
            this.profileImage = undefined;
          }
          let liveInfos = this.liveInfos[0];
          let resultViewInfos = [];

          if (liveInfos.artworkImages && liveInfos.artworkImages.length) {
            //let resultRsrcTp = liveInfos.mimeType === 'image/jpeg' ? '101' : '111';
            resultViewInfos = liveInfos.artworkImages.filter(
              artwork => artwork.rsrcTp === '111',
            );
          }

          resultViewInfos.map(x => {
            x.title = liveInfos.title;
            this.liveDropsThumbs.push(x);
          });
        }
        //메인 배너 슬라이드용 liveForBanner, upcomingForBanner 생성, type추가하여 전달
        this.liveForBanner = [...res.liveInfos];
        this.liveForBanner.map(x => {
          x.type = 'live';
        });

        this.upcomingForBanner = [...res.upcomingInfos];

        this.upcomingForBanner.map(x => {
          x.type = 'upcoming';
        });

        //NOTE: EndedForBanner
        if (
          this.bannerInfos.length < 1 &&
          this.liveForBanner.length < 1 &&
          this.upcomingForBanner.length < 1
        ) {
          this.endedForBanner = [...res.endedInfos];
          this.endedForBanner.map(x => {
            x.type = 'ended';
          });
        } else {
          this.endedForBanner = [];
          this.endedForBanner.map(x => {
            x.type = 'ended';
          });
        }

        // NOTE: leave for history
        // if (res.bannerInfos && res.bannerInfos.length) {
        //   if (this.$isMobile()) {
        //     res.bannerInfos[0].pcRsrcInfo[0].resUrl =
        //       'https://artnft.lgartlab.com/faq/attach/09e548896c0742b4b1a26bc66576691b.jpg';
        //   }
        // }

        this.bannerInfos = [...res.bannerInfos]
          .map(bannerInfo => {
            bannerInfo.type = 'banner';
            if (bannerInfo?.targetLink == 'Tutorial') {
              bannerInfo.desc = 'A step-by-step guide on using LG Art Lab';
            }

            bannerInfo.resourceInfo = this.$isMobile()
              ? bannerInfo.mobileRsrcInfo?.pop()
              : bannerInfo.pcRsrcInfo?.pop();

            return bannerInfo;
          })
          .filter(bannerInfo => !!bannerInfo.resourceInfo?.rsrcId);

        this.homeKeywordInfos = res.homeKeywordInfos;
        this.hotInMarketplaceItems = res.hotInMarketplaceItems;
        this.recentActivityItems = res.recentActivityItems;
        this.bannerData = [
          ...this.bannerInfos,
          ...this.liveForBanner,
          ...this.upcomingForBanner,
          ...this.endedForBanner,
        ];
      }

      this.api.getMostKeywords({}).then(res => {
        if (res.code === 0) {
          this.mosKeyword = res.homeKeywordInfos;
        }
      });
    });
  },
  methods: {
    goTagSearch(tag) {
      this.$router.push({
        name: 'search',
        params: { keyword: tag },
      });
    },
    goCuration() {
      this.$router.push({
        name: 'drops-curation',
        query: {
          dropsId: this.liveInfos[0].dropsId,
          artworkId: this.liveInfos[0].artworkId,
        },
      });
    },
    goMarketplace() {
      this.$router.push({
        name: 'marketplace',
        params: { sortType: 'popular' },
      });
    },
  },
};
</script>
<style scoped>
@import url(../assets/css/main.css);
@import url(../assets/css/customGlobal.css);
</style>
