import { openDB } from 'idb';
import { ApiService } from  '../service/apiservice';
const api = new ApiService();

export const web3Store = {
  namespaced: true,
  state: {
    account: '',
    provider: '',
    topic: "",
    chains: '',
    accounts: '',
    _state: {
      web3Modal: {
          isInitialized: false,
          loading: {
              isLoading: false,
              isSendTransactionOnLoading: false,
          },
          currentSession: {},
      },
      signClient: {
          isInitialized: false,
          loading: {
              isLoading: false,
              isSendTransactionOnLoading: false,
          },
          currentSession: {},
      }
  },
  sessionClient: {},
  web3ModalStatus: {},    
  },
  getters: {
    account: state => {
      return state.account;
    },
    provider: state => {
      return state.provider;
    },
    topic: state => {
      return state.topic;
    },
    chains: state => {
      return state.chains;
    },
    accounts: state => {
      return state.accounts;
    },
    isInitialized: state => objectName => {
      return state._state[objectName]?.isInitialized;
    },
    getWeb3State: state => objectName => {
        return state._state[objectName];
    },
    getWeb3ModalStatus: state => {
        return state.web3ModalStatus;
    },
    getWeb3ModalSession: state => {
        return state._state.web3Modal.currentSession;
    },
    getSignClientSession: state => {
        return state._state.signClient.currentSession;
    },    
  },
  mutations: {
    account: (state, account) => {
      state.account = account;
    },
    provider: (state, provider) => {
      state.provider = provider;
    },
    SET_TOPIC(state, topic) {
      state.topic = topic;
    },
    SET_CHAINS(state, chains) {
      state.chains = chains;
    },
    SET_ACCOUNTS(state, accounts) {
      state.accounts = accounts;
    },
    setSignClient(state, client) {
      state.signClient = client;
  },
    setWeb3State:(state, {objectName, stateName, currentState})=> {
        if( !objectName || !stateName ) {
            return null;
        }
        state._state[objectName][stateName] = currentState;
    },
    setLoading:(state, {objectName, stateName, currentState})=> {
        if( !objectName || !stateName ) {
            return null;
        }
        state._state[objectName].loading[stateName] = currentState;
    },
    setWeb3ModalStatus: (state, web3ModalStatus) => {
        state.web3ModalStatus = web3ModalStatus
    },
    setWeb3ModalSession: (state, web3ModalSession) => {
        state._state.web3Modal.currentSession = web3ModalSession
    },
    setSignClientSession(state, signClientSession) {
        state._state.signClient.currentSession = signClientSession
    },    
  },
  actions : {
    async connectETH({getters, rootGetters}) {
      let walletParams = {
        bcNwTp: '20',
        walletAddr: getters.getWeb3ModalStatus.address,
        wlltType: 'WLT003',
      };
      // if(rootGetters['userStore/ethWallet'].walletYn == 'N') {
        console.log('=====ETH Wallet Connect API Call====');
        if(rootGetters['appStore/accessToken']) {
          await api.connectWallet(walletParams);
        }
      // }
    },
    async extractDataFromIndexedDB({ commit }) {
      try {
        // IndexedDB에서 데이터 가져오기
        const db = await openDB('WALLET_CONNECT_V2_INDEXED_DB', 1);
        const tx = db.transaction('keyvaluestorage', 'readonly');
        const data = await tx.objectStore('keyvaluestorage').get("wc@2:client:0.3:session");
        const parseData = JSON.parse(data);
        const firstEl = parseData[0];

        let fullAccount = firstEl.namespaces.eip155.accounts[0];
        let account = fullAccount.split(":").pop();


        console.log("Topic:", firstEl.topic);
        console.log("Account:", account);
        console.log("ChainID:", firstEl.namespaces.eip155.chains[0]);

        commit('SET_TOPIC', firstEl.topic);
        commit('SET_ACCOUNTS', account);        
        commit('SET_CHAINS',firstEl.namespaces.eip155.chains[0]);
    
      } catch (error) {
        console.error('Error extracting data from IndexedDB:', error);
      }
    }
  },
}
