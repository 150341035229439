<template>
  <div class="user_item type_card">
    <button @click="goArtistProfile(itemData.mbrNo)">
      <div class="item_head">
        <div class="thumb_area" aria-hidden="true" tabindex="-1">
          <img
            v-if="itemData.repInfo && itemData.repInfo.length"
            :src="itemData.repInfo[0].resUrl"
            :width="337"
            :height="189"
            alt="artist bacgkround image"
          />
          <img
            v-else
            :src="`${require('/src/assets/img/img_mob_illust_nodata.png')}`"
            :width="337"
            :height="189"
            alt="nodata"
          />
        </div>
      </div>
      <div class="item_body" :class="{ is_pc: !$isMobile() }">
        <div class="basic_info_group">
          <div class="profile_info is_art" tabindex="0">
            <div
              v-if="itemData.avatarInfo && itemData.avatarInfo.length"
              class="profile"
              :style="`background-image: url(${itemData.avatarInfo[0].resUrl})`"
            >
              <span data-v-3015e6ff="" class="is_blind"
                >Artist profile picture</span
              >
            </div>
            <IcProfile
              v-else
              :width="110"
              :height="110"
              :stroke-width="0.8"
              class="profile"
              :iconLabel="'No artist profile'"
            />
            <IcRing
              class="ic_ring"
              :width="110"
              :height="110"
              :small-ring="14.8"
            />
          </div>
          <div class="ttl_area">
            <h3 class="ttl">
              <b>{{ itemData.userName }}</b>
            </h3>
            <p class="desc">{{ itemData.about }}</p>
          </div>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
import IcRing from '@/components/ic/IcRing';
export default {
  name: 'ArtistItem',
  components: { IcRing, IcProfile },
  props: {
    itemData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    goArtistProfile(mbrNo) {
      this.$router.push({
        name: 'ProfileArtist',
        params: {
          mbrNo: mbrNo,
        },
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.artist_list .user_item .item_body.is_pc {
  height: 190px;
}
</style>
