<template>
  <header id="header">
    <article class="gnb clearFix">
      <div
        class="logo_wrap"
        @click="(showMobMenu = false), (showSearch = false)"
      >
        <router-link to="/" class="logo_area">
          <h1>
            <Logo />
          </h1>
        </router-link>
      </div>
      <template v-if="!$parent.isMob && openService">
        <nav class="menu_wrap main_menu_wrap">
          <ul class="menu_list">
            <li
              v-for="(item, i) in mainMenuItems"
              :key="'main_menu' + i"
              class="menu_node"
              @click="pageMove(item.link)"
            >
              <router-link :to="item.link" class="menu_item"
                ><span>{{ item.name }}</span>
              </router-link>
            </li>
          </ul>
        </nav>
        <div class="menu_wrap sub_menu_wrap">
          <ul class="menu_list">
            <li class="menu_node">
              <button
                class="menu_item"
                ref="searchBtn"
                @click="showDropMenu('showSearch')"
              >
                <IcSearch />
              </button>
            </li>
            <li v-if="!accessToken" class="menu_node">
              <a
                ref="signIn"
                href="javascript:;"
                class="menu_item type_line"
                title="Open in new Sign in popup"
                @click="openPortal"
              >
                <span>Sign In</span>
              </a>
            </li>
            <template v-else>
              <li class="menu_node">
                <button
                  ref="showAlarm"
                  class="menu_item type_ic btn_alarm"
                  :class="{ is_active: redDotYn === 'Y' }"
                  @click="showDropMenu('showAlarm')"
                >
                  <IcAlarm />
                  <span class="is_blind">Alarm</span>
                </button>
                <div
                  v-if="showAlarm"
                  class="dep2_menu_wrap alarm_menu_wrap notification-wrapper"
                >
                  <div class="ttl_area">
                    <h4 class="ttl c_point">Hello, {{ userName }}!</h4>
                  </div>
                  <div class="has_scroll_y">
                    <ul
                      class="alarm_list"
                      v-if="notificationInfos && notificationInfos.length > 0"
                    >
                      <li
                        v-for="(item, i) in notificationInfos"
                        :key="'main_menu' + i"
                        class="alarm_node"
                      >
                        <div class="alarm_item">
                          <p class="cate">{{ item.title }}</p>
                          <h4 class="msg">
                            <b>{{ item.content }}.</b>
                          </h4>
                          <span class="date">{{
                            item.regDatetime | GmtToTz | UsaFormat
                          }}</span>
                        </div>
                      </li>
                    </ul>
                    <ul
                      v-if="notificationInfos.length === 0 && isLoading"
                      class="alarm_list"
                    >
                      <LoadingSpinner />
                    </ul>
                    <ul
                      v-if="notificationInfos.length === 0 && !isLoading"
                      class="alarm_list"
                    >
                      <div style="padding: 10px 0px">no notifications</div>
                    </ul>
                  </div>
                  <button
                    ref="closeAlarm"
                    class="btn type_ic close_menu"
                    aria-label="Close my list menu"
                    @click="showAlarm = false"
                  >
                    <IcCloseS />
                  </button>
                </div>
              </li>
              <li class="menu_node">
                <!-- is_active -->
                <button
                  ref="member"
                  class="menu_item member_btn type_ic"
                  @click="showDropMenu('showMyListMenu')"
                >
                  <div
                    class="pc-profile profile_info"
                    v-if="profileInfos && profileInfos.length"
                  >
                    <span
                      class="profile"
                      :style="`background-image: url(${profileInfos[0].resUrl})`"
                    ></span>
                  </div>
                  <div class="pc-profile profile_info" v-else>
                    <IcMember />
                  </div>
                  <span class="is_blind">My list Menu</span>
                </button>
                <div
                  v-if="showMyListMenu"
                  class="dep2_menu_wrap my_account_menu_wrap"
                >
                  <div class="ttl_area">
                    <h4 class="ttl c_point">Hello, {{ userName }}!</h4>
                  </div>
                  <ul class="menu_list dep2_menu_list">
                    <li
                      v-for="(item, i) in myMenuItems"
                      :key="'main_menu' + i"
                      class="menu_node"
                      @click="showMyListMenu = !showMyListMenu"
                    >
                      <router-link :to="item.link" class="menu_item pc_item"
                        ><span>{{ item.name }}</span>
                      </router-link>
                    </li>
                    <li class="menu_node">
                      <button class="menu_item" @click="logout">
                        Sign Out
                      </button>
                    </li>
                  </ul>
                  <button
                    class="btn type_ic close_menu"
                    aria-label="Close my list menu"
                    @click="showMyListMenu = false"
                  >
                    <IcCloseS />
                  </button>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </template>
      <template v-if="$parent.isMob && openService">
        <div class="menu_wrap mob_menu_wrap">
          <ul class="menu_list">
            <li v-if="!showMobMenu" class="menu_node">
              <button class="menu_item" @click="[openModal('SearchModal')]">
                <IcMobSearch />
              </button>
            </li>
            <li v-else-if="accessToken" class="menu_node">
              <!-- is_active -->
              <button
                ref="member"
                class="menu_item type_ic btn_alarm"
                :class="{ is_active: redDotYn === 'Y' }"
                @click="getNotificationList"
              >
                <IcMobAlarm />
                <span class="is_blind">Alarm</span>
              </button>
            </li>
            <li class="menu_node">
              <button
                class="menu_item btn_control_menu toggle_menu"
                :class="{ is_active: showMobMenu }"
                :aria-label="'menu'"
                :aria-expanded="showMobMenu ? 'true' : 'false'"
                @click="showDropMenu('showMobMenu')"
              >
                <i class="ic ic_menu"></i>
                <span class="is_blind">menu</span>
              </button>
            </li>
          </ul>
        </div>
      </template>
    </article>
    <transition name="drawer">
      <aside v-if="$parent.isMob && showMobMenu" class="anb" role="menubar">
        <nav class="menu_wrap mob_menu_wrap mob_nav">
          <button
            v-if="!accessToken"
            class="menu_item btn_signin"
            title="Open in new Sign in popup"
            @click="openPortal"
          >
            <span>Sign In</span>
          </button>
          <div v-else class="profile_info">
            <span
              v-if="profileInfos && profileInfos.length"
              class="profile"
              :style="`background-image: url(${profileInfos[0].resUrl})`"
            ></span>
            <IcProfile v-else class="profile" />
            <span class="name">{{ userName }}</span>
          </div>
          <ul class="menu_list" v-if="openService" role="menu">
            <li
              v-for="(item, i) in mainMenuItems"
              :key="'mob_menu' + i"
              class="menu_node"
              @click="pageMove(item.link)"
              role="menuitem"
            >
              <router-link :to="item.link" class="menu_item"
                ><span>{{ item.name }}</span>
              </router-link>
            </li>
            <li
              role="menuitem"
              class="menu_node"
              :aria-expanded="showDep2 ? 'unfold' : 'collapsed'"
            >
              <a
                href="javascript:;"
                class="menu_item has_sub"
                :class="{ is_active: showDep2 }"
                @click="showDropMenuMobile"
              >
                <span class="is_blind"
                  >{{ showDep2 ? 'unfold' : 'collapsed' }},</span
                >
                <span>My Account</span>
                <ArwSelect
                  stroke-color="var(--txt-gray)"
                  :iconLabel="showDep2 ? 'unfold' : 'collapsed'"
                />
              </a>
              <transition name="drop">
                <ul v-if="showDep2" class="menu_list dep2_menu_list">
                  <li
                    role="menuitem"
                    v-for="(item, i) in myMenuItems"
                    :key="'main_menu' + i"
                    class="menu_node"
                    @click="showMobMenu = false"
                  >
                    <router-link :to="item.link" class="menu_item"
                      ><span>{{ item.name }}</span>
                    </router-link>
                  </li>
                </ul>
              </transition>
            </li>
          </ul>
          <ul class="menu_list foot_menu_list">
            <li
              v-for="(item, i) in fnbMenuItems"
              :key="'main_menu' + i"
              class="menu_node"
              @click="showMobMenu = false"
            >
              <router-link :to="item.link" class="menu_item">
                <span>{{ item.name }}</span>
              </router-link>
            </li>
          </ul>
          <button
            v-if="accessToken"
            class="menu_item btn_signout"
            @click="logout"
          >
            <IcMobSignOut /> <span>Sign Out</span>
          </button>
        </nav>
      </aside>
    </transition>
    <transition name="fade">
      <aside v-if="showSearch" class="search_sec search_ui">
        <SearchInner @searchClose="searchClose()" :type="'web'" />
      </aside>
    </transition>
    <NotificationModal
      v-if="showAlarm && $isMobile()"
      ref="NotificationModal"
      :isLoading="isLoading"
      :showAlarm="showAlarm"
      :itemData.sync="notificationInfos"
      @alarmClose="alarmClose()"
    />
    <SearchModal ref="SearchModal" />
  </header>
</template>

<script>
import Logo from '@/components/ic/Logo';
import IcMember from '@/components/ic/IcMember';
import IcSearch from '@/components/ic/IcSearch';
import IcCloseS from '@/components/ic/IcCloseS';
import ArwSelect from '@/components/ic/arwSelect';
import NotificationModal from '@/components/common/NotificationModal';
import SearchModal from '@/components/common/SearchModal';
import SearchInner from '@/components/common/SearchInner';
import IcAlarm from '@/components/ic/IcAlarm';
import IcMobSearch from '@/components/ic/IcMobSearch';
import IcMobSignOut from '@/components/ic/IcMobSignOut';
import IcMobAlarm from '@/components/ic/IcMobAlarm';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import IcProfile from '@/components/ic/IcProfile';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'HeaderBase',
  components: {
    IcMobAlarm,
    IcMobSignOut,
    IcMobSearch,
    IcAlarm,
    NotificationModal,
    SearchModal,
    SearchInner,
    ArwSelect,
    IcCloseS,
    Logo,
    IcMember,
    IcSearch,
    LoadingSpinner,
    IcProfile,
  },
  data() {
    return {
      mainMenuItems: [
        {
          name: 'Drops',
          link: '/drops',
        },
        {
          name: 'Marketplace',
          link: '/marketplace',
        },
        {
          name: 'My Collections',
          link: '/collections',
        },
      ],
      myMenuItems: [
        {
          name: 'Profile',
          link: '/mylist/myartist',
        },
        {
          name: 'Favorites',
          link: '/mylist/favorites',
        },
        {
          name: 'My wallet',
          link: '/mylist/wallet',
        },
        {
          name: 'Activity',
          link: '/mylist/activities',
        },
        {
          name: 'Settings',
          link: '/mylist/settings',
        },
      ],
      fnbMenuItems: [
        {
          name: 'Privacy Policy',
          link: '/privacy',
        },
        {
          name: 'Terms of  Service',
          link: '/terms',
        },
        {
          name: 'Notice Board',
          link: '/notice',
        },
        {
          name: 'Help Center',
          link: '/help',
        },
        {
          name: 'About Us',
          link: '/aboutUs',
        },
      ],
      userProfile: 'profile/img_profile_01_m.png',
      showSearch: false,
      showAlarm: false,
      showMyListMenu: false,
      showMobMenu: false,
      showDep2: false,
      winW: 0,
      alarmData: [],
      notificationInfos: [],
      isLoading: false,
      showElemGroup: [
        'showSearch',
        'showAlarm',
        'showMyListMenu',
        'showMobMenu',
        'showDep2',
      ],
      expirationAlert: {
        type: 'warn_2',
        desc: 'Your login session has expired. Please sign in again.',
        success: 'Sign in',
      },
      loginAlert: {
        type: 'warn_2',
        desc: 'Please sign in.',
        success: 'Sign in',
      },
      loginFailAlert: {
        type: 'warn',
        desc: 'Sign in failed. Please Sign in again.',
      },
    };
  },
  watch: {
    $route() {
      if (this.accessToken) {
        //비정상 탈퇴회원 탈퇴 동기화 처리 ex) 탈퇴팝업 탈퇴진행 후 팝업닫기
        if (this.svcJoinYn === 'N') {
          this.withdraw();
        }
        //user 닉네임 비활성화 : nickname 등록 화면을 제거하고 자동생성 제공으로 변경
        //userName 미입력 회원 페이지 이동시 수시로 입력 안내
        // if (this.userName === '') {
        //   this.$emit('openSignin');
        // }
      }
    },
    showMyListMenu(v) {
      if (!v) {
        setTimeout(() => {
          if (this.$refs.member) {
            this.$refs.member.focus();
          } else {
            this.$refs.signIn.focus();
          }
        }, 100);
      } else {
        setTimeout(() => {
          document.querySelector('.pc_item').focus();
        }, 500);
      }
    },
    showSearch(v) {
      if (!v) {
        setTimeout(() => {
          this.$refs.searchBtn.focus();
        }, 100);
      }
    },
    showAlarm(v) {
      if (!v) {
        setTimeout(() => {
          this.$refs.showAlarm.focus();
        }, 100);
      } else {
        setTimeout(() => {
          this.$refs.closeAlarm.focus();
        }, 100);
      }
    },
    //세션 만료 시 로그아웃 처리
    expiredFlag(v) {
      if (v === true) {
        this.$root.$emit('openAlert', this.expirationAlert);
        this.logout();
      }
    },
    userType(v) {
      if (v == 2) {
        this.myMenuItems[0].link = '/mylist/myartist';
      } else {
        this.myMenuItems[0].link = '/mylist/user';
      }
    },
  },
  computed: {
    ...mapGetters({
      accessToken: 'appStore/accessToken',
      refreshToken: 'appStore/refreshToken',
      expiredFlag: 'appStore/expiredFlag',
      userName: 'userStore/userName',
      userType: 'userStore/userType',
      svcJoinYn: 'userStore/svcJoinYn',
      redDotYn: 'userStore/redDotYn',
      mbrNo: 'userStore/mbrNo',
      profileInfos: 'userStore/profileInfos',
    }),
    openService() {
      return JSON.parse(process.env.VUE_APP_OPEN_SERVICE) ? true : false;
    },
  },
  mounted() {
    if (!sessionStorage.getItem('refreshTime')) {
      this.logout('expiredLogout');
    }

    this.$root.$on('alertSubmit', Boolean => {
      if (Boolean) {
        this.openPortal();
      }
    });

    //회원가입
    this.$EventBus.$on('empLogin', emptest => {
      this.openPortal(emptest);
    });

    //로그아웃
    this.$EventBus.$on('logout', () => {
      this.logout();
    });

    //회원정보 수정 - 주  *정보 사용하지 않고 있는 기능
    this.$EventBus.$on('empEdit', () => {
      this.openEdit();
    });

    this.$EventBus.$on('showAlarm', boolean => {
      this.showAlarm = boolean;
    });

    this.$EventBus.$on('showMyListMenu', boolean => {
      this.showMyListMenu = boolean;
    });

    this.$EventBus.$on('showMobMenu', boolean => {
      this.showMobMenu = boolean;
    });
    window.loginProc = empParam => {
      this.empParam = empParam;
      this.login();
    };

    //회원정보 수정 시 토큰 재발급
    window.editProc = () => {
      this.setStateYn('Y');
      this.refresh();
    };

    //지갑 QR이동 시 로그인이 안된 회원이면 로그인 알림
    if (!this.accessToken) {
      if (sessionStorage.getItem('redirect')) {
        this.$root.$emit('openAlert', this.loginAlert);
      } else {
        sessionStorage.removeItem('redirect');
      }
    }

    //2분마다 redDotYn Check
    setInterval(() => {
      if (this.accessToken) {
        this.api.getStatus({}).then(res => {
          if (res.code === 0) {
            this.setReddotYn(res.redDotYn);
          }
        });
      }
    }, 60 * 1000 * 2);
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    openPortal(emptest) {
      this.showSearch = false;

      let base_url;
      let callbackUrl = window.location.origin + '/loginProcess';
      let svcCode = 'SVC318';
      let client_id = process.env.VUE_APP_EMPKEY;
      let country = 'US';
      let state = 'Cross-siterequestforgery';
      let language = 'en';

      //기존 EMP 호출 팝업
      // let language = 'en-US';
      // let authSvr = 'oauth2';
      // let thirdparty = 'FBK,GGL,APPL,AMZ';
      //empUrl = `${base_url}?authSvr=${authSvr}&callbackUrl=${callbackUrl}&redirect_uri=${callbackUrl}&country=${country}&language=${language}&svcCode=${svcCode}&client_id=${client_id}&show_3rd_party_login=${thirdparty}&state=${state}&expire_session=Y`;

      let empUrl;

      callbackUrl = encodeURIComponent(callbackUrl);

      //if (emptest == 'emptest') {
      //  base_url = process.env.VUE_APP_NEW_EMP + '/lgacc/service/v1/signin';
      //  empUrl = `${base_url}?callback_url=${callbackUrl}&redirect_url=${callbackUrl}&country=${country}&language=${language}&svc_code=${svcCode}&client_id=${client_id}&state=${state}&webview_yn=N`;
      //} else {
      //  if (process.env.VUE_APP_PROFILE !== 'PRD') {
      //    base_url = process.env.VUE_APP_NEW_EMP + '/lgacc/service/v1/signin';
      //    empUrl = `${base_url}?callback_url=${callbackUrl}&redirect_url=${callbackUrl}&country=${country}&language=${language}&svc_code=${svcCode}&client_id=${client_id}&state=${state}&webview_yn=N`;
      //  } else {
      //    base_url = process.env.VUE_APP_EMP + '/login/sign_in';
      //    let language = 'en-US';
      //    let authSvr = 'oauth2';
      //    let thirdparty = 'FBK,GGL,APPL,AMZ';
      //    empUrl = `${base_url}?authSvr=${authSvr}&callbackUrl=${callbackUrl}&redirect_uri=${callbackUrl}&country=${country}&language=${language}&svcCode=${svcCode}&client_id=${client_id}&show_3rd_party_login=${thirdparty}&state=${state}&expire_session=Y`;
      //  }
      //}

      base_url = process.env.VUE_APP_NEW_EMP + '/lgacc/service/v1/signin';
      empUrl = `${base_url}?callback_url=${callbackUrl}&redirect_url=${callbackUrl}&country=${country}&language=${language}&svc_code=${svcCode}&client_id=${client_id}&state=${state}&webview_yn=N`;

      const setOption =
        'top=10, left=10, width=550, height=650, status=no, menubar=no, toolbar=no, resizable=no';

      //this.windowRef = window.open(empUrl, 'EMP LOGIN', setOption);

      let ipParam = {};

      this.api.checkIp(ipParam).then(res => {
        console.log(res);
        try {
          //if (process.env.VUE_APP_PROFILE === 'PRD') {
          if (res.ipCheck) {
            this.windowRef = window.open(empUrl, 'EMP LOGIN', setOption);
          } else {
            let errorMsg = {
              type: 'networkError',
            };
            this.$root.$emit('openAlert', errorMsg);
          }
          // else {
          //  this.windowRef = window.open(empUrl, 'EMP LOGIN', setOption);
          //}
        } catch (er) {
          console.log(er);
        }
      });
    },
    openEdit() {
      const base_url =
        process.env.VUE_APP_EMP + '/member/edit_info_confirm_password';
      const callbackUrl = window.location.origin + '/editProcess';
      const svcCode = 'SVC318';
      const authSvr = 'emp';
      const country = 'US';
      const language = 'en-US';
      const empUrl = `${base_url}?authSvr=${authSvr}&callbackUrl=${callbackUrl}&country=${country}&language=${language}&svcCode=${svcCode}&recertType=N`;
      const setOption =
        'top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no';
      this.windowRef = window.open(empUrl, 'EMP EDIT', setOption);
    },
    showDropMenu(name) {
      this.showElemGroup.forEach(v => {
        v === name ? (this[name] = !this[name]) : (this[v] = false);
      });

      if (this.showAlarm) {
        this.getNotificationList();
        this.$EventBus.$emit('showAlarm', this.showAlarm);
      }
      if (this.showMyListMenu) {
        this.$EventBus.$emit('showMyListMenu', this.showMyListMenu);
      }

      if (this.showMobMenu) {
        this.$EventBus.$emit('showMobMenu', this.showMobMenu);
      }
    },
    showDropMenuMobile() {
      if (!this.accessToken) {
        this.showMobMenu = false;
        this.$root.$emit('openAlert', this.loginAlert);
        return false;
      }
      this.showDep2 = !this.showDep2;
    },
    async refresh() {
      let param = {
        refreshToken: this.refreshToken,
      };
      await this.api.reissueToken(param).then(res => {
        if (res.code === 0) {
          this.setAccessToken(res.accessToken);
          this.setRefreshToken(res.refreshToken);
          sessionStorage.setItem('accessTime', this.toCurrentTime());
        }
      });
    },
    logout(type) {
      this.settingEmp();
      this.settingUser();
      this.closeMenuAll();
      this.closeEvent();

      if (type === 'expiredLogout') return;

      if (
        this.$route.name !== 'home' &&
        this.$route.name !== 'temporarylogin'
      ) {
        this.$router.push('/');
      }
    },
    openModal(ref) {
      this.closeMenuAll();
      this.$refs[ref].open();
    },
    closeMenuAll() {
      this.showElemGroup.forEach(v => {
        this[v] = false;
      });
    },
    closeEvent() {
      this.$root.$off('restoreTrue');
      this.$root.$off('withdrawSuccess');
      this.$root.$off('withdrawPopup');
      this.$root.$off('rangeOver');
      this.$root.$off('deleteConfirmAlert');
    },
    pageMove(link) {
      if (this.$isMobile()) {
        this.showMobMenu = false;
      } else {
        this.showSearch = false;
        this.showMobMenu = false;
      }

      if (link === '/drops' || link === '/marketplace') {
        return false;
      }
      if (link === '/collections' && !this.accessToken) {
        this.$root.$emit('openAlert', this.loginAlert);
      }
    },
    async login() {
      await this.api.login(this.empParam).then(res => {
        this.$root.$emit('closeAlert');
        if (res.code === 0) {
          if (process.env.VUE_APP_PROFILE === 'DEV') {
            console.log(
              '==========로그인 성공 : ',
              this.$dayJs().format('HH:mm:ss'),
              ' ==========',
            );
          }
          this.setProfileInfos(res.profileInfos); // 로그아웃 후 로그인시 이전 프로필 이미지가 남아있는 현상 방지
          let empParam = {
            accessToken: res.accessToken,
            refreshToken: res.refreshToken,
          };
          let userParam = {
            redDotYn: res.redDotYn,
            userName: res.userName,
            userType: res.userType,
            mbrNo: res.mbrNo,
            email: res.email,
            svcJoinYn: res.svcJoinYn,
            walletYn: res.walletYn,
            wlltType: res.wlltType,
            ethWallet: res.walletInfos
              ? res.walletInfos.find(v => v.bcNwTp === '20')
              : {},
            hdrWallet: res.walletInfos
              ? res.walletInfos.find(v => v.bcNwTp === '10')
              : {},
            stateYn: res.stateYn,
            profileInfos: res.profileInfos,
          };
          this.settingEmp(empParam);
          this.settingUser(userParam);

          this.$gtag.event('login', { method: res.mbrNo });

          let redirect = sessionStorage.getItem('redirect');
          if (redirect) {
            sessionStorage.removeItem('redirect');
            this.$router.push(redirect);
          }
          //user 닉네임 비활성화 : nickname 등록 화면을 제거하고 자동생성 제공으로 변경
          // if (!res.userName) {
          //   this.$emit('openSignin');
          // }
        } else {
          this.$root.$emit('openAlert', this.loginFailAlert);
        }
      });
    },
    searchClose() {
      this.showSearch = false;
    },
    alarmClose() {
      this.showAlarm = false;
    },
    async withdraw() {
      await this.api.withdrawalProfile({}).then(res => {
        if (res.code === 0) {
          console.log('탈퇴 동기화');
        }
        this.logout();
      });
    },
    ...mapMutations({
      setAccessToken: 'appStore/accessToken',
      setRefreshToken: 'appStore/refreshToken',
      setStateYn: 'userStore/stateYn',
      setReddotYn: 'userStore/redDotYn',
      setProfileInfos: 'userStore/profileInfos',
    }),
    ...mapActions({
      settingEmp: 'appStore/settingEmp',
      settingUser: 'userStore/settingUser',
    }),
    getNotificationList() {
      let data = {
        mbrNo: this.mbrNo,
      };
      this.isLoading = true;
      this.api.getNotificationList(data).then(res => {
        this.isLoading = false;
        if (res.code === 0) {
          this.setReddotYn('N');
          let tmp = res.notificationInfos;

          tmp.forEach((key, i) => {
            let title = '';
            switch (key.ntcTpCd) {
              case '20':
                title = 'Notice Board';
                break;
              case '60':
                title = 'Marketplace';
                break;
              default:
                title = 'DROPS';
                break;
            }
            tmp[i].title = title;
          });
          this.notificationInfos = tmp;
          if (this.$isMobile()) this.showAlarm = true;
        }
      });
    },
  },
};
</script>

<style scoped></style>
