<template>
  <main id="contents" class="my_list_page favorites_page">
    <div class="set_inner clearFix">
      <h1 class="page_ttl only_pc">My Account</h1>
      <h1 class="page_ttl dv_tab">Favorites</h1>
      <AccountMenu />
      <div class="min_inner" v-if="favoriteInfos">
        <section class="favorites_sec">
          <div class="favorites_sec_head">
            <h2 class="ttl">
              <em>{{ totalCount }}</em> Collections
            </h2>
          </div>
          <div v-if="totalCount > 0" class="favorites_sec_body">
            <ul class="grid grid_15 ib edit_list">
              <li
                v-for="item in favoriteInfos"
                :key="item.id"
                class="col col_4 edit_node"
              >
                <FavoritesItem :itemData="item" />
              </li>
            </ul>
          </div>
          <div v-else class="favorites_sec_body">
            <div class="guide_ui no_data">
              <div class="img_area">
                <picture>
                  <source
                    media="(max-width: 600px)"
                    type="image/png"
                    :srcset="`${require('@/assets/img/img_mob_illust_nofavorite_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nofavorite_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nofavorite.png')}`"
                  />
                  <img
                    :src="require('@/assets/img/img_illust_nofavorite.png')"
                    alt=""
                  />
                </picture>
              </div>
              <p>You don’t have any favorite artworks yet!</p>
              <button
                class="btn w_xl h_l strong"
                @click="goMarketPlaceItemList()"
              >
                Go to Marketplace
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import FavoritesItem from '@/components/mylist/FavoritesItem';
import AccountMenu from '@/components/common/AccountMenu';
import { mapGetters } from 'vuex';

export default {
  name: 'FavoritesView',
  components: { AccountMenu, FavoritesItem },
  data() {
    return {
      totalCount: 0,
      favoriteInfos: null,
      editionInfos: [],
    };
  },
  computed: {
    ...mapGetters({
      accessToken: 'appStore/accessToken',
      mbrNo: 'userStore/mbrNo',
    }),
  },
  async mounted() {
    await this.api.getMyFavoriteList({}).then(res => {
      if (res.code === 0) {
        this.favoriteInfos = res.favoriteInfos;
        this.totalCount = res.totalCount;
      }
    });
  },
  methods: {
    goMarketPlaceItemList() {
      this.$router.push('/marketplace');
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/mylist.css';
</style>
