<template>
  <div ref="trigger" />
</template>

<script>
export default {
  mounted() {
    const options = {
      root: null,
      threshold: 1,
    };
    const handleIntersect = entry => {
      if (entry.isIntersecting) {
        if (this.$parent.$refs.ulRef) this.$emit('scroll', this.$refs.trigger);
      }
    };

    let observer = new IntersectionObserver(entries => {
      handleIntersect(entries[0]);
    }, options);
    observer.observe(this.$refs.trigger);
  },
};
</script>
