var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edit_item type_card"},[_c('div',{staticClass:"item_head"},[_c('button',{on:{"click":_vm.goRouterMove}},[_c('div',{staticClass:"thumb_area",attrs:{"aria-hidden":"true"}},[(_vm.source.small)?_c('img',{attrs:{"src":_vm.source.small,"width":480,"alt":_vm.itemData.artworkId ? _vm.itemData.artworkId : ''}}):_c('img',{attrs:{"src":require('@/assets/img/img_illust_nodata.png'),"alt":"img_mob_illust_nodata_l"}})])]),_c('div',{staticClass:"ic_area type_blockchains"},[(_vm.itemData.bcNwTp == '10')?_c('IcHedera'):_c('IcEth')],1),(!_vm.isOtherMakret)?_c('button',{staticClass:"btn type_ic btn_like",class:{
        is_active: _vm.likeStatus,
      },attrs:{"type":"button"},on:{"click":function($event){return _vm.likeThis(_vm.itemData)}}},[(!_vm.likeStatus)?_c('IcLike',{ref:"icLike",attrs:{"status":!_vm.likeStatus,"icon-color":"#fff"}}):_c('IcLike',{ref:"icLike",attrs:{"icon-color":"#ff521d"}})],1):_vm._e()]),_c('div',{staticClass:"item_body"},[_c('div',{staticClass:"link",attrs:{"aria-describedby":_vm.itemData.artworkId},on:{"click":_vm.goRouterMove}}),_c('div',{staticClass:"basic_info_group"},[((_vm.userType == 1 || _vm.userType == 2) && !_vm.isOtherMakret)?_c('div',{staticClass:"profile_info is_art",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.goArtistProfile()}}},[(_vm.itemData.artistAvatarInfo && _vm.itemData.artistAvatarInfo.length)?_c('span',{staticClass:"profile",style:(`background-image: url(${
            _vm.itemData.artistAvatarInfo.length > 0
              ? _vm.itemData.artistAvatarInfo[0].resUrl
              : ''
          })`)}):_c('IcProfile',{staticClass:"profile"}),_c('IcRing',{staticClass:"ic_ring"}),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.itemData.artistName))])],1):_c('div',{staticClass:"profile_info is_art",attrs:{"tabindex":"0"}},[_c('span',{staticClass:"profile",style:(`background-image: url(${_vm.itemData.otherMaplLogoUrl})`)}),_c('IcRing',{staticClass:"ic_ring"}),_vm._v(" "+_vm._s(_vm.itemData.otherPlatform)+" ")],1),(_vm.isOtherMakret)?_c('div',{staticClass:"ttl_area"},[_c('h3',{staticClass:"ttl",attrs:{"id":_vm.itemData.id}},[_vm._v(_vm._s(_vm.itemData.title))]),_c('p',{staticClass:"edit"},[_vm._v(" ")])]):_c('div',{staticClass:"ttl_area"},[_c('h3',{staticClass:"ttl",attrs:{"id":_vm.itemData.id}},[_vm._v(_vm._s(_vm.itemData.title))]),(_vm.viewType === 'profile' || _vm.viewType === 'other')?_c('p',{staticClass:"edit"},[(_vm.itemData.listType === 'M')?_c('span',[_vm._v(" "+_vm._s(_vm.itemData.editionId)+" of "+_vm._s(_vm.itemData.totalCount)+" "+_vm._s(_vm.itemData.totalEdition > 1 ? 'Editions' : 'Edition')+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.itemData.totalCount)+" "+_vm._s(_vm.itemData.totalEdition > 1 ? 'Editions' : 'Edition')+" ")])]):_c('p',{staticClass:"edit"},[_vm._v(" "+_vm._s(_vm.itemData.totalEdition)+" of "+_vm._s(_vm.itemData.totalCount)+" "+_vm._s(_vm.itemData.totalEdition > 1 ? 'Editions' : 'Edition')+" ")])]),(_vm.userType == 2 && _vm.itemData.ownerName && !_vm.hideOwner)?_c('div',{staticClass:"owner_info_area"},[_c('dl',[_c('dt',[_vm._v("Owned by")]),_c('dd',{staticClass:"profile_info is_art"},[(
                _vm.itemData.ownerAvatarInfo &&
                _vm.itemData.ownerAvatarInfo.length > 0
              )?_c('span',{staticClass:"profile",style:(`background-image: url(${_vm.itemData.ownerAvatarInfo[0].resUrl})`)}):_c('IcProfile',{staticClass:"profile"}),_c('IcRing',{staticClass:"ic_ring"}),_c('b',{staticClass:"name"},[_vm._v(_vm._s(_vm.itemData.ownerName))])],1)])]):_vm._e()]),_c('div',{staticClass:"state_info_group has_btn clearFix"},[_c('div',{staticClass:"state_info"},[(_vm.showPrice)?_c('div',{staticClass:"price_area",class:{ priceOwnMob: _vm.isMob }},[_c('p',{staticClass:"type"},[_vm._v("Price")]),_c('p',{staticClass:"price"},[(_vm.itemData.bcNwTp == '10')?_c('b',[_vm._v(_vm._s(_vm._f("setNum")(_vm.itemData.price))+" USDC")]):_c('b',[_vm._v(_vm._s(_vm.itemData.price)),_c('span',{staticClass:"unit"},[_vm._v("Ξ")]),_c('span',{staticClass:"dollars"},[_vm._v("("+_vm._s(_vm._f("crtpyAbbreviate")(_vm.itemData.priceToEthUsd))+")")])])])]):_c('div',{staticClass:"price_area"},[(_vm.itemData.otherMaplYn == 'Y')?_c('p',{staticClass:"price chip"},[_vm._v(" Other Market ")]):_c('p',{staticClass:"price chip"},[_vm._v("Not for Sale")])]),(_vm.itemData.listType === 'D')?_c('div',{class:{
            status_chip: _vm.isMob,
          }},[_c('p',{staticClass:"price chip"},[_vm._v("Live")])]):_vm._e()]),(
          (!_vm.itemData.otherPlatform || _vm.itemData.otherPlatform === '') &&
          _vm.itemData.listType === 'M'
        )?_c('div',{staticClass:"btn_group"},[_c('button',{staticClass:"share",attrs:{"type":"button","aria-expanded":_vm.isShare ? 'true' : 'false'},on:{"click":_vm.shareBtn}},[_c('IcOutLink')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShare),expression:"isShare"}],staticClass:"layer_area layer_area_copy_action isShare",attrs:{"role":"dialog"}},[_c('div',{staticClass:"copy_group"},[_c('div',{staticClass:"link_area"},[_c('button',{ref:"copybtn",on:{"click":function($event){return _vm.copyAction(_vm.urlLink)}}},[_c('IcLink'),_vm._v("Copy Link ")],1)]),_c('div',{staticClass:"contents_area"},[_c('button',{on:{"click":function($event){return _vm.openModal('CopyModal', _vm.itemData)}}},[_c('IcCopy'),_vm._v("Copy Contents ")],1)]),_c('div',{staticClass:"contents_area"},[_c('button',{on:{"click":function($event){return _vm.copyAction(_vm.itemData.contractAddress)}}},[_c('IcCopy'),_vm._v("Copy Contract Address ")],1)]),_c('div',{staticClass:"contents_area"},[_c('button',{on:{"click":function($event){return _vm.copyAction(_vm.itemData.collectibleID)}}},[_c('IcCopy'),_vm._v("Copy Collectable ID ")],1)])]),_c('button',{staticClass:"btn type_ic close_menu",attrs:{"aria-label":"Close my list menu"},on:{"click":_vm.shareBtnClose}},[_c('IcCloseS')],1)])]):_vm._e()])]),_c('ContentsCopy',{ref:"CopyModal",attrs:{"itemData":_vm.itemData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }