<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout';
import EmptyLayout from '@/layouts/EmptyLayout';
export default {
  name: 'LayoutLoader',
  components: {
    DefaultLayout,
    EmptyLayout,
  },
  computed: {
    layout() {
      return this.$route?.meta?.layout ?? 'DefaultLayout';
    },
  },
};
</script>

<style scoped></style>
