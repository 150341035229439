<template>
  <IconBase
    icon-name="icOutLink"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1"
  >
    <path d="M12 7H7v16h16v-5M22.5 7.5l-7.2 7.2M15 7h8v8" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcOutLink',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Out link',
    },
  },
};
</script>

<style scoped></style>
