<template>
  <IconBase
    icon-name="icTwitter"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    :iconColor="iconColor"
  >
    <g id="그룹_3887" data-name="그룹 3887" transform="translate(-5 -5)">
      <path
        id="twitter_5968958"
        d="M23.9,7.789,30.6,0H29.01L23.192,6.763,18.545,0h-5.36l7.027,10.227L13.185,18.4h1.588l6.144-7.142L25.825,18.4h5.36L23.9,7.789Zm-2.175,2.528L21.01,9.3,15.345,1.2h2.439l4.572,6.54.712,1.018,5.943,8.5H26.572l-4.85-6.937Z"
        transform="translate(-2.186 11)"
        fill="#fff"
      />
      <rect
        id="사각형_4490"
        data-name="사각형 4490"
        width="30"
        height="30"
        transform="translate(5 5)"
        fill="none"
      />
    </g>
    />
  </IconBase>
</template>

<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcTwitter',
  components: { IconBase },
  props: {
    iconColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Twitter',
    },
  },
};
</script>

<style scoped></style>
