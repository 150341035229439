<template>
  <li class="skeleton-item">
    <div class="skeleton-info">
      <p class="skeleton-name" />
    </div>
  </li>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
@keyframes loading {
  0% {
    transform: translateX(0);
  }
  50%,
  100% {
    transform: translateX(460px);
  }
}

.skeleton-item {
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
  margin-top: 3px;
}

.skeleton-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
}

.skeleton-name {
  width: 75%;
  height: 18px;
  background: #363535;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.skeleton-name::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #363535, rgb(69, 67, 67), #363535);
  animation: loading 2s infinite linear;
}
</style>
