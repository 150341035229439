<template>
  <IconBase
    icon-name="arwPrevS"
    :icon-label="iconLabel"
    :width="24"
    :height="24"
    viewBox="0 0 24 24"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="2"
  >
    <path data-name="Path 2582" d="M15.175 5.651 8.5 12.008l6.675 6.993" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'ArwPrevS',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Prev',
    },
  },
};
</script>

<style scoped></style>
