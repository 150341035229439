<template>
  <IconBase
    icon-name="icSearch"
    :icon-label="iconLabel"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :stroke-color="strokeColor"
    :stroke-width="2"
  >
    <circle class="st1" cx="10.8" cy="11" r="7" />
    <path class="st1" d="m16.2 16.5 5 5" fill="none" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcMobSearch',
  components: { IconBase },
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Search',
    },
  },
};
</script>

<style scoped></style>
