<template>
  <main id="contents" class="drops_page curation_page">
    <h1 class="page_ttl is_blind">Drops</h1>
    <div class="set_inner">
      <section class="rep_sec">
        <div class="img_wrap">
          <div class="thumb_area">
            <img
              v-if="mainDropImage"
              :src="mainDropImage"
              :width="1440"
              :alt="detailInfo.title"
            />
            <img
              v-else
              :src="require('@/assets/img/bg_skeletonDropsCuration.png')"
              :width="1440"
              :alt="'bg_skeletonDropsCuration'"
            />
          </div>
        </div>
        <div class="info_wrap">
          <div class="ttl_area profile_info is_art" @click="goArtistProfile()">
            <span
              v-if="avatarInfo && avatarInfo.length"
              class="profile"
              :style="`background-image: url(${detailInfo.avatarInfo[0].resUrl})`"
            ></span>
            <IcProfile v-else class="profile" />
            <IcRing
              :width="50"
              :height="50"
              :small-ring="14.97"
              class="ic_ring"
            />
            <h3 class="ttl name">{{ detailInfo.artistName }}</h3>
          </div>
          <div class="grid grid_30 desc_info_group">
            <div class="col col_6 artist_info">
              <p class="desc">{{ detailInfo.artistDescription }}</p>
            </div>
            <div
              v-if="detailInfo.title && detailInfo.artworkDescription"
              class="col col_6 drops_info"
            >
              <h4 class="ttl">{{ detailInfo.title }}</h4>
              <p class="desc">{{ detailInfo.artworkDescription }}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="list_sec live_sec">
        <header class="sec_head">
          <div class="ttl_area">
            <h2 class="ttl" v-if="detailInfo.dropsDivision == 'live'">
              <b>Live</b> Drops
            </h2>
            <h2 class="ttl" v-else-if="detailInfo.dropsDivision == 'upcoming'">
              <b>Upcoming</b> Drops
            </h2>
            <h2 class="ttl" v-else><b>Ended</b> Drops</h2>
          </div>
        </header>
        <div class="sec_body">
          <div class="live_list_wrap">
            <ul class="live_list">
              <li
                v-for="item in dropDetailInfos"
                :key="item.id"
                class="live_node"
              >
                <div
                  class="live_item clearFix"
                  :aria-describedby="'Capture of Artwork for the ' + item.title"
                >
                  <div class="item_head">
                    <div class="thumb_area">
                      <button>
                        <img
                          v-if="item.mimeType === 'video/mp4'"
                          :src="item.videoThumbInfos[1].resUrl"
                          :width="598"
                          :alt="'Capture of Artwork for the ' + item.title"
                        />
                        <img
                          v-else
                          :src="item.imageInfos[1].resUrl"
                          :width="598"
                          :alt="'Capture of Artwork for the ' + item.title"
                        />
                      </button>
                    </div>
                  </div>
                  <div class="item_body clearFix">
                    <!-- Dev: 220414 추가 - 모바일용 link 추가에 따른 아이디, role 추가 -->
                    <div
                      role="text"
                      :id="item.artworkId"
                      class="ttl_area"
                      :class="{ liveDrop: detailInfo.dropsDivision == 'live' }"
                    >
                      <h3 class="ttl">
                        <b>{{ item.title }}</b>
                      </h3>
                      <p class="edit">
                        <span :class="{ 'text-indent': editionHide }">
                          {{
                            editionHide ? '' : 'Edition of ' + item.totalEdition
                          }}
                        </span>
                        <span
                          v-if="
                            item.soldedCount > 0 &&
                            detailInfo.dropsDivision == 'ended'
                          "
                        >
                          | {{ item.soldedCount }} Sold</span
                        >
                      </p>
                      <p class="desc">{{ item.artworkDescription }}</p>
                    </div>
                    <!-- upcomingStartDate -->
                    <div
                      v-if="detailInfo.dropsDivision == 'upcoming'"
                      class="time_cover"
                    >
                      <div class="btn_area upcomingDate">
                        {{
                          setUpcomingDateStr(dropsId, item.upcomingStartDate)
                        }}
                      </div>
                    </div>
                    <div v-else class="time_cover">
                      <CountDown
                        :state="item.soldOutYn"
                        :date-time="item.dropsEndDate"
                        :drops-division="detailInfo.dropsDivision"
                        :solded-count="item.soldedCount"
                      />
                      <div
                        class="btn_area"
                        v-if="
                          item.soldedCount > 0 ||
                          detailInfo.dropsDivision == 'live'
                        "
                      >
                        <!-- <a :href="link" class="btn strong w_s h_l"
                        >View Details</a
                      > -->

                        <button
                          class="btn w_s h_l strong"
                          @click="
                            goDetails(
                              item.artworkId,
                              detailInfo.dropsDivision,
                              item,
                            )
                          "
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import IcProfile from '@/components/ic/IcProfile';
import IcRing from '@/components/ic/IcRing';
import CountDown from '@/components/drops/CountDown';

export default {
  name: 'DropsView',
  components: { IcRing, IcProfile, CountDown },
  data() {
    return {
      dropsId: '',
      artworkId: '',
      detailInfo: {},
      avatarInfo: [],
      dropDetailInfos: [],
      live: {
        dropsEndDate: '2022-05-01 00:00:00',
      },
      img_l: 'thumb_01_l.png',
      img_m: 'thumb_01_m.png',
      mockImg: '',
      dropId: '',
      mainDropImage: '',
      endedSlidOpt: {
        loop: false,
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 30,
        pagination: {
          el: '.edition_slide_wrap .pagination_area',
        },
        navigation: {
          prevEl: '.edition_slide_wrap .slide_nav_area .prev',
          nextEl: '.edition_slide_wrap .slide_nav_area .next',
        },
      },
    };
  },
  async mounted() {
    if (this.$route.query.dropsId === undefined) {
      this.$router.push('/drops');
    } else {
      let param = { dropId: this.$route.query.dropsId };
      await this.api.getLiveDropDetail(param).then(res => {
        if (res.code === 0) {
          this.detailInfo = res.detailInfo;
          this.dropsId = this.detailInfo.dropsId;
          this.artworkId = this.detailInfo.artworkId;
          this.avatarInfo = this.detailInfo.avatarInfo;
          this.mainDropImage = this.detailInfo.imageInfos[0].resUrl;

          this.dropDetailInfos = res.dropDetailInfos;

          this.upcomingStartDate = res.upcomingStartDate;
        } else {
          this.$router.push('/drops');
        }
      });
    }
  },
  computed: {
    editionHide() {
      return process.env.VUE_APP_DROPS_EDITION_HIDE.includes(this.dropsId) &&
        this.detailInfo.dropsDivision == 'upcoming'
        ? true
        : false;
    },
  },
  methods: {
    goDetails(artworkId, status, item) {
      if (status == 'ended' && item.soldedCount == 0) {
        // debugger;
        return;
      }

      if (status == 'upcoming') {
        return;
      }

      // 고객의 요청으로 인한 임시 리다이렉트
      // Temporary redirect due to customer request 2024-11-07
      if (process.env.VUE_APP_PROFILE === 'PRD') {
        if (this.$route.query.dropsId === 'D0000000038') {
          if (status != 'ended') {
            this.$router.push({
              name: 'dropsDetail',
              query: {
                dropId: 'D0000000041',
                artworkId: 'A0000000063',
              },
            });
          } else {
            this.$router.push({
              name: 'marketplaceDetail',
              query: {
                artworkId: artworkId,
                editionId: item.editionId,
              },
            });
          }
        }
      }

      if (status == 'ended') {
        this.$router.push({
          name: 'marketplaceDetail',
          query: {
            artworkId: artworkId,
            editionId: item.editionId,
          },
        });
      } else {
        this.$router.push({
          name: 'dropsDetail',
          query: {
            dropId: this.dropsId,
            artworkId: artworkId,
          },
        });
      }
    },
    goArtistProfile() {
      this.$router.push({
        name: 'ProfileArtist',
        params: {
          mbrNo: this.detailInfo.artistId,
        },
      });
    },
  },
};
</script>
<style scoped>
@import '../../assets/css/drops.css';
.upcomingDate {
  font-size: 2rem;
}

@media screen and (min-width: 1025px) {
  .drops_page.curation_page .live_sec .live_item .ttl_area .desc {
    -webkit-line-clamp: 5 !important;
  }
}

.text-indent {
  text-indent: 3em;
  white-space: pre;
  /* margin: 20px 0; */
}
</style>
