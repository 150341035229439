import { cacheStoreActions } from './cacheStoreActions';
export const cacheStore = {
  namespaced: true,
  state: {
    groupIndex: 0, // group index
    itemIndex: 0, // item index
    scrollTop: 0, // scroll top
  },
  getters: {
    groupIndex: state => {
      return state.groupIndex;
    },
    itemIndex: state => {
      return state.itemIndex;
    },
    scrollTop: state => {
      return state.scrollTop;
    },
  },
  mutations: {
    groupIndex: (state, groupIndex) => {
      state.groupIndex = groupIndex;
    },
    itemIndex: (state, itemIndex) => {
      state.itemIndex = itemIndex;
    },
    scrollTop: (state, scrollTop) => {
      state.scrollTop = scrollTop;
    },
  },
  actions: cacheStoreActions,
};
