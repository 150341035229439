<template>
  <div class="modal_wrap" :class="{ is_show: commonModal }">
    <div class="modal" role="dialog">
      <div class="modal_head">
        <h3 class="modal_ttl">{{ subject }}</h3>
      </div>
      <div class="modal_body">
        <p class="desc">
          {{ content }}
        </p>
      </div>
      <div class="modal_foot">
        <div class="btn_area">
          <button class="btn w_s h_m strong" @click="close">
            {{ btnTxt }}
          </button>
        </div>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';

export default {
  name: 'noticeModal',
  components: { IcCloseM },
  props: {
    modalId: {
      type: String,
      default: 'modal',
    },
    modalSize: {
      type: String,
      default: '',
    },
    modalData: {
      type: Object,
      default: null,
    },
    commonModal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    commonModal: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.$root.$emit('setScrollFixed', true);
        } else {
          this.$root.$emit('setScrollFixed', false);
        }
      },
    },
  },
  computed: {
    subject() {
      return 'Welcome to LG Art Lab';
    },
    content() {
      return `Hello CES members.\nWe appreciate your journey to our platform.\nThe actual drop will start in February.\nPlease set a reminder from your mobile\nto purchase when the drop starts.
`;
    },
    btnTxt() {
      return 'Got it';
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$root.$emit('setScrollFixed', false);
      this.$emit('closeCommon');
    },
  },
};
</script>

<style scoped>
/*
.modal_wrap.is_home {
  background: none !important;
}
*/
.img_area {
  max-width: 16.4rem;
  margin: 1.5rem auto;
}
.modal .modal_body + .modal_foot {
  margin-top: 2.5rem;
}
.is_loading {
  background: #e9ecef !important;
  color: black !important;
}
.desc {
  white-space: pre-line;
  text-align: center;
}
</style>
