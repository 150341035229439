<template>
  <div class="modal_wrap" :class="{ is_show: showQrModal }">
    <div
      :id="modalId"
      class="modal qrModal"
      role="dialog"
      :aria-labelledby="modalId + '_title'"
      :aria-describedby="modalId + '_desc'"
    >
      <div class="modal_head">
        <h3 :id="modalId + '_title'" class="modal_ttl">
          {{ modalData.title }}
        </h3>
        <p :id="modalId + '_desc'">{{ modalData.desc }}</p>
      </div>
      <div class="modal_body">
        <div class="qr_group">
          <div class="img_area">
            <div class="qr-code">
              <qrcode-vue
                :value="qrcodeUrl"
                :size="qrcodeSize"
                level="L"
                background="#fff"
                margin="10"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal_foot">
        <p v-if="this.modalData.title === 'Purchase'" class="txt box_txt">
          After the purchase is complete, You can find the purchased artwork in
          <span>[My Collections]</span> or
          <span>[My Account] <ArwNextS :stroke-width="1.2" /> [Profile]</span>
        </p>
      </div>
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import ArwNextS from '@/components/ic/arwNextS';
import IcCloseM from '@/components/ic/IcCloseM';
export default {
  name: 'QrModal',
  components: { QrcodeVue, IcCloseM, ArwNextS },
  props: {
    modalId: {
      type: String,
      default: 'purchaseModal',
    },
    modalData: {
      type: Object,
      default: null,
    },
    page: {
      type: String,
      default: 'Drop',
    },

    dropId: {
      type: String,
      default: '',
    },
    artworkId: {
      type: String,
      default: '',
    },
    editionId: {
      type: Number,
      default: null,
    },
    showQrModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      progressAlert: {
        type: 'question2',
        desc: 'The process is still in progress.\n Are you sure you want to close?',
      },
      qrcodeUrl: '',
      qrcodeSize: 380,
    };
  },
  watch: {
    showQrModal: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.setFocusTrap(true, '.modal.qrModal');
          this.$root.$emit('setScrollFixed', true);
        } else {
          this.setFocusTrap(false, '.modal.qrModal');
          this.$root.$emit('setScrollFixed', false);
        }
      },
    },
  },
  mounted() {
    if (!this.$isMobile()) {
      let setUrl = '';
      let myself = this.modalId === 'changeSellingModal' ? '&myself=true' : '';
      if (this.dropId && this.artworkId) {
        setUrl = `/drops/detail?dropId=${this.dropId}&artworkId=${this.artworkId}${myself}`;
      } else if (this.artworkId) {
        setUrl = `/marketplace/detail?artworkId=${this.artworkId}&editionId=${this.editionId}${myself}`;
      } else {
        setUrl = '/mylist/user?redirect=true';
      }
      this.qrcodeUrl = window.location.origin + setUrl;
    }
  },
  methods: {
    close() {
      this.setFocusTrap(false, '.modal.qrModal');
      this.$root.$emit('setScrollFixed', false);
      this.$emit('close', 'showQrModal');
    },
  },
};
</script>

<style scoped>
.modal_body .time_area {
  display: inline-block;
  min-width: 170px;
  padding: 9px 18px;
  margin: 20px 0 0;
  border: 1px solid #707070;
  font-size: 1.6rem;
  font-weight: 300;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.modal_foot p.txt {
  font-size: 1.4rem;
  color: var(--txt-gray-2);
}
.modal_foot p.txt span {
  color: #fff;
}
.modal_foot p.txt .ic {
  vertical-align: -7px;
  margin: 0 -3px 0 -4px;
}
.modal_foot .btn_area {
  margin-top: 30px;
}
.qr_group {
  position: relative;
}
.qr_group.is_disabled .img_area {
  opacity: 0.2;
}
.qr_group .img_area {
  display: flex;
  justify-content: center;
}
.qr_group .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.qr-code {
  background-color: #fff;
  padding: 10px;
}
</style>
