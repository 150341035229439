import { userStoreActions } from './userStoreActions';
export const userStore = {
  namespaced: true,
  state: {
    userId: '', // user id
    walletInfo: {
      walletAddress: '', // 지갑 주소
      nftCount: 0, // NFT 갯수
      nftBalance: 0, // NFT 잔액
    },
    redDotYn: '', // reddot 여부 확인(Y:미확인알림 있음, N:없음)
    profileInfos: [], // profile infos
    profileCoverInfos: [], // profile cover infos
    familyInfos: [], // family infos
    userType: '', // 사용자 타입: 1: 유저, 2: 아티스트, 3: 갤러리
    userName: '', // 회원 명
    mbrNo: '', // 회원 번호
    email: '', // 이메일
    svcJoinYn: '', // 서비스 가입 여부
    walletYn: '', // 지갑 연결 유무
    // WIP : eth, hdr
    ethWallet: {}, // 이더리움 지갑
    hdrWallet: {}, // 헤데라 지갑
    stateYn: '', // 주소 입력 유무
    profileDetails: {
      artistDescription: '', // 아티스트 프로필 설명
      facebookUrl: '', // 페이스북 URL
      twitterUrl: '', // 트위터 URL
      instagramUrl: '', // 인스타그램 URL
      webSiteUrl: '', // 웹사이트 URL
    },
    reminderInfos: [], // 알림 정보
    followers: 0, // 팔로워 수
    following: 0, // 팔로잉 수
    zpcd: "",
    stat: "", 
    city: "",
    wlltType: "",
  },
  getters: {
    userId: state => {
      return state.userId;
    },
    walletInfo: state => {
      return state.walletInfo;
    },
    redDotYn: state => {
      return state.redDotYn;
    },
    profileInfos: state => {
      return state.profileInfos;
    },
    profileCoverInfos: state => {
      return state.profileCoverInfos;
    },
    familyInfos: state => {
      return state.familyInfos;
    },
    userType: state => {
      return state.userType;
    },
    userName: state => {
      return state.userName;
    },
    mbrNo: state => {
      return state.mbrNo;
    },
    email: state => {
      return state.email;
    },
    svcJoinYn: state => {
      return state.svcJoinYn;
    },
    walletYn: state => {
      return state.walletYn;
    },
    stateYn: state => {
      return state.stateYn;
    },
    profileDetails: state => {
      return state.profileDetails;
    },
    reminderInfos: state => {
      return state.reminderInfos;
    },
    followers: state => {
      return state.followers;
    },
    following: state => {
      return state.following;
    },
    zpcd: state => {
      return state.zpcd;
    },
    stat: state => {
      return state.stat;
    },
    city: state => {
      return state.city;
    },            
    wlltType: state => {
      return state.wlltType;
    },            
    hdrWallet: state => {
      return state.hdrWallet;
    },            
    ethWallet: state => {
      return state.ethWallet;
    },            
  },
  mutations: {
    userId: (state, userId) => {
      state.userId = userId;
    },
    walletInfo: (state, walletInfo) => {
      state.walletInfo = walletInfo;
    },
    redDotYn: (state, redDotYn) => {
      state.redDotYn = redDotYn;
    },
    profileInfos: (state, profileInfos) => {
      state.profileInfos = profileInfos;
    },
    profileCoverInfos: (state, profileCoverInfos) => {
      state.profileCoverInfos = profileCoverInfos;
    },
    familyInfos: (state, familyInfos) => {
      state.familyInfos = familyInfos;
    },
    userType: (state, userType) => {
      state.userType = userType;
    },
    userName: (state, userName) => {
      state.userName = userName;
    },
    mbrNo: (state, mbrNo) => {
      state.mbrNo = mbrNo;
    },
    email: (state, email) => {
      state.email = email;
    },
    svcJoinYn: (state, svcJoinYn) => {
      state.svcJoinYn = svcJoinYn;
    },
    walletYn: (state, walletYn) => {
      state.walletYn = walletYn;
    },
    stateYn: (state, stateYn) => {
      state.stateYn = stateYn;
    },
    profileDetails: (state, profileDetails) => {
      state.profileDetails = profileDetails;
    },
    reminderInfos: (state, reminderInfos) => {
      state.reminderInfos = reminderInfos;
    },
    followers: (state, followers) => {
      state.followers = followers;
    },
    following: (state, following) => {
      state.following = following;
    },
    zpcd: (state, zpcd) => {
      state.zpcd = zpcd;
    },
    stat: (state, stat) => {
      state.stat = stat;
    },
    city: (state, city) => {
      state.city = city;
    },            
    wlltType: (state, wlltType) => {
      state.wlltType = wlltType;
    },
    ethWallet: (state, ethWallet) => {
      state.ethWallet = ethWallet;
    },
    hdrWallet: (state, hdrWallet) => {
      state.hdrWallet = hdrWallet;
    },
  },
  actions: userStoreActions,
};
