<template>
  <IconBase
    icon-name="icCheck"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="1.2"
  >
    <path data-name="Path 2349" d="M25.89 8.748 13.162 22.476 5.667 13.98" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcCheck',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Checked',
    },
  },
};
</script>

<style scoped></style>
