<template>
  <IconBase
    icon-name="icCamera"
    :icon-label="iconLabel"
    :width="30"
    :height="30"
    viewBox="0 0 30 30"
    :strokeColor="strokeColor"
    stroke-width="1.2"
  >
    <path d="M7.5 11.3h2v.2h-2v-.2z" />
    <path
      d="M18 6.4h-6.2l-1.1 2H4.6v14.9h20.8V8.4h-6.2l-1.2-2z"
      stroke-linejoin="round"
    />
    <circle cx="15.1" cy="15.9" r="4.1" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcCamera',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Camera',
    },
  },
};
</script>

<style scoped></style>
