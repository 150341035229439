<template>
  <i class="ic" :style="`width: ${width / 10}rem; height: ${height / 10}rem`">
    <span class="is_blind">{{ iconLabel }}</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :viewBox="viewBox"
      aria-hidden="true"
    >
      <g :fill="iconColor" :stroke-width="strokeWidth" :stroke="strokeColor">
        <slot />
      </g>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'IconBase',
  props: {
    viewBox: {
      type: String,
      default: null,
    },
    iconName: {
      type: String,
      default: '',
    },
    iconLabel: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    width: {
      type: [String, Number],
      default: 30,
    },
    height: {
      type: [String, Number],
      default: 30,
    },
    strokeWidth: {
      type: [String, Number],
      default: 3,
    },
    strokeColor: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>

<style scoped></style>
