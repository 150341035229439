import { render, staticRenderFns } from "./IcItems.vue?vue&type=template&id=d4972898&scoped=true"
import script from "./IcItems.vue?vue&type=script&lang=js"
export * from "./IcItems.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4972898",
  null
  
)

export default component.exports