<template>
  <IconBase
    icon-name="IcDownload"
    :icon-label="iconLabel"
    :width="24"
    :height="24"
    viewBox="0 0 24 24"
    :stroke-color="strokeColor"
    :stroke-width="strokeWidth"
  >
    <path data-name="Path 195" d="M20.5 20h-17" />
    <path data-name="Path 197" d="M12.001 15V4" />
    <path data-name="Path 2367" d="m6.5 10 5.5 5 5.5-5" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcMobDownload',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    strokeWidth: {
      type: [Number, String],
      default: 2,
    },
    iconLabel: {
      type: String,
      default: 'Download',
    },
  },
};
</script>

<style scoped></style>
