<template>
  <main id="contents" class="my_collection_page mode_page">
    <h1 class="page_ttl is_blind">MY Collections View Mode</h1>
    <div class="set_inner" v-if="collections">
      <section
        class="collection_list_sec"
        v-if="collections && collections.length > 0"
      >
        <div class="collection_list_sec_head">
          <div class="ttl_group">
            <div class="ttl_area">
              <h2 class="ttl">My Collections</h2>
              <p role="status">Total Collections {{ collectionsCount }}</p>
            </div>
            <a href="/collections" class="btn type_line_w w_l h_l mode">
              <ArwPrevs />
              <span>Back</span>
            </a>
          </div>
        </div>
        <div
          v-for="item in collections"
          :key="item.artworkId"
          class="collection_list_sec_body"
          :class="{ is_mode: showButtons }"
        >
          <div
            class="ttl_area"
            v-if="item.collectionInfos && item.collectionInfos.length > 0"
          >
            <!-- <h2 class="ttl">
              <em>{{ item.name }}</em
              >'s Collections
              <em> {{ item.collectionInfos.length }}</em>
            </h2> -->
          </div>
          <ul class="grid grid_15 ib edit_list">
            <template v-for="(card, index) in item.collectionInfos">
              <li
                v-if="item.isViewAll || index < 8 || showButtons"
                :key="card.id"
                class="col col_3 edit_node"
              >
                <CollectionsCard
                  :itemData="card"
                  :show-badge="true"
                  :showButtons="showButtons"
                  :isAllChecked="isAllChecked"
                  @setChecked="setChkItem"
                />
              </li>
            </template>
          </ul>
          <div
            class="view_btn_area"
            v-if="
              !showButtons &&
              item.collectionInfos &&
              item.collectionInfos.length > 8
            "
          >
            <button
              class="view_btn"
              :class="{ is_active: item.isViewAll }"
              type="button"
              @click="viewBtn(item)"
            >
              {{ item.isViewAll ? 'Fold' : 'View All' }}
              <ArwSelect
                aria-hidden="true"
                stroke-color="var(--disabled-colr)"
              />
            </button>
          </div>
        </div>
        <div class="btn_group" :class="{ mob_style: showButtons }">
          <div class="btn_area">
            <!--
              <button class="dv_mob" v-if="showButtons" @click="selectBtn">
                <IcCloseS stroke-width="2" stroke-color="#fff" />
              </button>
               <button
                class="btn w_xs h_l"
                :class="showButtons ? 'type_line_w' : 'strong'"
                @click="selectBtn"
                ref="selectBtn"
              >
                {{ showButtons ? 'Cancel' : 'Select' }}
              </button> -->
            <div class="input_area check" v-if="showButtons">
              <div class="input_box">
                <label for="allCheckbox">
                  <input
                    id="allCheckbox"
                    type="checkbox"
                    v-model="isAllChecked"
                    @change="allSelect"
                    label="isAllChecked"
                  />
                  <span>All</span>
                </label>
                <span class="txt_selected" role="status"
                  >{{ selectedCollections.length }} selected</span
                >
              </div>
            </div>
            <template v-if="showButtons">
              <button class="btn w_xs h_l strong" @click="play">Play</button>
              <button class="btn w_xs h_l basic" @click="download">
                Download
              </button>
              <button class="play dv_mob" @click="play">
                <IcPlayS />
              </button>
              <button class="download dv_mob" @click="download">
                <IcMobDownload />
              </button>
            </template>
          </div>

          <!-- <div
              class="input_area select type_hoz drop_ui"
              :class="{ mob_none: showButtons }"
            >
              <div class="input_box drop_box">
                <button
                  class="drop_btn has_arw"
                  :class="{ is_active: showSelect }"
                  role="combobox"
                  aria-haspopup="listbox"
                  aria-controls="dropOpts"
                  aria-labelledby="dropLabel"
                  :aria-activedescendant="'sort_op_' + focusOpt"
                  @click="openDrop('dropOpts', $event)"
                  :disabled="showButtons"
                >
                  {{ selectedOpt.label }}
                  <ArwSelect aria-hidden="true" />
                </button>
                <transition name="fade">
                  <ul
                    v-if="showSelect"
                    ref="dropOpts"
                    id="dropOpts"
                    class="drop_op_list"
                    role="listbox"
                  >
                    <li
                      v-for="(opt, i) in accountData"
                      :key="'sort_op_' + i"
                      :id="'sort_op_' + i"
                      role="option"
                      aria-selected="false"
                      :class="[
                        { is_focus: focusOpt === i },
                        { is_active: selectedOpt.value === opt.value },
                      ]"
                      @click="selOtp(opt, i)"
                    >
                      <span>{{ opt.label }}</span>
                    </li>
                  </ul>
                </transition>
              </div>
            </div> -->
        </div>
      </section>
      <section class="collection_list_sec" v-else>
        <div class="collection_list_sec_head">
          <div class="ttl_group">
            <div class="ttl_area">
              <h2 class="ttl">My Collections</h2>
            </div>
          </div>
        </div>
        <div class="collection_list_sec_body">
          <div class="guide_ui no_data">
            <div class="img_area">
              <picture>
                <source
                  media="(max-width: 600px)"
                  type="image/png"
                  :srcset="`${require('@/assets/img/img_mob_illust_nodata_l.png')} 4x, ${require('@/assets/img/img_mob_illust_nodata_m.png')} 3x, ${require('@/assets/img/img_mob_illust_nodata.png')}`"
                />
                <img
                  :src="require('@/assets/img/img_illust_nodata.png')"
                  alt="nodata"
                />
              </picture>
            </div>
            <p>{{ userName }} doesn’t own Any Artworks yet!</p>
            <button
              class="btn w_xl h_l strong"
              @click="goMarketPlaceItemList()"
            >
              Go to Marketplace
            </button>
          </div>
        </div>
      </section>
    </div>
    <ErrorModal />
    <LoadingModal :isLoading="isLoading" message="Wait for Downloads..." />
    <DetailViewer
      v-if="isShowDetailView"
      :collections="selectedCollections"
      :currentIndex="0"
      :isFullScreen="true"
      :singleVideoLoop="selectedCollections.length == 1"
      @hide="hide"
    />
  </main>
</template>

<script>
import ArwPrevs from '@/components/ic/arwPrevS';
import CollectionsCard from '@/components/mycollection/CollectionsCard';
import ArwSelect from '@/components/ic/arwSelect';
import ErrorModal from '@/components/mycollection/ErrorModal';
//import IcCloseS from '@/components/ic/IcCloseS';
import IcPlayS from '@/components/ic/IcPlayS';
import IcMobDownload from '@/components/ic/IcMobDownload';
import DetailViewer from '@/views/DetailViewer.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MyCollectionModeView',
  components: {
    IcMobDownload,
    IcPlayS,
    //IcCloseS,
    ErrorModal,
    ArwSelect,
    CollectionsCard,
    ArwPrevs,
    DetailViewer,
  },
  data() {
    return {
      // accountData: [
      //   {
      //     label: 'All Accounts',
      //     value: 'All',
      //   },
      // ],
      //showSelect: false,
      // selectedOpt: {
      //   label: 'All Accounts',
      //   value: 'All',
      // },
      //focusOpt: 0,
      showButtons: true, //기획 변경으로 Select, Cancel 버튼 미노출 및 활성화, Fold기능 비활성(기존 조건 유지)
      collections: null,
      collectionsStore: [],
      collectionsCount: 0,
      selectedCollections: [],
      isShowDetailView: false,
      isAllChecked: false,
      isLoading: false,
      downloadEmpty: {
        type: 'warn',
        desc: 'Please select collection.',
        id: 'downloadempty',
      },
      downloadExceed: {
        type: 'warn',
        desc: 'You can download 5 or more Artworks at a time.',
        id: 'downloadExceed',
      },
      downloadOtherMarket: {
        type: 'warn',
        desc: 'External works cannot be downloaded. \nPlease cancel the check box.',
        id: 'downloadExceed',
      },
      downloadFailAlert: {
        type: 'warn',
        desc: 'Sorry, Something is wrong.',
      },
      requireSelected: {
        type: 'warn',
        desc: 'Please select collection.',
      },
    };
  },
  computed: {
    ...mapGetters({
      userName: 'userStore/userName',
    }),
  },
  mounted() {
    this.api.getMyCollectionList({}).then(res => {
      if (res.code === 0) {
        res.myCollectionInfos.map(v => (v.isViewAll = false));
        if (res.myCollectionInfos[0].collectionInfos) {
          this.collections = res.myCollectionInfos;
          this.collectionsStore = res.myCollectionInfos;
          this.collections.map(
            v =>
              (this.collectionsCount += v.collectionInfos
                ? v.collectionInfos.length
                : 0),
          );
          this.collections[0].collectionInfos.map((v, i) => {
            v.idx = i;
          });
        } else {
          this.collections = [];
        }
      } else {
        this.$router.push('/network');
      }
    });
    // this.api.getUserFamilyList({}).then(res => {
    //   const name = [];
    //   if (res.code === 0) {
    //     this.collections.map(v => name.push(v.name));
    //     res.familyInfos.map(v => {
    //       if (name.includes(v.userName)) {
    //         this.accountData.push({
    //           label: v.userName,
    //           value: v.mbrNo,
    //         });
    //       }
    //     });
    //   } else {
    //     this.$router.push('/network');
    //   }
    // });
  },
  methods: {
    goMarketPlaceItemList() {
      this.$router.push('/marketplace');
    },
    viewBtn(item) {
      item.isViewAll = !item.isViewAll;
    },
    // openDrop() {
    //   this.showSelect = !this.showSelect;
    //   this.showFilter = false;
    // },
    // selOtp(opt, idx) {
    //   this.showSelect = false;
    //   this.selectedOpt = opt;
    //   this.focusOpt = idx;
    //   // this.collections = opt;
    //   this.collections = this.collectionsStore;
    //   if (opt.value !== 'All')
    //     this.collections = this.collections.filter(v => v.mbrNo === opt.value);

    //   this.collectionsCount = 0;
    //   this.collections.map(
    //     v => (this.collectionsCount += v.collectionInfos.length),
    //   );
    // },
    selectBtn() {
      this.showButtons = !this.showButtons;
    },
    setChkItem(data) {
      // 체크된 아이템 정보 받음
      if (data.isChecked) {
        this.selectedCollections.push(data.item);
      } else {
        for (const key in this.selectedCollections) {
          if (this.selectedCollections[key].idx === data.item.idx)
            this.selectedCollections.splice(key, 1);
        }
      }
    },
    hide() {
      this.isShowDetailView = false;
    },
    allSelect() {
      if (this.isAllChecked) {
        this.selectedCollections = [];
        this.collections.map(v =>
          v.collectionInfos.map(v2 => this.selectedCollections.push(v2)),
        );
      } else this.selectedCollections = [];
    },
    download() {
      if (!this.selectedCollections.length) {
        this.$root.$emit('openAlert', this.downloadEmpty);
        return;
      }

      for (let item of this.selectedCollections) {
        if (item.otherMaplYn == 'Y') {
          this.$root.$emit('openAlert', this.downloadOtherMarket);
          return false;
        }
      }

      if (this.selectedCollections.length >= 5) {
        this.$root.$emit('openAlert', this.downloadExceed);
        return;
      }

      this.isLoading = true;
      const editionKeys = [];
      this.selectedCollections.map(v => {
        editionKeys.push({ artworkId: v.artworkId, editionId: v.editionId });
      });
      const data = {
        editionKeys: editionKeys,
      };
      this.axios({
        method: 'post',
        url: '/api/v1/download/multipleFile',
        responseType: 'blob',
        data: data,
      }).then(res => {
        this.isLoading = false;
        if (res.status === 200) {
          const blob = new Blob([res.data], { type: 'application/zip' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `artLab.zip`;
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } else {
          this.$root.$emit('openAlert', this.downloadFailAlert);
        }
      });
    },
    play() {
      if (this.selectedCollections && this.selectedCollections.length) {
        // NOTE: 23-02-14  expose FHD video(1920 * 1080) at detailViewer - requested by hyLee (PM)
        // TODO : resource List sort method commonization ( like mixins )
        let standardOfPriority = 51;

        this.selectedCollections.forEach(async selectedCollection => {
          if (
            selectedCollection.otherMaplYn == 'Y' &&
            !selectedCollection.videoInfos &&
            !selectedCollection.videoFail
          ) {
            selectedCollection.videoInfos = await this.setOtherMarket(
              selectedCollection.contractAddr,
              selectedCollection.tokenId,
            );
          }
          if (
            selectedCollection?.videoInfos &&
            selectedCollection.videoInfos.length > 0
          ) {
            selectedCollection.videoInfos.sort((a, b) => {
              if (
                Math.abs(a.rsrcTp - standardOfPriority) >
                Math.abs(b.rsrcTp - standardOfPriority)
              ) {
                return 1;
              } else if (
                Math.abs(a.rsrcTp - standardOfPriority) <
                Math.abs(b.rsrcTp - standardOfPriority)
              ) {
                return -1;
              } else {
                return a.rsrcTp < b.rsrcTp ? 1 : -1;
              }
            });
          }
        });
        this.isShowDetailView = true;
      } else {
        this.$root.$emit('openAlert', this.requireSelected);
      }
    },
  },
};
</script>

<style scoped>
.btn.mode i {
  margin-right: 5px !important;
  margin-left: -5px !important;
}
</style>
