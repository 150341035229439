<template>
  <div class="modal_wrap" :class="{ is_show: showWallet }">
    <div class="modal qrModal" :class="modalSize" role="dialog">
      <div class="modal_head">
        <h3 class="modal_ttl">
          {{ modalData.title }}
        </h3>
        <p>{{ modalData.desc }}</p>
      </div>
      <div class="modal_body">
        <div class="qr_group">
          <div class="img_area">
            <div class="qr-code">
              <qrcode-vue
                :value="qrcodeUrl"
                :size="qrcodeSize"
                level="L"
                background="#fff"
                margin="10"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="modalData.title === 'Connect Your Wallet'" class="modal_foot">
        <!-- Dev: 220503 수정 -->
        <p class="txt box_txt">
          If you want to connect wallet later, you can do so in
          <span>My Account <ArwNextS :stroke-width="1.2" /> My Wallet.</span>
        </p>
        <div class="btn_area" @click="close">
          <router-link to="/help">
            <button class="btn strong w_xl h_m">Learn more about Wallet</button>
          </router-link>
        </div>
      </div>
      <!-- <div v-if="modalData.title === 'Change Accounts'" class="modal_foot">
        <p class="txt box_txt">
          After Change is done with Wallypto in your phone, please click the
          button below.
        </p>
        <div class="btn_area">
          <button class="btn strong w_xl h_m">
            Changing Accounts complete
          </button>
        </div>
      </div> -->
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import ArwNextS from '@/components/ic/arwNextS';
import IcCloseM from '@/components/ic/IcCloseM';
export default {
  name: 'WalletQrModal',
  components: { QrcodeVue, IcCloseM, ArwNextS },
  props: {
    modalSize: {
      type: String,
      default: '',
    },
    modalData: {
      type: Object,
      default: () => ({
        title: 'Connect Your Wallet',
        desc: 'Connect a wallet to purchase NFTs from LG Art Lab. \nUse your mobile phone and scan the QR code ',
      }),
    },
    showWallet: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showWallet: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.setFocusTrap(true, '.modal.qrModal');
          this.$root.$emit('setScrollFixed', true);
        } else {
          this.setFocusTrap(false, '.modal.qrModal');
          this.$root.$emit('setScrollFixed', false);
        }
      },
    },
  },
  data() {
    return {
      qrcodeUrl: '',
      qrcodeSize: 380,
    };
  },
  mounted() {
    this.qrcodeUrl = window.location.origin + '/mylist/wallet?redirect=true';
  },
  methods: {
    close() {
      this.setFocusTrap(false, '.modal.qrModal');
      this.$root.$emit('setScrollFixed', false);
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal_body .time_area {
  display: inline-block;
  min-width: 170px;
  padding: 9px 18px;
  margin: 20px 0 0;
  border: 1px solid #707070;
  font-size: 1.6rem;
  font-weight: 300;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.modal .modal_body + .modal_foot {
  margin-top: 24px;
}
.modal_foot p.txt {
  font-size: 1.4rem;
  color: var(--txt-gray-2);
}
.modal_foot p.txt span {
  color: #fff;
}
.modal_foot p.txt .ic {
  vertical-align: -7px;
  margin: 0 -3px 0 -4px;
}
.modal_foot .btn_area {
  margin-top: 30px;
}
.qr_group {
  position: relative;
}
.qr_group.is_disabled .img_area {
  opacity: 0.2;
}
.qr_group .img_area {
  display: flex;
  justify-content: center;
}
.qr_group .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.qr-code {
  background-color: #fff;
  padding: 10px;
}
</style>
