<template>
  <main id="contents" class="footer_page help_page">
    <div class="set_inner">
      <div class="page_ttl_area">
        <h1 class="page_ttl">Help Center</h1>
        <div class="btn_area">
          <button class="" @click="openMailForm" ref="modalBtn">
            <div class="mail-form">
              <span>Submit a request</span>
              <ArwNextS />
            </div>
          </button>
        </div>
      </div>
      <section class="board_sec">
        <div class="search_wrap">
          <div class="input_area search">
            <div class="input_box">
              <input
                v-model="search"
                type="search"
                placeholder="Search help articles…"
                @keyup.enter="handleSearch"
                ref="searchRef"
                label="Search"
                title="Search"
              />
              <button class="btn_search" @click="handleSearch">
                <IcSearch class="ic_search" />
              </button>
              <button
                v-if="search !== ''"
                @click="clear"
                type="button"
                class="btn type_ic remove_word"
              >
                <IcCloseS
                  :iconLabel="'Clear search field'"
                  stroke-color="#fff"
                  :width="20"
                  :height="20"
                  :stroke-width="1"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="tab_wrap">
          <div class="tab_list" role="tablist" v-if="resultIndex === 0">
            <div
              class="tab_node"
              v-for="(item, index) in tabMenu"
              :key="index"
              @click="handleTabClick(index)"
              style="white-space: pre-wrap"
            >
              <button
                class="tab_item"
                role="tab"
                :class="{ is_active: index === activeIndex }"
              >
                <span v-if="index === activeIndex" class="is_blind"
                  >selected</span
                >
                {{ item.cateName }}
              </button>
            </div>
          </div>
        </div>
        <!-- Dev : 검색 전 레이아웃 -->
        <template v-if="resultIndex === 0">
          <ul>
            <li v-for="item in faqInfos" :key="item.num">
              <div class="board_item basic">
                <router-link
                  :aria-describedby="'board' + item.num"
                  :to="{
                    name: `helpCenterDetail`,
                    query: { num: item.num, cateNo: cateNo },
                  }"
                >
                  <div class="item_body">
                    <div class="item_info_area">
                      <p class="ttl">{{ item.title }}</p>
                    </div>
                  </div>
                  <div class="btn_area">
                    <button class="only_pc">
                      <ArwNext />
                    </button>
                    <button class="dv_tab">
                      <ArwNextS />
                    </button>
                  </div>
                </router-link>
              </div>
            </li>
          </ul>
          <div>
            <button class="license_area" @click="openLicense">
              <div class="license">
                Open source license
                <span class="is_blind">Open in new window</span>
                <ArwNextS stroke-color="var(--txt-gray)" />
              </div>
            </button>
            <Pagination
              v-model="currentPage"
              :totalCount="totalCount"
              pageSize="10"
            ></Pagination>
          </div>
        </template>
      </section>
    </div>
    <MailModal ref="MailModal" @closeModal="closeModal" />
  </main>
</template>

<script>
import ArwNext from '@/components/ic/arwNext';
import ArwNextS from '@/components/ic/arwNextS';
import IcSearch from '@/components/ic/IcSearch';
import IcCloseS from '@/components/ic/IcCloseS';
import MailModal from '@/components/common/MailModal';
import Pagination from '@/components/common/Pagination';
import { mapGetters } from 'vuex';
export default {
  name: 'HelpCenterBoardView',
  components: {
    IcSearch,
    ArwNext,
    IcCloseS,
    ArwNextS,
    MailModal,
    Pagination,
  },
  data() {
    return {
      tabMenu: [],
      activeIndex: 0,
      resultIndex: 0,
      search: '',
      faqInfos: [],
      // paginations
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      rows: 10,
      maxViewCount: 5, // 최대 페이지 표시 개수
      remainder: 0,
      cateNo: 0,
      loginAlert: {
        type: 'warn_2',
        desc: 'Please sign in.',
        success: 'Sign in',
      },
    };
  },
  computed: {
    ...mapGetters({
      accessToken: 'appStore/accessToken',
    }),
  },

  watch: {
    async currentPage() {
      await this.getFaqList(this.cateNo);
    },
  },
  async mounted() {
    await this.getFaqList();
  },
  methods: {
    getFaqList(cateNo) {
      this.cateNo = cateNo || 0;
      const data = {
        cateNo: this.cateNo,
        page: this.currentPage,
      };

      this.api.getFaqList(data).then(res => {
        if (res.code === 0) {
          this.tabMenu = res.faqCategoryInfos;
          this.faqInfos = res.faqInfos;
          this.totalCount = res.totalCnt;
          this.pageCount = Math.ceil(this.totalCount / this.rows);
        } else {
          this.$router.push('/network');
        }
      });
    },
    handleTabClick(index) {
      if (this.activeIndex !== index) {
        this.currentPage = 1;
        this.remainder = 0;
        this.getFaqList(this.tabMenu[index].cateNo);
      }
      this.activeIndex = index;
    },
    handleSearch(event) {
      let keyword = event.target.value || this.search;
      if (!keyword) return false;
      this.$router.push({
        name: 'helpCenterResult',
        params: { keyword },
      });
    },
    openMailForm() {
      if (this.accessToken) {
        this.$refs['MailModal'].open();
      } else {
        this.$root.$emit('openAlert', this.loginAlert);
      }
    },
    openLicense() {
      let setOption =
        'top=10, left=10, width=700, height=600, status=no, menubar=no, toolbar=no, resizable=no';
      let url = '../openSourceLicenseInfo';
      window.open(url, 'ArtLab OpenSourceLicense', setOption);
    },
    clear() {
      this.search = '';
      this.keyword = '';
      this.$refs.searchRef.focus();
    },
    closeModal() {
      setTimeout(() => {
        this.$refs.modalBtn.focus();
      }, 100);
    },
  },
};
</script>

<style scoped>
.guide_ui .img_area {
  margin-bottom: -1rem;
}
.request-ic {
  width: 2rem;
  height: 2rem;
}
.btn-mail {
  color: #fff;
  border: 1px solid var(--disabled-colr);
}
.mail-form {
  display: flex;
  align-items: center;
  font-size: 1.8rem !important;
}
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
}
@media screen and (max-width: 600px) {
  .guide_ui .img_area {
    margin-bottom: 0;
  }
}
.license_area {
  cursor: pointer;
  margin-top: 4px;
  position: absolute;
  z-index: 5;
}
.license {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--txt-gray);
}

@media screen and (max-width: 767px) {
  .license {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--txt-gray);
  }
  .license_area {
    margin-top: 4px;
    position: absolute;
    text-align: left;
  }
  .pagination_wrap {
    margin-top: 2rem;
  }
  .license_area {
    margin-top: 50px;
  }
}
textarea:focus,
input:focus {
  outline: none;
}
</style>
