import Vue from 'vue';

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */

Vue.filter('numberFormatter', function (num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      );
    }
  }
  return num ? String(num) : 0;
});

/**
 * 10000 => "10,000"
 * @param {number} num
 */
Vue.filter('addCommas', function (num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
});

/**
 * set Number 12345678 -> 12,345,678.00
 *  * @param {String} string
 */
Vue.filter('setNum', function (num) {
  if (num) {
    return num
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return (+num || 0).toFixed(2);
  }
});

Vue.filter('usdFormatter', function (num, digits = 0) {
  let NumAbbr = require('number-abbreviate');
  let numAbbr = new NumAbbr();
  return numAbbr.abbreviate(num, digits);
});
/**
 * Upper case first char
 * @param {String} string
 */
Vue.filter('uppercaseFirst', function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
});

Vue.filter('crtpyAbbreviate', function (num, digits = 2) {
  let NumAbbr = require('number-abbreviate');
  let numAbbr = new NumAbbr();

  let result;
  if (num > 0) {
    //0620 요청 해당 케이스 < 0 표기
    if (num.toFixed(2) == 0 && num > 0) {
      return '< $0.01';
    }
    //0514 금액 관련은 소수점 2자리 반올림으로 일단 적용
    result =
      num !== undefined
        ? numAbbr.abbreviate(num.toFixed(digits), digits)
        : 'NULL';
  } else {
    result = num;
  }
  return '$' + result;
});
