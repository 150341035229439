<template>
  <IconBase
    icon-name="icAlarm"
    :icon-label="iconLabel"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :stroke-color="strokeColor"
    :stroke-width="2"
  >
    <path
      data-name="Path 75"
      d="M17.21 14.298V8.67a5.22 5.22 0 0 0-10.44 0v5.628l-1.847 2.008h14.134Z"
    />
    <path data-name="Path 109" d="M9.064 20.161h5.711" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcMobAlarm',
  components: { IconBase },
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Alarm',
    },
  },
};
</script>

<style scoped></style>
