<template>
  <div class="modal_wrap" :class="{ is_show: show }" v-if="!inner">
    <div
      id="UpdateInfoModal"
      ref="UpdateInfoModal"
      class="modal"
      role="dialog"
      aria-labelledby="UpdateInfoModal_title"
      aria-describedby="UpdateInfoModal_desc"
    >
      <!-- PUB : ZIP CODE 디자인 수정 S -->
      <div class="modal_head">
        <h3 id="UpdateInfoModal_title" class="modal_ttl">
          Enter Your ZIP Code
        </h3>
      </div>
      <div class="modal_body">
        <div class="img_area">
          <picture>
            <img
              :srcset="`${require('@/assets/img/img_modal_illust_update_info_l.png')} 4x, ${require('@/assets/img/img_modal_illust_update_info_m.png')} 3x, ${require('@/assets/img/img_modal_illust_update_info.png')}`"
              :src="require('@/assets/img/img_illust_update_info.png')"
              alt="illust_update_info"
              width="164"
            />
          </picture>
        </div>
        <p class="desc">
          To purchase, we need our Zip Code to estimate your tax in total.
          Please enter your Zip Code in the box below.
        </p>
        <div
          class="input_area select type_hoz drop_ui"
          :class="{ is_pc: !$isMobile() }"
        >
          <label for="ZipCode" class="is_blind">Zip Code</label>
          <!-- 에러있을때 인풋박스에 border-color 추가 : is_border: isError -->
          <input
            type="text"
            id="ZipCode"
            class="drop_btn has_arw inputbox"
            :class="{ is_pc: !$isMobile(), is_border: isError }"
            placeholder="Enter your ZIP Code"
            title="Enter your ZIP Code"
            v-model="zipCode"
            ref="zipcode"
            @keyup="checkKey"
            @input="checkInput"
            @blur="checkBlur"
          />
          <p v-if="errorMsg" class="error_msg" :class="{ is_pc: !$isMobile() }">
            Invalid Zip Code
          </p>
          <div
            class="input_box drop_box"
            v-if="showSelectBox"
            :class="{ is_pc: !$isMobile() }"
          >
            <span class="is_blind" id="openBox">City</span>
            <button
              class="drop_btn has_arw"
              :class="{ is_active: showSelect, is_pc: !$isMobile() }"
              role="combobox"
              aria-haspopup="listbox"
              aria-controls="openSelectBox"
              aria-labelledby="openBox"
              :aria-expanded="showSelect ? 'true' : 'false'"
              :aria-activedescendant="'sort_op_' + focusOpt"
              @click="openBox($event)"
            >
              {{ viewCity }}
              <ArwSelect aria-hidden="true" />
            </button>
            <transition name="fade">
              <ul
                v-if="showSelect"
                class="drop_op_list"
                :class="{
                  is_scroll: showSelectBoxScroll,
                  is_edit: profileType == 'edit',
                  is_pc: !$isMobile(),
                }"
                role="listbox"
              >
                <li
                  v-for="(opt, i) in cityData"
                  :key="'sort_op_' + i + 1"
                  :id="'sort_op_' + i + 1"
                  role="option"
                  aria-selected="false"
                  @click="selOtp(opt, i)"
                  @keydown.enter="selOtp(opt, i)"
                  tabindex="0"
                >
                  <div class="input_area check">
                    <div class="input_box">
                      <label>
                        <span>
                          {{ cityElipsis(opt.city) }}, {{ opt.stat }}
                        </span>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            </transition>
          </div>
        </div>
        <!-- <div
          v-if="selectId && showPriceBox"
          class="price_box"
          :class="{ is_pc: !$isMobile() }"
        > -->
        <div class="price_area">
          <span class="tit">Price & Tax Info</span>
          <div
            class="price_box"
            :class="{ is_pc: !$isMobile() }"
            v-if="selectId && showPriceBox"
          >
            <p>
              <span class="gray_tit">Price</span>
              <b v-if="bcNwTp == '10'"
                ><span>{{ price | setNum }} USDC</span></b
              >
              <b v-else
                >{{ price }}<span class="unit">Ξ</span
                ><span class="dollars"
                  >({{ priceToEthUsd | crtpyAbbreviate }})</span
                ></b
              >
            </p>
            <p>
              <span class="gray_tit">Estimated Tax</span>
              <b v-if="bcNwTp == '10'">
                <span class="gray_tit">Estimated Tax</span>
                {{ taxOnly | setNum }} USDC
              </b>
              <b v-else
                >{{ taxOnly }}<span class="unit">Ξ</span
                ><span class="dollars"
                  >({{ taxToEthUsd | crtpyAbbreviate }})</span
                ></b
              >
            </p>
          </div>
          <div v-else class="price_box">
            <p class="desc">
              After entering a zip code, you can find out the price and tax
              information of the artwork you want to purchase.
            </p>
          </div>
        </div>
      </div>
      <div class="modal_foot">
        <div class="btn_area">
          <button class="btn w_s h_m basic" @click="close">Cancel</button>
          <button class="btn w_s h_m strong" @click="save">Save</button>
        </div>
      </div>
      <!-- PUB : ZIP CODE 디자인 수정 E -->
      <button class="btn type_ic close_modal" @click="close">
        <IcCloseM />
      </button>
    </div>
  </div>
  <div v-else class="inner">
    <div
      class="input_area select type_hoz drop_ui"
      :class="{ is_pc: !$isMobile() }"
    >
      <label for="ZipCode" class="is_blind">Zip Code</label>
      <!-- 에러있을때 인풋박스에 border-color 추가 : is_border: isError -->
      <input
        type="text"
        id="ZipCode"
        class="drop_btn has_arw inputbox"
        :class="{ is_pc: !$isMobile(), is_border: isError }"
        placeholder="Enter your ZIP Code"
        title="Enter your ZIP Code"
        v-model="zipCode"
        ref="zipcode"
        @keyup="checkKey"
        @input="checkInput"
        @blur="checkBlur"
      />
      <p v-if="errorMsg" class="error_msg" :class="{ is_pc: !$isMobile() }">
        Invalid Zip Code
      </p>
      <div
        class="input_box drop_box"
        v-if="showSelectBox"
        :class="{ is_pc: !$isMobile() }"
      >
        <span class="is_blind" id="openBox">City</span>
        <button
          class="drop_btn has_arw"
          :class="{ is_active: showSelect, is_pc: !$isMobile() }"
          role="combobox"
          aria-haspopup="listbox"
          aria-controls="openSelectBox"
          aria-labelledby="openBox"
          :aria-expanded="showSelect ? 'true' : 'false'"
          :aria-activedescendant="'sort_op_' + focusOpt"
          @click="openBox($event)"
        >
          {{ viewCity }}
          <ArwSelect aria-hidden="true" />
        </button>
        <transition name="fade">
          <ul
            v-if="showSelect"
            class="drop_op_list"
            :class="{
              is_scroll: showSelectBoxScroll,
              is_edit: profileType == 'edit',
              is_pc: !$isMobile(),
            }"
            role="listbox"
          >
            <li
              v-for="(opt, i) in cityData"
              :key="'sort_op_' + i + 1"
              :id="'sort_op_' + i + 1"
              role="option"
              aria-selected="false"
              @click="selOtp(opt, i)"
              tabindex="0"
              @keydown.enter="selOtp(opt, i)"
            >
              <div class="input_area check">
                <div class="input_box">
                  <label>
                    <span>{{ cityElipsis(opt.city) }}, {{ opt.stat }} </span>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import IcCloseM from '@/components/ic/IcCloseM';
import ArwSelect from '@/components/ic/arwSelect';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'UpdateInfoModal',
  components: { IcCloseM, ArwSelect },
  props: {
    price: {
      type: Number,
      default: 0,
    },
    inner: {
      type: Boolean,
      default: false,
    },
    profileType: {
      type: String,
      default: '',
    },
    selectId: {
      type: [String, Number],
      default: '',
    },
    bcNwTp: {
      type: [String, Number],
      default: '',
    },
    priceToEthUsd: {
      type: [String, Number],
      default: '',
    },
  },
  watch: {
    inner: {
      immediate: true,
      handler(v) {
        if (v == true) {
          this.zipCode = this.zpcd;
          this.cityOpt.city = this.city;
          this.cityOpt.stat = this.stat;
          this.modifyCnt = 0;
          this.show = true;
          this.showSelect = false;

          if (this.zipCode) this.checkPrice();
        } else {
          this.zipCode = '';
          this.show = false;
          this.reset();
        }
      },
    },
  },
  data() {
    return {
      show: false,
      focusOpt: 0,
      zipCode: '',
      showSelect: false,
      cityParam: {},
      cityOpt: {},
      cityData: [],
      saveParam: {},
      errorMsg: false,
      isError: false,
      polling: null,
      overtimer: 18000,
      modifyCnt: 0,
    };
  },
  // PUB : 에러있을때 인풋박스 border-color 추가 isError
  computed: {
    ...mapGetters({
      zpcd: 'userStore/zpcd',
      city: 'userStore/city',
      stat: 'userStore/stat',
    }),
    showSelectBox() {
      return this.zipCode.length === 5 && this.cityData.length > 0
        ? true
        : false;
    },
    showSelectBoxScroll() {
      return this.zipCode.length === 5 && this.cityData.length > 1
        ? true
        : false;
    },
    showPriceBox() {
      return this.zipCode.length === 5 &&
        this.cityOpt.city &&
        this.price !== 0 &&
        this.cityData.length > 0
        ? true
        : false;
    },
    viewCity() {
      return this.cityOpt.city
        ? this.cityElipsis(this.cityOpt.city) + ', ' + this.cityOpt.stat
        : 'Please Select City';
    },
    taxOnly() {
      return this.cityOpt.tax ? this.cityOpt.tax : 0;
    },
    taxToEthUsd() {
      return this.cityOpt.tax ? this.cityOpt.taxToEthUsd : 0;
    },
  },
  methods: {
    open() {
      this.show = true;

      setTimeout(() => {
        this.$refs.zipcode.focus();
      }, 100);
      this.$root.$emit('setScrollFixed', true);
    },
    cityElipsis(value) {
      if (value && value.length > 28) {
        return value.substring(0, 25) + '...';
      }
      return value;
    },
    close() {
      this.reset();
      this.zipCode = '';
      this.show = false;
      this.showSelect = false;
      this.modifyCnt = 0;
      clearInterval(this.polling);
      this.$root.$emit('setScrollFixed', false);
    },
    openBox() {
      this.showSelect = !this.showSelect;
    },
    selOtp(opt, i) {
      this.focusOpt = i;
      this.cityOpt = opt;
      this.showSelect = false;

      let modalZipCodeInfo = {
        zipCode: this.zipCode,
        cityOpt: this.cityOpt,
      };

      this.$emit('setZipcode', modalZipCodeInfo);
    },
    checkBlur() {
      if (!this.zipCode) return false;
      var regExp = /^\.|\.$/;
      let value = this.zipCode;
      if (regExp.test(value)) {
        this.zipCode = value.replace('.', '');
      }
      this.checkPrice();
    },
    checkInput(e) {
      var value = e.target.value;
      var regExp = /^\d{0,10}?$/;
      if (!regExp.test(value) || value.length > 5) {
        let result = value.substring(0, 5);
        this.zipCode = result;
      }
      if (this.zipCode.length == 5) {
        this.zipCodeCityList();
      } else {
        this.reset();
      }
    },
    checkKey(e) {
      e = e || window.event;
      let charCode = e.which || e.keyCode;
      if (!((charCode >= 48 && charCode <= 57) || charCode === 46)) {
        e.preventDefault();
        return false;
      }
    },
    checkPrice() {
      let regex = /\d{5}/g;
      if (this.zipCode.length > 5) {
        this.zipCode = this.zipCode.match(regex, '')[0];
      }

      if (this.zipCode.length == 5) {
        this.zipCodeCityList();
      }
    },
    async zipCodeCityList() {
      if (this.cityParam.zpcd === this.zipCode) {
        return false;
      }
      this.cityParam = {
        zpcd: this.zipCode,
        price: this.price,
      };

      if (this.bcNwTp) this.cityParam.bcNwTp = this.bcNwTp;

      await this.api.getZipCodeCityList(this.cityParam).then(res => {
        if (res.code === 0) {
          if (res.cityInfo.length > 0) {
            this.cityData = res.cityInfo;
            this.errorMsg = false;
            this.isError = false;
            // PUB : 에러있을때 인풋박스 border-color 추가 isError

            if (res.cityInfo.length == 1) {
              this.selOtp(res.cityInfo[0], 1);
            } else {
              if (this.zipCode === this.zpcd && this.modifyCnt == 0) {
                this.modifyCnt++;
                return false;
              }
              this.showSelect = true;
            }
          } else {
            this.reset();
            this.errorMsg = true;
            this.isError = true;
            // PUB : 에러있을때 인풋박스 border-color 추가 isError
            this.$emit('setZipcode', false);
          }
        } else {
          //this.$root.$emit('openAlert', this.apiFailAlert);
          this.reset();
        }
      });
    },
    reset() {
      this.cityOpt = {};
      this.cityData = [];
      this.cityParam = {};
      this.saveParam = {};
      this.focusOpt = 0;
      this.errorMsg = false;
      this.isError = false;
      // PUB : 에러있을때 인풋박스 border-color 추가 isError
    },
    async save() {
      if (!this.cityOpt.city) {
        return false;
      }
      this.saveParam = {
        zpcd: this.zipCode,
        city: this.cityOpt.city,
        stat: this.cityOpt.stat,
      };

      await this.api.setZipCodeSave(this.saveParam).then(res => {
        if (res.code === 0) {
          this.setZpcd(this.saveParam.zpcd);
          this.setCity(this.saveParam.city);
          this.setStat(this.saveParam.stat);
          this.$emit('zipcodeSave', this.selectId);

          this.close();
        }
      });
    },
    ...mapMutations({
      setUserName: 'userStore/userName',
      setZpcd: 'userStore/zpcd',
      setCity: 'userStore/city',
      setStat: 'userStore/stat',
    }),
  },
};
</script>
<!-- PUB : CSS수정 S -->
<style scoped>
.img_area {
  max-width: 16.4rem;
  margin: 1.5rem auto;
}
.modal .modal_body + .modal_foot {
  margin-top: 4.5rem;
}
.desc {
  font-weight: 300;
}
.drop_ui {
  position: relative;
  display: flex;
  margin-top: 2.8rem;
}
.drop_btn {
  width: 100%;
  padding-right: 0px !important;
  background: #2e2e2e;
}
.drop_btn.is_border {
  border: 1px solid #e31f1f;
}
.drop_ui .drop_btn::placeholder {
  color: #6c6c6c !important;
}
/* .drop_btn.is_pc {
  min-width: 300px !important;
  width: 300px !important;
  min-width: 0 !important;
  text-align: left;
} */
.inputbox {
  outline: 0 !important;
}
.drop_btn.is_pc.inputbox {
  width: 215px;
  min-width: 215px;
  margin-right: 10px;
}
.drop_btn.inputbox::placeholder {
  color: var(--txt-gray-2);
}
.drop_btn.has_arw {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.drop_box.is_pc {
  letter-spacing: -1px;
  width: 335px !important;
}
.input_area.check label {
  text-align: left;
  padding-left: 0px !important;
}
.drop_op_list {
  position: absolute;
  top: unset !important;
  bottom: unset !important;
}
.is_scroll {
  top: unset !important;
  height: 115px !important;
  overflow-y: auto;
}
.is_scroll.is_edit {
  height: 98px !important;
}
.is_scroll.is_edit.is_pc {
  height: 113px !important;
}
.error_msg {
  position: absolute;
  left: 0;
  bottom: -2.2rem;
  float: none;
  color: #e31f1f;
  font-size: 1.3rem;
}
.price_area {
  width: 100%;
  margin: 3rem 0;
  padding: 2rem;
  border: 1px solid #535353;
}
.price_area .tit {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: #fff;
  font-weight: 300;
}
.price_area .tit::before {
  content: '';
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  background: url(../../assets/img/ic/ic_info.svg) center center no-repeat;
  background-size: contain;
}
.price_box {
  width: 100%;
  margin-top: 1rem;
}
.price_box p {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 0 3.5rem;
}
.price_box p + p {
  margin-top: 0.8rem;
}
.price_box .desc {
  text-align: left;
  color: #aaa;
  font-size: 1.4rem;
}
.gray_tit {
  color: var(--txt-gray-2);
  margin-right: 10px;
}
.price_tit {
  font-size: 2rem;
  font-weight: 600;
}
.price_box b {
  display: inline-block;
  font-size: 2rem;
  font-weight: 300;
}

.price_box b .unit {
  padding: 0 0.3rem 0 0.1rem;
}
.price_box b .dollars {
  font-size: 1.4rem;
  font-weight: 200;
}

.inner {
  margin-bottom: 10px;
}
.drop_ui .drop_op_list [role='option'] {
  padding: 5px 14px !important;
}
.drop_ui.is_pc {
  text-align: left;
}

@media screen and (max-width: 767px) {
  .drop_ui {
    flex-direction: column;
  }
  .drop_ui.select .input_box {
    margin-top: 1rem;
  }
  .desc {
    font-size: 1.4rem;
  }
  .price_box p {
    padding: 0;
  }
  .drop_btn.is_pc.inputbox {
    width: 100%;
    margin-right: 0;
  }
  .gray_tit {
    font-size: 1.4rem;
  }
  .error_msg {
    position: relative;
    left: unset;
    bottom: unset;
    margin-top: 0.5rem;
    text-align: left;
  }
  .zipcode_group .drop_ui {
    margin-top: 0 !important;
  }
  .modal .modal_body + .modal_foot {
    margin-top: 3rem;
  }
  .modal_foot .btn_area {
    display: flex;
  }
  .modal_foot .btn_area button {
    flex: 1;
    min-width: auto;
  }
}
</style>
<!-- PUB : CSS수정 E -->
