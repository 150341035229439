<template>
  <main id="contents" class="purchase_page">
    <h1 class="page_ttl is_blind">Purchase</h1>
    <article class="purchase_sec">
      <div class="set_inner">
        <div v-if="type === 'done'" class="guide_ui">
          <div class="img_area">
            <picture>
              <img
                :src="require('@/assets/img/img_illust_done.png')"
                alt="No Result"
              />
            </picture>
          </div>
          <h2 class="ttl">Purchase is complete</h2>
          <p class="desc">
            Your purchase’s successfully done! <br />
            You can appreciate and enjoy your collections in
            <span class="c_point">My Collections</span> or
            <span class="c_point">Profiles</span>.
          </p>
          <div class="add_help_msg_box">
            <div class="add_help_inner">
              <div class="add_help_head">
                <h3>Add artwork to your wallet.</h3>
                <p>
                  copy both Contract Address and Collectible ID and paste them
                  to your wallet.
                </p>
              </div>
              <div class="add_help_content">
                <div class="add_help_content_item">
                  <h4>Contract Address</h4>
                  <div class="add_help_copy">
                    <p>
                      {{ address }}
                    </p>
                    <button class="btn type_ic btn_copy" @click="copy(address)">
                      <IcCopy />
                    </button>
                  </div>
                </div>
                <div class="add_help_content_item">
                  <h4 v-if="purchase == 'eth'">Token Id</h4>
                  <h4 v-else>Collectible ID</h4>
                  <div class="add_help_copy">
                    <p>{{ collectibleId }}</p>
                    <button
                      class="btn type_ic btn_copy"
                      @click="copy(collectibleId)"
                    >
                      <IcCopy />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_area">
            <a href="/" class="btn basic w_l h_l">Home</a>
            <router-link to="/collections" class="btn strong w_l h_l"
              ><span class="dv_pc">Go to </span>My Collections</router-link
            >
          </div>
        </div>
        <div v-if="type === 'fail'" class="guide_ui">
          <div class="img_area">
            <picture>
              <img
                :src="require('@/assets/img/img_illust_warn.png')"
                alt="No Result"
              />
            </picture>
          </div>
          <h2 class="ttl">Something Went Wrong.</h2>
          <p class="desc">
            We’re sorry, something went wrong. <br />Transaction failed.
            Something is wrong with your wallet. Please try again later. <br />
            <!-- <span class="c_point code">001</span> -->
          </p>
          <div class="btn_area" @click="$router.go(-1)">
            <button class="btn strong w_l h_l">Back to previous Page</button>
          </div>
        </div>
      </div>
    </article>
    <aside v-if="polling" class="progress_sec">
      <div class="progress_area">
        <progress max="100" value="20"></progress>
      </div>
      <div class="set_inner">
        <div class="guide_ui">
          <h3 class="ttl">Payment is in Progress.</h3>
          <div class="img_area">
            <picture>
              <img
                :src="require('@/assets/img/img_illust_nodata.png')"
                alt="nodata"
              />
            </picture>
          </div>
          <p>Do not close your browser until payment&nbsp;is&nbsp;complete.</p>
        </div>
      </div>
    </aside>
  </main>
</template>

<script>
import IcCopy from '@/components/ic/IcCopy';

export default {
  name: 'PurchaseView',
  components: {
    IcCopy,
  },
  data() {
    return {
      type: '',
      address: '',
      collectibleId: '',
      overtimer: 180000,
      polling: null,
      signAddr: '',
      canLeaveSite: false,
      purchase: '',
      params: {},
    };
  },
  mounted() {
    this.signAddr = this.$route.params.signAddr;
    this.purchase = this.$route.params.purchase;

    if (!this.signAddr) {
      this.$router.go(-1);
      return;
    }

    this.params = {
      signAddr: this.signAddr,
    };

    this.polling = setInterval(() => {
      if (this.purchase === 'eth') {
        this.setEth();
      } else if (this.purchase === 'ethfail') {
        this.clear();
      } else if (this.purchase === 'drops') {
        this.dropsPurchasCheck();
      } else {
        this.marketPurchasCheck();
      }
      if (this.overtimer <= 0) {
        this.clear();
      }
      this.overtimer -= 3000;
    }, 3000);
  },
  methods: {
    async dropsPurchasCheck() {
      await this.api.getDropPurchaseCmpltYn(this.params).then(res => {
        if (res.code === 0) {
          if (res.purchaseCmpltYn === 'Y') {
            this.setPurchase(res.nftBcId);
          } else if (res.purchaseCmpltYn === 'N') {
            this.clear();
          }
        } else if (res.code === 1039) {
          //지갑 Cancel
          this.clear();
        } else {
          this.clear();
        }
      });
    },
    async marketPurchasCheck() {
      await this.api.getMarketPurchaseCmpltYn(this.params).then(res => {
        if (res.code === 0) {
          if (res.purchaseCmpltYn === 'Y') {
            this.setPurchase(res.nftBcId);
          } else if (res.purchaseCmpltYn === 'N') {
            this.clear();
          }
        } else if (res.code === 1039) {
          //지갑 Cancel
          this.clear();
        } else {
          this.clear();
        }
      });
    },
    setEth() {
      this.clear();
      this.type = 'done';
      this.address = this.signAddr;
      this.collectibleId = this.$route.params.tokenId;
    },
    setPurchase(nftBcId) {
      this.clear();
      this.type = 'done';
      let addressInfo = nftBcId.split('@');
      this.address = addressInfo[1];
      this.collectibleId = addressInfo[0];
    },
    clear() {
      this.clearAllInterval();
      this.polling = null;
      this.type = 'fail';
    },
    clearAllInterval() {
      let id = window.setInterval(function () {}, 0);
      while (id--) {
        window.clearInterval(id);
      }
    },
    copy(copyValue) {
      const textarea = document.createElement('textarea');
      textarea.textContent = copyValue;
      document.body.append(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();
      this.$toast.open('Saved to clipboard');
    },
  },
  beforeDestroy() {
    this.clearAllInterval();
  },
};
</script>

<style scoped>
.purchase_sec {
  display: flex;
  min-height: calc(var(--min) * 1px);
  align-items: center;
}
.purchase_sec .btn_area {
  margin-top: 70px;
}
.progress_sec {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #000;
}
.progress_area progress {
  height: 0.6rem;
}
.progress_sec .set_inner,
.progress_sec .guide_ui {
  height: 100%;
}
.progress_sec .guide_ui {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.add_help_msg_box {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.add_help_inner {
  background: #252525;
  width: 650px;
  padding: 30px;
}
.add_help_head h3 {
  font-size: 2.7rem;
  margin-bottom: 0.5rem;
}
.add_help_head p {
  text-align: left;
  font-size: 1.8rem;
}
.add_help_content {
  text-align: left;
}
.add_help_content_item {
  margin-top: 3rem;
}
.add_help_content_item h4 {
  font-weight: 300;
  color: #b9b9b9;
}
.add_help_content_item .add_help_copy {
  display: flex;
  margin-top: 0.5rem;
}
.add_help_content_item .add_help_copy p {
  max-width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.6rem;
}
.add_help_content_item .add_help_copy .btn_copy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px !important;
  height: 25px !important;
  margin-left: 0.5rem;
  margin-top: -0.2rem;
}
.add_help_content_item .add_help_copy .btn_copy i {
  width: 1.7rem !important;
  height: 1.7rem !important;
}
@media screen and (max-width: 600px) {
  .purchase_sec .btn_area {
    display: flex;
    margin-top: 5rem;
    width: 100%;
  }
  .purchase_sec .btn_area .btn {
    flex: 1;
    min-width: 0;
    /* max-width: 18rem; */
    padding: 0 1rem;
    width: 50%;
  }
  .add_help_inner {
    width: 100%;
  }
  .add_help_head h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  .add_help_head p {
    text-align: left;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 450px) {
  .purchase_sec .btn_area .btn {
    flex: 1;
    min-width: 0;
    max-width: 50%;
    padding: 0 1rem;
  }
  .purchase_sec .btn_area {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .purchase_sec .btn_area .btn {
    min-width: 0;
  }
}
</style>
