<template>
  <IconBase
    icon-name="icHederaS"
    :icon-label="iconLabel"
    :width="32"
    :height="34"
    viewBox="0 0 32 34"
  >
    <g id="그룹_3882" data-name="그룹 3882" transform="translate(-6 5)">
      <path
        id="패스_3833"
        data-name="패스 3833"
        d="M31.059,8.5a6.938,6.938,0,0,0-2.573-2.518L21.16,1.846,19.52.92a7.186,7.186,0,0,0-7.04,0L3.515,5.981c-.163.092-.32.189-.475.3L3,6.305A6.9,6.9,0,0,0,.8,8.759,6.773,6.773,0,0,0,0,11.938V22.061a6.772,6.772,0,0,0,.726,3.045c.064.132.14.264.214.393a6.849,6.849,0,0,0,.794,1.087q.15.17.312.328a6.891,6.891,0,0,0,1.069.861c.128.084.263.165.4.242l8.968,5.062a7.188,7.188,0,0,0,7.04,0L24,30.547l4.484-2.531c.018-.009.033-.021.051-.031q.083-.049.159-.1a6.916,6.916,0,0,0,2.2-2.121c.079-.121.155-.244.225-.37A6.755,6.755,0,0,0,32,22.063V11.94A6.766,6.766,0,0,0,31.059,8.5Z"
        transform="translate(6 -4.999)"
        fill="#333"
      />
      <path
        id="패스_3812"
        data-name="패스 3812"
        d="M26.6,30.4H24.788V25.96H16.813V30.4H15V16h1.813v4.334h7.975V16H26.6Zm-9.7-6.1h7.974V22H16.9Z"
        transform="translate(1.2 -11.2)"
        fill="#fff"
      />
    </g>
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcHederaS',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'hedera',
    },
  },
};
</script>

<style scoped></style>
