<template>
  <IconBase
    icon-name="icPauseS"
    :icon-label="iconLabel"
    :width="10"
    :height="14"
    viewBox="0 0 10 14"
    icon-color="none"
    :stroke-color="strokeColor"
    :stroke-width="2"
  >
    <path data-name="Rectangle 3688" d="M0 0h2v14H0z" />
    <path data-name="Rectangle 3689" d="M8 0h2v14H8z" />
  </IconBase>
</template>
<script>
import IconBase from '@/components/ic/IconBase';

export default {
  name: 'IcPauseS',
  components: { IconBase },
  props: {
    strokeColor: {
      type: String,
      default: '#fff',
    },
    iconLabel: {
      type: String,
      default: 'Pause',
    },
  },
};
</script>

<style scoped></style>
