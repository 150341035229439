import dayJs from 'dayjs'
export const appStoreActions = {
    settingEmp({commit}, empParam) {
        const stateInfo = {
            accessToken: '',
            refreshToken: '',
            expiredFlag: '',
        };
        const nowDate = dayJs().format('YYYY-MM-DD HH:mm:ss');

        for (const [key, value] of Object.entries(stateInfo)) {
            if(empParam) {
                commit(key, empParam[key]);
            } else {
                commit(key, value);
            }
        }
        
        if(empParam) {
            sessionStorage.setItem('accessTime', nowDate);
        } else {
            sessionStorage.removeItem('accessTime')
            sessionStorage.removeItem('refreshTime')
        }
    }
};