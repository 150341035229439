<template>
  <div class="modal_wrap">
    <div class="modal s type_dialog">
      <div class="modal_body">
        <IcWarn />
        <p class="txt">
          You cannot download 5 or<br />
          more Artworks at a time.
        </p>
      </div>
      <div class="modal_foot">
        <div class="btn_area">
          <button type="button" class="btn h_m strong">OK</button>
        </div>
      </div>
      <button class="btn type_ic close_modal">
        <IcCloseM />
      </button>
    </div>
  </div>
</template>

<script>
import IcWarn from '@/components/ic/IcWarn';
import IcCloseM from '@/components/ic/IcCloseM';
export default {
  name: 'ErrorModal',
  components: { IcWarn, IcCloseM },
};
</script>

<style scoped></style>
